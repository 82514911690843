
import './App.css';

//import Papa from "papaparse"
//import { FiPlus,FiEdit,FiCopy,FiTrash2 } from "react-icons/fi";
import { Icon, createIcon } from '@chakra-ui/react'

import { Menu, MenuButton, MenuList, MenuItem, background } from '@chakra-ui/react'
import { SimpleGrid, GridItem, Text, Box, Stack, VStack, HStack,  Spacer, Flex } from "@chakra-ui/layout";

import { Button } from "@chakra-ui/button";
import { Avatar } from "@chakra-ui/avatar";
import { Image } from "@chakra-ui/image";
import { FormLabel, Switch, Checkbox,Input } from '@chakra-ui/react'
import { FiBell, FiSettings, FiSearch, FiLogOut, FiLogIn, FiMessageSquare, FiHeart } from "react-icons/fi";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'

import i18n from './i18n';
import glovar from './components/content/glovar';

import {BrowserRouter as Router, Routes, Route, useParams} from 'react-router-dom'
import PrivateRoute from './PrivateRoute'

import RegisterApp from './RegisterApp'
import Register from './Register'
import ForgotPassword from './ForgotPassword';

import Login from './Login'
import { useFetchTodoUser } from "./components/utils/useFetchTodoUser";
import { useFetchTodoHistory } from "./components/utils/useFetchTodoHistory";
import { useFetchTodoNewsSearch  } from "./components/utils/useFetchTodoNewsSearch";

import ToptraderList from "./components/content/ToptraderList";
import PortfolioList from "./components/content/PortfolioList";
import PortfolioTopTraderList from "./components/content/PortfolioTopTraderList";
import PortfolioTopTraderListNoeffect from "./components/content/PortfolioTopTraderListNoeffect";

import TrackRecordTopTraderList from  "./components/content/TrackRecordTopTraderList";
import TopTradersRanking from  "./components/content/TopTradersRanking";
import CarrusselTopTraders from  "./components/content/CarrusselTopTraders";
import ContactsList from  "./components/content/ContactsList";

import RSSNewsList from "./components/content/RSSNewsList";
import FavoritosList from "./components/content/FavoritosList";
import MorePopularList from "./components/content/MorePopularList";

import {useState, useEffect} from 'react'
import Homefrontuser from './components/content/homefrontuser';
import AddContentApp from './components/content/AddContentApp';

import eventBus from "./components/eventBus";
import React from 'react';
import BigCardTopTrader from "./components/content/BigCardTopTrader";
import { getToken, getUser, getEmail, removeUserSession,getModeUser, setUserSession, getPortfolio, getFavoritos, getHistorial,
  getporfolio_ED,getTOPTRADERSLIST,setTOPTRADERSLIST,getLastTimeTOPTRADERSLIST, 
  getMarketCAP,
  //isValorFAV,
  //getData,getDataCSV,
  getMOREPOPULAR,getLastTimeMOREPOPULAR, getMYWATCHLIST, getLastTimeMYWATCHLIST, getMYPORTFOLIO,getLastTimeMYPORTFOLIO,
AdComponent728, AdComponent468,AdComponent600, AdComponent320 } from './utils/Common';

  

  import { getMONEDADivisa } from './components/content/glovar'

  import Chart from "./components/content/Chart";
  import ChartCandle from "./components/content/ChartCandle";


  import ReactGA from 'react-ga4';
  import { json } from 'd3-fetch';
  import SearchAssetFront from './components/content/SearchAssetFront';
  import HeaderMenu from './components/content/HeaderMenu';
  import FooterMenu from './components/content/FooterMenu';
  import ShowAssetsSummary from './components/content/ShowAssetsSummary';
  import ShowPopNewsletter from './components/content/ShowPopNewsletter';
  import ShowMiniMenuBacktest from './components/content/ShowMiniMenuBacktest';
  import { Divider } from '@chakra-ui/react'
  import emailjs from "@emailjs/browser"


  import CookieConsentPopup from './components/content/CookieConsentPopup';
  import ShowLegalText from './components/content/ShowLegalText';
  import ShowRadar from './components/content/ShowRadar';


  //const TRACKING_ID = "G-MXC10KRDQD"; // OUR_TRACKING_ID
  const TRACKING_ID = "G-8H3LMBCW9L";
  ReactGA.initialize(TRACKING_ID);



var SHOW_TOPTRADER_PROFILE=null;
var SHOW_TOPTRADER_NO_EFFECT=false;
var TOPTRADER_COTIS=null;
var SHOW_ELEMENT_DATA=null;

//var SHOW_MY_PROFILE=null;
glovar.SHOW_MY_PROFILE=null;



var edit_stock=false;
var DATA_COTIS=null;



export default class App extends React.Component {

 
  state = {
    selectedIndex: null,
    selectedCriteri: null,
    showSearch:false,
    ShowPopNewsLogin:false
  };

  /*handleIndex = (selectedIndex) => {
    this.setState({ selectedIndex }, () =>
      console.log(`Option selected:`, this.state.selectedIndex)
    );
  };

  handleCriteri = (selectedCriteri) => {
    this.setState({ selectedCriteri }, () =>
      console.log(`Option selected:`, this.state.selectedCriteri)
    );
  };*/

  handleIndex = (selectedIndex) => {
    this.setState({ selectedIndex })
  };

  handleCriteri = (selectedCriteri) => {
    this.setState({ selectedCriteri });
  };

  EventGA = (category, action, label) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label
    });
  };
   
  
  constructor() {
    super();
 
    

  
  }

  btndownloadApp(app) {
   if(app==1) window.open(glovar.URL_APPSTORE,"_self")
   if(app==2) window.open(glovar.URL_PLAYSTORE,"_self")
  }

  changeSwitchCarteraOLD()
  {
    //SHOW_PUBLIC_CARTERA=!SHOW_PUBLIC_CARTERA;
    this.setState({});
  }

   getMenus(){
    //console.log("holaaaa")
  }

  changemenu(id)  {

    //console.log("ID_MENU:"+id)
    if(id>200) {
      window.location.replace('/home')
      if(id==201) { glovar.CURRENT_MENU_ID=1; }
      if(id==202) { glovar.CURRENT_MENU_ID=1; }
      if(id==203) { glovar.CURRENT_MENU_ID=1; }
      else { glovar.CURRENT_MENU_ID=1; }
    }
    if(id>100) {
      if(id==101) window.open(i18n.t("url_twitter"),"_blank")
      if(id==102) window.open(i18n.t("url_instagram"),"_blank")
    }
    else {

    if(id==12) {
      window.location.replace('/home')
      glovar.CURRENT_MENU_ID=1; 
      this.setState({});
      return;
    }

    if(id==13) { //menu radar
      window.location.replace('/radar')
      glovar.CURRENT_MENU_ID=1; 
      this.setState({});
      return;
    }
     if(id==3) {
       //subo a MONGO DB
       if(glovar.MODE_USER>=1) {
       
        //window.location.replace('/'+glovar.USER_USERNAME) 
        /*glovar.SHOW_PUBLIC_CARTERA=false;
        glovar.SHOW_PUBLIC_CARTERA_TIT_BENEF=true;

        glovar.SHOW_PUBLIC_HISTORIAL=true;
        glovar.SHOW_PUBLIC_HISTORIAL_TIT_BENEF=true;*/

        glovar.CURRENT_MENU_ID=3;
        this.setState({});
      //console.log("subo a mongo db!!!!!!!!!!")

       /*##################################################*/
       /*# Pugem les dades del usuario al servidor de BD   #*/
       if(1==2) 
       { //ya lo hemos subido una vez
        try 
        {
         var headers = new Headers(); 

         //console.log("glovar.USER_API_POST:"+glovar.USER_API_POST)
         //console.log("glovar.PASS_API_POST:"+glovar.PASS_API_POST)

         headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API_POST + ":" + glovar.PASS_API_POST));
         headers.set('Content-Type', 'application/json');
     
         //glovar.CURRENT_USER=cartera;
         //glovar.current_porfolio_ED=cartera.cartera_ed;
                      
          //console.log(JSON.stringify(headers))

          var carteraPARSED=JSON.stringify(glovar.CURRENT_PORTFOLIO);
          carteraPARSED = carteraPARSED.replace('[', '');
          carteraPARSED = carteraPARSED.replace(']', '');

          var historialPARSED=JSON.stringify(glovar.CURRENT_HISTORIAL);
          historialPARSED = historialPARSED.replace('[', '');
          historialPARSED = historialPARSED.replace(']', '');

         const response = fetch(glovar.URL_BACKEND_PROD+'/api/users', {
               //mode: 'no-cors', //si deixo no-cors no deixa ficar headers
               //method: "POST",
               method: "PUT",  
               headers: headers,
              
               body: 
               JSON.stringify({ 
                rentab: glovar.current_porfolio_ED.rentab,
                name: glovar.USER_USERNAME,//glovar.CURRENT_USER.name,
                idCartera:  glovar.USER_USERNAME, //glovar.CURRENT_USER.idCartera,
                id:  0,//glovar.USER_USERNAME,//glovar.CURRENT_USER.name,//glovar.TOPTRADERS_PRO1[j].id,
                descript: glovar.current_porfolio_ED.descript,//glovar.TOPTRADERS_PRO1[j].descript,
                risk_level: glovar.current_porfolio_ED.risk_level,
                max_level: glovar.current_porfolio_ED.max_level,
                countriesID:glovar.current_porfolio_ED.countriesID,
                activos: glovar.current_porfolio_ED.activos,
                op_cerradas: glovar.current_porfolio_ED.op_cerradas,
                rentmedia_op:glovar.current_porfolio_ED.rentmedia_op,
                sentido: glovar.current_porfolio_ED.sentido,
                temporal: glovar.current_porfolio_ED.temporal,
                historial: historialPARSED,//JSON.stringify(glovar.CURRENT_HISTORIAL),
                cartera: carteraPARSED,//JSON.stringify(glovar.CURRENT_PORTFOLIO),//glovar.TOPTRADERS_PRO1[j].cartera_text,
                //cartera_text:glovar.TOPTRADERS_PRO1[j].cartera_text,
                //cartera:glovar.TOPTRADERS_PRO1[j].cartera,
                capital:glovar.current_porfolio_ED.capital,
                invertido:glovar.current_porfolio_ED.invertido,
                moneda:glovar.current_porfolio_ED.moneda,
                profit:glovar.current_porfolio_ED.profit,
                currency:glovar.current_porfolio_ED.currency,
                priority:glovar.current_porfolio_ED.priority,
                rentab_acum:glovar.current_porfolio_ED.rentab_acum,
                lastDataUpdateCartera_ed:glovar.current_porfolio_ED.lastDataUpdateCartera_ed, 
                lastDataUpdateFavoritos:glovar.current_porfolio_ED.lastDataUpdateFavoritos, 
                lastDataUpdateHistorial:glovar.current_porfolio_ED.lastDataUpdateHistorial,
                lastDataUpdatePortfolio:glovar.current_porfolio_ED.lastDataUpdatePortfolio,
                type_cartera:"0",//glovar.TOPTRADERS_PRO1[j].type_cartera, //por defecto solo la pueden ver los premiums!
                op_win:"0",// glovar.TOPTRADERS_PRO1[j].op_win,
                followers:"0",// glovar.TOPTRADERS_PRO1[j].followers,
                cap_inicial:"0",//glovar.TOPTRADERS_PRO1[j].cap_inicial,
                ranking:"0",//glovar.TOPTRADERS_PRO1[j].ranking,
                FOLLOWERS_LIST:"0"//:glovar.TOPTRADERS_PRO1[j].FOLLOWERS_LIST
  
           //id:"1",//glovar.TOPTRADERS_PRO1[j].id,
           //descript: "que pasa nen me voy de subidon!"
              })
         }) 

         //console.log("pujat correctament dades user OK1 ");  //+glovar.CURRENT_USER.name)
        }
        catch(Exx) { /*console.log("PETADA pujat dades user: "+Exx)*/ }
      
      
         /*.then(response => response.json())
            .then(json => {
                  console.log("pujat correctament toptrader:"+glovar.TOPTRADERS_PRO1[j].name)
         });*/
        }
        /*##################################################*/
        /*##################################################*/
       }
       else {
        //console.log("para subir debes haber iniciado sesión")
       }

     }
     else { 
     glovar.CURRENT_MENU_ID=id;
    
    //per evitar que peti si no hem visualitzat mai un toptrader

    try {
     if(id==2  && !SHOW_TOPTRADER_PROFILE && glovar.TOPTRADERSLIST.length>1) {
       SHOW_TOPTRADER_PROFILE=glovar.TOPTRADERSLIST[0]
     }
    }
    catch(exxxx) {}
    //setrefresh(id);
    this.setState({});
  }
  }
  }

  logout() {
    
    glovar.USER_LOADED=false;
    glovar.MODE_USER=0;
    glovar.current_porfolio_ED=null;
    removeUserSession();
    
   
   this.setState({ email: "",
   password: ""});
  }
  

  loadFBData(data) {
    //this.getFBDocs(db);
    //this.getTopTradersLeague(db);
    //this.getRadar(db);
    this.setState({});
  }

  /*  
  async getFBDocs(db) {


  
    //const docRef1 = doc(db, "general", "versions");
    //const docVERSIONS = await getDoc(docRef1);
    
    
    //console.log("glovar.USER_USERNAME:"+glovar.USER_USERNAME);
    glovar.CURRENT_USER=null;
    const queryUser = doc(db, "users", glovar.USER_USERNAME);
    
    //Seguimos manteniendo el user_anom pq no tenemos cartera, favoritos ni historial en el server
  
    //const queryUser = doc(db, "users", glovar.USER_USERNAME);
    
  
    //console.log("antes del doc")
    const docUser = await getDoc(queryUser);
    //console.log("ya tengo el doc!!!")
  
   
    try { glovar.APP_STATE_STATE=docUser.data().appState; } catch(Exxx) { glovar.APP_STATE_STATE="none"; }
    try 
    { 
     if(glovar.APP_STATE_STATE==glovar.APP_STATE_ACTIVE) {  glovar.APP_STATE_RUNNING=true; }
    }
    catch(Exxxs) {}  
  
    
    {
      //console.log('#CARTERA PREEEEEEEEEEMIUM!!!!!!!!!!');
      //console.log(docUser.data().id, " => ", docUser.data().data());
    
      var SHOW_CARTERA=0;
     
      const lang =glovar.APP_LANG;
      var NEW_PRIORITY=-1;
      SHOW_CARTERA=1;
    
      //console.log('(docUser.data().get(lang):'+docUser.data().get('lang'));
    
        
    
      //if(SHOW_CARTERA==1 || docUser.data().get('priority')<1000)
      {
    
       var cartera = {
        rentab: 0,
        name: 'GLOBAL STOCKS',
        idCartera: 'ID_GLOBAL',
        descript: 'Global stock portfolio description',
        risk_level: 3,
        max_level: 5,
        countriesID: ' ',
        activos: '1,2,3',
        op_cerradas: 26,
        rentmedia_op: 13.27,
        sentido: 1,
        temporal: 1,
        historial:'',
        cartera_text:'',
        cartera_ed:[],
        cartera:[],
        favoritos:[], 
        capital:0,
        invertido:0,
        moneda:' ',
        profit:0,
        priority:0,
        rentab_acum:0,
        type_cartera:2, //por defecto solo la pueden ver los premiums!
        op_win: 8,
        followers: 333,
        cap_inicial:1000000,
        ranking:1
      };
    
    
     
      if(NEW_PRIORITY>=0)
      {
       cartera.priority=NEW_PRIORITY;
      }
      else {
       try { cartera.priority=docUser.data().get('priority'); } catch(Exxx) {}
      }
     
    
    
      try { cartera.rentab=docUser.data().rentab; } catch(Exxx) {}
    
    
      try {
       if (glovar.APP_FORCE_LANG=='ES') {  cartera.name=docUser.data().name; }
       else { cartera.name=docUser.data().name_en; }
      }
      catch(car1) { try { cartera.name=docUser.data().name; } catch(Exxx) {}}
      //cartera.name=docUser.data().name');
    
     
      try { cartera.idCartera=docUser.data().idCartera; } catch(Exxx) {}
    
      try {
      if (glovar.APP_FORCE_LANG=='ES') {  cartera.descript=docUser.data().descript; }
      else { cartera.descript=docUser.data().descript_en; }
     }
     catch(car1) { try { cartera.descript=docUser.data().descript; } catch(Exxx) {} }
    
   
      //cartera.descript=docUser.data().descript');
    
      try { cartera.cartera_ed=docUser.data().cartera_ed; } catch(Exxx) {}
  
  
      try {
  
        cartera.favoritos=docUser.data().favoritos;
        
      cartera.risk_level=docUser.data().risk_level;
      cartera.max_level=docUser.data().max_level;
      cartera.countriesID=docUser.data().countriesID;
    
      cartera.activos=docUser.data().activos;
      cartera.op_cerradas=docUser.data().op_cerradas;
      cartera.rentmedia_op=docUser.data().rentmedia_op;
      cartera.sentido=docUser.data().sentido;
      cartera.temporal=docUser.data().temporal;
      cartera.historial=docUser.data().historial;
      cartera.cartera_text=docUser.data().cartera;
    
      cartera.cartera=docUser.data().cartera;
  
      cartera.capital=docUser.data().capital;
      cartera.invertido=docUser.data().invertido;
      cartera.moneda=docUser.data().moneda;
      cartera.profit=docUser.data().profit;
      cartera.rentab_acum=docUser.data().rentab_acum;
    } catch(Exxx) {}
  
      try { cartera.type_cartera=docUser.data().type_cartera_android; if(cartera.type_cartera==null) { cartera.type_cartera=docUser.data().type_cartera; } }
      catch (exxxx) { try { cartera.type_cartera=docUser.data().type_cartera; } catch(Exxx) {} }
    
      //User banneado de carteras FREE con ads
      if(glovar.USER_BANNED_TYPE_CARTERA==1)
      {
       if(cartera.type_cartera>2) { cartera.type_cartera='2'; }
      }
     try {
      cartera.acciones=docUser.data().acciones;
      cartera.coti=docUser.data().coti;
    
      cartera.op_win=docUser.data().op_win;
      cartera.followers=docUser.data().followers;
      cartera.ranking=docUser.data().ranking;
      cartera.id=docUser.data().id;
    } catch(Exxx) {}
      //Falta comprobarlo con la BD
      cartera.subscribed=false;
    
      
    
      try 
      {
       if(glovar.listTopTradersSUBS!=null && glovar.listTopTradersSUBS.length>0)
       {
        for(let z=0; z<glovar.listTopTradersSUBS.length; z++)
        {
         try { if(glovar.listTopTradersSUBS[z].id==cartera.id) { 
           cartera.subscribed=true;
           //console.log('TROBAT SUBSCRIBED!!!')
           break } } catch(Exxxx) {}
        }
       }
      }
      catch(Exxxxa) {}
      //console.log('cartera.type_cartera:'+cartera.type_cartera);
    
      //const ID_CARTERA=docUser.data().idCartera');
    
    
      //glovar.TOPTRADERS_PRO1.push(cartera);
      glovar.CURRENT_USER=cartera;
  
      glovar.current_porfolio_ED=cartera.cartera_ed;
  
      //console.log("vencimiento web:"+docUser.data().vencimiento_web)
      //console.log('#countriesID:'+docUser.get('countriesID'));
      //console.log('#014"op_cerradas:'+docUser.data().get('op_cerradas'));
      //console.log('#### 0 TOP TRADERS!!!!!!!!:'+cartera.idCartera+' '+cartera.subscribed);
      
      //nom=glovar.TOPTRADERS_PRO1[0].idCartera//cartera.idCartera;
      //this.setState({});
     
      
     }
     }//);
  
     
     var ARRAY_COTIS="";
     glovar.CURRENT_FAVORITOS=null;
     glovar.CURRENT_FAVORITOS = [];
  
     glovar.CURRENT_PORTFOLIO=null;
     glovar.CURRENT_PORTFOLIO = [];
  
     glovar.CURRENT_HISTORIAL=null;
     glovar.CURRENT_HISTORIAL = [];
  
     let j=0;
     //for(let j=0;j<glovar.TOPTRADERS_PRO1.length;j++)
     {
      //if(glovar.TOPTRADERS_PRO1[j].idCartera==glovar.CURRENT_TRADER)
      try 
      {
        //console.log("SE CUMPLE LA CONDICION!!!")
        //glovar.CURRENT_HISTORIAL=glovar.CURRENT_USER.historial.split('},');
        glovar.CURRENT_HISTORIAL=glovar.CURRENT_USER.historial;
      }
      catch(Exxx) {}
     }
  
     //for(let j=0;j<glovar.TOPTRADERS_PRO1.length;j++)
     {
      
  
      //console.log("Hola 0000")
      glovar.CURRENT_FAVORITOS=glovar.CURRENT_USER.favoritos;
      //console.log("Hola 0001:"+glovar.CURRENT_USER.favoritos)
        
  
     }
  
     try 
     {
       
       glovar.CURRENT_PORTFOLIO=glovar.CURRENT_USER.cartera;
       
     
     }
     catch(Exxx) {}
  
     
     glovar.FIREBASE_LOADED=1;
    }*/

  componentDidMount() {

    console.log("EJECUTO DID MOUNT!!!!!!!!1")
    /*
    const currentPath = window.location.pathname;
    console.log('Ruta específicaDidMount:', currentPath);
    if(currentPath=='/home') {
      glovar.CURRENT_MENU_ID=1;
    }
    else {
      glovar.CURRENT_MENU_ID=5;
    }

    glovar.CURRENT_MENU_ID=1;
    */

    /*window.addEventListener('load', function() {
      // Verificar el estado del historial
      if (this.window.history.state === null) {
        // Carga inicial de la página
        console.log('Carga inicial de la página');
      } else {
        // Llamada a replace("/")
        console.log('Llamada a replace("/")');
        this.changemenu(201);
      }
    });*/
    /*<script type="text/javascript">
	atOptions = {
		'key' : 'f29a724eac91492e19bb63a483d5527b',
		'format' : 'iframe',
		'height' : 60,
		'width' : 468,
		'params' : {}
	};
	document.write('<scr' + 'ipt type="text/javascript" src="http' + (location.protocol === 'https:' ? 's' : '') + '://www.effectivecreativeformat.com/f29a724eac91492e19bb63a483d5527b/invoke.js"></scr' + 'ipt>');
</script>*/

    /*try { var scr1 = document.createElement("script");
    scr1.innerHTML = `
    var scr = document.createElement("script");
    var atOptions = {
      'key' : 'f29a724eac91492e19bb63a483d5527b',
      'format' : 'iframe',
      'height' : 60,
      'width' : 468,
      'params' : {}
      }
      scr.src =
      "http" +
      (location.protocol === "https:" ? "s" : "") +
      "://www.effectivecreativeformat.com/f29a724eac91492e19bb63a483d5527b/invoke.js";
      document.querySelector("#_MainOuterIndexJs").appendChild(scr);
    `;
    document.querySelector("#_MainOuterIndexJs").appendChild(scr1);
    }
    catch(Exxx) {console.log("petada:"+Exxx)}*/

    /*getData().then(data => {
			DATA_COTIS=data;
      //console.log(JSON.stringify(DATA_COTIS))
		})*/

    
    //getDataCSV("META");
    //console.log("results:"+results)

    /*getDataCSV().then(data => {
			console.log(data);
      //console.log(JSON.stringify(DATA_COTIS))
		})*/

    document.title="Pro1X"

    document.icon="assets/logopro1xsmall.png"

    

    try { 
      //var userLang = navigator.language || navigator.userLanguage; 
      var userLang = window.navigator.userLanguage || window.navigator.language;
      //alert(userLang); //works IE/SAFARI/CHROME/FF
      if(userLang.includes('es-') || userLang =="es") { glovar.USER_LANG_PRO1="ES" } 
      else if(userLang.includes('fr-') || userLang =="fr") { glovar.USER_LANG_PRO1="FR" } 
      else if(userLang.includes('de-') || userLang =="de") { glovar.USER_LANG_PRO1="DE" } 

    }
    catch(Exxxx) {}

    

    //var userLang = navigator.language || navigator.userLanguage; 
    //console.log("userLang:"+userLang)
    //console.log("navigator.userAgentData:"+JSON.stringify(navigator.userAgentData))


    glovar.COOKIE_ACCEPTED=localStorage.getItem(glovar.COOKIE_KEY_ACCEPTED)
    glovar.COOKIE_ACCEPTED_ANALYTICS=localStorage.getItem(glovar.COOKIE_KEY_ACCEPTED_ANALYTICS)
    glovar.COOKIE_ACCEPTED_ADVERTISING=localStorage.getItem(glovar.COOKIE_KEY_ACCEPTED_ADVERTISING)
    glovar.COOKIE_REJECTED=localStorage.getItem(glovar.COOKIE_KEY_REJECTED)


    glovar.DEFAULT_INDEX_TABS_FOLLOWS=0;
    glovar.REFRESH_FRONTUSER=false;
    glovar.DONT_REFRESH_FETCH_TODO_USER=false;

    try {
      const token = getToken();
      if (token) 
      {
        //console.log("token:"+token)
        glovar.USER_LOADED=true;
       }
      }
      catch(Exxxs) {}
  
      try {
      const userSession = getUser();
      if (userSession) //&& glovar.USER_USERNAME=='Anonimous') 
      {
        //console.log("userSession:"+userSession)
        glovar.USER_USERNAME=userSession;
       }
      }
      catch(Exxx) {}
  
      try {
        const userEmail = getEmail();
        if (userEmail)// && glovar.USER_EMAIL=='Anonimous') 
        {
          //console.log("userEmail:"+userEmail)
          glovar.USER_EMAIL=userEmail;
         }
        }
        catch(Exxx) {}

        try {
          const modeUser = getModeUser();

          //console.log("glovar.MODE_USER:"+glovar.MODE_USER)
          //if(glovar.CURRENT_FAVORITOS) { console.log("glovar.CURRENT_FAVORITOS.length:"+glovar.CURRENT_FAVORITOS.length); }
          
          if (modeUser && glovar.MODE_USER==0) 
          {
            try { glovar.MODE_USER=parseInt(modeUser); } catch(ex) {glovar.MODE_USER=modeUser;}
           }
           //console.log("modeUser:"+modeUser)

           /*
           //if(glovar.MODE_USER>=1) 
           {
            this.getFBDocs(db).then(() => 
            {
              console.log("finalizado ok!!!!!!!!!")
              this.setState({});
          //eventBus.dispatch("refreshDB", { message: "coupone applied" });
          //navigate('/')
         
        })
        .catch((error) => {
          console.error("petada:"+error);
          //eventBus.dispatch("refreshDB", { message: "coupone applied" });
          //navigate('/')
        });
           }*/
          }
          catch(Exx) {}

          try {

            try {
              //console.log("lleno TOPTRADERLIST")
            const toptraderList = getTOPTRADERSLIST();
            if (toptraderList)// && glovar.USER_EMAIL=='Anonimous') 
            {
              glovar.lastTimeTOPTRADERSLIST=getLastTimeTOPTRADERSLIST();
             //console.log("si que hay guarado!!!")
              //console.log("getLastTimeTOPTRADERSLIST:"+glovar.lastTimeTOPTRADERSLIST)
              var TimeNow=Date.now();
              var difNow=TimeNow-glovar.lastTimeTOPTRADERSLIST;
              //console.log("han pasado los milis:"+difNow)
              //console.log("1glovar.MAXMILIS_REFRESH_TOPTRADERSLIST:"+glovar.MAXMILIS_REFRESH_TOPTRADERSLIST)

              if(difNow<glovar.MAXMILIS_REFRESH_TOPTRADERSLIST) {
                glovar.TOPTRADERSLIST=JSON.parse(toptraderList);
                //console.log("utilizo cache!!")
                
              }
              else {
                //console.log("fuerzo download from server")
                //glovar.TOPTRADERSLIST=null
              }
              
              
              //console.log("cargo lista toptraders")
             }
             else {
               //console.log("no hay nada guardado TOPTRADERSLIST")
             }
            }
            catch(Exx) {}


            try {
              
            const morepularList = getMOREPOPULAR();
            if (morepularList)// && glovar.USER_EMAIL=='Anonimous') 
            {
              glovar.lastTimeMOREPOPULAR_COTIS=getLastTimeMOREPOPULAR();
             //console.log("si que hay guarado!!!")
              //console.log("glovar.lastTimeMOREPOPULAR_COTIS:"+glovar.lastTimeMOREPOPULAR_COTIS)
              var TimeNow=Date.now();
              var difNow=TimeNow-glovar.lastTimeMOREPOPULAR_COTIS;
              //console.log("han pasado los milis:"+difNow)
              //console.log("1glovar.MAXMILIS_REFRESH_MORE_POPULAR:"+glovar.MAXMILIS_REFRESH_MORE_POPULAR)

              if(difNow<glovar.MAXMILIS_REFRESH_MORE_POPULAR) {
                glovar.MOREPOPULAR_COTIS=JSON.parse(morepularList);
                //console.log("utilizo cache   MORE POPULAAR!!")
                
              }
              else {
                //console.log("fuerzo download from server")
                //glovar.TOPTRADERSLIST=null
              }
              
              
              //console.log("cargo lista toptraders")
             }
             else {
               //console.log("no hay nada guardado TOPTRADERSLIST")
             }
            }
            catch(Exx) {}

             
           //console.log("1MODE USER:"+glovar.MODE_USER) 
           //console.log("2MODE USER:"+glovar.MODE_USER) 
           //console.log("3MODE USER:"+glovar.MODE_USER) 
           //console.log("4MODE USER:"+glovar.MODE_USER) 
           //console.log("5MODE USER:"+glovar.MODE_USER) 
           if(glovar.MODE_USER>=1)
           {
          
            try {
              
              const mywatchlistList = getMYWATCHLIST();
              if (mywatchlistList)// && glovar.USER_EMAIL=='Anonimous') 
              {
                glovar.lastTimeMYWATCHLIST_COTIS=getLastTimeMYWATCHLIST();
               //console.log("si que hay guarado!!!")
                //console.log("glovar.lastTimeMOREPOPULAR_COTIS:"+glovar.lastTimeMOREPOPULAR_COTIS)
                var TimeNow=Date.now();
                var difNow=TimeNow-glovar.lastTimeMYWATCHLIST_COTIS;
                //console.log("han pasado los milis:"+difNow)
                //console.log("1glovar.MAXMILIS_REFRESH_MORE_POPULAR:"+glovar.MAXMILIS_REFRESH_MORE_POPULAR)
  
                if(difNow<glovar.MAXMILIS_REFRESH_MY_WATCHLIST) 
                {
                  glovar.MYWATCHLIST_COTIS=JSON.parse(mywatchlistList);
                  //console.log("utilizo cache   MORE POPULAAR!!")
                  
                }
                else {
                  //console.log("fuerzo download from server")
                  //glovar.TOPTRADERSLIST=null
                }
                
                
                //console.log("cargo lista toptraders")
               }
               else {
                 //console.log("no hay nada guardado TOPTRADERSLIST")
               }
              }
              catch(Exx) {}

              try {
              
                const myportfolioList = getMYPORTFOLIO();
                if (myportfolioList)// && glovar.USER_EMAIL=='Anonimous') 
                {
                  glovar.lastTimeMYPORTFOLIO_COTIS=getLastTimeMYPORTFOLIO();
                 //console.log("si que hay guarado!!!")
                  //console.log("glovar.lastTimeMYPORTFOLIO_COTIS:"+glovar.lastTimeMYPORTFOLIO_COTIS)
                  var TimeNow=Date.now();
                  var difNow=TimeNow-glovar.lastTimeMYPORTFOLIO_COTIS;
                  //console.log("han pasado los milis:"+difNow)
                  //console.log("glovar.MAXMILIS_REFRESH_MY_PORTFOLIO:"+glovar.MAXMILIS_REFRESH_MY_PORTFOLIO)
    
                  if(difNow<glovar.MAXMILIS_REFRESH_MY_PORTFOLIO) {
                    glovar.MYPORTFOLIO_COTIS=JSON.parse(myportfolioList);
                    //console.log("refresco glovar.MYPORTFOLIO_COTIS:"+JSON.stringify(myportfolioList))
                  }
                  else {
                  }
                 }
                 else {
                   //console.log("no hay nada guardado TOPTRADERSLIST")
                 }
                }
                catch(Exx) {}

              

            
            const userFavoritos = getFavoritos();
            if (userFavoritos)// && glovar.USER_EMAIL=='Anonimous') 
            {
             //console.log("1userFavoritos:"+userFavoritos)
             glovar.CURRENT_FAVORITOS=JSON.parse(userFavoritos);
             console.log("userfAVORITOS:"+JSON.stringify(userFavoritos));
            }

            const userPortfolio = getPortfolio();
        if (userPortfolio)// && glovar.USER_EMAIL=='Anonimous') 
        {
          //console.log("userPortfolio:"+JSON.stringify(userPortfolio))
          //console.log("userPortfolio:"+userPortfolio)
          //let userPortfolio1=JSON.stringify(userPortfolio);
          glovar.CURRENT_PORTFOLIO=JSON.parse(userPortfolio);
          
         }

         const userHistorial = getHistorial();
         if (userHistorial)// && glovar.USER_EMAIL=='Anonimous') 
         {
           //console.log("userPortfolio:"+JSON.stringify(userPortfolio))
           //console.log("userPortfolio:"+userPortfolio)
           //let userPortfolio1=JSON.stringify(userPortfolio);
           glovar.CURRENT_HISTORIAL=JSON.parse(userHistorial);
          }

      

         const userPortfolioED = getporfolio_ED();
         if (userPortfolioED)// && glovar.USER_EMAIL=='Anonimous') 
         {
           //console.log("userPortfolioED:"+JSON.stringify(userPortfolioED))
           //console.log("userPortfolio:"+userPortfolio)
           //let userPortfolio1=JSON.stringify(userPortfolio);
           glovar.current_porfolio_ED=JSON.parse(userPortfolioED);
          }
          else {
            //onsole.log("no hay nada guardado")
          }

          
        
          

         this.setState({})


          }
            
           

          }
          catch(Exx) { /*console.log("petada al parsear:"+Exx)*/}


          //eventBus.dispatch("btnFollow_m0", { data: glovar.USER_LANG_PRO1  });

          //eventBus.dispatch("EventGA", { data:  { category, action, label }});


          try {
            eventBus.on("EventGA", (data) =>
            {
              //console.log(JSON.stringify(data))
              //console.log(data.category);
            try { this.EventGA(data.category,data.action,data.label); } catch (exx) { /*console.log("petada event:"+exx)*/}
            }
            
          );
          }
          catch(Exxx) {}


          try {
            eventBus.on("btnNewsLT", (data) =>
            {
              //console.log(JSON.stringify(data))
            try { this.EventGA("click_element","btnNewsLT_"+data.data, "btnNewsLT"); } catch (exx) { /*console.log("petada event:"+exx)*/}
            }
            //console.log("refreshDB")
          );
          }
          catch(Exxx) {}

          
          try {
            eventBus.on("btnRunBT", (data) =>
            {
              //console.log(JSON.stringify(data))
            try { this.EventGA("click_element","btnRunBT_"+data.data, "btnRunBT"); } catch (exx) { /*console.log("petada event:"+exx)*/}
            }
            //console.log("refreshDB")
          );
          }
          catch(Exxx) {}

          try {
            eventBus.on("btnPropBT", (data) =>
            {
              //console.log(JSON.stringify(data))
            try { this.EventGA("click_element","btnPropBT_"+data.data, "btnPropBT"); } catch (exx) { /*console.log("petada event:"+exx)*/}
            }
            //console.log("refreshDB")
          );
          }
          catch(Exxx) {}

        
          try {
            eventBus.on("btnFINDst", (data) =>
            {
            try { this.EventGA("click_element","btnFINDst_"+data.data, "btnFINDst"); } catch (exx) { }
            }
          );
          }
          catch(Exxx) {}

          try {
            eventBus.on("fullSizeChart", (data) =>
            {
              //console.log("dispatch FULLSIZECHART!")
              glovar.CURRENT_MENU_ID=6;
              this.setState({ currentTicker: data});
  

              //this.setState({})
              //window.location.replace('/') 
              
              //console.log(JSON.stringify(data))
            //try { this.EventGA("click_element","btnNewsLT_"+data.data, "btnNewsLT"); } catch (exx) { /*console.log("petada event:"+exx)*/}
            }
            //console.log("refreshDB")
          );
          }
          catch(Exxx) {}


          try {
            eventBus.on("backToGridChart", (data) =>
            {
              //console.log("dispatch BACKTOGRIDCHART!")
              glovar.CURRENT_MENU_ID=4;
              this.setState({ currentTicker: data});

              //this.setState({})
              //window.location.replace('/') 
              
              //console.log(JSON.stringify(data))
            //try { this.EventGA("click_element","btnNewsLT_"+data.data, "btnNewsLT"); } catch (exx) { /*console.log("petada event:"+exx)*/}
            }
            //console.log("refreshDB")
          );
          }
          catch(Exxx) {}
          



       



          try {
            eventBus.on("btnFollow_m0", (data) =>
            {
              //console.log(JSON.stringify(data))
            try { this.EventGA("click_element","btnFollowM0_"+data.data, "btnFollow_m0"); } catch (exx) { /*console.log("petada event:"+exx)*/}
            }
            //console.log("refreshDB")
          );
          }
          catch(Exxx) {}


          //eventBus.dispatch("btnFollow_m1", { data1: glovar.USER_USERNAME, data2: TOPTRADER.name   });
          try {
            eventBus.on("btnFollow_m1", (data) =>
            {
              //console.log(JSON.stringify(data))
            try { this.EventGA("click_element","btnFollowM_"+glovar.MODE_USER, "btnFollowM"+glovar.MODE_USER); } catch (exx) { /*console.log("petada event:"+exx)*/}
            try { this.EventGA("click_element","btnFollowM_"+glovar.MODE_USER+"_"+data.data1+"_"+data.data2, "btnFollowM"); } catch (exx) { /*console.log("petada event:"+exx)*/}
            }
            //console.log("refreshDB")
          );
          }
          catch(Exxx) {}


          try {
            eventBus.on("loginUser", (data) =>
            //this.loadFBData(data)
            //this.loadFBData(data) 
            //this.showRefreshHomefront()
           
            {
              //console.log(JSON.stringify(data))
            try { this.EventGA("click_element","LOGINOK_"+data.data, "loginOK"); } catch (exx) { /*console.log("petada event:"+exx)*/}
            }
            //console.log("refreshDB")
          );
          }
          catch(Exxx) {}
        


          try {
            eventBus.on("RSSnew", (data) =>
            //this.loadFBData(data)
            //this.loadFBData(data) 
            //this.showRefreshHomefront()
           
            {
              //console.log(JSON.stringify(data))
            try { this.EventGA("click_element","RSSnew_"+glovar.USER_LANG_PRO1+"_"+data.data, "RSSnew"); } catch (exx) { /*console.log("petada event:"+exx)*/}
            }
            //console.log("refreshDB")
          );
          }
          catch(Exxx) {}
        
          try {
            eventBus.on("showRefreshHomefront", (data) =>
            //this.loadFBData(data)
            //this.loadFBData(data) 
            this.showRefreshHomefront()
            //console.log("refreshDB")
          );
          }
          catch(Exxx) {}


    try {
      eventBus.on("refreshDB", (data) =>
      //this.loadFBData(data)
      this.loadFBData(data) 
      //console.log("refreshDB")
    );
    }
    catch(Exxx) {}
    
    try {
      eventBus.on("gotoTopTraders", (data) =>
      {
        this.changemenu(2);
      //this.showTopTraderPortfolio(data.tt)

      //try { this.EventGA("carroTopTr","carroTopTr_"+glovar.USER_LANG_PRO1, data.tt.name); } catch (exx) { /*console.log("petada event:"+exx)*/}
      //try { this.EventGA("carroTopTr","carroTopTr_"+data.sym+"to "+data.tt.name, data.tt.name); } catch (exx) { /*console.log("petada event:"+exx)*/}



      //console.log("data.sym:"+data.sym)
      }
    );
    }
    catch(Exxx) {}


    try {
      eventBus.on("showTopTraderfromCarr", (data) =>
      {
      this.showTopTraderPortfolio(data.tt)

      try { this.EventGA("carroTopTr","carroTopTr_"+glovar.USER_LANG_PRO1, data.tt.name); } catch (exx) { /*console.log("petada event:"+exx)*/}
      try { this.EventGA("carroTopTr","carroTopTr_"+data.sym+"to "+data.tt.name, data.tt.name); } catch (exx) { /*console.log("petada event:"+exx)*/}



      //console.log("data.sym:"+data.sym)
      }
    );
    }
    catch(Exxx) {}


    try {
      eventBus.on("showTopTrader", (data) =>
      this.showTopTraderPortfolio(data.tt)
      //console.log("que pasa neeeeeeeeeeeeeeen")
    );
    }
    catch(Exxx) {}

    try {
      eventBus.on("showElement", (data) =>
      this.showElement(data.el)
      //console.log("que pasa neeeeeeeeeeeeeeen")
    );
    }
    catch(Exxx) {}

    try {
      eventBus.on("showTopTrader2", (data) =>
      this.showTopTraderPortfolio2(data.tt)
      //console.log("que pasa neeeeeeeeeeeeeeen")
    );
    }
    catch(Exxx) {}

    //console.log("intento cargar la lista de toptraders")
    if(!(glovar.TOPTRADERSLIST.length>1)) 
    {
   //console.log("cargo la lista de toptradersXXX")
    let headers = new Headers(); 
    headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API + ":" + glovar.PASS_API));
   
  fetch(glovar.URL_BACKEND_PROD+'/api/toptraders', { method:'GET', //mode: 'no-cors',
 //fetch('https://p1tback-production.up.railway.app/api/toptraders',{ method:'GET', //mode: 'no-cors',
 headers: headers,
 //credentials: 'user:passwd'
})
.then(res => res.json())
.then(res => {
  //console.log("resposta toptraders:"+res);
  glovar.TOPTRADERSLIST=res;

  //Aqui tenemos que ordenarlo por rent.
  try { glovar.TOPTRADERSLIST.sort(function(a,b){ return parseFloat(b.rentab)  - parseFloat(a.rentab);}) }
  catch(Exxpp) {}

  setTOPTRADERSLIST(glovar.TOPTRADERSLIST);
  //console.log(res)
  //for(let ii=0; ii<res.length; ii++) { console.log("TOPTRADER: "+res[ii].name) }
});
    }

 //var isFetching=true;
 //{glovar.TOPTRADERSLIST, isFetching} = useFetchTodoToptraders();//"IAG.MC,EURUSD=X")


    const uniqueIds = [];
  
  /*const todoRSSUnique = todoRSS.filter(element => {
    const isDuplicate = uniqueIds.includes(element.link);

    if (!isDuplicate) {
      uniqueIds.push(element.link);

      return true;
    }

    return false;
  });*/

 

 //todoRSS.sort(function(a,b){ return new Date(b.pubDate) - new Date(a.pubDate); });  
  /*if (!isFetching) { 
    console.log("holaaa")
    todoRSSUnique.sort(function(a,b){ return new Date(b.pubDate) - new Date(a.pubDate); });   
    console.log("holaaa:"+todoRSSUnique.length)
  }*/
  }

  showRefreshHomefront() {
    glovar.REFRESH_FRONTUSER=true;
    this.setState({})
  }

  showElement(data) {

    try { this.EventGA("click_element","showElement_MAIN", data.name); } catch (exx) { /*console.log("petada event:"+exx)*/}

    try {
      glovar.CURRENT_MENU_ID=4;
      //SHOW_TOPTRADER_NO_EFFECT=false;
      SHOW_ELEMENT_DATA=data;
  
    //console.log(data.pos)
    /*
  this.refreshCARTERACotis2(CARTERA_INFO_CARTERA).then(response => {
    glovar.TOPTRADERS_PRO1[data.pos].CARTERA_INFO_CARTERA=response;*/
  //console.log("xxxfinal refreshCARTERACotis2");
  this.setState({});
  //
  
    }
    catch(Esss) {/*No mostramos cartera*/}
    
    //console.log(SHOW_PORTFOLIO_TOPTRADER_POS)
    
  }


  showTopTraderPortfolio(data) {

    try { this.EventGA("click_element","showTopTraderPortfolio_MAIN", data.name); } catch (exx) { /*console.log("petada event:"+exx)*/}

    //var audio = new Audio('assets/0now.mp3');
    //audio.play()

    try {
      glovar.CURRENT_MENU_ID=2;
      SHOW_TOPTRADER_NO_EFFECT=false;
      SHOW_TOPTRADER_PROFILE=data;
  
    //console.log(data.pos)
    /*
  this.refreshCARTERACotis2(CARTERA_INFO_CARTERA).then(response => {
    glovar.TOPTRADERS_PRO1[data.pos].CARTERA_INFO_CARTERA=response;*/
  //console.log("xxxfinal refreshCARTERACotis2");
  this.setState({});
  //
  
    }
    catch(Esss) {/*No mostramos cartera*/}
    
    //console.log(SHOW_PORTFOLIO_TOPTRADER_POS)
    
  }

 

  showTopTraderPortfolio2(data) {

    try { this.EventGA("click_element","showTopTraderPortfolio_RANKING", data.name); } catch (exx) { /*console.log("petada event:"+exx)*/}

    //var audio = new Audio('assets/0now.mp3');
    //audio.play()

    try {

 
  var todoPortfolio=JSON.parse("["+data.cartera+"]")

  
    //console.log("showTopTraderPortfolio2")
    //console.log(todoPortfolio)

    var ARRAY_PORT_COTIS="";
    for(let j=0; j<todoPortfolio.length; j++) 
    {
      if(todoPortfolio[j].symbol) {
        ARRAY_PORT_COTIS=ARRAY_PORT_COTIS+todoPortfolio[j].symbol+","
      }
    }
    var todoCotisPortfolio

    (async () => {
      //let response = await fetch('/article/promise-chaining/user.json');
      //let user = await response.json();
      //console.log("que pasa neeeeeeeeeeeen")
      //let githubResponse = await fetch('https://thingproxy.freeboard.io/fetch/https://query2.finance.yahoo.com/v7/finance/quote?symbols='+ARRAY_PORT_COTIS);

      //NewVersion
      let headers = new Headers(); 
      headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API_COTIS + ":" + glovar.PASS_API_COTIS));
      headers.set('Parameters',btoa(ARRAY_PORT_COTIS));

      let githubResponse = await fetch(glovar.URL_FETCH_COTIS,{ method:'GET', headers: headers })
      let githubUser = await githubResponse.json();
      todoCotisPortfolio=githubUser;
      //Old version
      /*let githubResponse = await fetch(glovar.URL_FETCH_COTIS+ARRAY_PORT_COTIS);
      let githubUser = await githubResponse.json();
      //console.log("final awaaaaaaaaaaaaaat")
      todoCotisPortfolio=githubUser.quoteResponse.result;
      */

      //console.log("isFetchingCotis11:"+JSON.stringify(todoCotisPortfolio))
      glovar.CURRENT_MENU_ID=2;
      TOPTRADER_COTIS=todoCotisPortfolio;
      SHOW_TOPTRADER_NO_EFFECT=true;
      SHOW_TOPTRADER_PROFILE=data;
      this.setState({});
    })();

    //var todoCotisPortfolio=showAvatar(ARRAY_PORT_COTIS);

      //const { todoCotisPortfolio, isFetchingCotis } = useFetchTodoPortfolio(ARRAY_PORT_COTIS)//"IAG.MC,EURUSD=X")

      
      

      
  
    //console.log(data.pos)
    /*
  this.refreshCARTERACotis2(CARTERA_INFO_CARTERA).then(response => {
    glovar.TOPTRADERS_PRO1[data.pos].CARTERA_INFO_CARTERA=response;*/
  //console.log("xxxfinal refreshCARTERACotis2");
  //this.setState({});
  //
  
    }
    catch(Esss) {/*No mostramos cartera*/}
  }


  setNewsLogin(booleanus) {
    this.setState({ShowPopNewsLogin:booleanus})
  }
   
  

   render() {

    
    console.log("glovar.CURRENT_MENU_ID:"+glovar.CURRENT_MENU_ID);

    const currentPath = window.location.pathname;
    console.log('Ruta específicaDidMount:', currentPath);
    if(currentPath=='/home' && glovar.CURRENT_MENU_ID==5) {
      glovar.CURRENT_MENU_ID=1;
    }
    /*
    else 
      glovar.CURRENT_MENU_ID=5;
    }*/

    

    const EventGA = (category, action, label) => {
      ReactGA.event({
        category: category,
        action: action,
        label: label
      });
    };

    
    function ShowSearchBar(params) {

      const [apiResult, setApiResult] = useState([]);
      const [texte, setTexte] = useState('');

      var cars = [];

      var MOBILE=false;
      var leftsearchbox='12pc';
      var WITH_BOX_SEARCH='30pc';
      var TWITTER_ICON=false;

      try { if(params.type==1) { 
              MOBILE=true; 
              leftsearchbox='0pc';
              WITH_BOX_SEARCH='22pc'
              TWITTER_ICON=true;
            } 
          } catch(Exxx) {}

      //https://query1.finance.yahoo.com/v1/finance/search?q=pedo

      function HandleChange(e)
      {

        try { if(e.target.value.length<=0) { setApiResult([])} } catch(Exx) {}
        var SEARCH_STRING=e.target.value;
        
        if(e.target.value.length>0)
        {
     
          const link = "https://thingproxy.freeboard.io/fetch/https://query1.finance.yahoo.com/v1/finance/search?q="+SEARCH_STRING;
          //console.log("link:"+link);

    fetch(link).then(response => response.json())
   .then(json => {
    
    //console.log("complete:"+JSON.stringify(json))
        try {
          glovar.READY_TO_READ=true;
          glovar.LAST_ELEMENT_SEARCH=json;
          //console.log("le doy valor true!!!")
          
        }
        catch (Exx) {}
        setApiResult(json.quotes)
        //setTodo(CHART_COTIS)
        //setIsFetching(false);
        /*results.data.map((d) => {
          //console.log("d")
        });*/
        //console.log("All done!");

   })
   .catch((e) => {
    {
      //var TICKER="AAPL"
      //console.log("ERROR PARSING:"+e)
      let headers = new Headers(); 
     headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API_COTIS + ":" + glovar.PASS_API_COTIS));
     headers.set('Parameters',btoa(SEARCH_STRING));
  
  
     fetch(glovar.URL_FETCH_COTIS_SEARCH,{ method:'GET', //mode: 'no-cors',
     headers: headers,
     })
    .then(res => res.json())
    .then(res => {
       //console.log("res:"+res)
      if(res=="auth/user-not-found" || res=="auth/wrong-password") {
           return;
      }
  
      if(res=="auth/Unauthorized" || res=="auth/too-many-requests") {       //setError("ERROR: Unauthorized.");  //Please try again or contact support if you are unable to access your account");
        return;
      }
  
      //console.log(res)
      setApiResult(res)
      //setTodoData(res) 
      //setApiResult(['AAPL', 'DOCN', 'MSFT', 'GOOG', 'AMZN'])

      //setTodoSearch(['AAPL', 'DOCN', 'MSFT', 'GOOG', 'AMZN'])
      /*switch(SEARCH_STRING.length) { 
        case 0: setApiResult([]); break;
        case 1: setApiResult(['AAPL']); break;
        case 2: setApiResult(['AAPL', 'DOCN']); break;
        case 3: setApiResult(['AAPL', 'DOCN', 'MSFT']); break;
        case 4: setApiResult(['AAPL', 'DOCN', 'MSFT', 'GOOG']); break;
        default:setApiResult(['AAPL', 'DOCN', 'MSFT', 'GOOG', 'AMZN']); break;
      }*/
      //setApiResult(['AAPL', 'DOCN', 'MSFT', 'GOOG', 'AMZN'])
    })
    .catch((e) => {
      //console.log(e);
     })
     .finally(() => {
      //setIsFetchingSearch(false);
     
     });
    
    }

   })
   .finally(() => {
   //setIsFetching(false);
   });


    

   
 
  
  
   
  
  }

        //console.log("mierdaaaaaaaaaaa")
        //cars = ['Ford', 'BMW', 'Audi'];

       // const { todoSearch, isFetchingSearch } = useFetchTodoSearch(e.target.value)//"IAG.MC,EURUSD=X")  
    
        /*if(!isFetchingSearch) {
          setApiResult(todoSearch)
        }*/
        if(1==2)
        {
          //var TICKER="AAPL"
          
          let headers = new Headers(); 
         headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API_COTIS + ":" + glovar.PASS_API_COTIS));
         headers.set('Parameters',btoa(SEARCH_STRING));
      
      
         fetch(glovar.URL_FETCH_COTIS_SEARCH,{ method:'GET', //mode: 'no-cors',
         headers: headers,
         })
        .then(res => res.json())
        .then(res => {
           //console.log("res:"+res)
          if(res=="auth/user-not-found" || res=="auth/wrong-password") {
               return;
          }
      
          if(res=="auth/Unauthorized" || res=="auth/too-many-requests") {       //setError("ERROR: Unauthorized.");  //Please try again or contact support if you are unable to access your account");
            return;
          }
      
          //console.log(res)
          setApiResult(res)
          //setTodoData(res) 
          //setApiResult(['AAPL', 'DOCN', 'MSFT', 'GOOG', 'AMZN'])

          //setTodoSearch(['AAPL', 'DOCN', 'MSFT', 'GOOG', 'AMZN'])
          /*switch(SEARCH_STRING.length) { 
            case 0: setApiResult([]); break;
            case 1: setApiResult(['AAPL']); break;
            case 2: setApiResult(['AAPL', 'DOCN']); break;
            case 3: setApiResult(['AAPL', 'DOCN', 'MSFT']); break;
            case 4: setApiResult(['AAPL', 'DOCN', 'MSFT', 'GOOG']); break;
            default:setApiResult(['AAPL', 'DOCN', 'MSFT', 'GOOG', 'AMZN']); break;
          }*/
          //setApiResult(['AAPL', 'DOCN', 'MSFT', 'GOOG', 'AMZN'])
        })
        .catch((e) => {
          //console.log(e);
         })
         .finally(() => {
          //setIsFetchingSearch(false);
         
         });
        
        }
    

        //setApiResult(['AAPL', 'DOCN', 'MSFT', 'GOOG', 'AMZN'])
        //console.log("X:"+e.target.value)
        /*
        setTexte(e)
        
        
        
        //api(e.target.value)
        //  .then((result)=>{
        //    setApiResult(result);
        //  });
          
          //const queryOptions = { lang: 'es-ES', region: 'ES' };
          //const queryOptions = {  lang: glovar.APP_FORCE_LANG };
          
          //yahooFinance2.autoc(e,queryOptions).then(function(response) {
    
           //const queryOptions = {  lang: glovar.APP_FORCE_LANG, region: 'ES' };
    
           //const queryOptions = { modules: ['price'] }; // defaults
    
           //const queryOptions = {  enableCb:false,newsCount:0, quotesCount:20, lang: glovar.APP_FORCE_LANG, region: 'ES' };
           const queryOptions = {
            quotesCount: 20,
            enableCb: false,
            //enableFuzzyQuery:true,
            newsCount: 0,
            //lang: "es-ES",
            region: "ES"
          };
            yahooFinance2.search(e, queryOptions,{ validateResult: false }).then(function(response) {
            
            //console.log('YAHOO FINANCE:'+JSON.stringify(response.Result))
            //console.log('03YAHOO FINANCE:'+JSON.stringify(response.quotes))
            
            //setApiResult(response.Result)
            setApiResult(response.quotes)
            //console.log('Successfully UNsubscribed to topic:', response);
          })
          .catch(function(error) {
            //console.log('Error UNsubscribing to topic:', error);
          })
          */
      };

      /*function gotoSymbol(props) {
        glovar.LAST_ELEMENT_SEARCH=props.brand;
        glovar.READY_TO_READ=true;
        console.log("holaaa:"+JSON.stringify( glovar.LAST_ELEMENT_SEARCH))
        //window.location.replace('/symbol/'+props.brand.symbol) 
      }*/

      function CardTicker(props) {
        return(
        
        
        <Box alignItems="center"  _hover={{ cursor: "pointer" }}  backgroundColor="#F6F6F6"
        pl={3} pr={3} pt={3} pb={3} mt={2} 
         onClick= {() => { 
          //gotoSymbol(props)
          window.location.replace('/symbol/'+props.brand.symbol) 
          try { EventGA("search_bar","showSearch_"+props.brand.symbol, props.brand.symbol); } catch (exx) { /*console.log("petada event:"+exx)*/}
        }
         }>
            
            <HStack flexDirection="row" justifyContent="space-between">
            <Text>{props.brand.shortname ? `${props.brand.shortname}` : `${props.brand.longname}`}</Text>
            <Flex flexDirection="column" align="flex-end">
            <Text fontWeight="bold">{ props.brand.symbol }</Text>
            <Text >{props.brand.typeDisp}-{props.brand.exchange}</Text>
            </Flex>
            </HStack>
            </Box>);
        //return (<Flex alignItems="center"  >I am a { props.brand }</Flex>);
      }

     /*
      <TouchableOpacity onPress={()=>addToPORTFOLIO(name)}>
      <View style={styles.item}>
        <View style={styles.firstrow}>
        <Text style={styles.name}>{name.shortname ? `${name.shortname}` : `${name.longname}`}</Text>
        <View style={styles.firstcolumn}>
        <Text style={styles.ticker}>{name.symbol}</Text>
        <Text style={styles.equity}>{name.typeDisp}-{name.exchange}</Text>
        </View>
        </View>
      </View>
      </TouchableOpacity>
      */

      return(

        <Box zIndex={20}  ml={leftsearchbox}> 
        <HStack>
        <Input
        placeholder={i18n.t("busca_activo")}
         focusBorderColor='gray.400'
         borderColor='gray.400'
        mt='8px'
        type="search"
        width={WITH_BOX_SEARCH}//'30pc'
        onChange={HandleChange}
      /> 

{TWITTER_ICON&& <Image src={`${process.env.PUBLIC_URL}/assets/twitterxicon.png`} h="46px" pr="10px" pl="2px" pt="6px" _hover={{ cursor: "pointer" }}
       onClick= {() =>  {
         //try { EventGA("button_sup","pslogoHomeDT", "pslogoHomeDT"); } catch (exx) { /*console.log("petada event:"+exx)*/}
         if(MOBILE) { 
          try { EventGA("btn_twitter","btn_twitter_MBL", "btn_twitter_MBL"); } catch (exx) { /*console.log("petada event:"+exx)*/} 
         }
         else {
          try { EventGA("btn_twitter","btn_twitter_DSK", "btn_twitter_DSK"); } catch (exx) { /*console.log("petada event:"+exx)*/} 
         }

         window.open(i18n.t("url_twitter"),"_blank")
       }} />
     }
</HStack>
<Box background="white" mixHeight={600} width={WITH_BOX_SEARCH}/*'30pc'*/>
        {apiResult.map((result) => <CardTicker brand={result} />)}
        </Box>

      </Box>
      );
    }



    function CarruselTopTradersOLD(params) {

      var llistatTickers=[]
      //console.log("params.data:"+JSON.stringify(params.data[0]))
      //console.log("antes bucleee!")
      for(let z=0;z<params.data.length;z++) {

        //console.log("dentro bucleee!")
        try { 
          //console.log("hola:"+params.data[z].relatedTickers);

          var array=params.data[z].relatedTickers;
          for (let zz=0;zz<array.length;zz++) {

            //console.log("array[zz]:"+array[zz]+" vs "+params.symbol)
            if(!llistatTickers.includes(array[zz]) && params.symbol!=array[zz]) {
               llistatTickers.push(array[zz])
            }
          }

        }
        catch(Exx) {}
        /*
        var array=params.data[z];
        console.log("array.length:"+JSON.stringify(array.size))

        for (let zz=0;zz<array.length;zz++) {

          console.log("array[zz]:"+array[zz])
          if(!llistatTickers.includes(array[zz])) {
             llistatTickers.push(array[zz])
          }
        }*/
      }
      
      //console.log("llistatTickers:"+llistatTickers)
      if(params.data!=null) 
      {
        return(
          <Flex background="white" flexDirection="row" mt={2} >
          {llistatTickers.filter((result, idx) => idx <10).map((result) => 
           
           <Box marginRight={2} p={1} pr={1.5} pl={1.5} _hover={{ cursor: "pointer" }}  rounded={9} background="black" textColor="white" fontWeight="semibold" fontSize={14}
           onClick= {() => { 
            //gotoSymbol(props)
            window.location.replace('/symbol/'+result) 
            try { EventGA("carroussel","carroussel_"+glovar.USER_LANG_PRO1, result); } catch (exx) { /*console.log("petada event:"+exx)*/}
            try { EventGA("carroussel","carrou_"+params.symbol+"to "+result, result); } catch (exx) { /*console.log("petada event:"+exx)*/}
          }
           }
           > {result} </Box>
           
           )}
          </Flex>
        );
      }

    }
  

    function CarruselTickers(params) {

     


      var llistatTickers=[]
      //console.log("params.data:"+JSON.stringify(params.data[0]))
      //console.log("antes bucleee!")
      for(let z=0;z<params.data.length;z++) {

        //console.log("dentro bucleee!")
        try { 
          //console.log("hola:"+params.data[z].relatedTickers);

          var array=params.data[z].relatedTickers;
          for (let zz=0;zz<array.length;zz++) {

            //console.log("array[zz]:"+array[zz]+" vs "+params.symbol)
            if(!llistatTickers.includes(array[zz]) && params.symbol!=array[zz]) {
               llistatTickers.push(array[zz])
            }
          }

        }
        catch(Exx) {}
        /*
        var array=params.data[z];
        console.log("array.length:"+JSON.stringify(array.size))

        for (let zz=0;zz<array.length;zz++) {

          console.log("array[zz]:"+array[zz])
          if(!llistatTickers.includes(array[zz])) {
             llistatTickers.push(array[zz])
          }
        }*/
      }
      
      //console.log("llistatTickers:"+llistatTickers)
      if(params.data!=null) 
      {
        return(
          <Flex background="white" flexDirection="row" mt={2} >
          {llistatTickers.filter((result, idx) => idx <6).map((result) => 
           
           <Box marginRight={2} p={1} pr={1.5} pl={1.5} _hover={{ cursor: "pointer" }}  rounded={9} background="black" textColor="white" fontWeight="semibold" fontSize={14}
           onClick= {() => { 
            //gotoSymbol(props)
            window.location.replace('/symbol/'+result) 
            try { EventGA("carroussel","carroussel_"+glovar.USER_LANG_PRO1, result); } catch (exx) { /*console.log("petada event:"+exx)*/}
            try { EventGA("carroussel","carrou_"+params.symbol+"to "+result, result); } catch (exx) { /*console.log("petada event:"+exx)*/}
          }
           }
           > {result} </Box>
           
           )}
          </Flex>
        );
      }

    }
  
    function getRankingColor(id) {
      switch(id) {
        case 0:
        case 1:  return('#1EB41D');
        case 2:
        case 4:
                 return('#D61A1A'); //vermell
        case 3:
                 return('#D61A1A');
      }
    }


    function ShowFitxaElement(params) {

      
      //console.log("glovar.SHOW_MOBILE_LAYOUT_SHORTTERM:"+glovar.SHOW_MOBILE_LAYOUT_SHORTTERM)
      if(glovar.SHOW_MOBILE_LAYOUT_SHORTTERM) {
        //console.log("SOY TRUE!!")
      }
      const [showPopPup,setshowPopPup] = useState(false);
       const [email,setEmail] = useState("");

      //console.log("showPopPup:"+showPopPup)

      //ReactGA.pageview("hola!!!"); //window.location.pathname + window.location.search);
      //console.log("window.location.pathname + window.location.search")
      //Send pageview with a custom path
      try { ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search }); } catch(Exx) {}
      //console.log(window.location.pathname + window.location.search)
      //ReactGA.modalview('/about/contact-us');

      //getDataCSV("AAPL");
      window.scrollTo(0, 0)

      var element;
      var ticker_symbol;
      var FULL_LOAD=false;
      var TAG_RSS=false;

      var FROM_URL_MENU=0;
      var FULL_SIZE_CHART=0;
      var EXTRA_INFO_MARKET=0; //0->Nothing, 1->Premarket, 2->Postmarket
      let extraMarketPrice="";
      let extraMarketChange="";
      let extraMarketChangePercent="";
      let extraMarketText="";

      try { if(params.fromURLMenu!=null) { FROM_URL_MENU=params.fromURLMenu; } } catch(Exx) {}
      try { if(params.fullsizeChart!=null) { FULL_SIZE_CHART=params.fullsizeChart; } } catch(Exx) {}
      
      
      //console.log("FROM_URL_MENU:"+FROM_URL_MENU);


      let { ticker_param } = useParams();

      if(params.element_data==null) 
      {
        //console.log("URL EXTERNA:"+JSON.stringify(params));
        
        //console.log("ticker:"+ticker_param);
        ticker_symbol=ticker_param;
        FULL_LOAD=true;

         //console.log("URL EXTERNA:"+JSON.stringify(params));
        
        //console.log("ticker:"+ticker_param);
        if(params.ticker!=null) {
          ticker_symbol=params.ticker;
        }
        //console.log("ticker_symbol:"+ticker_symbol);
      }
      else {
        element=params.element_data;
        ticker_symbol=params.element_data.symbol;
        //console.log("datos:"+JSON.stringify(element));
      }
      try { EventGA("click_element","showFitxaElem_"+glovar.USER_LANG_PRO1, glovar.USER_LANG_PRO1); } catch (exx) { /*console.log("petada event:"+exx)*/}
      try { EventGA("click_element","showFitxaElement_"+ticker_symbol, ticker_symbol); } catch (exx) { /*console.log("petada event:"+exx)*/}

      //console.log("READY_TO_READ:"+ glovar.READY_TO_READ);
      if ( glovar.READY_TO_READ) 
      {

        glovar.READY_TO_READ=false;
        //console.log(glovar.LAST_ELEMENT_SEARCH);
         
      }
      //const { todoHistory, isFetchingHistory } = useFetchTodoHistory(ticker_symbol)//"IAG.MC,EURUSD=X")  

      const { todoHistory, todoData, isFetchingHistory, isFetchingData } = useFetchTodoHistory(FULL_LOAD,ticker_symbol)//"IAG.MC,EURUSD=X")  
    

      //var listRSStags=[]
      //Aqui discernimos si tiene tag o no
      //ticker_symbol,name
      //https://cincodias.elpais.com/tag/rss/meta_platforms/
      TAG_RSS=false;
      //listRSStags=[];


      //filtramos eurostoxx pq no hay news en yahoo
      var ticker_symbol_SEARCH=ticker_symbol;
      
      try { if(ticker_symbol_SEARCH=="^STOXX50E") {ticker_symbol_SEARCH="^FCHI"} } catch(Exxx) {}
      try { if(ticker_symbol_SEARCH=="^NDX") {ticker_symbol_SEARCH="^IXIC"} } catch(Exxx) {}

      //const { todoNews, searchedNews, isFetchingNews } = useFetchTodoNewsSearch(ticker_symbol)//"IAG.MC,EURUSD=X")  
      const { todoNews, searchedNews, isFetchingNews } = useFetchTodoNewsSearch(ticker_symbol_SEARCH)//"IAG.MC,EURUSD=X")  
  
    


      if(FULL_LOAD) {
        if(!isFetchingData) {
          element=todoData;
          //element=element[0];
          //console.log("ELEMENT:"+JSON.stringify(element))
          //console.log("element:"+element[0].region)
          //console.log("ELEMENT:"+JSON.stringify(element))
        }
      }

      EXTRA_INFO_MARKET =0;
      extraMarketPrice="";
      extraMarketChange="";
      extraMarketChangePercent="";

    //const { todoTopTraders, isFetchingTopTraders } = useFetchTodoUser(params.OWN_USER,id)//"IAG.MC,EURUSD=X")
    //const { todoHistory, isFetchingHistory } = useFetchTodoHistory(params.element_data.symbol)//"IAG.MC,EURUSD=X")  
    //const { todoHistory, isFetchingHistory } = useFetchTodoHistory("META")//"IAG.MC,EURUSD=X")  

    try {
     if((element.marketState==="PREPRE" || element.marketState==="CLOSED") && element.postMarketChange) { //Postmarket

      EXTRA_INFO_MARKET =2;
      extraMarketText="After hours"
      extraMarketPrice=element.postMarketPrice;
      extraMarketChange=(element.postMarketChange).toFixed(2); 
      extraMarketChangePercent=(element.postMarketChangePercent).toFixed(2);
      if(element.postMarketChange>0) { extraMarketChange="+"+extraMarketChange;  extraMarketChangePercent="+"+extraMarketChangePercent;}
     }
     else if(element.marketState==="PRE" && element.preMarketChangePercent) { //Premarket
      EXTRA_INFO_MARKET =1;
      extraMarketText="Pre-Market"
      extraMarketPrice=element.preMarketPrice;
      extraMarketChange=(element.preMarketChange).toFixed(2);
      extraMarketChangePercent=(element.preMarketChangePercent).toFixed(2);
      if(element.preMarketChange>0) { extraMarketChange="+"+extraMarketChange;  extraMarketChangePercent="+"+extraMarketChangePercent;}
      }
    }
    catch(Exx) {}

      /*else if(mode==1 && resultado.marketState!="CLOSED") { //entre semana dia de sessión
      //
      }
      else if (mode==2 || resultado.marketState=="CLOSED") { //entre semana dia de sessión
      postmarket
      }*/



    //if(!isFetchingHistory) 
    if(!FULL_LOAD || !isFetchingData)
    {

      const ED_Candlesticks=[];
      ED_Candlesticks.push(todoHistory[todoHistory.length-10])
      ED_Candlesticks.push(todoHistory[todoHistory.length-9])
      ED_Candlesticks.push(todoHistory[todoHistory.length-8])
      ED_Candlesticks.push(todoHistory[todoHistory.length-7])
      ED_Candlesticks.push(todoHistory[todoHistory.length-6])
      ED_Candlesticks.push(todoHistory[todoHistory.length-5])
      ED_Candlesticks.push(todoHistory[todoHistory.length-4])
      ED_Candlesticks.push(todoHistory[todoHistory.length-3])
      ED_Candlesticks.push(todoHistory[todoHistory.length-2])
      ED_Candlesticks.push(todoHistory[todoHistory.length-1])
      //console.log("D-2:"+JSON.stringify(todoHistory[todoHistory.length-3]))
      //console.log("D-1:"+JSON.stringify(todoHistory[todoHistory.length-2]))
      //console.log("D-0:"+JSON.stringify(todoHistory[todoHistory.length-1]))

      //console.log("POS 1:"+JSON.stringify(DATA_COTIS[0]))
      //console.log("POS2 1:"+JSON.stringify(todoHistory[0]))
      //console.log("POS2 1:"+JSON.stringify(glovar.DATA_COTIS2[0]))
      //element_data
      //paràmetres obtinguts per URL
      //let { id } = useParams();

      //var element=params.element_data;

      //console.log(JSON.stringify(element))
     
     //name={todo.shortName} price={todo.regularMarketPrice} diff={todo.regularMarketChangePercent} moneda={m}
  
     var name=element.shortName;
     var price=element.regularMarketPrice;
     var  diff=element.regularMarketChangePercent;

     var valor_coti=element.regularMarketPrice;
     var rating="";
     var ratingID=0;
     var rating_target=element.target;
     var rating_stop=element.stop;
     var potencial='';
     var score=6;


     //console.log("data_val.trend:"+JSON.stringify(data_val.trend))
    if(1==1) //de moment ho ensenyem sempre
    {
     var sent_ALCISTA='▲   '+i18n.t('m_alc');
     var sent_BAJISTA='▼   '+i18n.t('m_baj');

     var COLOR_ALCISTA='#1EB41D';
     var COLOR_BAJISTA='#D61A1A';

     var COLOR_SENTIDO=COLOR_ALCISTA;
  
     var sentido=1;
     var sentido_text=sent_ALCISTA;

 

if(element.trend==1 && element.target>(valor_coti*1.11))  { sentido_text=sent_ALCISTA; COLOR_SENTIDO=COLOR_ALCISTA; }
else if (element.trend==2 && element.target>(valor_coti*1.05) ) { sentido_text=sent_ALCISTA; COLOR_SENTIDO=COLOR_ALCISTA; }
else if (element.trend==2 && element.target<(valor_coti*0.95) ) { sentido_text=sent_BAJISTA; COLOR_SENTIDO=COLOR_BAJISTA; }
else if (element.trend==2) { sentido_text=sent_ALCISTA; COLOR_SENTIDO=COLOR_ALCISTA; }
else if(element.trend==3 && element.target<(valor_coti*0.90))  { sentido_text=sent_BAJISTA; COLOR_SENTIDO=COLOR_BAJISTA;}
else if(element.trend==1) {sentido_text=sent_ALCISTA; COLOR_SENTIDO=COLOR_ALCISTA; }
else { sentido_text=sent_BAJISTA; COLOR_SENTIDO=COLOR_BAJISTA; }








try {
  if(element.target>valor_coti) { potencial=((element.target-valor_coti)/valor_coti)*100; }
  else { potencial=((valor_coti-element.target)/valor_coti)*100; }

  if(potencial<10) { potencial=potencial.toFixed(2); }
  else if(valor_coti<100) { potencial=potencial.toFixed(2);  }
  else { potencial=potencial.toFixed(1);  }
} catch(Exxxc) {}


try {
  score=5;
  var volatil=5;
  if(potencial>5) { volatil=6; score=6; }
  if(potencial>10) { volatil=7; score=7; }
  else if(potencial>15) { volatil=8; score=8; }
  else if(potencial>25) { volatil=9; score=9; }
  else if(potencial>50) { volatil=9; score=10; }
}
catch(Exxxsad) {} 

var entrada=valor_coti;//104.29

try {
  if(entrada<10) {  entrada=entrada.toFixed(4-2); }
  else if(entrada<100) {  entrada=entrada.toFixed(4-2); }
  else if(entrada<1000) {  entrada=entrada.toFixed(3-2); }
  else if(entrada<10000) {  entrada=entrada.toFixed(2-2); }
  else if(entrada<100000) {  entrada=entrada.toFixed(2-2); }
  else if(entrada>100000) {entrada=entrada.toFix(1-1); }
  }
  catch(Exx) { }


var max_volatil=9//FITXA_SIGNAL.max_volatil; //6


//"trend":1, (alcista), 2(neutral), 3(bajista)

//if(element.trend==1) { rating="Sobreponderar"; ratingID=0; }
if(element.trend==1 && element.target>(valor_coti*1.11))  { rating=i18n.t('compra'); ratingID=1; }
else if (element.trend==2 && element.target>(valor_coti*1.05) ) { rating=i18n.t('compra'); ratingID=1; }
else if (element.trend==2 && element.target<(valor_coti*0.95) ) { rating=i18n.t('venta'); ratingID=2; }
else if (element.trend==2) { rating=i18n.t('mantener'); ratingID=3; }
else if(element.trend==3 && element.target<(valor_coti*0.90))  { rating=i18n.t('venta'); ratingID=2; }
else if(element.trend==1) { rating=i18n.t('sobreponderar'); ratingID=0; }
else { rating=i18n.t('infraponderar'); ratingID=4; }



try {
  if(rating_target<10) { rating_target=rating_target.toFixed(2); }
  else if(rating_target<100) { rating_target=rating_target.toFixed(2);  }
  else if(rating_target<1000) { rating_target=rating_target.toFixed(1);  }
  else  { rating_target=rating_target.toFixed(0);  }
  }
  catch(Exx) { }

  try {
    if(rating_stop<10) { rating_stop=rating_stop.toFixed(2); }
    else if(rating_stop<100) { rating_stop=rating_stop.toFixed(2);  }
    else if(rating_stop<1000) {rating_stop=rating_stop.toFixed(1);  }
    else  { rating_stop=rating_stop.toFixed(0);  }
    }
    catch(Exx) { }

  

//"target":155.873088}



/*
<Text style={{color: '#1EB41D', marginTop:10, marginLeft:10, fontWeight: 'bold',width:'20%',
 fontSize: 16-(glovar.SIZE_LESS*2)}}>+109.6%</Text>
<Text style={{color: 'black', marginTop:10,fontWeight: 'bold',width:'22%',
 fontSize: 16-(glovar.SIZE_LESS*2)}}>263.56€</Text>
<Text style={{color: 'black',marginTop:10,fontWeight: 'bold',width:'15%',
 fontSize: 16-(glovar.SIZE_LESS*2)}}>8/10</Text>
<Text style={{color: 'black',marginTop:10,fontWeight: 'bold',width:'15%',
 fontSize: 16-(glovar.SIZE_LESS*2)}}>2/5</Text>
 */


try {
if(valor_coti<10) { if((''+valor_coti).length>6) valor_coti=valor_coti.toFixed(4); }
else if(valor_coti<100) { if((''+valor_coti).length>7) valor_coti=valor_coti.toFixed(4); }
else if(valor_coti<1000) { if((''+valor_coti).length>8) valor_coti=valor_coti.toFixed(3); }
else if(valor_coti<10000) { if((''+valor_coti).length>7) valor_coti=valor_coti.toFixed(2); }
else if(valor_coti<100000) { if((''+valor_coti).length>8) valor_coti=valor_coti.toFixed(2); }
else if(valor_coti>100000) {valor_coti=valor_coti.toFix(1); }
}
catch(Exx) { }

var DUMMY_VALUE="##.##"

var candle_type="Inverted Hamer"
var candle_signal=" Bullish reversal"
var candle_Reliability="High"
var candle_ago=" 1"

var chart_r1=" 46.80$"
var chart_r2=" 50.50$"
var chart_r3=" 66.60$"
var chart_s1=" 32.10$"
var chart_s2=" 25.50$"

var fibo_r1=" 46.80$"
var fibo_r2=" 50.50$"

var fibo_s1=" 32.10$"
var fibo_s2=" 25.50$"
var fibo_s3=" 25.50$"

var avg_05=" 46.80$"
var avg_30=" 50.50$"
var avg_50=" 32.10$"
var avg_100=" 25.50$"
var avg_200=" 25.50$"


if(glovar.MODE_USER<2) { //for not loaded users, we don't show info!

  potencial="##.#";
  entrada="##.##"
  rating_target="##.##"
  rating_stop="##.##"
  volatil="#"
 
  candle_type="-----------"
  candle_signal=" ---------------"

  candle_Reliability="----"
  candle_ago=" ##"

  chart_r1=" ##.##"
  chart_r2=" ##.##"
  chart_r3=" ##.##"
  chart_s1=" ##.##"
  chart_s2=" ##.##"

  fibo_r1=" ##.##"
  fibo_r2=" ##.##"

  fibo_s1=" ##.##"
  fibo_s2=" ##.##"
  fibo_s3=" ##.##"

  avg_05=" ##.##"
  avg_30=" ##.##"
  avg_50=" ##.##"
  avg_100=" ##.##"
  avg_200=" ##.##"

  if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
  {

    candle_type="-----------"
  candle_signal="--------"

  candle_Reliability="----"
  candle_ago=" ##"

  chart_r1=" ##.##"
  chart_r2=" ##.##"
  chart_r3=" ##.##"
  chart_s1=" ##.##"
  chart_s2=" ##.##"

  fibo_r1=" ##.##"
  fibo_r2=" ##.##"

  fibo_s1=" ##.##"
  fibo_s2=" ##.##"
  fibo_s3=" ##.##"

  avg_05=" #.#"
  avg_30=" #.#"
  avg_50=" #.#"
  avg_100=" #.#"
  avg_200=" #.#"
  }


 /* 
 fontSize: 16-(glovar.SIZE_LESS*2)}}>+{potencial}%</Text>
 fontSize: 16-(glovar.SIZE_LESS*2)}}>{entrada}{moneda}</Text>
 fontSize: 16-(glovar.SIZE_LESS*2)}}>{rating_target}{moneda}</Text>
 fontSize: 16-(glovar.SIZE_LESS*2)}}>{rating_stop}{moneda}</Text>
 fontSize: 16-(glovar.SIZE_LESS*2)}}>{volatil}/{max_volatil}</Text>
 */
}


var moneda="$"//MONEDA_STOCK//'€'
var BENEFIS=true



var COLOR_STROKE='rgb(30, 180, 29)';
var COLOR_FILL='rgba(30, 180, 29, 0.05)';

if(element.regularMarketChange<0) {
     BENEFIS=false
     COLOR_STROKE='rgb(214, 26, 26)';
     COLOR_FILL='rgba(214, 26, 26, 0.05)';
    }

  }

     var moneda="$"
     try { moneda=getMONEDADivisa(element.currency,element.quoteType); } catch(Exx) {}
     //if(element.currency=="EUR") {moneda="€"}

     //####################//
    //# Check names      #//
    //####################//
    if(name=="IBEX 35...") {name="IBEX 35"; }
    if(name=="ESTX 50 PR.EUR") { name="EuroStoxx 50"}
    

    //if(!isFetchingTopTraders || PROFILE_CACHE) 
    {
  
      let color1="#1FB31F"
      let color2="#FFFFFF"
  
      var subtitle="subtitle"
      var title=name;
      var percent=Number(diff).toFixed(2);
      var change=Number(element.regularMarketChange).toFixed(2);
      var loss=Number(diff).toFixed(2);
      var coti=price;
      var loss=(diff<0);
  
      var simbol="";
  
      try {
      if(coti<1) 
      {
        
          var text = coti.toString();
          var index = text.indexOf(".");
          if(index>=1) {
          var index2 = ((text.length - index - 1));
          if(index2>5) { coti=coti.toFixed(5)} 
          //console.log("decimaleeeeeeeeeeees:"+index2)
          }
      }
      }
      catch(Exxx) {}
  
      //const icon = () => { return(<FiArrowDown />); }
  
      if(diff>0) {
          color1="#1FB31F"
            color2="#FFFFFF"
            simbol="+"
      }
      else {
          color1="#D61A18"
          color2="#FFFFFF"
      }
  
  
      var subtitle="subtitle"
      var title=name;
      var percent=Number(diff).toFixed(2);
      var change=Number(element.regularMarketChange).toFixed(2);
      var loss=Number(diff).toFixed(2);
      var coti=price;
      var loss=(diff<0);
  
      var simbol="";
  
      var RANGO_52S='35%';
  var num52s=35;

  var bookValue='N/A';
  try { if(element.bookValue!=null) { bookValue=(element.bookValue)+moneda; } } catch(Exxx){} //Book Value Per Share


  var priceToBook='N/A'; //(element.priceToBook); //Price/Book
  try { if(element.priceToBook!=null) priceToBook= parseFloat(element.priceToBook).toFixed(4); } catch(Exxx) {}

  var BPA_est_2021='N/A'
  try { if(element.epsCurrentYear!=null) { BPA_est_2021=(element.epsCurrentYear)+moneda; } } catch(Exxxx) {} //EPS

  var PER_est_2021='N/A';  //PE Ratio
  try { if(element.priceEpsCurrentYear!=null) { PER_est_2021=parseFloat((element.priceEpsCurrentYear)).toFixed(2); }} catch(Exxxx) {} //PE Ratio
  
  var BPA_proxanno='N/A';
  try { if(element.epsForward!=null) { BPA_proxanno=(element.epsForward)+moneda;}} catch(Exxxx) {}

  var PER_proxanno='N/A';
  try { if(element.forwardPE!=null) { PER_proxanno=parseFloat((element.forwardPE)).toFixed(2);}} catch(Exxxx) {}
   
  var sharesOutstanding='N/A';
  try { if(element.sharesOutstanding!=null) { sharesOutstanding=element.sharesOutstanding;}} catch(Exxxx) {}

  var firstTrade='N/A'
  
  try { var fecha1=new Date(element.firstTradeDateMilliseconds);
  //if(fecha1!=null && fecha1!='Invalid Date') { firstTrade=moment(fecha1).format('ll'); }; 
  } catch(Exxx) {}


  var PER='N/A';
try { if(element.trailingPE!=null) { PER=element.trailingPE; PER=parseFloat(PER).toFixed(2);}}  catch(Exxx) {}

var benef_accion='N/A';
try { if(element.epsTrailingTwelveMonths!=null) {benef_accion=element.epsTrailingTwelveMonths+moneda}}  catch(Exxx) {}

var Dividendo='N/A';
try { if(element.trailingAnnualDividendRate!=null) {Dividendo=element.trailingAnnualDividendRate+moneda}}  catch(Exxx) {}

var Rent_dividendo='N/A';
try { if(element.trailingAnnualDividendYield!=null) {Rent_dividendo=(element.trailingAnnualDividendYield*100)+'%'; 
                                                      Rent_dividendo=parseFloat(Rent_dividendo).toFixed(2)+'%'; }}  catch(Exxx) {}

var fecha_resultados=i18n.t("Desconocida"); //'Desconocida'
//earningsTimestamp
try { var fecha=new Date(element.earningsTimestampStart);
    //console.log('prefecha:'+element.earningsTimestampStart);
    //console.log('fecha:'+fecha)
      //if(fecha!=null && fecha!='Invalid Date') { fecha_resultados=moment(fecha).format('ll'); }

     fecha=new Date(element.earningsTimestampEnd);
     //if(fecha!=null && fecha!='Invalid Date') { fecha_resultados=fecha_resultados+'-'+moment(fecha).format('ll'); }


}
catch(Exep) {}



var marketCAP=0;
try {
    if(element.marketCap>0)
    {
     marketCAP=element.marketCap;
     marketCAP=getMarketCAP(element.marketCap,'es') 
    }
  }
  catch(Exasdad) { }
  

var rent=element.regularMarketChangePercent;//3.26
var benef=element.regularMarketChange;//25.13

rent= parseFloat(rent).toFixed(2);
benef=parseFloat(benef).toFixed(3);

try { if(element.regularMarketPrice<1) { benef=element.regularMarketChange; benef=parseFloat(benef).toFixed(4); } } catch(Exxx) {}

var LowSesion=element.regularMarketDayLow;
  var HighSesion=element.regularMarketDayHigh;
  var ticker=element.symbol;
  var Volume=element.regularMarketVolume;



  try 
  {
      //var cotix=valor_coti;
      //cotix=12.4;
    var num52=(element.fiftyTwoWeekHigh-element.fiftyTwoWeekLow);
    var num52L=(coti-element.fiftyTwoWeekLow);
    num52s=(num52L/num52)*100;
    //console.log('num52s:'+num52s)
    num52s=parseInt(num52s*0.95);
    //console.log('num52s:'+num52s)
    RANGO_52S=num52s+'%'
    //RANGO_52S="95%"
  }
  catch(exxxxx) { /*console.log('petadaaaaaaa:'+exxxxx)*/ }

      try {
      if(coti<1) 
      {
        
          var text = coti.toString();
          var index = text.indexOf(".");
          if(index>=1) {
          var index2 = ((text.length - index - 1));
          if(index2>5) { coti=coti.toFixed(5)} 
          //console.log("decimaleeeeeeeeeeees:"+index2)
          }
      }
      }
      catch(Exxx) {}
  
      //const icon = () => { return(<FiArrowDown />); }
  
      if(diff>0) {
          color1="#1FB31F"
            color2="#FFFFFF"
            simbol="+"
      }
      else {
          color1="#D61A18"
          color2="#FFFFFF"
      }

      if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
      {
  //return( <AddContentApp mode={4}></AddContentApp>);

  return (
    <Box  backgroundColor="#F3F2EF"><Box>
   <HeaderMenu/>
    <Box  backgroundColor="#FFFFFF" mt={-8} pb={2} pl={2} alignContent="center">
    <ShowSearchBar type={1}/>
    </Box>
  </Box>

  {showPopPup&&
        <Box zIndex={3} background="red"position="absolute"
  >
          <div className='popup'>
          <div className='popup_inner_mobile'>
          <VStack width="100%" flexDirection="column" justifyContent="center" p={5} pt={2} pb={20} alignContent="center">
          <Text textAlign="center" fontSize="24" textColor="black" fontFamily="Tahoma" fontWeight="semibold" marginBottom={2}> 
          {i18n.t("op_no_dispo")}
            </Text>
            <Text textAlign="center" mt={10} fontSize="16" textColor="black" fontFamily="Tahoma" fontWeight="normal" > 
            {i18n.t("quieres_activa")}  <strong>{i18n.t("subs_news")}</strong>
            </Text>
          
            <Text textAlign="center" mt={5} fontSize="16" textColor="black" fontFamily="Tahoma" fontWeight="normal" > 
            {i18n.t("activ_func_aviso")}
            </Text>
  
            <div className="formField">
              <input
                type="email"
                id="email"
                className="formFieldInput"
                placeholder={i18n.t("intro_email")}
                name="email"
                value={email}
                required
                onChange={e => setEmail(e.target.value)}
                //value={this.state.email}
                //onChange={this.handleChange}
    
    
              />
            </div>
  
            <Flex marginTop={15}>
            <button className="formSignInButton" marginTop={5} _hover={{ cursor: "pointer" }} 
          onClick= {(e) =>
           {
            //sendEmail(e)
            {
              e.preventDefault();
          
              try {
              if(email.length>10 && email.includes("@") && email.includes(".")) {
          
               var templateParams = {
                  email_subscriber: email,
              };
              
          
               templateParams.email_subscriber=email;
               emailjs.send(glovar.REF_EMAIL_SERVICE, glovar.REF_EMAIL_TEMPLATE, templateParams, glovar.REF_EMAIL_KEY)
               .then(function(response) {
                 //console.log('SUCCESS!', response.status, response.text);
               }, function(error) {
                 //console.log('FAILED...', error);
               });
              }
              else {
                
              }
          
              }
              catch(Exxx) {}
            };
            //console.log("buscar!!")
            setshowPopPup(false)
           }
           }
           style={{ paddingLeft:20, paddingRight:20, marginTop:"10px", fontWeight:"semibold", textAlign:"center", fontSize:"large", marginLeft:5}}>
           {i18n.t("subs_mayus")}</button>
           </Flex>   
           <Text  as='u' textAlign="center" paddingTop="10px" fontSize="12" textColor="black" fontFamily="Tahoma" 
           _hover={{ cursor: "pointer" }} fontWeight="normal" 
           onClick= {() =>
            {
   
             //console.log("buscar!!")
             setshowPopPup(false)
            }
            }> 
            {i18n.t("no_gracias")}
            </Text>
  
           </VStack>
          </div>
        </div>
        </Box>}

    <SimpleGrid columns={14} gap={0} p={0}>
  
     
      
      {1==1&&<GridItem colSpan={14} rounded={10} backgroundColor="#FFFFFF" m={2}> 
          
      {<GridItem colSpan={6} rounded={10} backgroundColor="#FFFFFF" minHeight="16pc"> 
          
      
          <HStack ml={4} mr={4} mt={2}  justifyContent="space-between">
           <Text mt={0} fontSize="26" textColor="black"  fontWeight="semibold" > 
            {name}       
           </Text>
          </HStack>
          
         <HStack  ml={4} mr={4} justifyContent="space-between">
         <VStack alignItems='left'>
         <Text fontSize="36" mt={-4} mb={-4} fontWeight="bold" >{coti}{moneda}</Text>
         <Text paddingLeft={1} fontSize="16" textColor="black" mb={-1} fontWeight="normal" > 
            {element.regularMarketDayLow}{moneda} -   {element.regularMarketDayHigh}{moneda}     
           </Text>
           </VStack>
         <div alignItems='right'>
         <Text mt={-2} fontSize="18" align="right" textColor="black" textAlign="right" fontWeight="bold" > 
            {element.symbol}       
           </Text>
         <HStack>

     

         <Text
             fontSize="16"
             textColor={loss ? "red" : "green"}
             fontWeight="semibold"
             align="right"
             //w={"2pc"}
           >
             {simbol}{change}
           </Text>
         <Box
           borderRadius="lg"
           fontSize="16"
           fontWeight="semibold"
           textAlign="center"
           paddingTop={0.5} paddingBottom={0.5}
           paddingLeft={4}paddingRight={4}
           //w={"4pc"}
           //h={"1.5pc"}
           bg={color1}
           textColor={color2}
         >
           {simbol}{percent}%
         </Box>
        
         </HStack>
         <Text fontSize="13" align="right" textColor="black"  textAlign="right" fontWeight="normal" mb={4} > 
           Vol: {element.regularMarketVolume}       
           </Text>
         </div>
         </HStack>

         {(EXTRA_INFO_MARKET>=1)&&<HStack alignItems='center'background="#F2F2F2" mt={-3} mb={2} 
          rounded={9} justifyContent='space-around' marginLeft="30px" marginRight="30px"
         round={10} p={1}  >
        <Text textColor="black" fontSize={14} mt={-2} mb={-2}>⚠️{" "}<Text as="span">{extraMarketText}:</Text></Text>
        
      <HStack m={0}><Text fontSize="16" mt={-4} mb={-4} fontWeight="bold">{extraMarketPrice}{moneda}</Text>
      <Text paddingLeft={0} fontSize="14"   mt={-4} mb={-4} textColor={(extraMarketChange<0) ? "red" : "green"}
          fontWeight="bold"
          align="right"
          //w={"2pc"}
        >
          {extraMarketChange} ({extraMarketChangePercent}%)
        </Text></HStack>
        </HStack>}


         {glovar.TOPTRADERS_ACTIVE&&<CarrusselTopTraders symbol={element.symbol} mobile={1}/>}

         {(glovar.MODE_USER<2)&&
         <HStack  background="lightgray"  marginLeft="20px" marginRight="20px" rounded={9} mt="0pc" justifyContent='space-around'>
           <AdComponent320/></HStack>}


           {1==2&&<div>
         {(glovar.MODE_USER<2)&&<Text fontSize="15" align="left" ml={5} mt="1pc"/*"5pc"*/ mb="0px"/*"10px"*/ textColor="#666666"  fontWeight="semibold" > 
         {i18n.t("rango_52s")}  
           </Text>}
   
           {(glovar.MODE_USER>=2)&&<Text fontSize="15" align="left" ml={5} mt="5pc" mb="10px" textColor="#666666"  fontWeight="semibold" > 
         {i18n.t("rango_52s")}  
           </Text>}
   
           <HStack style={{ marginTop:4, marginLeft:20, marginRight:20, height:10, flexDirection: 'row', backgroundColor:'#E0E0E0' }}>
             </HStack>
           
             <HStack style={{ marginLeft:20}}> 
             <Image style={{  marginLeft:RANGO_52S, width: 16, height: 16, marginTop:-10, resizeMode: 'contain', }} 
            src={`${process.env.PUBLIC_URL}/assets/triangleok.png`}/>
             </HStack>
   
           <HStack style={{  marginBottom:"0.5pc", justifyContent:'space-between', marginLeft:20, marginRight:20, flexDirection:'row', marginTop: 4 }}>
           <Text style={{ color: 'black',fontSize: 16-(glovar.SIZE_LESS*2), fontWeight: 'bold'}}>{element.fiftyTwoWeekLow}{moneda}</Text>
           <Text style={{ color: 'black',fontSize: 16-(glovar.SIZE_LESS*2), fontWeight: 'bold'}}>{element.fiftyTwoWeekHigh}{moneda}</Text>
           </HStack>
           </div>}


   
           {glovar.SHOW_MOBILE_LAYOUT_SHORTTERM&&<HStack fontSize="2xl" 
             zIndex={1}
             //marginBottom="-70px" 
             //marginBottom="30px"  
             marginTop="100px"
            >
        
        

      


       <button className="formSignInButtonMobileFront"  zIndex={1} _hover={{ cursor: "pointer" }} 
        onClick= {() => {
         setEmail(" ")
         //setshowPopPup(true);

         //this.setState({ ShowPopNewsLogin: true});
         setshowPopPup(!showPopPup);
         
         
         if(glovar.REGISTER_ONLINE==true) {
           //try { sendEventGA("button_sup","pressbtn_regonline", "pressbtn_regonline3"); } catch (exx) { }
           //window.location.replace('/register')
           }
         else {
             //try { sendEventGA("button_sup","pressbtn_register", "pressbtn_register"); } catch (exx) {}
             //window.location.replace('/registerApp')
         }
        }
        }
         style={{ width: "10pc", fontWeight:"450", zIndex:1, 
         marginLeft:"35%",  marginRight:"35%", //backgroundColor:"#555555",
         textAlign:"center"}}>
         {i18n.t("Login")}</button>    

       
             
         </HStack>}
         

         {glovar.SHOW_MOBILE_LAYOUT_SHORTTERM&&<VStack
     
     opacity={0.4} // Ajusta la opacidad según desees
     position="relative"   
     zIndex="0"
    
         //width="100%"
         //height="140"
   >   

<HStack style={{ fontSize: 15-(glovar.SIZE_LESS*2), marginLeft:10, marginRight:10, marginTop:-120, 
   width:"98%",
   flexDirection: 'row', fontWeight: 'bold', backgroundColor:"#EEEEEE"}} >
        <Text style={{ marginLeft:10, color: 'black', justifyContent:'space-between'}}>
           Short Term</Text>
           <Spacer/>
           <Text style={{ marginRight:10, color: 'black', justifyContent:'space-between'}}>
           Techinchal Indicators</Text>

           </HStack>



      
      <HStack style={{ marginTop:0, marginBottom:0,
      marginLeft:10, marginRight:10, 
      width:"98%",
        height:140, 
        flexDirection: 'row', backgroundColor:"#FAFAFA" }} >
      <Box width={"25%"} marginLeft={0} marginRight={1}>
      
      <Box  background="white">
      {!isFetchingHistory&&<ChartCandle  data={ED_Candlesticks} colorLine={color1} />}
      </Box>
      </Box>
      <Box width={"26%"} >
        
        
      <Text style={{ color: 'black',  textAlign:'left', marginBottom:10, TextContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'650'}}>Candle Pattern</Text>
      
      <Text style={{ color: 'white', backgroundColor:'black', borderRadius:4, 
      paddingLeft:2, textAlign:"center", fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}>{candle_type}</Text>
      
      <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>Signal:</b> {candle_signal}</Text>
      <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>Reliability:</b> {candle_Reliability}</Text>
      <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>Candles Ago:</b> {candle_ago}</Text>
     
      {1==1&&<Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>Description: </b> ...</Text>}
    
      
      </Box>

      {1==2&&<Box width={"15%"} >
        
        <Text style={{ color: 'black',  textAlign:'left', TextContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'650', marginBottom:4, marginTop:2}}>Classic</Text>
       
        <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>R1:</b> {chart_r1}{moneda}</Text>
        <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>R2:</b> {chart_r2}{moneda}</Text>
        <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>R3:</b> {chart_r3}{moneda}</Text>
        <HStack style={{
          alignContent:'center', width:'70%', marginTop:'4px', marginBottom:'4px', 
          height:'1px', backgroundColor: '#999999', borderRadius: 5,
        }}/>

        <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>S1:</b> {chart_s1}{moneda}</Text>
        <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>S1:</b> {chart_s2}{moneda}</Text>
         </Box>}

         {1==2&&<Box width={"15%"} >
        
        <Text style={{ color: 'black',  textAlign:'left', TextContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'650', marginBottom:4, marginTop:2}}>Fibonacci</Text>
       
        <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>R1:</b> {fibo_r1}{moneda}</Text>
        <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>R2:</b> {fibo_r2}{moneda}</Text>
        <HStack style={{
          alignContent:'center', width:'70%', marginTop:'4px', marginBottom:'4px', 
          height:'1.5px', backgroundColor: '#999999', borderRadius: 5,
        }}/>
        <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>S1:</b> {fibo_s1}{moneda}</Text>
        <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>S2:</b> {fibo_s2}{moneda}</Text>
        <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>S3:</b> {fibo_s3}{moneda}</Text>
         </Box>}

         <Box width={"22%"} >
        
        <Text style={{ color: 'black',  textAlign:'left', TextContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'650', marginBottom:10}}>Moving Avg</Text>
       
        <HStack> <Box style={{  height: 6, width: 6,  backgroundColor: COLOR_BAJISTA,/*this.getRankingColor(ratingID),*/ 
                   borderRadius: 2, marginRight:-4 }}>
                 </Box>
                 <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>M05:</b> {avg_05}{moneda}</Text>
                 </HStack>

                 <HStack> <Box style={{  height: 6, width: 6,  backgroundColor: COLOR_BAJISTA,/*this.getRankingColor(ratingID),*/ 
                   borderRadius: 2, marginRight:-4 }}>
                 </Box>
                 <Text style={{ color: 'black',  justifyContent:'left',  fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>M30:</b>  {avg_30}{moneda}</Text>
                 </HStack>

                 <HStack> <Box style={{  height: 6, width: 6,  backgroundColor: COLOR_SENTIDO,/*this.getRankingColor(ratingID),*/ 
                   borderRadius: 2, marginRight:-4 }}>
                 </Box>
                 <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>M50:</b> {avg_50}{moneda}</Text>
                 </HStack>

                 <HStack> <Box style={{  height: 6, width: 6,  backgroundColor: COLOR_SENTIDO,/*this.getRankingColor(ratingID),*/ 
                   borderRadius: 2, marginRight:-4 }}>
                 </Box>
                 <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>M100:</b> {avg_100}{moneda}</Text>
                 </HStack>

                 <HStack> <Box style={{  height: 6, width: 6,  backgroundColor: COLOR_SENTIDO,/*this.getRankingColor(ratingID),*/ 
                   borderRadius: 2, marginRight:-4 }}>
                 </Box>
                 <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>M200:</b> {avg_200}{moneda}</Text>
                 </HStack>

     </Box>

      <Box width={"15%"} height="120px">
     
      
        <HStack marginLeft="2%"  marginTop="10px">

        <VStack height="100px" align="left" justifyContent="space-between">
       <Text style={{ color: 'black',  justifyContent:"space-between",  fontSize: 13-(glovar.SIZE_LESS*2), fontWeight:'500'}}>Strong Buy</Text>
       
        <Text style={{ color: 'black',  justifyContent:"space-between",   fontSize: 13-(glovar.SIZE_LESS*2), fontWeight:'500'}}>Neutral</Text>
       
        <Text style={{ color: 'black',  justifyContent:"space-between",  fontSize: 13-(glovar.SIZE_LESS*2), fontWeight:'500'}}>Strong Sell</Text>
          
       </VStack>

        <Box
  w='12%'
  h='100px'
  bgGradient='linear(#1EB41D 15%, #EE9F38 66%, #D61A1A 100%)'
 
/>
<HStack style={{ marginLeft:-20}}> 
          <Image style={{  marginLeft:RANGO_52S, width: 12, height: 12, marginTop:-10, resizeMode: 'contain', }} 
         src={`${process.env.PUBLIC_URL}/assets/triangleindiok.png`}/>
          </HStack>
          
      </HStack>

      </Box>
      </HStack>
      </VStack>}

      
           {glovar.SHOW_MOBILE_LAYOUT_SHORTTERM&&<VStack
     
     opacity={0.4} // Ajusta la opacidad según desees
     position="relative"   
     zIndex="0"
         width="100%"
         //height="140"
   > 
          
         <HStack style={{
           width:"96%",
          alignItems:'center',
          marginInline:6,
          paddingInline:8,
          height: 20,
          flexDirection:'row',
          backgroundColor: 'black',
          marginTop: 12,
          justifyContent:"space-between",
          color: 'white',
          borderRadius: 0,
        }}>
              
            
        <Text style={{ color: 'white',width:'15%', fontSize: 14-(glovar.SIZE_LESS*2)}}>Trend</Text>
        <Text style={{ color: 'white', fontSize: 14-(glovar.SIZE_LESS*2)}}>Potential</Text>
        <Text style={{ color: 'white', fontSize: 14-(glovar.SIZE_LESS*2)}}>Trigger</Text>
        <Text style={{ color: 'white', fontSize: 14-(glovar.SIZE_LESS*2)}}>Target</Text>
        <Text style={{ color: 'white', fontSize: 14-(glovar.SIZE_LESS*2)}}>Stop</Text>
        <Text style={{ color: 'white', fontSize: 14-(glovar.SIZE_LESS*2)}}>Volatil</Text>
              </HStack>

        
           <HStack style={{ height: 30, flexDirection: 'row', marginBottom:20,
          width:"96%",
          }}> 
      <Box style={{ alignItems:'center', marginTop:4,  height: 26-4, width:'25%',flexDirection: 'row', 
                   backgroundColor: COLOR_SENTIDO,//this.getRankingColor(ratingID), 
                  marginLeft: 6, padding: 8-4-2, borderRadius: 8-4 }}>
                  <Text style={{color: 'white',
              fontSize: 14-(glovar.SIZE_LESS*2),
              alignItems:'center',
              alignContent:'center',
              textAlign:'center',
              fontWeight: 'bold',
              width:'100%',
              flexDirection: 'column',
              }}>{sentido_text}</Text></Box>
      
      
             <Text style={{color: COLOR_SENTIDO,//this.getRankingColor(ratingID),
             marginTop:4, marginLeft:6, fontWeight: 'bold',width:'18%',
              fontSize: 16-(glovar.SIZE_LESS*2)}}>+{potencial}%</Text>
                <Text style={{color: 'black', marginTop:4,fontWeight: 'bold',width:'19%',
              fontSize: 16-(glovar.SIZE_LESS*2)}}>{entrada}{moneda}</Text>
             <Text style={{color: 'black', marginTop:4,fontWeight: 'bold',width:'19%',
              fontSize: 16-(glovar.SIZE_LESS*2)}}>{rating_target}{moneda}</Text>
             <Text style={{color: 'black',marginTop:4,fontWeight: 'bold',width:'19%',
              fontSize: 16-(glovar.SIZE_LESS*2)}}>{rating_stop}{moneda}</Text>
              <Text style={{color: 'black',marginTop:4, marginLeft:10, fontWeight: 'bold',width:'12%',
              fontSize: 16-(glovar.SIZE_LESS*2)}}>{volatil}/{max_volatil}</Text>
             
              </HStack>
              
              </VStack>}

              {!glovar.SHOW_MOBILE_LAYOUT_SHORTTERM&&<Box p={1.5} mb={0}>
      {1==1&&<Text textColor="#666666" fontSize="14" mt={0} fontWeight="semibold" >Similar to {element.symbol}:</Text>}
      {1==1&&<CarruselTickers data={todoNews} symbol={element.symbol}/>}
      </Box>}

         </GridItem>}


            </GridItem>}

            {(FROM_URL_MENU==1 && glovar.TOPTRADERS_ACTIVE)&&<GridItem colSpan={14} rounded={10} backgroundColor="#FFFFFF" m={2} mt={1} pt={1} pb={2}> 
            <Text style={{ color: 'black',fontSize: 14, fontWeight:500, marginLeft:"10px",  paddingBottom:"4px"}}>
              TopTraders Ranking</Text>
      <ToptraderList name={"Djpedo en cabina"} price={"19.567,12"} diff={"+6.69%"}></ToptraderList>
      </GridItem>}

      {glovar.SHOW_MOBILE_LAYOUT_SHORTTERM&&<GridItem colSpan={14} rounded={10}  backgroundColor="#FFFFFF" m={2} mt={1}> 
      {(!params.OWN_USER&&glovar.APP_DOWNLOAD_ACTIVE)&&<AddContentApp mode={5} mobile={1}></AddContentApp>}
      

      <Box p={1.5}>
      {1==1&&<Text textColor="#666666" fontSize="14" mt={-1} fontWeight="semibold" >Similar to {element.symbol}:</Text>}
      {1==1&&<CarruselTickers data={todoNews} symbol={element.symbol}/>}
      </Box>

      </GridItem>}
    </SimpleGrid>
  
  
  
  
    <SimpleGrid columns={14} p={0} gap={2} m={0} >


    {1==2&&<GridItem colSpan={14} paddingTop={2} rounded={10}  backgroundColor="#FFFFFF" paddingBottom={2}> 
      
      <SearchAssetFront/>
      </GridItem>}

      <GridItem colSpan={14} rounded={10} mb={2} mt={1} ml={2} mr={2}> 
      <ShowMiniMenuBacktest fitxaDesk={1} ticker={element.shortName} symbol={element.symbol} element={element} />
      </GridItem>

      
      <GridItem colSpan={14} rounded={10} mb={-8}> 
      <ShowPopNewsletter fitxaDesk={1}/> 
      </GridItem>













      {!glovar.SHOW_MOBILE_LAYOUT_SHORTTERM&&<GridItem colSpan={14} rounded={10} backgroundColor="#FFFFFF" m={2} mt={-3} mb={1}> 
      {<GridItem colSpan={6} rounded={10} backgroundColor="#FFFFFF" minHeight="12pc"> 
          
      
        


   
           {!glovar.SHOW_MOBILE_LAYOUT_SHORTTERM&&<HStack fontSize="2xl" 
             zIndex={1}
             //marginBottom="-70px" 
             //marginBottom="30px"  
             marginTop="100px"
            >
        
        

      


       <button className="formSignInButtonMobileFront"  zIndex={1} _hover={{ cursor: "pointer" }} 
        onClick= {() => {
         setEmail(" ")
         //setshowPopPup(true);

         //this.setState({ ShowPopNewsLogin: true});
         setshowPopPup(!showPopPup);
         
         
         if(glovar.REGISTER_ONLINE==true) {
           //try { sendEventGA("button_sup","pressbtn_regonline", "pressbtn_regonline3"); } catch (exx) { }
           //window.location.replace('/register')
           }
         else {
             //try { sendEventGA("button_sup","pressbtn_register", "pressbtn_register"); } catch (exx) {}
             //window.location.replace('/registerApp')
         }
        }
        }
         style={{ width: "10pc", fontWeight:"450", zIndex:1, 
         marginLeft:"35%",  marginRight:"35%", //backgroundColor:"#555555",
         textAlign:"center"}}>
         {i18n.t("Login")}</button>    

       
             
         </HStack>}
         

         {!glovar.SHOW_MOBILE_LAYOUT_SHORTTERM&&<VStack
     
     opacity={0.4} // Ajusta la opacidad según desees
     position="relative"   
     zIndex="0"
    
         //width="100%"
         //height="140"
   >   

<HStack style={{ fontSize: 15-(glovar.SIZE_LESS*2), marginLeft:10, marginRight:10, marginTop:-120, 
   width:"98%",
   flexDirection: 'row', fontWeight: 'bold', backgroundColor:"#EEEEEE"}} >
        <Text style={{ marginLeft:10, color: 'black', justifyContent:'space-between'}}>
           Short Term</Text>
           <Spacer/>
           <Text style={{ marginRight:10, color: 'black', justifyContent:'space-between'}}>
           Techinchal Indicators</Text>

           </HStack>



      
      <HStack style={{ marginTop:0, marginBottom:0,
      marginLeft:10, marginRight:10, 
      width:"98%",
        height:140, 
        flexDirection: 'row', backgroundColor:"#FAFAFA" }} >
      <Box width={"25%"} marginLeft={0} marginRight={1}>
      
      <Box  background="white">
      {!isFetchingHistory&&<ChartCandle  data={ED_Candlesticks} colorLine={color1} />}
      </Box>
      </Box>
      <Box width={"26%"} >
        
        
      <Text style={{ color: 'black',  textAlign:'left', marginBottom:10, TextContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'650'}}>Candle Pattern</Text>
      
      <Text style={{ color: 'white', backgroundColor:'black', borderRadius:4, 
      paddingLeft:2, textAlign:"center", fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}>{candle_type}</Text>
      
      <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>Signal:</b> {candle_signal}</Text>
      <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>Reliability:</b> {candle_Reliability}</Text>
      <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>Candles Ago:</b> {candle_ago}</Text>
     
      {1==1&&<Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>Description: </b> ...</Text>}
    
      
      </Box>

      {1==2&&<Box width={"15%"} >
        
        <Text style={{ color: 'black',  textAlign:'left', TextContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'650', marginBottom:4, marginTop:2}}>Classic</Text>
       
        <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>R1:</b> {chart_r1}{moneda}</Text>
        <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>R2:</b> {chart_r2}{moneda}</Text>
        <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>R3:</b> {chart_r3}{moneda}</Text>
        <HStack style={{
          alignContent:'center', width:'70%', marginTop:'4px', marginBottom:'4px', 
          height:'1px', backgroundColor: '#999999', borderRadius: 5,
        }}/>

        <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>S1:</b> {chart_s1}{moneda}</Text>
        <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>S1:</b> {chart_s2}{moneda}</Text>
         </Box>}

         {1==2&&<Box width={"15%"} >
        
        <Text style={{ color: 'black',  textAlign:'left', TextContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'650', marginBottom:4, marginTop:2}}>Fibonacci</Text>
       
        <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>R1:</b> {fibo_r1}{moneda}</Text>
        <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>R2:</b> {fibo_r2}{moneda}</Text>
        <HStack style={{
          alignContent:'center', width:'70%', marginTop:'4px', marginBottom:'4px', 
          height:'1.5px', backgroundColor: '#999999', borderRadius: 5,
        }}/>
        <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>S1:</b> {fibo_s1}{moneda}</Text>
        <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>S2:</b> {fibo_s2}{moneda}</Text>
        <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>S3:</b> {fibo_s3}{moneda}</Text>
         </Box>}

         <Box width={"22%"} >
        
        <Text style={{ color: 'black',  textAlign:'left', TextContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'650', marginBottom:10}}>Moving Avg</Text>
       
        <HStack> <Box style={{  height: 6, width: 6,  backgroundColor: COLOR_BAJISTA,/*this.getRankingColor(ratingID),*/ 
                   borderRadius: 2, marginRight:-4 }}>
                 </Box>
                 <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>M05:</b> {avg_05}{moneda}</Text>
                 </HStack>

                 <HStack> <Box style={{  height: 6, width: 6,  backgroundColor: COLOR_BAJISTA,/*this.getRankingColor(ratingID),*/ 
                   borderRadius: 2, marginRight:-4 }}>
                 </Box>
                 <Text style={{ color: 'black',  justifyContent:'left',  fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>M30:</b>  {avg_30}{moneda}</Text>
                 </HStack>

                 <HStack> <Box style={{  height: 6, width: 6,  backgroundColor: COLOR_SENTIDO,/*this.getRankingColor(ratingID),*/ 
                   borderRadius: 2, marginRight:-4 }}>
                 </Box>
                 <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>M50:</b> {avg_50}{moneda}</Text>
                 </HStack>

                 <HStack> <Box style={{  height: 6, width: 6,  backgroundColor: COLOR_SENTIDO,/*this.getRankingColor(ratingID),*/ 
                   borderRadius: 2, marginRight:-4 }}>
                 </Box>
                 <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>M100:</b> {avg_100}{moneda}</Text>
                 </HStack>

                 <HStack> <Box style={{  height: 6, width: 6,  backgroundColor: COLOR_SENTIDO,/*this.getRankingColor(ratingID),*/ 
                   borderRadius: 2, marginRight:-4 }}>
                 </Box>
                 <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>M200:</b> {avg_200}{moneda}</Text>
                 </HStack>

     </Box>

      <Box width={"15%"} height="120px">
     
      
        <HStack marginLeft="2%"  marginTop="10px">

        <VStack height="100px" align="left" justifyContent="space-between">
       <Text style={{ color: 'black',  justifyContent:"space-between",  fontSize: 13-(glovar.SIZE_LESS*2), fontWeight:'500'}}>Strong Buy</Text>
       
        <Text style={{ color: 'black',  justifyContent:"space-between",   fontSize: 13-(glovar.SIZE_LESS*2), fontWeight:'500'}}>Neutral</Text>
       
        <Text style={{ color: 'black',  justifyContent:"space-between",  fontSize: 13-(glovar.SIZE_LESS*2), fontWeight:'500'}}>Strong Sell</Text>
          
       </VStack>

        <Box
  w='12%'
  h='100px'
  bgGradient='linear(#1EB41D 15%, #EE9F38 66%, #D61A1A 100%)'
 
/>
<HStack style={{ marginLeft:-20}}> 
          <Image style={{  marginLeft:RANGO_52S, width: 12, height: 12, marginTop:-10, resizeMode: 'contain', }} 
         src={`${process.env.PUBLIC_URL}/assets/triangleindiok.png`}/>
          </HStack>
          
      </HStack>

      </Box>
      </HStack>
      </VStack>}

      
           {!glovar.SHOW_MOBILE_LAYOUT_SHORTTERM&&<VStack
     
     opacity={0.4} // Ajusta la opacidad según desees
     position="relative"   
     zIndex="0"
         width="100%"
         //height="140"
   > 
          
         <HStack style={{
           width:"96%",
          alignItems:'center',
          marginInline:6,
          paddingInline:8,
          height: 20,
          flexDirection:'row',
          backgroundColor: 'black',
          marginTop: 12,
          justifyContent:"space-between",
          color: 'white',
          borderRadius: 0,
        }}>
              
            
        <Text style={{ color: 'white',width:'15%', fontSize: 14-(glovar.SIZE_LESS*2)}}>Trend</Text>
        <Text style={{ color: 'white', fontSize: 14-(glovar.SIZE_LESS*2)}}>Potential</Text>
        <Text style={{ color: 'white', fontSize: 14-(glovar.SIZE_LESS*2)}}>Trigger</Text>
        <Text style={{ color: 'white', fontSize: 14-(glovar.SIZE_LESS*2)}}>Target</Text>
        <Text style={{ color: 'white', fontSize: 14-(glovar.SIZE_LESS*2)}}>Stop</Text>
        <Text style={{ color: 'white', fontSize: 14-(glovar.SIZE_LESS*2)}}>Volatil</Text>
              </HStack>

        
           <HStack style={{ height: 30, flexDirection: 'row', marginBottom:20,
          width:"96%",
          }}> 
      <Box style={{ alignItems:'center', marginTop:4,  height: 26-4, width:'25%',flexDirection: 'row', 
                   backgroundColor: COLOR_SENTIDO,//this.getRankingColor(ratingID), 
                  marginLeft: 6, padding: 8-4-2, borderRadius: 8-4 }}>
                  <Text style={{color: 'white',
              fontSize: 14-(glovar.SIZE_LESS*2),
              alignItems:'center',
              alignContent:'center',
              textAlign:'center',
              fontWeight: 'bold',
              width:'100%',
              flexDirection: 'column',
              }}>{sentido_text}</Text></Box>
      
      
             <Text style={{color: COLOR_SENTIDO,//this.getRankingColor(ratingID),
             marginTop:4, marginLeft:6, fontWeight: 'bold',width:'18%',
              fontSize: 16-(glovar.SIZE_LESS*2)}}>+{potencial}%</Text>
                <Text style={{color: 'black', marginTop:4,fontWeight: 'bold',width:'19%',
              fontSize: 16-(glovar.SIZE_LESS*2)}}>{entrada}{moneda}</Text>
             <Text style={{color: 'black', marginTop:4,fontWeight: 'bold',width:'19%',
              fontSize: 16-(glovar.SIZE_LESS*2)}}>{rating_target}{moneda}</Text>
             <Text style={{color: 'black',marginTop:4,fontWeight: 'bold',width:'19%',
              fontSize: 16-(glovar.SIZE_LESS*2)}}>{rating_stop}{moneda}</Text>
              <Text style={{color: 'black',marginTop:4, marginLeft:10, fontWeight: 'bold',width:'12%',
              fontSize: 16-(glovar.SIZE_LESS*2)}}>{volatil}/{max_volatil}</Text>
             
              </HStack>
              
              </VStack>}

              

         </GridItem>}
         </GridItem>}
      
























      
    <GridItem colSpan={14} rounded={10} backgroundColor="#FFFFFF" m={2} mt={0}  >
  {isFetchingNews&&<Box mt={10}>
  <AddContentApp mode={5}></AddContentApp>
  </Box>}
  {!isFetchingNews&&<GridItem colSpan={14} rounded={10} backgroundColor="#FFFFFF" m={0.5} mt={0} >

  <Tabs isFitted  variantColor="red" mr={0} ml={0}  defaultIndex={0} >
  <TabList mb='0'>
    <Tab fontSize={14} fontWeight="semibold" color='gray' 
    _selected={{ color: 'black', borderBottomColor: "black", /*bg: 'blue.500'*/ }}>{i18n.t("noticias_rel")} </Tab>
    
  </TabList>

  <TabPanels>
    <TabPanel>
      

<Box overflowY="auto"mr={-2} ml={-5}  maxHeight={400}>
<RSSNewsList listRSS={todoNews} tag={searchedNews} tagStock={true}></RSSNewsList>
</Box>
    </TabPanel>
    
  </TabPanels>
</Tabs>





   </GridItem>}
   </GridItem>

   

      
    <GridItem colSpan={14} rounded={10}   backgroundColor="#FFFFFF" marginRight={2} marginLeft={2}> 
     


      <VStack mb={0} mt={0}>   
      {1==3&&glovar.DATA_COTIS2&&<Chart  data={glovar.DATA_COTIS2} colorLine={color1} />}
      {1==3&&!glovar.DATA_COTIS2&&<Chart  data={DATA_COTIS} colorLine={color1} />}
      {!isFetchingHistory&&<Chart  data={todoHistory} colorLine={color1} ticker={ticker} />}
      </VStack> 

      </GridItem>
  
       
      

  <GridItem colSpan={14} rounded={10} backgroundColor="#FFFFFF" m={2} mt={1}  >
  
  <Tabs isFitted  variantColor="red" mr={2} ml={2}  defaultIndex={glovar.DEFAULT_INDEX_TABS_FOLLOWS} >
  <TabList mb='0'>
    <Tab fontSize={14} fontWeight="semibold" color='gray' 
    _selected={{ color: 'black', borderBottomColor: "black", /*bg: 'blue.500'*/ }}>{i18n.t("datos_tecnicos")}  </Tab>
    {1==2&&<Tab fontSize={14} fontWeight="semibold" color='gray' 
    _selected={{ color: 'black',  borderBottomColor: "black" /*bg: 'blue.500'*/ }}>{i18n.t("info_fin")}</Tab>}
  </TabList>
  <TabPanels>
    <TabPanel paddingLeft={0.5} paddingRight={0.5} paddingTop={2}>
    
    
       <HStack mt="1.2pc" mb="1.2pc"> <HStack width="50%"  ml="0%" mr="5%" justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('apertura')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{element.regularMarketOpen}{moneda}</Text>
        </HStack>
        <HStack  width="50%"  ml="5%" mr="5%"  justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('cierre_ant')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{element.regularMarketPreviousClose}{moneda}</Text>
        </HStack></HStack>
        
        <HStack  mb="1.2pc"> <HStack width="50%"  ml="0%" mr="5%" justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('cap_bursatil')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{marketCAP}</Text>
        </HStack>
        <HStack  width="50%"  ml="5%" mr="5%"  justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('pres_resultados')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{fecha_resultados}</Text>
        </HStack></HStack>

        <HStack  mb="1.2pc"> <HStack width="50%"  ml="0%" mr="5%" justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('var_52s')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{element.fiftyTwoWeekLowChange}{moneda}</Text>
        </HStack>
        <HStack  width="50%"  ml="5%" mr="5%"  justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('var_p_52s')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{(element.fiftyTwoWeekLowChangePercent*100).toFixed(2)}%</Text>
        </HStack></HStack>

        <HStack  mb="1.2pc"> <HStack width="50%"  ml="0%" mr="5%" justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('vol3m')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{element.averageDailyVolume3Month}</Text>
        </HStack>
        <HStack  width="50%"  ml="5%" mr="5%"  justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('vol10d')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{element.averageDailyVolume10Day}</Text>
        </HStack></HStack>


        <HStack  mb="1.2pc"> <HStack width="50%"  ml="0%" mr="5%" justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('per_ratio')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{PER}</Text>
        </HStack>
        <HStack  width="50%"  ml="5%" mr="5%"  justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('benef_acc')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{benef_accion}</Text>
        </HStack></HStack>


        <HStack  mb="1.2pc"> <HStack width="50%"  ml="0%" mr="5%" justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('dividendo')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{Dividendo}</Text>
        </HStack>
        <HStack  width="50%"  ml="5%" mr="5%"  justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('rent_div')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{Rent_dividendo}</Text>
        </HStack></HStack>

        {1==2&&<Text textColor="#666666" fontSize="14" mt={-1} fontWeight="semibold" >Similar to {element.symbol}:</Text>}
        {1==2&&<CarruselTickers data={todoNews} symbol={element.symbol}/>}
       


    </TabPanel>
    <TabPanel paddingLeft={0.5} paddingRight={0.5} paddingTop={2}>
    
    </TabPanel>
  </TabPanels>
</Tabs>
  
     </GridItem>

     {1==2&&<GridItem colSpan={14} marginTop={4} rounded={10} marginBottom={-8}> 
      
      <ShowPopNewsletter fitxaDesk={1}/>
      </GridItem>}
     

   </SimpleGrid> 
  
  
  <Box mt="20%">
  <FooterMenu/>
  </Box>
  
  
  </Box> 
   );
      }
      else
  {

    const chartStyle = {
      position: 'relative',
      width: '100%',
      height: '100%',
      backgroundImage: `${process.env.PUBLIC_URL}/assets/logopro1xsmall.png` , // Replace with the actual path to your image
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center'
      };
      

      if(FULL_SIZE_CHART) 
      {

        return (
    
          
          <Box  backgroundColor="#F3F2EF">
            {FROM_URL_MENU==0&&<CookieConsentPopup/>}
            <Box>
          <HeaderMenu showSearch={true}/>
         </Box>
        
          <SimpleGrid columns={14} gap={1} p={2}>
            <GridItem colSpan={1}>
            <Box mt={4}>
          <VStack>
            <ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/ic_briefcase_white_48dp.png`} id={201} />
            {1==1&&<ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/ic_compass_white_48dp.png`} id={13} />}
            {1==2&&<ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/baseline_notifications_white_48.png`} id={12} />}
            {1==2&&<ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/ic_podium_white_24dp.png`} id={202} />}
            {glovar.MODE_USER>=1&&<ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/baseline_account_box_white_48.png`} id={3} />}
           
            {1==2&&<ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/ic_chart_areaspline_white_24dp.png`} id={4} />}
          </VStack>
        </Box>
            </GridItem>
            

            <GridItem colSpan={12} rounded={10}  backgroundColor="#FFFFFF" m={0}> 
     


      <VStack mb={2} mt={2} 
      background-size= "auto"
			backgroundRepeat="no-repeat"
			backgroundPosition="center"
      
       // Adjust the opacity as needed
      backgroundImage={`${process.env.PUBLIC_URL}/assets/logosmallbn2.png`}>  
      
      
      {!isFetchingHistory&&<Chart  data={todoHistory} colorLine={color1} labelChart={name} ticker={ticker} fullsizeChart={1}/>}
     
   
 
      </VStack> 

      </GridItem>


            </SimpleGrid>

            
    <SimpleGrid columns={14} gap={1} p={2} mt={-1} mb={-1} >
    <GridItem colStart={2} colEnd={14} marginTop={0} marginBottom={0}> 
      
      <ShowPopNewsletter fitxaDesk={1}/>
      </GridItem>
      </SimpleGrid>

            </Box>
            );


      }
      else {
   return (
    
    <Box  backgroundColor="#F3F2EF">
       {FROM_URL_MENU==0&&<CookieConsentPopup/>}
      <Box>
    <HeaderMenu showSearch={true}/>
   </Box>

   {showPopPup&&
      <Box zIndex={3} background="red"position="absolute"
>
        <div className='popup'>
        <div className='popup_inner'>
        <VStack width="100%" flexDirection="column" justifyContent="center" p={5} pt={2} pb={20} alignContent="center">
        <Text textAlign="center" fontSize="24" textColor="black" fontFamily="Tahoma" fontWeight="semibold" marginBottom={2}> 
        {i18n.t("op_no_dispo")}
          </Text>
          <Text textAlign="center" mt={10} fontSize="16" textColor="black" fontFamily="Tahoma" fontWeight="normal" > 
          {i18n.t("quieres_activa")}  <strong>{i18n.t("subs_news")}</strong>
          </Text>
        
          <Text textAlign="center" mt={5} fontSize="16" textColor="black" fontFamily="Tahoma" fontWeight="normal" > 
          {i18n.t("activ_func_aviso")}
          </Text>

          <div className="formField" 
          //key={keyform}
          >
            <input
            
              type="email"
              id="email"
              className="formFieldInput"
              placeholder={i18n.t("intro_email")}
              name="email"
              value={email}
              required
              onChange={e => setEmail(e.target.value)}
              //value={this.state.email}
              //onChange={this.handleChange}
  
  
            />
          </div>

          <Flex marginTop={15}>
          <button className="formSignInButton" marginTop={5} _hover={{ cursor: "pointer" }} 
        onClick= {(e) =>
         {
          //sendEmail(e)

          {
            e.preventDefault();
        
            try {
            if(email.length>10 && email.includes("@") && email.includes(".")) {
        
             var templateParams = {
                email_subscriber: email,
            };
            
        
             templateParams.email_subscriber=email;
             emailjs.send(glovar.REF_EMAIL_SERVICE, glovar.REF_EMAIL_TEMPLATE, templateParams, glovar.REF_EMAIL_KEY)
             .then(function(response) {
               //console.log('SUCCESS!', response.status, response.text);
             }, function(error) {
               //console.log('FAILED...', error);
             });
            }
            else {
              
            }
        
            }
            catch(Exxx) {}
          };

          //console.log("buscar!!")
          setshowPopPup(false)
         }
         }
         style={{ paddingLeft:20, paddingRight:20, marginTop:"10px", fontWeight:"semibold", textAlign:"center", fontSize:"large", marginLeft:5}}>
         {i18n.t("subs_mayus")}</button>
         </Flex>   
         <Text  as='u' textAlign="center" paddingTop="10px" fontSize="12" textColor="black" fontFamily="Tahoma" 
         _hover={{ cursor: "pointer" }} fontWeight="normal" 
         onClick= {() =>
          {
 
           //console.log("buscar!!")
           setshowPopPup(false)
          }
          }> 
          {i18n.t("no_gracias")}
          </Text>

         </VStack>
        </div>
      </div>
      </Box>}
  
    <SimpleGrid columns={14} gap={1} p={2}>
      <GridItem colSpan={1}>
      <Box mt={4}>
    <VStack>
      <ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/ic_briefcase_white_48dp.png`} id={201} />
      {1==1&&<ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/ic_compass_white_48dp.png`} id={13} />}
      {1==2&&<ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/baseline_notifications_white_48.png`} id={12} />}
      {1==2&&<ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/ic_podium_white_24dp.png`} id={202} />}
      {glovar.MODE_USER>=1&&<ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/baseline_account_box_white_48.png`} id={3} />}
     
      {1==2&&<ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/ic_chart_areaspline_white_24dp.png`} id={4} />}
    </VStack>
  </Box>
      </GridItem>
     
      
      {<GridItem colSpan={6} rounded={10} backgroundColor="#FFFFFF" minHeight="16pc"> 
          
      
       <HStack ml={4} mr={0} mt={1}  justifyContent="space-between" width="100%">
        <Text width="100%" mt={0} fontSize="28" textColor="black"  fontWeight="semibold" > 
         {name}    
        </Text>
        <Box width="45%">
     
      <AddContentApp mode={7} name={name} symbol={element.symbol} valor={element} /*fav={isValorFAV(element.symbol)}*/></AddContentApp>
      
      </Box>
       </HStack>
       
      <HStack  ml={4} mr={4} justifyContent="space-between">
      <VStack alignItems='left'>
      <Text fontSize="36" mt={-4} mb={-4} fontWeight="bold" >{coti}{moneda}</Text>
      <Text paddingLeft={1} fontSize="16" textColor="black" mb={-1} fontWeight="normal" > 
         {element.regularMarketDayLow}{moneda} -   {element.regularMarketDayHigh}{moneda}     
        </Text>
        </VStack>

        {(EXTRA_INFO_MARKET>=1)&&<VStack alignItems='center'background="#F2F2F2" round={10} pl={2} pr={2} pt={1} pb={1} rounded={10} >
        <Text textColor="black" fontSize={13} textDecoration="underline" mt={-1} mb={-2}>{extraMarketText}</Text>
        
      <HStack><Text fontSize="16" mt={-4} mb={-4} fontWeight="bold">{extraMarketPrice}{moneda}</Text>
      <Text paddingLeft={0} fontSize="14"   mt={-4} mb={-4} textColor={(extraMarketChange<0) ? "red" : "green"}
          fontWeight="bold"
          align="right"
          //w={"2pc"}
        >
          {extraMarketChange} ({extraMarketChangePercent}%)
        </Text></HStack>
        </VStack>}
        

      <div alignItems='right'>
      <Text mt={-2} fontSize="17"  align="right" textColor="black" textAlign="right" fontWeight="bold" > 
         {element.symbol}       
        </Text>
      <HStack>
      <Text
          fontSize="16"
          textColor={loss ? "red" : "green"}
          fontWeight="semibold"
          align="right"
          //w={"2pc"}
        >
          {simbol}{change}
        </Text>
      <Box
        borderRadius="lg"
        fontSize="16"
        fontWeight="semibold"
        textAlign="center"
        paddingTop={0.5} paddingBottom={0.5}
        paddingLeft={4}paddingRight={4}
        //w={"4pc"}
        //h={"1.5pc"}
        bg={color1}
        textColor={color2}
      >
        {simbol}{percent}%
      </Box>
     
      </HStack>
      <Text fontSize="13" align="right" textColor="black"  textAlign="right" fontWeight="normal" > 
        Vol: {element.regularMarketVolume}       
        </Text>
      </div>


    

      </HStack>

      
      {glovar.TOPTRADERS_ACTIVE&&<CarrusselTopTraders symbol={element.symbol}/>}
       

      {(glovar.MODE_USER<2)&&
      <HStack  background="lightgray"  marginLeft="20px" marginRight="20px" rounded={9} mt="0.5pc" justifyContent='space-around'>
        {1==1&&<AdComponent468/>}</HStack>}

      {1==2&&<div>
      {(glovar.MODE_USER<2)&&<Text fontSize="15" align="left" ml={5} mt="0pc"/*"5pc"*/ mb="0px"/*"10px"*/ textColor="#666666"  fontWeight="semibold" > 
      {i18n.t("rango_52s")}  
        </Text>}

        {(glovar.MODE_USER>=2)&&<Text fontSize="15" align="left" ml={5} mt="3pc" mb="10px" textColor="#666666"  fontWeight="semibold" > 
      {i18n.t("rango_52s")}  
        </Text>}

        <HStack style={{ marginTop:0, marginLeft:20, marginRight:20, height:10, flexDirection: 'row', backgroundColor:'#E0E0E0' }}>
          </HStack>
        
          <HStack style={{ marginLeft:20}}> 
          <Image style={{  marginLeft:RANGO_52S, width: 16, height: 16, marginTop:-10, resizeMode: 'contain', }} 
         src={`${process.env.PUBLIC_URL}/assets/triangleok.png`}/>
          </HStack>

          {(glovar.MODE_USER<2)&&<HStack style={{  marginBottom:"0pc", justifyContent:'space-between', marginLeft:20, marginRight:20, flexDirection:'row', marginTop: -4 }}>
        <Text style={{ color: 'black',fontSize: 16-(glovar.SIZE_LESS*2), fontWeight: 'bold'}}>{element.fiftyTwoWeekLow}{moneda}</Text>
        <Text style={{ color: 'black',fontSize: 16-(glovar.SIZE_LESS*2), fontWeight: 'bold'}}>{element.fiftyTwoWeekHigh}{moneda}</Text>
        </HStack>}

        {(glovar.MODE_USER>=2)&&<HStack style={{  marginBottom:"0.5pc", justifyContent:'space-between', marginLeft:20, marginRight:20, flexDirection:'row', marginTop: 4 }}>
        <Text style={{ color: 'black',fontSize: 16-(glovar.SIZE_LESS*2), fontWeight: 'bold'}}>{element.fiftyTwoWeekLow}{moneda}</Text>
        <Text style={{ color: 'black',fontSize: 16-(glovar.SIZE_LESS*2), fontWeight: 'bold'}}>{element.fiftyTwoWeekHigh}{moneda}</Text>
        </HStack>}
        </div>}


        <VStack
     
     opacity={0.4} // Ajusta la opacidad según desees
     position="relative"   
     zIndex="0"
         //width="100%"
         //height="140"
   >   

<HStack style={{ fontSize: 15-(glovar.SIZE_LESS*2), marginLeft:10, marginRight:10, marginTop:10,
   width:"98%",
   flexDirection: 'row', fontWeight: 'bold', backgroundColor:"#EEEEEE"}} >
        <Text style={{ marginLeft:10, color: 'black', justifyContent:'space-between'}}>
           Short Term</Text>
           <Spacer/>
           <Text style={{ marginRight:10, color: 'black', justifyContent:'space-between'}}>
           Techinchal Indicators</Text>

           </HStack>



      
      <HStack style={{ marginTop:0, 
      marginLeft:10, marginRight:10, 
      width:"98%",
        height:140, 
        flexDirection: 'row', backgroundColor:"#FAFAFA" }} >
      <Box width={"18%"} marginLeft={1}>
      
      <Box  background="white">
      {!isFetchingHistory&&<ChartCandle  data={ED_Candlesticks} colorLine={color1} />}
      </Box>
      </Box>
      <Box width={"26%"} >
        
        
      <Text style={{ color: 'black',  textAlign:'left', marginBottom:10, TextContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'650'}}>Candlestick Pattern</Text>
      
      <Text style={{ color: 'white', backgroundColor:'black', borderRadius:4, 
      paddingLeft:2, textAlign:"center", fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}>{candle_type}</Text>
      
      <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>Signal:</b> {candle_signal}</Text>
      <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>Reliability:</b> {candle_Reliability}</Text>
      <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>Candles Ago:</b> {candle_ago}</Text>
     
      {1==2&&<Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>Description:</b> <u>More...</u></Text>}
    
      
      </Box>

      <Box width={"15%"} >
        
        <Text style={{ color: 'black',  textAlign:'left', TextContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'650', marginBottom:4, marginTop:2}}>Classic</Text>
       
        <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>R1:</b> {chart_r1}{moneda}</Text>
        <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>R2:</b> {chart_r2}{moneda}</Text>
        <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>R3:</b> {chart_r3}{moneda}</Text>
        <HStack style={{
          alignContent:'center', width:'70%', marginTop:'4px', marginBottom:'4px', 
          height:'1px', backgroundColor: '#999999', borderRadius: 5,
        }}/>

        <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>S1:</b> {chart_s1}{moneda}</Text>
        <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>S1:</b> {chart_s2}{moneda}</Text>
         </Box>

         <Box width={"15%"} >
        
        <Text style={{ color: 'black',  textAlign:'left', TextContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'650', marginBottom:4, marginTop:2}}>Fibonacci</Text>
       
        <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>R1:</b> {fibo_r1}{moneda}</Text>
        <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>R2:</b> {fibo_r2}{moneda}</Text>
        <HStack style={{
          alignContent:'center', width:'70%', marginTop:'4px', marginBottom:'4px', 
          height:'1.5px', backgroundColor: '#999999', borderRadius: 5,
        }}/>
        <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>S1:</b> {fibo_s1}{moneda}</Text>
        <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>S2:</b> {fibo_s2}{moneda}</Text>
        <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>S3:</b> {fibo_s3}{moneda}</Text>
         </Box>

         <Box width={"20%"} >
        
        <Text style={{ color: 'black',  textAlign:'left', TextContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'650', marginBottom:10}}>Moving Avg</Text>
       
        <HStack> <Box style={{  height: 6, width: 6,  backgroundColor: COLOR_BAJISTA,/*this.getRankingColor(ratingID),*/ 
                   borderRadius: 2, marginRight:-4 }}>
                 </Box>
                 <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>M05:</b> {avg_05}{moneda}</Text>
                 </HStack>

                 <HStack> <Box style={{  height: 6, width: 6,  backgroundColor: COLOR_BAJISTA,/*this.getRankingColor(ratingID),*/ 
                   borderRadius: 2, marginRight:-4 }}>
                 </Box>
                 <Text style={{ color: 'black',  justifyContent:'left',  fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>M30:</b>  {avg_30}{moneda}</Text>
                 </HStack>

                 <HStack> <Box style={{  height: 6, width: 6,  backgroundColor: COLOR_SENTIDO,/*this.getRankingColor(ratingID),*/ 
                   borderRadius: 2, marginRight:-4 }}>
                 </Box>
                 <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>M50:</b> {avg_50}{moneda}</Text>
                 </HStack>

                 <HStack> <Box style={{  height: 6, width: 6,  backgroundColor: COLOR_SENTIDO,/*this.getRankingColor(ratingID),*/ 
                   borderRadius: 2, marginRight:-4 }}>
                 </Box>
                 <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>M100:</b> {avg_100}{moneda}</Text>
                 </HStack>

                 <HStack> <Box style={{  height: 6, width: 6,  backgroundColor: COLOR_SENTIDO,/*this.getRankingColor(ratingID),*/ 
                   borderRadius: 2, marginRight:-4 }}>
                 </Box>
                 <Text style={{ color: 'black',  justifyContent:'left', fontSize: 14-(glovar.SIZE_LESS*2), fontWeight:'500'}}><b>M200:</b> {avg_200}{moneda}</Text>
                 </HStack>

     </Box>

      <Box width={"15%"} height="120px">
     
      
        <HStack marginLeft="2%"  marginTop="10px">

        <VStack height="100px" align="left" justifyContent="space-between">
       <Text style={{ color: 'black',  justifyContent:"space-between",  fontSize: 13-(glovar.SIZE_LESS*2), fontWeight:'500'}}>Strong Buy</Text>
       
        <Text style={{ color: 'black',  justifyContent:"space-between",   fontSize: 13-(glovar.SIZE_LESS*2), fontWeight:'500'}}>Neutral</Text>
       
        <Text style={{ color: 'black',  justifyContent:"space-between",  fontSize: 13-(glovar.SIZE_LESS*2), fontWeight:'500'}}>Strong Sell</Text>
          
       </VStack>

        <Box
  w='12%'
  h='100px'
  bgGradient='linear(#1EB41D 15%, #EE9F38 66%, #D61A1A 100%)'
 
/>
<HStack style={{ marginLeft:-20}}> 
          <Image style={{  marginLeft:RANGO_52S, width: 12, height: 12, marginTop:-10, resizeMode: 'contain', }} 
         src={`${process.env.PUBLIC_URL}/assets/triangleindiok.png`}/>
          </HStack>
          
      </HStack>

      </Box>
      </HStack>
      </VStack>

      

             <HStack fontSize="2xl" 
             zIndex={1} marginTop="-76px"  marginBottom="30px"  >
        
        

      


       <button className="formSignInButton"  zIndex={1} _hover={{ cursor: "pointer" }} 
        onClick= {() => {
         setEmail(" ")
         //setshowPopPup(true);

         //this.setState({ ShowPopNewsLogin: true});
         setshowPopPup(!showPopPup);
         
         
         if(glovar.REGISTER_ONLINE==true) {
           //try { sendEventGA("button_sup","pressbtn_regonline", "pressbtn_regonline3"); } catch (exx) { }
           //window.location.replace('/register')
           }
         else {
             //try { sendEventGA("button_sup","pressbtn_register", "pressbtn_register"); } catch (exx) {}
             //window.location.replace('/registerApp')
         }
        }
        }
         style={{ width: "10pc", fontWeight:"450", zIndex:1,  
         marginLeft:"40%",  marginRight:"40%", //backgroundColor:"#555555",
         textAlign:"center"}}>
         {i18n.t("Login")}</button>    

       
             
         </HStack>


   
         <VStack
     
     opacity={0.4} // Ajusta la opacidad según desees
     position="relative"   
     zIndex="0"
         width="100%"
         //height="140"
   >    
        <HStack style={{
          alignItems:'center',
          width:"98%",
          //marginInline:20,
          marginInline:10,
          paddingInline:8,
          height: 20,
          flexDirection:'row',
          backgroundColor: 'black',
          marginTop: 22,
          justifyContent:"space-between",
          backgroundColor:"#EEEEEE",
          color: 'black',
          fontSize: 15-(glovar.SIZE_LESS*2),
          borderRadius: 0,
          fontWeight: 'bold',
        }}>
              
              



            
        <Text style={{ width:'15%', fontSize: 15-(glovar.SIZE_LESS*2)}}>Trend</Text>
        <Text style={{ fontSize: 15-(glovar.SIZE_LESS*2)}}>Potential</Text>
        <Text style={{ fontSize: 15-(glovar.SIZE_LESS*2)}}>Trigger</Text>
        <Text style={{ fontSize: 15-(glovar.SIZE_LESS*2)}}>Target</Text>
        <Text style={{ fontSize: 15-(glovar.SIZE_LESS*2)}}>Stop</Text>
        <Text style={{ fontSize: 15-(glovar.SIZE_LESS*2)}}>Volatil</Text>
              </HStack>

        

      

    
           <HStack style={{ height: 30,   
      width:"97%", flexDirection: 'row', marginBottom:20, marginInline:10,
           justifyContent:"space-between" }}> 

      <Box style={{ alignItems:'center', marginTop:4,  height: 26-4,flexDirection: 'row',width:'15%',
                   backgroundColor: COLOR_SENTIDO,//this.getRankingColor(ratingID), 
                  marginLeft: 0, padding: 8-4-2, borderRadius: 8-4 }}>
                  <Text style={{color: 'white',
              fontSize: 14-(glovar.SIZE_LESS*2),
              alignItems:'center',
              alignContent:'center',
              textAlign:'center',
              fontWeight: 'bold',
              width:'100%',
              flexDirection: 'column',
              }}>{sentido_text}</Text></Box>
      
      
             <Text style={{color: COLOR_SENTIDO,//this.getRankingColor(ratingID),
             marginTop:4, marginLeft:6, fontWeight: 'bold', textAlign:'center',
              fontSize: 16-(glovar.SIZE_LESS*2)}}>+{potencial}%</Text>
                <Text style={{color: 'black', marginTop:4,fontWeight: 'bold',
              fontSize: 16-(glovar.SIZE_LESS*2)}}>{entrada}{moneda}</Text>
             <Text style={{color: 'black', marginTop:4,fontWeight: 'bold',
              fontSize: 16-(glovar.SIZE_LESS*2)}}>{rating_target}{moneda}</Text>
             <Text style={{color: 'black',marginTop:4,fontWeight: 'bold',
              fontSize: 16-(glovar.SIZE_LESS*2)}}>{rating_stop}{moneda}</Text>
              <Text style={{color: 'black',marginTop:4, marginLeft:0, fontWeight: 'bold', marginRight:10,
              fontSize: 16-(glovar.SIZE_LESS*2)}}>{volatil}/{max_volatil}</Text>
             
              </HStack>

</VStack>


              <HStack p={1.5} alignContent="center" marginLeft={2}>
      <Text textColor="#666666" fontSize="14" mt={-1} fontWeight="semibold" >Similar to {element.symbol}:</Text>
      <CarruselTickers data={todoNews} symbol={element.symbol}/>
      </HStack>
       
      </GridItem>}
      <GridItem colSpan={6} rounded={10}  backgroundColor="#FFFFFF" m={0}> 
     


      <VStack mb={2} mt={2} 
      background-size= "auto"
			backgroundRepeat="no-repeat"
			backgroundPosition="center"
      
       // Adjust the opacity as needed
      backgroundImage={`${process.env.PUBLIC_URL}/assets/logosmallbn2.png`}>  
      
      
      {1==3&&glovar.DATA_COTIS2&&<Chart  data={glovar.DATA_COTIS2} colorLine={color1} />}
      {1==3&&!glovar.DATA_COTIS2&&<Chart  data={DATA_COTIS} colorLine={color1} />}
      {(1==1)&&!isFetchingHistory&&<Chart  data={todoHistory} colorLine={color1} labelChart={name} ticker={ticker} />}
     
   
 
      </VStack> 

      </GridItem>
    </SimpleGrid>
  




    <SimpleGrid columns={14} gap={1} p={2} mt={-1} mb={-1} >
    <GridItem colStart={2} colEnd={14} marginTop={0} marginBottom={0}> 
      
      <ShowMiniMenuBacktest fitxaDesk={1} ticker={element.shortName} symbol={element.symbol} element={element}/>
      {1==3 && <ShowPopNewsletter fitxaDesk={1}/>}
      </GridItem>
      </SimpleGrid>


  
    <SimpleGrid columns={14} gap={1} p={2} mt={-1} >
  
  <GridItem colSpan={1}>
   
  
    <Box mt={2}>
  <VStack>
    {1==1&&<ItemSibedarRRSS icon={`${process.env.PUBLIC_URL}/assets/ic_twitter_white_24dp.png`} id={101} />}
    {1==2&&<ItemSibedarRRSS icon={`${process.env.PUBLIC_URL}/assets/ic_instagram_white_24dp.png`} id={102} />}
    
  </VStack>
  </Box>
    </GridItem>
  
  
    {<GridItem colSpan={6} rounded={10} backgroundColor="#FFFFFF" minHeight="20pc"> 

       
    {(FROM_URL_MENU==1&&1==2)&&<GridItem colSpan={14} rounded={10} backgroundColor="#FFFFFF" m={2} mt={1} pt={1} pb={2}> 
            <Text style={{ color: 'black',fontSize: 14, fontWeight:500, marginLeft:"10px",  paddingBottom:"4px"}}>
              TopTraders Ranking</Text>
      <ToptraderList name={"Djpedo en cabina"} price={"19.567,12"} diff={"+6.69%"}></ToptraderList>
      </GridItem>}

      
    <Tabs isFitted  variantColor="red" mr={2} ml={2}  defaultIndex={glovar.DEFAULT_INDEX_TABS_FOLLOWS} >
  <TabList mb='0'>
    <Tab fontSize={14} fontWeight="semibold" color='gray' 
    _selected={{ color: 'black', borderBottomColor: "black", /*bg: 'blue.500'*/ }}>{i18n.t("datos_tecnicos")}  </Tab>
    {1==2&&<Tab fontSize={14} fontWeight="semibold" color='gray' 
    _selected={{ color: 'black',  borderBottomColor: "black" /*bg: 'blue.500'*/ }}>{i18n.t("info_fin")}</Tab>}
  </TabList>
  <TabPanels>
    <TabPanel paddingLeft={0.5} paddingRight={0.5} paddingTop={2}>
    
    
       <HStack mt="1.2pc" mb="1.2pc"> <HStack width="50%"  ml="0%" mr="5%" justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('apertura')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{element.regularMarketOpen}{moneda}</Text>
        </HStack>
        <HStack  width="50%"  ml="5%" mr="5%"  justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('cierre_ant')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{element.regularMarketPreviousClose}{moneda}</Text>
        </HStack></HStack>
        
        <HStack  mb="1.2pc"> <HStack width="50%"  ml="0%" mr="5%" justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('cap_bursatil')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{marketCAP}</Text>
        </HStack>
        <HStack  width="50%"  ml="5%" mr="5%"  justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('pres_resultados')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{fecha_resultados}</Text>
        </HStack></HStack>

        <HStack  mb="1.2pc"> <HStack width="50%"  ml="0%" mr="5%" justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('var_52s')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{element.fiftyTwoWeekLowChange}{moneda}</Text>
        </HStack>
        <HStack  width="50%"  ml="5%" mr="5%"  justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('var_p_52s')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{(element.fiftyTwoWeekLowChangePercent*100).toFixed(2)}%</Text>
        </HStack></HStack>

        <HStack  mb="1.2pc"> <HStack width="50%"  ml="0%" mr="5%" justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('52_week_low')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{element.fiftyTwoWeekLow}{moneda}</Text>
        </HStack>
        <HStack  width="50%"  ml="5%" mr="5%"  justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('52_week_high')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{element.fiftyTwoWeekHigh}{moneda}</Text>
        </HStack></HStack>
        
        <HStack  mb="1.2pc"> <HStack width="50%"  ml="0%" mr="5%" justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('vol3m')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{element.averageDailyVolume3Month}</Text>
        </HStack>
        <HStack  width="50%"  ml="5%" mr="5%"  justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('vol10d')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{element.averageDailyVolume10Day}</Text>
        </HStack></HStack>


        <HStack  mb="1.2pc"> <HStack width="50%"  ml="0%" mr="5%" justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('per_ratio')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{PER}</Text>
        </HStack>
        <HStack  width="50%"  ml="5%" mr="5%"  justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('benef_acc')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{benef_accion}</Text>
        </HStack></HStack>


        <HStack  mb="1.2pc"> <HStack width="50%"  ml="0%" mr="5%" justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('dividendo')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{Dividendo}</Text>
        </HStack>
        <HStack  width="50%"  ml="5%" mr="5%"  justifyContent="space-between">
        <Text textColor="#666666" fontSize="14" fontWeight="semibold" >{i18n.t('rent_div')}</Text>
        <Text style={{ color: 'black',fontSize: 15, fontWeight: 'bold'}}>{Rent_dividendo}</Text>
        </HStack></HStack>


  



        <Text textColor="#666666" fontSize="14" mt={-1} fontWeight="semibold" >Similar to {element.symbol}:</Text>
         <CarruselTickers data={todoNews} symbol={element.symbol}/>
       


    </TabPanel>
    <TabPanel paddingLeft={0.5} paddingRight={0.5} paddingTop={2}>
    
    </TabPanel>
  </TabPanels>
</Tabs>

   
          
         </GridItem>}


  <GridItem colSpan={6} rounded={10} backgroundColor="#FFFFFF" m={0} mt={0}  >
  {isFetchingNews&&<Box mt={10}>
  <AddContentApp mode={5}></AddContentApp>
  </Box>}
  {!isFetchingNews&&<GridItem colSpan={6} rounded={10} backgroundColor="#FFFFFF" m={0.5} mt={0} >

  <Tabs isFitted  variantColor="red" mr={0} ml={0}  defaultIndex={0} >
  <TabList mb='0'>
    <Tab fontSize={14} fontWeight="semibold" color='gray' 
    _selected={{ color: 'black', borderBottomColor: "black", /*bg: 'blue.500'*/ }}>{i18n.t("noticias_rel")}</Tab>
    
  </TabList>

  <TabPanels>
    <TabPanel>
      

<Box overflowY="auto"mr={-2} ml={-5}  maxHeight={340}>
<RSSNewsList listRSS={todoNews} tag={searchedNews} tagStock={true}></RSSNewsList>
</Box>
    </TabPanel>
    
  </TabPanels>
</Tabs>





   </GridItem>}
       </GridItem>
       <GridItem colStart={2} colEnd={14} marginTop={2} marginBottom={0}> 
       {1==1 && <ShowPopNewsletter fitxaDesk={1}/>}
       </GridItem>
   </SimpleGrid> 
  
  
   <Box mt="10%">
  <FooterMenu/>
  </Box>
  
  </Box> 
   );
            }
  }
    }
  }
      

     


      
    }
  


    function changeSwitchCartera()
    {
      //SHOW_PUBLIC_CARTERA=!SHOW_PUBLIC_CARTERA;
      this.setState({});
    }

    function updateUserPortfolioOptions() {
      try 
          {
           var headers = new Headers(); 
  
           
  
           headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API_POST + ":" + glovar.PASS_API_POST));
           headers.set('Content-Type', 'application/json');
       
  
           const response = fetch(glovar.URL_BACKEND_PROD+'/api/users', {
                 //mode: 'no-cors', //si deixo no-cors no deixa ficar headers
                 //method: "POST",
                 method: "PUT",  
                 headers: headers,
                
                 body: 
                 JSON.stringify({ 
                  name: glovar.USER_USERNAME,//glovar.CURRENT_USER.name, (es el id del user)
                  SHOW_PUBLIC_PROFILE_USER: glovar.SHOW_PUBLIC_PROFILE_USER,
                  SHOW_PUBLIC_PROFILE_USER_INFO: glovar.SHOW_PUBLIC_PROFILE_USER_INFO,
                  SHOW_PUBLIC_PROFILE_BTNFOLLOW: glovar.SHOW_PUBLIC_PROFILE_BTNFOLLOW,
                  SHOW_PUBLIC_CARTERA: glovar.SHOW_PUBLIC_CARTERA,
                  SHOW_PUBLIC_CARTERA_TIT_BENEF: glovar.SHOW_PUBLIC_CARTERA_TIT_BENEF,
                  SHOW_PUBLIC_CARTERA_ONLY_FOLLOW: glovar.SHOW_PUBLIC_CARTERA_ONLY_FOLLOW,
                  SHOW_PUBLIC_HISTORIAL_ONLY_FOLLOW: glovar.SHOW_PUBLIC_HISTORIAL_ONLY_FOLLOW,
                  SHOW_PUBLIC_HISTORIAL: glovar.SHOW_PUBLIC_HISTORIAL,
                  SHOW_PUBLIC_HISTORIAL_TIT_BENEF: glovar.SHOW_PUBLIC_HISTORIAL_TIT_BENEF,
                  //NUM_FOLLOWERS: user.NUM_FOLLOWERS,
                  //NUM_VISITAS: user.NUM_VISITAS,
                  //NUM_LIKES: user.NUM_LIKES
                })
           }) 
  
           //console.log("pujat correctament updateUserPortfolioOptions ");  //+glovar.CURRENT_USER.name)
          }
          catch(Exx) { /*console.log("PETADA pujat dades user: "+Exx)*/ }
     }

  
    function PublicPortfolio(params) {
     
      let { id } = useParams();
      

      var show_cartera=false;
      var edit_stock=false;

      const [stock, setStock] = useState(edit_stock) 
      useEffect(() => { setStock(edit_stock); }, [edit_stock]);


      //console.log(params.OWN_USER)
      if(params.OWN_USER==1) {
        id=glovar.USER_USERNAME;
      }
      

      var NOSHOW_NOFOLLOWER=false;
     


      //SHOW_MY_PROFILE
      var PROFILE_CACHE=false;
      const obj = {}
      try { if(JSON.stringify(glovar.SHOW_MY_PROFILE).length>10) { glovar.PROFILE_CACHE=true; } } catch(Exx) {}
      //console.log("PROFILE_CACHE:"+PROFILE_CACHE)
      
      //var SHOW_TOPTRADER_PROFILE;//=glovar.TOPTRADERSLIST[0];

      //const { todoTopTraders, isFetchingTopTraders } = useFetchTodoUser(params.OWN_USER,id)//"IAG.MC,EURUSD=X")
      const { todoTopTraders, isFetchingTopTraders } = useFetchTodoUser(false,id,PROFILE_CACHE)//"IAG.MC,EURUSD=X")


      //const  todoTopTraders = useFetchTodoToptraders()
      //glovar.TOPTRADERSLIST=todoTopTraders
        
      
   
  
    if(!isFetchingTopTraders || PROFILE_CACHE) {
  
      
 
  //SHOW_TOPTRADER_PROFILE=todoTopTraders[0];
  if(!PROFILE_CACHE) { 
    try {
         //console.log("testeooo:"+todoTopTraders)
       if(todoTopTraders.length === 0) {
        //console.log("usuario NO existe")
        window.location.replace('/')
        return;
        }
      }
        catch(Exx) { /*console.log("petada:"+Exx)*/}

        glovar.SHOW_MY_PROFILE=todoTopTraders 
  }
  
  //console.log("resposta toptraders:"+JSON.stringify(SHOW_TOPTRADER_PROFILE));
  glovar.SHOW_MY_PROFILE.name=id;

  try {
    glovar.NUM_FOLLOWERS=glovar.SHOW_MY_PROFILE.list_followers.length;
  } catch(Exxx) {}
  
  try {
    glovar.NUM_FOLLOWING=glovar.SHOW_MY_PROFILE.list_following.length;
  } catch(Exxx) {}

  if(!params.OWN_USER) 
  {
    try {
      if(glovar.SHOW_MY_PROFILE.SHOW_PUBLIC_CARTERA_ONLY_FOLLOW==true) 
    {
      NOSHOW_NOFOLLOWER=true;
      try {
        if(glovar.SHOW_MY_PROFILE.list_followers.includes(glovar.USER_USERNAME)) { NOSHOW_NOFOLLOWER=false; }
      } catch(exx) {}

    }
   } catch(Exx) {NOSHOW_NOFOLLOWER=false; }
  }
  
  if(!params.OWN_USER || !PROFILE_CACHE) 
  {
    glovar.SHOW_PUBLIC_PROFILE_USER= glovar.SHOW_MY_PROFILE.SHOW_PUBLIC_PROFILE_USER;
    if(!glovar.SHOW_PUBLIC_PROFILE_USER && !params.OWN_USER) {
      window.location.replace('/')
      return;
    }
    glovar.SHOW_PUBLIC_PROFILE_USER_INFO = glovar.SHOW_MY_PROFILE.SHOW_PUBLIC_PROFILE_USER_INFO;
    glovar.SHOW_PUBLIC_PROFILE_BTNFOLLOW = glovar.SHOW_MY_PROFILE.SHOW_PUBLIC_PROFILE_BTNFOLLOW;

    glovar.SHOW_PUBLIC_CARTERA = glovar.SHOW_MY_PROFILE.SHOW_PUBLIC_CARTERA;
    glovar.SHOW_PUBLIC_CARTERA_TIT_BENEF =glovar.SHOW_MY_PROFILE.SHOW_PUBLIC_CARTERA_TIT_BENEF;

    glovar.SHOW_PUBLIC_CARTERA_ONLY_FOLLOW= glovar.SHOW_MY_PROFILE.SHOW_PUBLIC_CARTERA_ONLY_FOLLOW;
    glovar.SHOW_PUBLIC_HISTORIAL_ONLY_FOLLOW= glovar.SHOW_MY_PROFILE.SHOW_PUBLIC_HISTORIAL_ONLY_FOLLOW;

    glovar.SHOW_PUBLIC_HISTORIAL= glovar.SHOW_MY_PROFILE.SHOW_PUBLIC_HISTORIAL;
    glovar.SHOW_PUBLIC_HISTORIAL_TIT_BENEF = glovar.SHOW_MY_PROFILE.SHOW_PUBLIC_HISTORIAL_TIT_BENEF;
  }
  
  if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
  {
    try {
      if(params.OWN_USER) { EventGA("click_element","MYpubPortMB_"+glovar.SHOW_MY_PROFILE.name, glovar.SHOW_MY_PROFILE.name);  } 
      else { EventGA("click_element","pubPortMB_"+glovar.SHOW_MY_PROFILE.name, glovar.SHOW_MY_PROFILE.name);  } 
     } catch (exx) { /*console.log("petada event:"+exx)*/}
    


    return (
      <Box  backgroundColor="#F3F2EF" pb={"80px"}><Box>
     <Flex p={4} backgroundColor="#FFFFFF" maxHeight="90px">
        <Image src='assets/logopro1xsmall1.png' h="54px" _hover={{ cursor: "pointer" }}
        onClick= {() =>  {
          try { EventGA("button_sup","pslogoPuPortMB", "pslogoPuPortMB"); } catch (exx) { /*console.log("petada event:"+exx)*/}
          window.location.replace('/') 
        }}
       />   
     </Flex>
    </Box>

      <SimpleGrid columns={14} gap={0} p={0}>
    
       
        
        {1==1&&<GridItem colSpan={14} rounded={10} backgroundColor="#FFFFFF" m={1}> 
            
      
  
  {(!params.OWN_USER/*glovar.MODE_USER==0*/)&& <BigCardTopTrader   toptrader={glovar.SHOW_MY_PROFILE} toptraderpos={1} current_portfolio_ED={0} 
                                hide_follow={params.OWN_USER}
                                hideProfile={!glovar.SHOW_PUBLIC_PROFILE_USER} 
                                hideData={!glovar.SHOW_PUBLIC_PROFILE_USER_INFO}
                                hidefollow={!glovar.SHOW_PUBLIC_PROFILE_BTNFOLLOW}
                                mobile={1}
                                />}
  
              </GridItem>}
        <GridItem colSpan={14} rounded={10}  backgroundColor="#FFFFFF" m={2} mt={1}> 
        
        
        {!params.OWN_USER&&<AddContentApp mode={5} mobile={1}></AddContentApp>}
        
    
        </GridItem>
      </SimpleGrid>
    
    
    
    
      <SimpleGrid columns={14} p={0} gap={2} mt={0} m={2} >
    
  
     
    
    <GridItem colSpan={14} rounded={10}  gap={1} m={0} mt={0} >
         
    
  
           <Box backgroundColor="#FFFFFF" rounded={10} p={0} pl={4} pr={2} minHeight={"30pc"}>
  
  
          {!params.OWN_USER/*glovar.MODE_USER==0*/&&<Flex fontSize="sm" fontWeight="semibold" t={-1} mb={2} ml={2} pt={0} textColor="gray.800"> 
          <Text>{i18n.t("cartera_actual")}</Text>
          </Flex>}
          
        
  
  
          {glovar.MODE_USER>=0 &&<HStack mb={3} textColor="gray.400" fontSize="sm" justifyContent="space-between">
            <Text >{i18n.t("activo")}</Text>
            <Spacer></Spacer> 
           
            <Text >{i18n.t("entrada")}</Text>
            
            
            <Text >{i18n.t("precio")}</Text>
            
            <Text>Obj/Stop</Text>
          
           
          
            <Text >Benef/Rent</Text>
          </HStack>}
         
          {(!params.OWN_USER && !glovar.SHOW_PUBLIC_CARTERA) && 
            <VStack>
              
              <Text background={"black"} color="white" p={2} pl={4} pr={4} rounded={10} marginTop={10} marginBottom={2} fontSize="md" fontWeight="normal">
                El usuario ha configurado con <b>perfil privado</b> su CARTERA ACTUAL
                </Text>
            <AddContentApp mode={5}></AddContentApp>
            </VStack>
            } 

           {(!params.OWN_USER && glovar.SHOW_PUBLIC_CARTERA && NOSHOW_NOFOLLOWER) && 
            <VStack>
              
              <Text background={"black"} color="white" p={2} pl={4} pr={4} rounded={10} marginTop={10} marginBottom={2} fontSize="md" fontWeight="normal">
                El usuario ha configurado su CARTERA ACTUAL como visible SOLO PARA SUS SEGUIDORES.
                </Text>
            <AddContentApp mode={5}></AddContentApp>
            </VStack>
            }
          
          
          {(glovar.SHOW_PUBLIC_CARTERA && !params.OWN_USER/*glovar.MODE_USER==0*/ && !NOSHOW_NOFOLLOWER)&&
             <PortfolioTopTraderList toptrader={glovar.SHOW_MY_PROFILE} hidetitbenef={!glovar.SHOW_PUBLIC_CARTERA_TIT_BENEF}
             mobile={1} >
              </PortfolioTopTraderList>}
          </Box>
        
    
       
            
    
              
    
    
    
      
      
    
    
    </GridItem>
    <GridItem colSpan={14} rounded={10} backgroundColor="#FFFFFF" m={0} mt={1}  >
    
          
    
          
    
             
             
          
       {!params.OWN_USER/*glovar.MODE_USER==0*/&&<Flex fontSize="sm" fontWeight="semibold" mt={2}  ml={6}  textColor="gray.800"> 
          <Text>{i18n.t("track_record")}</Text>
          </Flex>}
  
  
         {(!params.OWN_USER && !glovar.SHOW_PUBLIC_HISTORIAL) && 
            <VStack>
              
            <Text background={"black"} color="white" p={2} rounded={10} pl={4} pr={4} marginTop={20} marginBottom={2} fontSize="md" fontWeight="normal">
              El usuario ha configurado con <b>perfil privado</b> su TRACK RECORD
              </Text>
          <AddContentApp mode={5}></AddContentApp>
          </VStack>
            } 
  
  
          {(params.OWN_USER==1/*glovar.MODE_USER>=1*/)&&
          <TrackRecordTopTraderList toptrader={glovar.SHOW_MY_PROFILE} 
                                          grayScale={!glovar.SHOW_PUBLIC_HISTORIAL} hidetitbenef={!glovar.SHOW_PUBLIC_HISTORIAL_TIT_BENEF}>
  
           </TrackRecordTopTraderList>
          
          }
           {(glovar.SHOW_PUBLIC_HISTORIAL && !params.OWN_USER/*glovar.MODE_USER==0*/)&&
           <TrackRecordTopTraderList toptrader={glovar.SHOW_MY_PROFILE} 
           mobile={1}
           hidetitbenef={!glovar.SHOW_PUBLIC_HISTORIAL_TIT_BENEF}></TrackRecordTopTraderList>}
  
          
    
        
    
        
         
    
              
    
    
    
       </GridItem>
     </SimpleGrid> 
    
    
    
    
    </Box> 
     );
//return( <AddContentApp mode={4}></AddContentApp>);
  } 
  else {

    try {
      if(params.OWN_USER) { EventGA("click_element","MYpubPortDT_"+glovar.SHOW_MY_PROFILE.name, glovar.SHOW_MY_PROFILE.name);  } 
      else { EventGA("click_element","pubPortDT_"+glovar.SHOW_MY_PROFILE.name, glovar.SHOW_MY_PROFILE.name);  } 
     } catch (exx) { /*console.log("petada event:"+exx)*/}
    

   return (
    
    <Box  backgroundColor="#F3F2EF" pb={"80px"}>
    <Box>
   <Flex p={4} backgroundColor="#FFFFFF" maxHeight="90px">
        <Image src='assets/logopro1xsmall1.png' h="54px" marginLeft="7.5pc" _hover={{ cursor: "pointer" }}
        onClick= {() =>  {
          try { EventGA("button_sup","pslogoPuPortDT", "pslogoPuPortDT"); } catch (exx) { /*console.log("petada event:"+exx)*/}
          window.location.replace('/') 
        }}
        />
         <Spacer />
           <ShowSearchBar/>
       

            <Spacer />
         <HStack fontSize="2xl" spacing="8" marginRight="8pc">
             {1==2&&<FiBell />}
             {1==2&&<FiSettings />}
             {glovar.USER_LOADED&&<HStack spacing="6">
           <Avatar w={12} h={10}
            onClick= {() =>{ try { EventGA("button_sup","ClickSupAvatar", "ClickSupAvatar"); } catch (exx) {} this.changemenu(3) }}></Avatar>
              <Text _hover={{ cursor: "pointer" }} 
                    style={{ color: 'black', fontSize: 18, fontWeight:500 }} 
                    onClick= {() =>{ try { EventGA("button_sup","ClickSupUsername", "ClickSupUsername"); } catch (exx) {} this.changemenu(3) }}>
                   {glovar.USER_USERNAME}
              </Text>
           <Box _hover={{ cursor: "pointer" }}>
           <div onClick= {() => window.confirm(i18n.t("leave_site_confirm")) && this.logout() }>
           <FiLogOut />
          </div>
          </Box></HStack>}
            {!glovar.USER_LOADED&&<Box>
              <button className="formLogInButton"  _hover={{ cursor: "pointer" }} 
            onClick= {() => {
              try { EventGA("button_sup","pressbtn_login", "pressbtn_login"); } catch (exx) { /*console.log("petada event:"+exx)*/}
              window.location.replace('/login') }
            }
            style={{  marginRight:"1pc", width: "10pc", textAlign:"center", fontWeight:600 }}>
            {i18n.t("login")}</button>         
            <button className="formSignInButton"  _hover={{ cursor: "pointer" }} 
           onClick= {() => {
            //try { EventGA("button_sup","pressbtn_register", "pressbtn_register"); } catch (exx) { /*console.log("petada event:"+exx)*/}
            //window.location.replace('/registerApp') }
            if(glovar.REGISTER_ONLINE==true) {
              try { EventGA("button_sup","pressbtn_regonline", "pressbtn_regonline"); } catch (exx) { /*console.log("petada event:"+exx)*/}
              window.location.replace('/register')
              }
              else {
                try { EventGA("button_sup","pressbtn_register", "pressbtn_register"); } catch (exx) { /*console.log("petada event:"+exx)*/}
                window.location.replace('/registerApp')
              }}
           }
            style={{ width: "10pc",  textAlign:"center"}}>
            {i18n.t("signup")}</button>          
            </Box>}
  
             
         </HStack>
   </Flex>
  </Box>
    <SimpleGrid columns={14} gap={1} p={2}>
      <GridItem colSpan={1}>
      <Box mt={4}>
    <VStack>
      <ItemSibedar icon="assets/ic_briefcase_white_48dp.png" id={201} />
      {1==1&&<ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/ic_compass_white_48dp.png`} id={13} />}
      {1==2&&<ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/baseline_notifications_white_48.png`} id={12} />}
      {1==2&&<ItemSibedar icon="assets/ic_podium_white_24dp.png" id={202} />}
      {params.OWN_USER==1/*glovar.MODE_USER>=1*/&&<ItemSibedar icon="assets/baseline_account_box_white_48.png" id={3} />}
     
      {1==2&&<ItemSibedar icon="assets/ic_chart_areaspline_white_24dp.png" id={4} />}
    </VStack>
  </Box>
      </GridItem>
     
      
      {1==1&&<GridItem colSpan={6} rounded={10} backgroundColor="#FFFFFF" m={2}> 
          
      {(params.OWN_USER==1)/*glovar.MODE_USER>=1*/&&<HStack fontSize="sm" justifyContent="space-between" marginRight={6} marginBottom={1} marginLeft={4} marginTop={2} > 
      <Checkbox 
          isDisabled={!glovar.SHOW_PUBLIC_PROFILE_USER}
          marginRight={8}
          colorScheme='gray'
          size='sm' 
          isChecked={glovar.SHOW_PUBLIC_PROFILE_BTNFOLLOW}
          onChange={() => { glovar.SHOW_PUBLIC_PROFILE_BTNFOLLOW=!glovar.SHOW_PUBLIC_PROFILE_BTNFOLLOW; 
            updateUserPortfolioOptions();
            eventBus.dispatch("showRefreshHomefront", null); }}>

          {i18n.t("permi_seguidores")}
          </Checkbox>

        <HStack  mb={4}>
          
      
  
          <Checkbox 
          isDisabled={!glovar.SHOW_PUBLIC_PROFILE_USER}
          marginRight={8}
          colorScheme='gray'
          size='sm' 
          isChecked={glovar.SHOW_PUBLIC_PROFILE_USER_INFO}
          onChange={() => { glovar.SHOW_PUBLIC_PROFILE_USER_INFO=!glovar.SHOW_PUBLIC_PROFILE_USER_INFO; 
            updateUserPortfolioOptions();
            eventBus.dispatch("showRefreshHomefront", null); }}>

           {i18n.t("show_capbenefis")}
          </Checkbox>
          <FormLabel htmlFor='email-alerts1' mb='0' fontSize="sm" fontWeight="semibold">{i18n.t("perfil_publico")}</FormLabel>
          <Switch id='email-alerts1'
          size="md"
          colorScheme='gray'
          //isChecked={value || false}
          value={glovar.SHOW_PUBLIC_PROFILE_USER}
          isChecked={glovar.SHOW_PUBLIC_PROFILE_USER}
          onChange={() => { 
            glovar.SHOW_PUBLIC_PROFILE_USER=!glovar.SHOW_PUBLIC_PROFILE_USER;
            if(!glovar.SHOW_PUBLIC_PROFILE_USER) {
              glovar.SHOW_PUBLIC_CARTERA=false; 
              glovar.SHOW_PUBLIC_HISTORIAL=false; 
            }
            
            updateUserPortfolioOptions();

            //console.log(glovar.SHOW_PUBLIC_CARTERA)
            eventBus.dispatch("showRefreshHomefront", null); }}
           />
        </HStack>
        </HStack>
        }

{(!params.OWN_USER/*glovar.MODE_USER==0*/)&& <BigCardTopTrader   toptrader={glovar.SHOW_MY_PROFILE} toptraderpos={1} current_portfolio_ED={0} 
                              hide_follow={params.OWN_USER}
                              hideProfile={!glovar.SHOW_PUBLIC_PROFILE_USER} 
                              hideData={!glovar.SHOW_PUBLIC_PROFILE_USER_INFO}
                              hidefollow={!glovar.SHOW_PUBLIC_PROFILE_BTNFOLLOW}
                              />}
                              


{(params.OWN_USER==1/*glovar.MODE_USER>=1*/)&& <BigCardTopTrader   toptrader={glovar.SHOW_MY_PROFILE} toptraderpos={1} current_portfolio_ED={0} 
                              hide_follow={params.OWN_USER}
                              hideProfile={!glovar.SHOW_PUBLIC_PROFILE_USER} 
                              hideData={!glovar.SHOW_PUBLIC_PROFILE_USER_INFO}
                              hidefollow={!glovar.SHOW_PUBLIC_PROFILE_BTNFOLLOW}
                              />}


          


      </GridItem>}
      <GridItem colSpan={6} rounded={10}  backgroundColor="#FFFFFF" m={2}> 
      


      {params.OWN_USER==false&&<AddContentApp mode={5}></AddContentApp>}
      {params.OWN_USER==true&&
      <SimpleGrid columns={4} gap={0} p={0} mt={0} >
        
      <GridItem colSpan={2}>
      <Text fontWeight="normal" fontSize={14}  mt={3} mb={-2} ml={4} textColor="black">{i18n.t("compa_perfil_contac")}</Text>
      <AddContentApp mode={6}></AddContentApp>
      {1==3&&<AddContentApp mode={5}></AddContentApp>}
      </GridItem>
      <GridItem colSpan={2}>     
      
      <Tabs isFitted  colorScheme='black' mr={2} defaultIndex={glovar.DEFAULT_INDEX_TABS_FOLLOWS} >
  <TabList mb='0'>
    <Tab fontSize={14}>{i18n.t("followers")} ({glovar.NUM_FOLLOWERS})</Tab>
    <Tab fontSize={14}>{i18n.t("following")} ({glovar.NUM_FOLLOWING})</Tab>
  </TabList>
  <TabPanels>
    <TabPanel 
    paddingLeft={0.5}
    paddingRight={0.5}
    paddingTop={2}
    >
    <ContactsList toptrader={glovar.SHOW_MY_PROFILE} modelist={glovar.MODE_FOLLOWERS}/>
    </TabPanel>
    <TabPanel
     paddingLeft={0.5}
     paddingRight={0.5}
     paddingTop={2}
     >
    <ContactsList toptrader={glovar.SHOW_MY_PROFILE} modelist={glovar.MODE_FOLLOWING} />
    </TabPanel>
  </TabPanels>
</Tabs>
        
        
      
      </GridItem>
      
      </SimpleGrid>}


      


      </GridItem>
    </SimpleGrid>
  
  
  
  
    <SimpleGrid columns={14} gap={1} p={2} mt={-1} >
  
  <GridItem colSpan={1}>
   
  
    <Box mt={2}>
  <VStack>
    {1==1&&<ItemSibedarRRSS icon="assets/ic_twitter_white_24dp.png" id={101} />}
    {1==2&&<ItemSibedarRRSS icon="assets/ic_instagram_white_24dp.png" id={102} />}
    
  </VStack>
  </Box>
    </GridItem>
  
   
  
  <GridItem colSpan={6} rounded={10}  m={2} mt={0} >
       
  

         <Box backgroundColor="#FFFFFF" rounded={10} p={2} pl={4} pr={2} minHeight={"30pc"}>


        {!params.OWN_USER/*glovar.MODE_USER==0*/&&<Flex fontSize="sm" fontWeight="semibold" t={-1} mb={2} ml={2} pt={0} textColor="gray.800"> 
        <Text>{i18n.t("cartera_actual")}</Text>
        </Flex>}
        
        {params.OWN_USER==1/*glovar.MODE_USER>=1*/&&<HStack fontSize="sm" justifyContent="space-between" marginRight={2} marginBottom={2} > 
        <Text fontWeight="semibold"   mt={2} mb={2} ml={0} pt={0} textColor="gray.800">{i18n.t("cartera_actual")}</Text>

        <Flex  mb={2}>
          
        <Checkbox 
          isDisabled={!glovar.SHOW_PUBLIC_CARTERA}
          marginRight={8}
          colorScheme='gray'
          size='sm' 
          isChecked={glovar.SHOW_PUBLIC_CARTERA_TIT_BENEF}
          onChange={() => { glovar.SHOW_PUBLIC_CARTERA_TIT_BENEF=!glovar.SHOW_PUBLIC_CARTERA_TIT_BENEF; 
            //console.log("glovar.SHOW_PUBLIC_CARTERA_TIT_BENEF:"+glovar.SHOW_PUBLIC_CARTERA_TIT_BENEF)
            updateUserPortfolioOptions();
            eventBus.dispatch("showRefreshHomefront", null); }}>

           Títulos y beneficio  
          </Checkbox>
          
          <Checkbox 
          isDisabled={!glovar.SHOW_PUBLIC_CARTERA}
          marginRight={8}
          colorScheme='gray'
          size='sm' 
          isChecked={glovar.SHOW_PUBLIC_CARTERA_ONLY_FOLLOW}
          onChange={() => { glovar.SHOW_PUBLIC_CARTERA_ONLY_FOLLOW=!glovar.SHOW_PUBLIC_CARTERA_ONLY_FOLLOW; 
            
            //console.log("glovar.SHOW_PUBLIC_CARTERA_ONLY_FOLLOW:"+glovar.SHOW_PUBLIC_CARTERA_ONLY_FOLLOW)
            updateUserPortfolioOptions();
            eventBus.dispatch("showRefreshHomefront", null); }}>

            Visible solo seguidores  
          </Checkbox>
          
          <FormLabel htmlFor='email-alerts' mb='0'  fontSize="sm" fontWeight="semibold"> visible en perfíl público</FormLabel>
          <Switch id='email-alerts'
          size="md"
          colorScheme='gray'
          //isChecked={value || false}
          value={glovar.SHOW_PUBLIC_CARTERA}
          isChecked={glovar.SHOW_PUBLIC_CARTERA}
          onChange={() => { 
              glovar.SHOW_PUBLIC_CARTERA=!glovar.SHOW_PUBLIC_CARTERA; 
              if(glovar.SHOW_PUBLIC_CARTERA) { glovar.SHOW_PUBLIC_PROFILE_USER=true }
              updateUserPortfolioOptions();
            eventBus.dispatch("showRefreshHomefront", null); }}
           />
        </Flex>
        </HStack>
        }


        {glovar.MODE_USER>=0 &&<Flex mb={3} textColor="gray.400" fontSize="sm">
          <Text w={"11pc"}>{i18n.t("activo")} - {i18n.t("f_entrada")}</Text>
          <Spacer />
          <Text ml={0}>{i18n.t("titulos")}</Text>
          <Spacer />
          <Text mr={3}>{i18n.t("entrada")}</Text>
          <Spacer />
          
          <Text ml={1}>{i18n.t("precio")}</Text>
          <Spacer />
          <Spacer />
          
          <Text ml={1}>{i18n.t("objetivo")} - {i18n.t("stop")}</Text>
         <Spacer />
         <Spacer />
         
        
          <Text mr={4}>{i18n.t("benef_rent")}</Text>
        </Flex>}
       
        {(!params.OWN_USER && !glovar.SHOW_PUBLIC_CARTERA) && 
          <VStack>
            
            <Text background={"black"} color="white" p={2} pl={4} pr={4} rounded={10} marginTop={10} marginBottom={2} fontSize="md" fontWeight="normal">
              El usuario ha configurado con <b>perfil privado</b> su CARTERA ACTUAL
              </Text>
          <AddContentApp mode={5}></AddContentApp>
          </VStack>
          } 
  
          {(!params.OWN_USER && glovar.SHOW_PUBLIC_CARTERA && NOSHOW_NOFOLLOWER) && 
          <VStack>
            
            <Text background={"black"} color="white" p={2} pl={4} pr={4} rounded={10} marginTop={10} marginBottom={2} fontSize="md" textAlign="center" fontWeight="normal">
            El usuario ha configurado su CARTERA ACTUAL como<br></br> <b>visible solo para sus SEGUIDORES.</b>
              </Text>
          <AddContentApp mode={5}></AddContentApp>
          </VStack>
          } 

       
        {1==3&&(glovar.MODE_USER<=2 && !SHOW_TOPTRADER_NO_EFFECT)&&<PortfolioTopTraderList toptrader={glovar.SHOW_MY_PROFILE}></PortfolioTopTraderList>}
        {1==3&&(glovar.MODE_USER<=2 && SHOW_TOPTRADER_NO_EFFECT)&&<PortfolioTopTraderListNoeffect toptrader={glovar.SHOW_MY_PROFILE} cotis={TOPTRADER_COTIS}></PortfolioTopTraderListNoeffect>}
        
        {(params.OWN_USER==1/*glovar.MODE_USER>=1*/)&&
        <PortfolioTopTraderListNoeffect toptrader={glovar.SHOW_MY_PROFILE} cotis={glovar.MYPORTFOLIO_COTIS} 
                                        grayScale={!glovar.SHOW_PUBLIC_CARTERA} hidetitbenef={!glovar.SHOW_PUBLIC_CARTERA_TIT_BENEF}>

         </PortfolioTopTraderListNoeffect>
        
        }
        {(glovar.SHOW_PUBLIC_CARTERA && !params.OWN_USER/*glovar.MODE_USER==0*/&& !NOSHOW_NOFOLLOWER)&&
           <PortfolioTopTraderList toptrader={glovar.SHOW_MY_PROFILE} hidetitbenef={!glovar.SHOW_PUBLIC_CARTERA_TIT_BENEF} >
            </PortfolioTopTraderList>}
        </Box>
      
  
     
          
  
            
  
  
  
    
    
  
  
  </GridItem>
  <GridItem colSpan={6} rounded={10} backgroundColor="#FFFFFF" m={2} mt={0}  >
  
        
  
        
  
           
           
        
     {!params.OWN_USER/*glovar.MODE_USER==0*/&&<Flex fontSize="sm" fontWeight="semibold" mt={2}  ml={6}  textColor="gray.800"> 
        <Text>{i18n.t("track_record")}</Text>
        </Flex>}

        {params.OWN_USER==1/*glovar.MODE_USER>=1*/&&<HStack fontSize="sm" justifyContent="space-between" marginRight={6} marginBottom={2}  mt={2}  ml={4}> 
        <Text fontWeight="semibold"    mt={2} mb={2} ml={0} pt={0} textColor="gray.800">{i18n.t("track_record")}</Text>

        <Flex  mb={2}>
          
          
          <Checkbox 
          isDisabled={!glovar.SHOW_PUBLIC_HISTORIAL}
          marginRight={8}
          size='sm' 
          colorScheme='gray'
          isChecked={glovar.SHOW_PUBLIC_HISTORIAL_TIT_BENEF}
          onChange={() => { glovar.SHOW_PUBLIC_HISTORIAL_TIT_BENEF=!glovar.SHOW_PUBLIC_HISTORIAL_TIT_BENEF; 
            updateUserPortfolioOptions();
            eventBus.dispatch("showRefreshHomefront", null); }}>

            Títulos y beneficio  
          </Checkbox>

          <Checkbox 
          isDisabled={!glovar.SHOW_PUBLIC_CARTERA}
          marginRight={8}
          colorScheme='gray'
          size='sm' 
          isChecked={glovar.SHOW_PUBLIC_HISTORIAL_ONLY_FOLLOW}
          onChange={() => { glovar.SHOW_PUBLIC_HISTORIAL_ONLY_FOLLOW=!glovar.SHOW_PUBLIC_HISTORIAL_ONLY_FOLLOW; 
           //console.log("lovar.SHOW_PUBLIC_HISTORIAL_ONLY_FOLLOW:"+glovar.SHOW_PUBLIC_HISTORIAL_ONLY_FOLLOW)
            updateUserPortfolioOptions();
            eventBus.dispatch("showRefreshHomefront", null); }}>

            Visible solo seguidores  
          </Checkbox>


          <FormLabel htmlFor='email-alerts' mb='0' fontSize="sm" fontWeight="semibold"> visible en perfíl público</FormLabel>
          <Switch id='email-alerts'
          size="md"
          colorScheme='gray'
          //isChecked={value || false}
          value={glovar.SHOW_PUBLIC_HISTORIAL}
          isChecked={glovar.SHOW_PUBLIC_HISTORIAL}
          onChange={() => { 
            glovar.SHOW_PUBLIC_HISTORIAL=!glovar.SHOW_PUBLIC_HISTORIAL; 
            if(glovar.SHOW_PUBLIC_HISTORIAL) { glovar.SHOW_PUBLIC_PROFILE_USER=true }
            updateUserPortfolioOptions();
            //console.log(glovar.SHOW_PUBLIC_HISTORIAL)
            eventBus.dispatch("showRefreshHomefront", null); }}
           />
        </Flex>
        </HStack>
        }

       {(!params.OWN_USER && !glovar.SHOW_PUBLIC_HISTORIAL) && 
          <VStack>
            
          <Text background={"black"} color="white" p={2} rounded={10} pl={4} pr={4} marginTop={20} marginBottom={2} fontSize="md" fontWeight="normal">
            El usuario ha configurado con <b>perfil privado</b> su TRACK RECORD
            </Text>
        <AddContentApp mode={5}></AddContentApp>
        </VStack>
          } 


        {(params.OWN_USER==1/*glovar.MODE_USER>=1*/)&&
        <TrackRecordTopTraderList toptrader={glovar.SHOW_MY_PROFILE} 
                                        grayScale={!glovar.SHOW_PUBLIC_HISTORIAL} hidetitbenef={!glovar.SHOW_PUBLIC_HISTORIAL_TIT_BENEF}>

         </TrackRecordTopTraderList>
        
        }
         {(glovar.SHOW_PUBLIC_HISTORIAL && !params.OWN_USER/*glovar.MODE_USER==0*/)&&
         <TrackRecordTopTraderList toptrader={glovar.SHOW_MY_PROFILE} hidetitbenef={!glovar.SHOW_PUBLIC_HISTORIAL_TIT_BENEF}></TrackRecordTopTraderList>}

        
  
      
  
      
       
  
            
  
  
  
     </GridItem>
   </SimpleGrid> 
  
  
  
  
  </Box> 
   );
  }
    }

      

     


      
    }

    /*if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
    {
    
     
//return( <AddContentApp mode={4}></AddContentApp>);
    }*/

      //try { ReactGA.pageview(window.location.pathname + window.location.search); } catch(Exx) {}
      //try { ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search }); } catch(Exx) {}
    
    //i18n.locale = "en";
    //console.log("i18:"+); //=> Hi!
    
    //i18n.locale = "pt-BR";
    //console.log("i18:"+i18n.t("vers_antigua")); //=> Olá!


  const ItemSibedar = ({ icon, active, id }) => (

      
      
    <Box
      _hover={{ cursor: "pointer" }}
      p={4}
      borderRadius="md"
      bg={(glovar.CURRENT_MENU_ID==id) ? "#000000" : "lightgray"}
      textColor={(glovar.CURRENT_MENU_ID==id) ? "white" : "gray"}
      onClick={() => {
        try {
           EventGA("click_element","chgMenu_"+glovar.CURRENT_MENU_ID+"to "+id, id);  
        }
          catch (exx) { /*console.log("petada event:"+exx)*/}

        this.changemenu(id)
      }}
    >
      <Image src={icon} w={5}></Image>
    </Box>
  );

  const ItemSibedarRRSS = ({ icon, active, id }) => (

      
      
    <Box
      _hover={{ cursor: "pointer" }}
      p={3}
      borderRadius="md"
      bg={(glovar.CURRENT_MENU_ID==id) ? "#000000" : "#888888"}
      textColor={(glovar.CURRENT_MENU_ID==id) ? "white" : "gray"}
      onClick={() => {
        try {
           EventGA("click_element","chgMenu_"+glovar.CURRENT_MENU_ID+"to "+id, id);  
        }
        catch (exx) { /*console.log("petada event:"+exx)*/}
         this.changemenu(id)}
      }
    >
      <Image src={icon} w={5}></Image>
    </Box>
  );
  
 
  

  if( glovar.CURRENT_MENU_ID==4) {

    if(SHOW_ELEMENT_DATA==null) {
      return(
      <ShowFitxaElement fromURLMenu={1} fullsizeChart={0} ticker={this.state.currentTicker.data} ShowPopNewsLogin={this.state.ShowPopNewsLogin}/>
      //<ShowFitxaElement/>
      
      );
    }
    else {
    return(
     <ShowFitxaElement element_data={SHOW_ELEMENT_DATA} fullsizeChart={0} ShowPopNewsLogin={this.state.ShowPopNewsLogin}/>
     
     );
    }

    
  }

  if( glovar.CURRENT_MENU_ID==6) {
    //console.log("SHOW_ELEMENT_DATA:")
    //console.log(JSON.stringify(SHOW_ELEMENT_DATA))

    if(SHOW_ELEMENT_DATA==null) {
      return(
      <ShowFitxaElement fromURLMenu={1} fullsizeChart={1} ticker={this.state.currentTicker.data}/>
      //<ShowFitxaElement/>
      
      );
    }
    else {
    return(
      <ShowFitxaElement element_data={SHOW_ELEMENT_DATA} fullsizeChart={1}/>
      //<ShowFitxaElement fromURLMenu={1}  fullsizeChart={1}/>
      
      );
    }

    
  }

  if( glovar.CURRENT_MENU_ID==3) {
    return(<PublicPortfolio OWN_USER={1}/>)
  }
  if(glovar.CURRENT_MENU_ID==2)
  {
    if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
    {
 //return( <AddContentApp mode={4}></AddContentApp>);
    return (
 <Box  backgroundColor="#F3F2EF" pb={"120px"}><Box>
    <Flex p={4} backgroundColor="#FFFFFF" maxHeight="130px" flexDirection="column" >
       <Image src={`${process.env.PUBLIC_URL}/assets/logopro1xsmall1.png`} h="54px" w="157px" _hover={{ cursor: "pointer" }}
         onClick= {() =>  {
           try { EventGA("button_sup","pslogoFitxMB", "pslogoFitxMB"); } catch (exx) { /*console.log("petada event:"+exx)*/}
           window.location.replace('/') 
         }}
        />   
       <ShowSearchBar type={1}/>
    </Flex>
   </Box>
 
   <SimpleGrid columns={14} gap={0} p={0}>
 
   <GridItem colSpan={14} rounded={10} backgroundColor="#FFFFFF" m={1}> 
         
         <BigCardTopTrader  mobile={1} toptrader={SHOW_TOPTRADER_PROFILE} toptraderpos={0} current_portfolio_ED={0}/>
 
     </GridItem> 
  
 
 
     <GridItem colSpan={14} rounded={10}  m={1} mt={1} >
      
 
      <Box backgroundColor="#FFFFFF" rounded={10} p={2} pl={4} pr={2} minHeight={"25pc"}>
         <Flex fontSize="sm" fontWeight="semibold" t={-1} mb={2} ml={2} pt={0} textColor="gray.800"> 
     <Text>{i18n.t("cartera_actual")}</Text>
       
     </Flex>
     {glovar.MODE_USER==2 &&<HStack mb={3} textColor="gray.400" fontSize="sm" justifyContent="space-between">
             <Text >{i18n.t("activo")}</Text>
             <Spacer></Spacer> 
            
             <Text >{i18n.t("entrada")}</Text>
             
             
             <Text >{i18n.t("precio")}</Text>
             
             <Text>Obj/Stop</Text>
           
            
           
             <Text >Benef/Rent</Text>
           </HStack>}
    
 
    {glovar.MODE_USER<=1&& 
       <AddContentApp mode={3} mobile={1}></AddContentApp>
       } 
     {(glovar.MODE_USER==2 && !SHOW_TOPTRADER_NO_EFFECT)&&<PortfolioTopTraderList mobile={1} toptrader={SHOW_TOPTRADER_PROFILE}></PortfolioTopTraderList>}
     {(glovar.MODE_USER==2 && SHOW_TOPTRADER_NO_EFFECT)&&<PortfolioTopTraderListNoeffect mobile={1} toptrader={SHOW_TOPTRADER_PROFILE} cotis={TOPTRADER_COTIS}></PortfolioTopTraderListNoeffect>}
 
     </Box>
   
 </GridItem>

 <GridItem colSpan={14} rounded={10}  backgroundColor="#FFFFFF" m={1}> 
     
     <TopTradersRanking mobile={1}/>
 
     </GridItem>

 <GridItem colSpan={14} rounded={10} backgroundColor="#FFFFFF" m={1} mt={1}  >
   
      <Flex fontSize="sm" fontWeight="semibold" mt={2}  ml={6}  textColor="gray.800"> 
      <Text>{i18n.t("track_record")}</Text>
        
      </Flex>
      <TrackRecordTopTraderList mobile={1} toptrader={SHOW_TOPTRADER_PROFILE}></TrackRecordTopTraderList>

    

    
     

          



   </GridItem>

 
     </SimpleGrid>
 
 
   </Box>
 
    );
      }
      else {
    return (
      <Router>

      <Routes>
        <Route exact path='/home'  element={
            <PrivateRoute>

      <Box  backgroundColor="#F3F2EF" pb={"80px"}>
  <Box>
  <HeaderMenu showSearch={true}/>
</Box>
  <SimpleGrid columns={14} gap={1} p={2}>
    <GridItem colSpan={1}>
    <Box mt={4}>
  <VStack>
    <ItemSibedar icon="assets/ic_briefcase_white_48dp.png" id={1} />
    {1==1&&<ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/ic_compass_white_48dp.png`} id={13} />}
    {1==2&&<ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/baseline_notifications_white_48.png`} id={12} />}
    {1==2&&<ItemSibedar icon="assets/ic_podium_white_24dp.png" id={2} />}
    {glovar.MODE_USER>=1&&<ItemSibedar icon="assets/baseline_account_box_white_48.png" id={3} />}
    
    {1==2&&<ItemSibedar icon="assets/ic_chart_areaspline_white_24dp.png" id={4} />}
  </VStack>
</Box>
    </GridItem>
   
    
    <GridItem colSpan={6} rounded={10} backgroundColor="#FFFFFF" m={2}> 
        {1==2&&<Homefrontuser></Homefrontuser>}
        <BigCardTopTrader   toptrader={SHOW_TOPTRADER_PROFILE} toptraderpos={0} current_portfolio_ED={0}/>

    </GridItem> 
    <GridItem colSpan={6} rounded={10}  backgroundColor="#FFFFFF" m={2}> 
    
    <TopTradersRanking/>

    </GridItem>
  </SimpleGrid>




  <SimpleGrid columns={14} gap={1} p={2} mt={-1} >

<GridItem colSpan={1}>
 

  <Box mt={2}>
<VStack>
  {1==1&&<ItemSibedarRRSS icon="assets/ic_twitter_white_24dp.png" id={101} />}
  {1==2&&<ItemSibedarRRSS icon="assets/ic_instagram_white_24dp.png" id={102} />}
  
</VStack>
</Box>
  </GridItem>

 

<GridItem colSpan={6} rounded={10}  m={2} mt={0} >
     

       <Box backgroundColor="#FFFFFF" rounded={10} p={2} pl={4} pr={2} minHeight={"30pc"}>
          <Flex fontSize="sm" fontWeight="semibold" t={-1} mb={2} ml={2} pt={0} textColor="gray.800"> 
      <Text>{i18n.t("cartera_actual")}</Text>
        
      </Flex>
      {glovar.MODE_USER==2 &&<Flex mb={3} textColor="gray.400" fontSize="sm">
        <Text w={"11pc"}>{i18n.t("activo")} - {i18n.t("f_entrada")}</Text>
        <Spacer />
        <Text ml={0}>{i18n.t("titulos")}</Text>
        <Spacer />
        <Text mr={3}>{i18n.t("entrada")}</Text>
        <Spacer />
        
        <Text ml={1}>{i18n.t("precio")}</Text>
        <Spacer />
        <Spacer />
        
        <Text ml={1}>{i18n.t("objetivo")} - {i18n.t("stop")}</Text>
       <Spacer />
       <Spacer />
       
      
        <Text mr={4}>{i18n.t("benef_rent")}</Text>
      </Flex>}
     

     {glovar.MODE_USER<=1&& 
        <AddContentApp mode={3}></AddContentApp>
        } 
      {(glovar.MODE_USER==2 && !SHOW_TOPTRADER_NO_EFFECT)&&<PortfolioTopTraderList toptrader={SHOW_TOPTRADER_PROFILE}></PortfolioTopTraderList>}
      {(glovar.MODE_USER==2 && SHOW_TOPTRADER_NO_EFFECT)&&<PortfolioTopTraderListNoeffect toptrader={SHOW_TOPTRADER_PROFILE} cotis={TOPTRADER_COTIS}></PortfolioTopTraderListNoeffect>}

      </Box>
    

   
        

          



  
  


</GridItem>
<GridItem colSpan={6} rounded={10} backgroundColor="#FFFFFF" m={2} mt={0}  >

      

      

         
         
      
      <Flex fontSize="sm" fontWeight="semibold" mt={2}  ml={6}  textColor="gray.800"> 
      <Text>{i18n.t("track_record")}</Text>
        
      </Flex>
      <TrackRecordTopTraderList toptrader={SHOW_TOPTRADER_PROFILE}></TrackRecordTopTraderList>

    

    
     

          



   </GridItem>
 </SimpleGrid> 




</Box> 
</PrivateRoute>
          }/> 
        <Route path="/symbol/:ticker_param" element={<ShowFitxaElement/> }/>
        {1==2&&<Route path="/login" element={<Login/>} />}
        {1==2&&<Route path="/registerApp" element={<RegisterApp/>} />}
        {1==2&&<Route path="/register" element={<Register/>} />}
        {1==2&& <Route path='/forgotPassword' element={<ForgotPassword/>} />} 
      </Routes>  
    
</Router>
);
        }
  }
  else if(glovar.CURRENT_MENU_ID==1)
  {
    if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
    {
      return(
      <Router>
      <Routes>
<Route
          path="//:id"
          element={<PublicPortfolio OWN_USER={0}/>}
        />
        <Route path="/symbol/:ticker_param" element={<ShowFitxaElement fromURLMenu={1}/> }/>
        <Route path={glovar.PATH_COOKIES_POLICY} element={<ShowLegalText textType={1}/> }/>
        <Route path={glovar.PATH_PRIVACY_POLICY} element={<ShowLegalText textType={2}/> }/>
        <Route path={glovar.PATH_TERMS_POLICY} element={<ShowLegalText textType={3}/> }/>
        
  <Route exact path='/'  element={
      <PrivateRoute>
<Box  backgroundColor="#F3F2EF" pb={"100px"}><Box>
    <Flex p={2} backgroundColor="#FFFFFF" maxHeight="80px" flexDirection="row" justifyContent="space-between">
       <Image src={`${process.env.PUBLIC_URL}/assets/logopro1xsmall1.png`} h="54px" w="157px" _hover={{ cursor: "pointer" }}
         onClick= {() =>  {
           try { EventGA("button_sup","pslogoFitxMB", "pslogoFitxMB"); } catch (exx) { /*console.log("petada event:"+exx)*/}
           window.location.replace('/') 
         }}
        />   
        <Menu>
  <MenuButton as={Button}>
    -
  </MenuButton>
  {!glovar.USER_LOADED&&<MenuList>
    <MenuItem  onClick= {() => {
            try { EventGA("button_sup","pressbtn_login", "pressbtn_login"); } catch (exx) { /*console.log("petada event:"+exx)*/}
            window.location.replace('/login') }
          }>{i18n.t("login")}</MenuItem>
    <MenuItem onClick= {() =>
          {
           // try { EventGA("button_sup","pressbtn_register", "pressbtn_register"); } catch (exx) { /*console.log("petada event:"+exx)*/}
           //window.location.replace('/registerApp') 
           if(glovar.REGISTER_ONLINE==true) {
            try { EventGA("button_sup","pressbtn_regonline", "pressbtn_regonline"); } catch (exx) { /*console.log("petada event:"+exx)*/}
            window.location.replace('/register')
            }
            else {
              try { EventGA("button_sup","pressbtn_register", "pressbtn_register"); } catch (exx) { /*console.log("petada event:"+exx)*/}
              window.location.replace('/registerApp')
            }
          }}>{i18n.t("signup")}</MenuItem>
  </MenuList>}
  {glovar.USER_LOADED&&<MenuList>
    <MenuItem minH='48px' 
    onClick= {() =>{ 
      try { EventGA("button_sup","ClickMBSupAvatar", "ClickSupMBAvatar"); } catch (exx) {} /*this.changemenu(3)*/ }
    }>
    <Avatar w={12} h={10} mr={4}></Avatar>
      <span>{glovar.USER_USERNAME}</span>
    </MenuItem>

    <MenuItem onClick= {() => window.confirm(i18n.t("leave_site_confirm")) && this.logout() }>{i18n.t("m_cerrarses")}</MenuItem>
  </MenuList>}
</Menu>
       

           

    </Flex>
    <Box  backgroundColor="#FFFFFF" mt={-2} pb={2} pl={2} alignContent="center">
    <ShowSearchBar type={1}/>
    </Box>
   </Box>
 
   <SimpleGrid columns={14} gap={0} p={0}>

   <GridItem colSpan={14} rounded={10} backgroundColor="#FFFFFF" m={2} mt={1}> 
        {(glovar.MODE_USER>=0 || glovar.REFRESH_FRONTUSER)&&<Homefrontuser mobile={1}></Homefrontuser>}
       
        </GridItem>

        <GridItem colSpan={14} rounded={10} backgroundColor="#FFFFFF" m={2} mt={1}> 
        <ToptraderList name={"Djpedo en cabina"} price={"19.567,12"} diff={"+6.69%"}></ToptraderList>
        </GridItem>

   <GridItem colSpan={14} rounded={10} backgroundColor="#FFFFFF" p={2} m={2} mt={1}>
        <Flex fontSize={14} fontWeight="semibold" t={-1} mb={0} ml={2} pt={0} textColor="gray.800"> 
        {glovar.MODE_USER==0&&<Text mb={-1}>{i18n.t("lo_mas_pop")}</Text>}
        {glovar.MODE_USER>=1&&<Text mb={-1}>{i18n.t("mis_favoritos")}</Text>}
      </Flex>
         
        {glovar.MODE_USER>=1&&<FavoritosList></FavoritosList>}
        {glovar.MODE_USER==0&& 
        <MorePopularList></MorePopularList>
        }
        </GridItem>

        <GridItem colSpan={14} rounded={10} backgroundColor="#FFFFFF" p={2} m={2} mt={1}>
        <Text fontSize={16} ml={2}  fontWeight="semibold" textColor="gray.800">{i18n.t("noticias")}</Text>
        <Box overflowY="auto" maxHeight={600}>
<RSSNewsList name={""} price={"19.567,12"} diff={"+6.69%"}></RSSNewsList>
</Box>
          </GridItem>
      

   </SimpleGrid>
   </Box>
      </PrivateRoute>
      
  }/> 

{1==2&&<Route path="/login" element={<Login/>} />}
{1==2&&<Route path="/registerApp" element={<RegisterApp/>} />}
{1==2&&<Route path="/register" element={<Register/>} />}
{1==2&&<Route path='/forgotPassword' element={<ForgotPassword/>} />}
</Routes>  
</Router>
 );
    }
    else {
      
    //console.log("reeender glovar.MODE_USER:"+glovar.MODE_USER)
  return (
<Router>

<Routes>
<Route
          path="//:id"
          element={<PublicPortfolio OWN_USER={0}/>}
        />
        <Route path="/symbol/:ticker_param" element={<ShowFitxaElement fromURLMenu={1}/> }/>
        
  {1==2&&<Route exact path='/home'  element={
      <PrivateRoute>

          <Box backgroundColor="#F3F2EF" pb={"80px"}>
          <HeaderMenu showSearch={true}/>
      <SimpleGrid   columns={14} gap={1} p={2}>
        <GridItem colSpan={1}>
        <Box mt={4}>
      <VStack>
        <ItemSibedar icon="assets/ic_briefcase_white_48dp.png" id={1} />
        {1==1&&<ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/ic_compass_white_48dp.png`} id={13} />}
        {1==2&&<ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/baseline_notifications_white_48.png`} id={12} />}
        {1==2&&<ItemSibedar icon="assets/ic_podium_white_24dp.png" id={2} />}
        {glovar.MODE_USER>=1&&<ItemSibedar icon="assets/baseline_account_box_white_48.png" id={3} />}
        
        {1==2&&<ItemSibedar icon="assets/ic_chart_areaspline_white_24dp.png" id={4} />}
      </VStack>
    </Box>
        </GridItem>
        <GridItem colSpan={4} rounded={10} backgroundColor="#FFFFFF" p={2} m={2}>
        <Flex fontSize="sm" fontWeight="semibold" t={-1} mb={0} ml={2} pt={0} textColor="gray.800"> 
      <Text>{i18n.t("lo_mas_pop")}</Text>
        
      </Flex>
      
        <MorePopularList></MorePopularList>
        </GridItem>
        <GridItem colSpan={4} rounded={10} backgroundColor="#FFFFFF" m={2}> 
        
        {1==2 &&<Image src='assets/moockup11.png' p={3} marginTop={1} />}
        
        {(glovar.MODE_USER==0 || glovar.REFRESH_FRONTUSER)&&<Homefrontuser></Homefrontuser>}
       
        </GridItem>
       
        <GridItem colSpan={4} rounded={10} backgroundColor="#FFFFFF" p={2} m={2}>
        <Flex fontSize="sm" fontWeight="semibold" t={-1} mb={0} ml={2} pt={0} textColor="gray.800"> 
      <Text>{i18n.t("mis_favoritos")}</Text>
        
      </Flex>
        {glovar.MODE_USER>=1&&<FavoritosList></FavoritosList>}
        {glovar.MODE_USER==0&& 
        <AddContentApp mode={1}></AddContentApp>
        }
        </GridItem>
       
      </SimpleGrid>


      {1==2&&<SimpleGrid columns={14} gap={0} p={6} paddingTop={4} paddingBottom={4} mt={0} >

<GridItem colSpan={1}>
  <Box mt={2}>
<VStack>
  {1==2&&<ItemSibedar icon="assets/ic_briefcase_white_48dp.png" id={1} />}
  {1==1&&<ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/ic_compass_white_48dp.png`} id={13} />}
  {1==2&&<ItemSibedar icon={`${process.env.PUBLIC_URL}/assets/baseline_notifications_white_48.png`} id={12} />}
  {1==2&&<ItemSibedar icon="assets/ic_podium_white_24dp.png" id={2} />}
  {1==2&&<ItemSibedar icon="assets/ic_chart_areaspline_white_24dp.png" id={4} />}
</VStack>
</Box>
  </GridItem>

<GridItem colSpan={12} rounded={10} backgroundColor="#FFFFFF" m={2} mt={-6} height="120px" >        </GridItem>
 </SimpleGrid>}

 

      <SimpleGrid columns={14} gap={1} p={2} mt={-1} >

<GridItem colSpan={1}>
  <Box mt={2}>
<VStack>
  {1==2&&<AdComponent600/>}
  {1==1&&<ItemSibedarRRSS icon="assets/ic_twitter_white_24dp.png" id={101} />}
  {1==2&&<ItemSibedarRRSS icon="assets/ic_instagram_white_24dp.png" id={102} />}
  {1==2&&<ItemSibedar icon="assets/baseline_notifications_white_48.png" id={12} />}
  {1==2&&<ItemSibedar icon="assets/ic_chart_areaspline_white_24dp.png" id={4} />}
</VStack>
</Box>
  </GridItem>

<GridItem colSpan={6} rounded={10}  m={2} mt={-2}  height="300px" >
     

      {1==2&&<Box
      //rounded={10}
        //backgroundColor="yellow"  
        //borderRadius="lg"
        fontSize="13"
        fontWeight="semibold"
        textAlign="center"
        paddingTop="0px"
        w="full"

        height="140px"
        mb={3}
        
      >
        {1==2&&<Image src='assets/moockup4.png' p={0} marginTop={0} />}
        {1==2&&<AdComponent728/>}
        <ToptraderList name={"Djpedo en cabina"} price={"19.567,12"} diff={"+6.69%"}></ToptraderList>
      </Box>}

     
          {1==2&&<Image src='assets/moockup2.png' p={3} marginTop={1} />}

          
          <Box ml={0} mr={0} mt={2} p={2} pl={4} rounded={10} backgroundColor="#FFFFFF" >
          <Flex fontSize="sm" fontWeight="semibold" t={-1} mb={2} ml={2} pt={0} textColor="gray.800"> 
      <Text>{i18n.t("mi_cartera")}</Text>
        
      </Flex>
      {glovar.MODE_USER>=1&&<Flex mb={3} textColor="gray.400" fontSize="sm">
        <Text w={"10pc"}>{i18n.t("activo")} - {i18n.t("f_entrada")}</Text>
        <Spacer />
        <Text ml={0}>{i18n.t("titulos")}</Text>
        <Spacer />
        <Text mr={3}>{i18n.t("entrada")}</Text>
        <Spacer />
        
        <Text ml={1}>{i18n.t("precio")}</Text>
        <Spacer />
        <Spacer />
        
        <Text ml={1}>{i18n.t("objetivo")} - {i18n.t("stop")}</Text>
       <Spacer />
       <Spacer />
       
      
        <Text mr={4}>{i18n.t("benef_rent")}</Text>
      </Flex>}
      <Stack minHeight="25pc">
      {glovar.MODE_USER==0&& 
        <AddContentApp mode={2}></AddContentApp>
        }
      {glovar.MODE_USER>=1&&<PortfolioList name={""} price={"19.567,12"} diff={"+6.69%"}></PortfolioList>}

    
</Stack>
    </Box>
          
  
</GridItem>
<GridItem colSpan={6} rounded={10} backgroundColor="#FFFFFF" m={2} mt={0} >
<Box overflowY="auto" maxHeight={600}>
<RSSNewsList name={""} price={"19.567,12"} diff={"+6.69%"}></RSSNewsList>
</Box>

   </GridItem>
 </SimpleGrid> 
    </Box> 
    </PrivateRoute>
  }/>}

{1==2&&<Route path="/login" element={<Login/>} />}
{1==2&&<Route path="/registerApp" element={<RegisterApp/>} />}
{1==2&&<Route path="/register" element={<Register/>} />}
{1==2&&<Route path='/forgotPassword' element={<ForgotPassword/>} />} 
</Routes>  

</Router>
    );
   }
  }

  //New landing
  else if(glovar.CURRENT_MENU_ID==5)
  {
    //if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
    if(1==2)
    {
      return(
      <Router>
      <Routes>
<Route
          path="//:id"
          element={<PublicPortfolio OWN_USER={0}/>}
        />
        <Route path="/symbol/:ticker_param" element={<ShowFitxaElement fromURLMenu={1}/> }/>
        
  <Route exact path='/'  element={
      <PrivateRoute>

        
<Box  backgroundColor="#F3F2EF" pb={"100px"}><Box>
    <Flex p={2} backgroundColor="#FFFFFF" maxHeight="80px" flexDirection="row" justifyContent="space-between">
       <Image src={`${process.env.PUBLIC_URL}/assets/logopro1xsmall1.png`} h="54px" w="157px" _hover={{ cursor: "pointer" }}
         onClick= {() =>  {
           try { EventGA("button_sup","pslogoFitxMB", "pslogoFitxMB"); } catch (exx) { /*console.log("petada event:"+exx)*/}
           window.location.replace('/') 
         }}
        />   
        <Menu>
  <MenuButton as={Button}>
    -
  </MenuButton>
  {!glovar.USER_LOADED&&<MenuList>
    <MenuItem  onClick= {() => {
            try { EventGA("button_sup","pressbtn_login", "pressbtn_login"); } catch (exx) { /*console.log("petada event:"+exx)*/}
            window.location.replace('/login') }
          }>{i18n.t("login")}</MenuItem>
    <MenuItem onClick= {() =>
          {
           // try { EventGA("button_sup","pressbtn_register", "pressbtn_register"); } catch (exx) { /*console.log("petada event:"+exx)*/}
           //window.location.replace('/registerApp') 
           if(glovar.REGISTER_ONLINE==true) {
            try { EventGA("button_sup","pressbtn_regonline", "pressbtn_regonline"); } catch (exx) { /*console.log("petada event:"+exx)*/}
            window.location.replace('/register')
            }
            else {
              try { EventGA("button_sup","pressbtn_register", "pressbtn_register"); } catch (exx) { /*console.log("petada event:"+exx)*/}
              window.location.replace('/registerApp')
            }
          }}>{i18n.t("signup")}</MenuItem>
  </MenuList>}
  {glovar.USER_LOADED&&<MenuList>
    <MenuItem minH='48px' 
    onClick= {() =>{ 
      try { EventGA("button_sup","ClickMBSupAvatar", "ClickSupMBAvatar"); } catch (exx) {} /*this.changemenu(3)*/ }
    }>
    <Avatar w={12} h={10} mr={4}></Avatar>
      <span>{glovar.USER_USERNAME}</span>
    </MenuItem>

    <MenuItem onClick= {() => window.confirm(i18n.t("leave_site_confirm")) && this.logout() }>{i18n.t("m_cerrarses")}</MenuItem>
  </MenuList>}
</Menu>
       

           

    </Flex>
    <Box  backgroundColor="#FFFFFF" mt={-2} pb={2} pl={2} alignContent="center">
    <ShowSearchBar type={1}/>
    </Box>
   </Box>
 
   <SimpleGrid columns={14} gap={0} p={0}>

   <GridItem colSpan={14} rounded={10} backgroundColor="#FFFFFF" m={2} mt={1}> 
        {(glovar.MODE_USER>=0 || glovar.REFRESH_FRONTUSER)&&<Homefrontuser mobile={1}></Homefrontuser>}
       
        </GridItem>

        <GridItem colSpan={14} rounded={10} backgroundColor="#FFFFFF" m={2} mt={1}> 
        <ToptraderList name={"Djpedo en cabina"} price={"19.567,12"} diff={"+6.69%"}></ToptraderList>
        </GridItem>

   <GridItem colSpan={14} rounded={10} backgroundColor="#FFFFFF" p={2} m={2} mt={1}>
        <Flex fontSize={14} fontWeight="semibold" t={-1} mb={0} ml={2} pt={0} textColor="gray.800"> 
        {glovar.MODE_USER==0&&<Text mb={-1}>{i18n.t("lo_mas_pop")}</Text>}
        {glovar.MODE_USER>=1&&<Text mb={-1}>{i18n.t("mis_favoritos")}</Text>}
      </Flex>
         
        {glovar.MODE_USER>=1&&<FavoritosList></FavoritosList>}
        {glovar.MODE_USER==0&& 
        <MorePopularList></MorePopularList>
        }
        </GridItem>

        <GridItem colSpan={14} rounded={10} backgroundColor="#FFFFFF" p={2} m={2} mt={1}>
        <Text fontSize={16} ml={2}  fontWeight="semibold" textColor="gray.800">{i18n.t("noticias")}</Text>
        <Box overflowY="auto" maxHeight={600}>
<RSSNewsList name={""} price={"19.567,12"} diff={"+6.69%"}></RSSNewsList>
</Box>
          </GridItem>
      

   </SimpleGrid>
   </Box>
      </PrivateRoute>
      
  }/> 

{1==2&&<Route path="/login" element={<Login/>} />}
{1==2&&<Route path="/registerApp" element={<RegisterApp/>} />}
{1==2&&<Route path="/register" element={<Register/>} />}
{1==2&&<Route path='/forgotPassword' element={<ForgotPassword/>} />} 
</Routes>  
</Router>
 );
    }
    else {
      
    console.log("reeender glovar.MODE_USER:"+glovar.MODE_USER)
  return (
    
<Router>
<CookieConsentPopup/>

<Routes>
<Route
          path="//:id"
          element={<PublicPortfolio OWN_USER={0}/>}
        />
        <Route path="/symbol/:ticker_param" element={<ShowFitxaElement fromURLMenu={1}/> }/>
        <Route path={glovar.PATH_COOKIES_POLICY} element={<ShowLegalText textType={1}/> }/>
        <Route path={glovar.PATH_PRIVACY_POLICY} element={<ShowLegalText textType={2}/> }/>
        <Route path={glovar.PATH_TERMS_POLICY} element={<ShowLegalText textType={3}/> }/>

        <Route path="/radar" element={<ShowRadar mode={0}/> }/>
        
  <Route exact path='/'  element={
      <PrivateRoute>

   <HeaderMenu showSearch={true}/>
   
  
   <SearchAssetFront/>
   <ShowAssetsSummary/>
   <Box marginBottom={40} marginTop={20}>
   <ShowPopNewsletter/>
   </Box>
   <FooterMenu/>
    </PrivateRoute>
  }/> 

{1==2&&<Route path="/login" element={<Login/>} />}
{1==3&&<Route path="/registerApp" element={<RegisterApp/>} />}
{1==2&&<Route path="/register" element={<Register/>} />}
{1==3&&<Route path='/forgotPassword' element={<ForgotPassword/>} />} 
</Routes>  

</Router>
    );
   }
  }
  }
}

//export default App;

//<RSSnew title={todoRSS.title} thumbnail={todoRSS.thumbnail} link={todoRSS.link} ></RSSnew>