import e from "../eventBus";
import M from './MiComponente';
  


/*
 export default ({ title, thumbnail, link, published,subtitle }) => {

    
     function caca () {
      try {
        eventBus.dispatch("RSSnew", { data:  subtitle });
      }
      catch(Exx) {}
     }

     let props = {
      title:title,
      thumbnail:thumbnail,
      link:link,
      published:published,
      subtitle:subtitle,
      onClick:caca
     }

     return MiComponente({...props})
*/

 /*
     return (
      <div>
        {}
        <MiComponente
          title={title}
          thumbnail={thumbnail}
          link={link}
          published={published}
          subtitle={subtitle}
          onClick={() => {
            caca(subtitle); // Llama a la función caca cuando se hace clic
          }}
        />
      </div>
    );*/
  
  
  
  
  
  
  
  

    /*return (
        <Box m={3}  _hover={{ cursor: "pointer" }}>
    <div>
    <a
              className="link"
              href={link}
              target="_blank"
              rel="nofollow noopener noreferrer"
              title={title}
              aria-label={link}
              key={link}
              onClick={() => { caca(); }}
            >
    <Flex alignItems="center"  >
           <HStack ml={0}  w="full"  >
        
           <Image src={thumbnail} w="94px" h="76px"></Image>

           <VStack  align={"start"} w="full"  >
           <Text
            fontSize="14"
            textColor="black"
            fontWeight="bold"
            noOfLines={2}
           >
            {title}          
            </Text>
          <HStack w="full" >
          <Text
             w="full"
            fontSize="13"
            textColor="green"
            fontWeight="normal"
           >
            {subtitle}
          </Text>
          
          <Text
            
            w="full"
            textAlign="right"
            fontSize="13"
            textColor="gray"
            fontWeight="normal"
            alignSelf="self-end"          >
            <Moment fromNow>{published}</Moment>
          </Text>
         

          </HStack>

        </VStack>

      </HStack>
    </Flex>
    </a>
    </div>
  </Box>
    );*/

function _0x19a3(_0x3d5287,_0x5c664e){const _0x1853c9=_0x1853();return _0x19a3=function(_0x19a3e8,_0x5d6907){_0x19a3e8=_0x19a3e8-0x1e1;let _0x266c64=_0x1853c9[_0x19a3e8];return _0x266c64;},_0x19a3(_0x3d5287,_0x5c664e);}function _0x1853(){const _0x4be71f=['586BqcAgo','dispatch','RSSnew','12103794DRIbVG','3134qrGjUf','4549376KZfzKg','210110nigVJK','1273264HLRISr','1995723IxOdgi','5133989fPZQWg','6ntkoMj'];_0x1853=function(){return _0x4be71f;};return _0x1853();}(function(_0x17733f,_0xb4d41f){const _0x2adc04=_0x19a3,_0x2c46ef=_0x17733f();while(!![]){try{const _0x5f0769=-parseInt(_0x2adc04(0x1ea))/0x1*(-parseInt(_0x2adc04(0x1e3))/0x2)+-parseInt(_0x2adc04(0x1e7))/0x3+parseInt(_0x2adc04(0x1e6))/0x4+parseInt(_0x2adc04(0x1e5))/0x5+parseInt(_0x2adc04(0x1e9))/0x6*(-parseInt(_0x2adc04(0x1e8))/0x7)+-parseInt(_0x2adc04(0x1e4))/0x8+parseInt(_0x2adc04(0x1e2))/0x9;if(_0x5f0769===_0xb4d41f)break;else _0x2c46ef['push'](_0x2c46ef['shift']());}catch(_0x20caa2){_0x2c46ef['push'](_0x2c46ef['shift']());}}}(_0x1853,0xa02fe));export default({title:_0x126c37,thumbnail:_0xa953c7,link:_0x4c2b96,published:_0xb6bc64,subtitle:_0x1f8686})=>{function _0x393c90(){const _0x56496a=_0x19a3;try{e[_0x56496a(0x1eb)](_0x56496a(0x1e1),{'data':_0x1f8686});}catch(_0x187490){}}let _0x25aa09={'title':_0x126c37,'thumbnail':_0xa953c7,'link':_0x4c2b96,'published':_0xb6bc64,'subtitle':_0x1f8686,'onClick':_0x393c90};return M({..._0x25aa09});};
  