import React, { useState } from 'react';
import './CookieConsentPopup.css';
import glovar from './glovar';



const COOKIE_KEY_ACCEPTED = glovar.COOKIE_KEY_ACCEPTED;//'cookiesAccepted';
const COOKIE_KEY_ACCEPTED_ANALYTICS = glovar.COOKIE_KEY_ACCEPTED_ANALYTICS;//'cookiesAcceptedAnalytics';
const COOKIE_KEY_REJECTED = glovar.COOKIE_KEY_REJECTED;//'cookiesRejected';
const COOKIE_KEY_ACCEPTED_ADVERTISING= glovar.COOKIE_KEY_ACCEPTED_ADVERTISING;

var MOBILE_UI=false;

const  CookieConsentPopup = ({ /*onAccept*/ }) => {

    //const [accepted, setAccepted] = useState(localStorage.getItem(COOKIE_KEY) === 'true');

    const [showPopup, setShowPopup] = useState(!((localStorage.getItem(COOKIE_KEY_ACCEPTED) === 'true') || (localStorage.getItem(COOKIE_KEY_REJECTED) === 'true')));

    const [showConfigPopup, setShowConfigPopup] = useState(false);
    const [essentialChecked, setEssentialChecked] = useState(true);
    const [analyticsChecked, setAnalyticsChecked] = useState((localStorage.getItem(COOKIE_KEY_ACCEPTED_ANALYTICS) === 'true'));
    const [advertisingChecked, setAdvertisingChecked] = useState((localStorage.getItem(COOKIE_KEY_ACCEPTED_ADVERTISING) === 'true'));
    

    if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
      MOBILE_UI=true;
    }

  
    const handleAccept = () => {
      // Agregar lógica para guardar la aceptación de cookies en una cookie o en el estado global de la aplicación.
      
      setAnalyticsChecked(true);
      setAdvertisingChecked(true);
      //setAccepted(true);
      localStorage.setItem(COOKIE_KEY_ACCEPTED_ANALYTICS, 'true'); 
      localStorage.setItem(COOKIE_KEY_ACCEPTED_ADVERTISING, 'true'); 
      localStorage.setItem(COOKIE_KEY_ACCEPTED, 'true'); // Almacenamos en localStorage que el usuario aceptó las cookies
      glovar.COOKIE_ACCEPTED_ANALYTICS=true;
      glovar.COOKIE_ACCEPTED=true;
      glovar.COOKIE_ACCEPTED_ADVERTISING=true;
      //glovar.FORCE_COOKIES_CONFIG=false;
      //onAccept(true); // Llamamos a la función onAccept con `true` como argumento

      
      setShowConfigPopup(false);
      setShowPopup(false);
  };

    
  
    const handleReject = () => {
      // Agregar lógica para rechazar el uso de cookies y manejarlo según tus necesidades.
      setShowPopup(false);
      setAnalyticsChecked(false);
      localStorage.setItem(COOKIE_KEY_ACCEPTED_ANALYTICS, 'false'); 
      localStorage.setItem(COOKIE_KEY_ACCEPTED_ADVERTISING, 'false'); 
      localStorage.setItem(COOKIE_KEY_REJECTED, 'true'); // Almacenamos en localStorage que el usuario aceptó las cookies
      glovar.COOKIE_ACCEPTED_ANALYTICS=false;
      glovar.COOKIE_ACCEPTED_ADVERTISING=false;
      glovar.COOKIE_REJECTED=true;
      //glovar.FORCE_COOKIES_CONFIG=false;
    };
  
    const handleConfigure = () => {
      setShowConfigPopup(true);
      //glovar.FORCE_COOKIES_CONFIG=false;
    };
  
    const handleSaveConfiguration = () => {
      // OCULTEM POPUP COOKIES.

      localStorage.setItem(COOKIE_KEY_ACCEPTED, 'true'); // Almacenamos en localStorage que el usuario aceptó las cookies
      glovar.COOKIE_ACCEPTED=true;
      //glovar.FORCE_COOKIES_CONFIG=false;

      if(analyticsChecked) {
        localStorage.setItem(COOKIE_KEY_ACCEPTED_ANALYTICS, 'true'); 
        glovar.COOKIE_ACCEPTED_ANALYTICS=true;
      }
      else {
        localStorage.setItem(COOKIE_KEY_ACCEPTED_ANALYTICS, 'false'); 
        glovar.COOKIE_ACCEPTED_ANALYTICS=false;
      }

      if(advertisingChecked) {
        localStorage.setItem(COOKIE_KEY_ACCEPTED_ADVERTISING, 'true'); 
        glovar.COOKIE_ACCEPTED_ADVERTISING=true;
      }
      else {
        localStorage.setItem(COOKIE_KEY_ACCEPTED_ADVERTISING, 'false'); 
        glovar.COOKIE_ACCEPTED_ADVERTISING=false;
      }

      
      setShowConfigPopup(false);
      setShowPopup(false);
    };

    /*useEffect(() => {
      if (accepted) {
        return; // Si ya se aceptaron las cookies, no mostramos el popup
      }
    }, [accepted]);*/

    //console.log("glovar.FORCE_COOKIES_CONFIG:"+glovar.FORCE_COOKIES_CONFIG)

    // Obtén la ruta de la URL actual
  const currentPathname = window.location.pathname;

  // Comprueba si la ruta es "/terms"
  const isOnTermsPage = currentPathname === glovar.PATH_COOKIES_POLICY;

  if(isOnTermsPage) {

    return(null)
  }

  else {

  
    return (
      (showPopup || showConfigPopup) && (<div className="cookie-banner-overlay">
        <div className={`cookie-consent-popup ${showPopup ? '' : 'hidden'}`}>
        {(showConfigPopup) ? (
          <div className="config-popup">
            <div className="cookie-pref-title-sup">🍪 Manage your privacy preferences</div>
            
           <div className="cookie-pref-title">
            <label>
              <input
                type="checkbox"
                className="input_checkbox"
                checked={essentialChecked}
                disabled={true}
              />
              Necessary cookies -Always active-
            </label>
            </div>
            <div className="cookie-pref-text">
            These cookies are used to provide users with services available through a Site and to use some of its features, such as the ability to log-in and access secure areas, provide pop-up notices, and accept language from sign-up forms. These cookies are essential for using and navigating a Site. Without them, basic functions of our Site would not work. Because these cookies are strictly necessary to deliver our Site's essential services, you cannot refuse them.
            </div>
            <br />
            <div className="cookie-pref-title">
            <label>
              <input
                type="checkbox"
                className="input_checkbox"
                checked={analyticsChecked}
                onChange={() => setAnalyticsChecked(!analyticsChecked)}
              />
              Performance / Analytics cookies
            </label>
           </div>
           <div className="cookie-pref-text">
           Performance and Analytics cookies allow us to analyze your visits and  actions on our websites, and offer you a more relevant experience.
            </div>
            <br />
            

             <div className="cookie-pref-title">
            <label>
              <input
                type="checkbox"
                className="input_checkbox"
                checked={advertisingChecked}
                onChange={() => setAdvertisingChecked(!analyticsChecked)}
              />
              Advertising cookies
            </label>
           </div>
           <div className="cookie-pref-text">
           These cookies are used to show advertising that is relevant to you.
            </div>
            <br />
            <div className="cookie-text">
            More information about our <a href={glovar.URL_COOKIES_POLICY} target="_blank" text-decoration="underline">Cookie Policy</a>.
            </div>
           
            <div style={{ textAlign: 'right' }}>
            {MOBILE_UI && <button onClick={handleSaveConfiguration} className="configure-button-popup"> Save prefs</button> }
            {!MOBILE_UI && <button onClick={handleSaveConfiguration} className="configure-button-popup"> Save preferences</button> }
         
                  
                  <button onClick={handleReject} className="reject-button">
                    Deny
                  </button>
                  <button onClick={handleAccept} className="accept-button">
                    Accept All
                  </button>
                  
                 
                </div>

          </div>
        ) : (
          <div>
            <div className="cookie-title">
            🍪 This website uses cookies
            </div>
            <div className="cookie-text">
            We use our own and third-party cookies to analyze our services and show you advertising related to your preferences, based on a profile created from your
             browsing habits, for example, websites visited. You can accept, configure, or reject the use of cookies below. More info about our <a href={glovar.URL_COOKIES_POLICY} target="_blank" text-decoration="underline">Cookie Policy</a>.
            </div>
            <div className="button-container">
              {!showConfigPopup && (
                <div>
                   {!MOBILE_UI&& <button onClick={handleAccept} className="accept-button"> Accept and close </button>}
                   {!MOBILE_UI&& <button onClick={handleConfigure} className="configure-button"> Configuration </button>}

                   
                   {MOBILE_UI&& <button onClick={handleConfigure} className="configure-button"> Configuration </button>}
                   {MOBILE_UI&& <button onClick={handleAccept} className="accept-button-mbl"> Accept and close </button>}
                </div>


              )}
            </div>
          </div>
        )}
      </div>
      </div>)
    );
  }
}
  
  export default CookieConsentPopup;