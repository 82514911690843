

import { Text } from "@chakra-ui/layout";
//import { Spacer } from "@chakra-ui/layout";
//import { Stack } from "@chakra-ui/layout";
//import { Heading } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
import { HStack } from "@chakra-ui/layout"; 
import { VStack } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";
import eventBus from "../eventBus";
import i18n from "../../i18n";

//import {getURLToptraderPicture} from "../utils/utils";

//import { FiArrowDown, FiArrowUp } from "react-icons/fi";

  
function changeMenu(toptrader) {
  //glovar.CURRENT_MENU_ID=3;
  //console.log("cambiamos de menu")
  eventBus.dispatch("showTopTrader", { tt: toptrader });
  //console.log('commmm pos:'+pos)
  } 

  export default ({Toptrader, index }) => {

   

   const getURLToptraderPicture = (id) => {

      //console.log("holaaaaaaaaaaaxxxxxxxxxxxx")
      let url=process.env.PUBLIC_URL+"/assets/logopro1xsmall.png";
    
      if(id>0 && id<=31) {
        url=process.env.PUBLIC_URL+"/assets/prof"+id+".jpg";
      }
      //console.log(url)
      return url;
    
     
     }

    let diff=1
    
    let posranking=index+1;
    
    let color1="#1FB31F"
    let color2="#FFFFFF"

    var subtitle="subtitle"
    //var title=name;
    var percent=Number(diff).toFixed(2);
    var change=Number(diff).toFixed(2);
    var loss=Number(diff).toFixed(2);
    var coti=3444; //price;
    var loss=(diff<0);

    var activosList = [ "varios","Acciones", "CFDs", "Forex","Commodities","Criptodivisas"];

    try {
      activosList[0]=i18n.t("activo");
      activosList[1]=i18n.t("acciones");
     //activo[2]="CFDs";
     //activo[3]="Forex";
     //activo[4]="Commodities"
     activosList[5]=i18n.t("cripto");
    }
    catch(exx) {}


    let ListaActivos="";
    try 
    {
      const myArrayActivos = Toptrader.activos.split(",");

      for(let zz=0; zz<myArrayActivos.length; zz++) {

        if(ListaActivos.length>0) { ListaActivos=ListaActivos+","+activosList[myArrayActivos[zz]]; }
        else {
          ListaActivos=activosList[myArrayActivos[zz]];
        }
      }
    }
    catch(errx) { /*console.log(errx);*/ ListaActivos="Varios"}
  

    //const icon = () => { return(<FiArrowDown />); }

    if(diff>0) {
        color1="#1FB31F"
          color2="#FFFFFF"
    }
    else {
        color1="#D61A18"
        color2="#FFFFFF"
    }

    //console.log("index toptrader:"+index); 
    /*
    rentab: Number,
    name: String,
    idCartera: String,
    id: Number,
    descript: String,
    risk_level: Number,
    max_level: Number,
    countriesID:String,
    activos: String,
    op_cerradas: Number,
    rentmedia_op: Number,
    sentido: Number,
    temporal: Number,
    historial: Array,
    cartera_text: Array,
    cartera:Array,
    capital:Number,
    invertido: Number,
    moneda:String,
    profit:Number,
    currency:String,
    priority: Number,
    rentab_acum: Number,
    type_cartera: Number, //por defecto solo la pueden ver los premiums!
    op_win: Number,
    followers: Number,
    cap_inicial:Number,
    ranking: Number,
    FOLLOWERS_LIST: Array
    */

    return (
        <Box marginEnd={3} rounded={10} height="140px"  onClick={() => changeMenu(Toptrader)}  _hover={{ cursor: "pointer" }}>
    <div>
    <Flex alignItems="start"  >
    <Box  rounded={10}  w="160px" height="140px"  backgroundColor="white" >
    
   < Box
        marginTop="0px"
        roundedTop={10}
        h="6px"
        backgroundColor="black"
      >
       
      </Box>

           <HStack >
        
           <Image src={getURLToptraderPicture(Toptrader.id)} rounded={6} w="54px" h="54px" marginLeft={1.5}  marginTop={1.5}></Image>

           <VStack  align={"start"}>
           <Text
         marginTop={0.5}
           
            fontSize="13"
            textColor="black"
            fontWeight="semibold"
            noOfLines={1}
           >
            {Toptrader.name}          
            </Text>
          <HStack >
          
      <HStack marginTop="-6px">
        <Flex><Image src={`${process.env.PUBLIC_URL}/assets/ops.png`} w="13px" h="13px"/><Text fontSize="10" fontWeight="bold"  >{Toptrader.op_cerradas}</Text></Flex>
        <Flex><Image src={`${process.env.PUBLIC_URL}/assets/opsbenef.png`}  w="13px" h="13px" ml={0.5}/><Text fontSize="10" fontWeight="bold"  ml={0.5}>{Toptrader.op_win}</Text></Flex>
        </HStack>
     
      
          </HStack>
          <Box><Flex marginTop="-4px"><Image src={`${process.env.PUBLIC_URL}/assets/follows.png`} w="12px" ml={0}/><Text fontSize="10" fontWeight="bold" ml={0.5} >{Toptrader.followers}</Text></Flex></Box>
        </VStack>
        
      </HStack>
   
      <Text
      marginTop={2}
          fontSize="11"
          textColor="black"
          fontWeight="normal"
          align="center"
         
        >
          {ListaActivos}
        </Text>
        
        <HStack alignItems="center" alignContent="center" alignSelf="center">
      <Box
        marginLeft="23%"
        borderRadius="lg"
        fontSize="13"
        fontWeight="semibold"
        textAlign="center"
        paddingTop="2px"
      
        w={"5.5pc"}
        h={"1.4pc"}
        bg={color1}
        textColor={color2}
      >
       +{Toptrader.rentab.toFixed(2)}%
      </Box>
      </HStack>
      
      <Box
        marginTop={11}
        roundedBottom={10}
        h="15px"
        fontSize="11px"
        fontWeight="semibold"
        textAlign="center"
        paddingTop="0"
        textColor="white"
        backgroundColor="black"
      >
       {posranking}
      </Box>

    </Box>
    </Flex>
    </div>
  </Box>
    );
  };
  