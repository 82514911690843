

import { Text } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
import { HStack } from "@chakra-ui/layout"; 
import { VStack } from "@chakra-ui/layout"; 
import { Image } from "@chakra-ui/image";

import Moment from 'react-moment';
import eventBus from "../eventBus";

  
export default  (props) => {
  
  //console.log("props:"+JSON.stringify(props))
  return (
    <Box m={3} _hover={{ cursor: 'pointer' }}>
      <div>
        <a
          className="link"
          href={props.link}
          target="_blank"
          rel="nofollow noopener noreferrer"
          title={props.title}
          aria-label={props.link}
          key={props.link}
          onClick={props.onClick} // Llama a la función onClick que se pasa como prop
        >
          <Flex alignItems="center">
            <HStack ml={0} w="full">
              <Image src={props.thumbnail} w="94px" h="76px"></Image>
              {1==1&&<VStack align={'start'} w="full">
                <Text fontSize="14" textColor="black" fontWeight="bold" noOfLines={2}>
                  {props.title}
                </Text>
                <HStack w="full">
                  <Text w="full" fontSize="13" textColor="green" fontWeight="normal">
                    {props.subtitle}
                  </Text>
                  <Text
                    w="full"
                    textAlign="right"
                    fontSize="13"
                    textColor="gray"
                    fontWeight="normal"
                    alignSelf="self-end"
                  >
                    <Moment fromNow>{props.published}</Moment>
                  </Text>
                </HStack>
              </VStack>}
            </HStack>
          </Flex>
        </a>
      </div>
    </Box>
  );
}