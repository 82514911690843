
import { tsvParse, csvParse, csvParseRows } from  "d3-dsv";
import { timeParse } from "d3-time-format";
import { csv,dsv,  text } from "d3-fetch"; 
import Papa from "papaparse"
import glovar from '../components/content/glovar';
import i18n from "../i18n"


import { useEffect, useRef } from "react";
//import { useFetchUpdatesUser } from "../utils/useFetchUpdatesUser";


export var addRemoveFAV = (VALOR,ADD_VALOR) => {
  
  
    var VALOR_FAVORITO=false; 
    if (ADD_VALOR===true) {
      VALOR_FAVORITO=true;
    }
    //VALOR_FAVORITO=!VALOR_FAVORITO;
    var MUST_REFRESH_SERVER=0;

    const timemilisFavoritos=Math.floor(Date.now()/1);
   
    
    if(glovar.CURRENT_FAVORITOS) {
      glovar.NUM_FAVORITOS=glovar.CURRENT_FAVORITOS.length;
    }
    else {
      glovar.NUM_FAVORITOS=0;
    }
    console.log("entro en ADDREMOVEFAV")

    if(VALOR_FAVORITO)
    {
      if(((glovar.MODE_USER==2 || glovar.MODE_USER_WEB==2) && glovar.NUM_FAVORITOS<glovar.MAX_FAVORITES_PREMIUM)
      || (glovar.NUM_FAVORITOS<glovar.MAX_FAVORITES_NO_PREMIUM))
      {
       glovar.CURRENT_FAVORITOS.push(VALOR);
       glovar.LOCAL_lastDataUpdateFavoritos=timemilisFavoritos;

       //setFavoritos(glovar.CURRENT_FAVORITOS);

       /*
       try { AsyncStorage.setItem('LOCAL_lastDataUpdateFavoritos',JSON.stringify(glovar.LOCAL_lastDataUpdateFavoritos)); } catch(exxxxx) {}
       AsyncStorage.setItem('favoritoslocal', JSON.stringify(glovar.CARTERA_FAVORITOS)).then(() => 
       {
        glovar.NUM_FAVORITOS=glovar.NUM_FAVORITOS+1;
        //console.log(JSON.stringify('FAVORITE ADDED OK:'+JSON.stringify(glovar.CARTERA_FAVORITOS)));
       });
       */
       //console.log('account loged!',response)
      //glovar.REFRESH_HOMESCREEN=true;
      glovar.NUM_FAVORITOS=glovar.NUM_FAVORITOS+1;
      MUST_REFRESH_SERVER=1;
      console.log("VALOR METIDO EN FAVORITOS!!")
      //try { analytics().setCurrentScreen('addFavoriteFitxa_init'); } catch(Exp) {}

      /*
      try { 
        //async () => 
        { analytics().logEvent('addfavoriteOK_event', {
        id: 'addfavoriteOK',
        value: 'symbol_favorite',
        variable: VALOR.symbol,
        num_favs: glovar.NUM_FAVORITOS,
        mode_user: glovar.MODE_USER,
        mode_user_web: glovar.MODE_USER_WEB
        });
      //console.log('addfavoriteOK_event variable:'+VALOR.symbol);
      }
      }
      catch(Exxxx) {}
      */
     }
     else 
     {
      //we undo the add/remove favorite action
      //VALOR_FAVORITO=!VALOR_FAVORITO;

      if(glovar.MODE_USER==2 || glovar.MODE_USER_WEB==2) //PREMIUM can't add more than MAX_FAVORITES_PREMIUM
      {
        var SampleText = i18n.t('max_fav_premium');
        var max_fav_premium = SampleText.replace("XX",""+glovar.MAX_FAVORITES_PREMIUM);
       

        /*
        Alert.alert(
          i18n.t('has_superado_fav'),//'Has superado el límite de favoritos',
          max_fav_premium+//i18n.t('max_fav_premium')+//'Ya tienes'+glovar.MAX_FAVORITES_PREMIUM+' valores FAVORITOS.\nPara evitar el consumo excesivo de datos y la ralentización de la app,' 
          i18n.t('max_fav_premium_2'))//+' elimina un valor de la lista antes de añadir uno nuevo.')
          */
      }
      else { //no PREMIUM USERS can't add more than MAX_FAVORITES_NO_PREMIUM. iF YOU WANT MORE, PLEASE UPGRADE TO PREMIUM

        /*
        Alert.alert(
          i18n.t('has_superado_fav'),//'Has superado el límite de favoritos',
          i18n.t('max_fav_be_premium'),//'Para poder añadir más valores a favoritos debes ser usuario Pro1 PREMIUM.',
          //i18n.t('funcio_only_pro1'),//'Funcionalidad exclusiva para usuarios\nPro1 PREMIUM disponible en próximas versiones.',
          [
            {
              text: i18n.t('m_quiero_ser_premium'),//'Quiero ser Pro1 PREMIUM',
              //onPress: () => console.log('Cancel Pressed'),
              onPress: () => this.props.navigation.navigate('PremiumScreen'),
              style: 'cancel'
            },
            { text: 'OK' }
          ],
          { cancelable: false }
        )*/
      }
      try { 
        //async () => 
        /*{ analytics().logEvent('addfavoriteNO_OK_event', {
        id: 'addfavoriteNO_OK',
        value: 'symbol_favorite',
        variable: VALOR.symbol,
        num_favs: glovar.NUM_FAVORITOS,
        mode_user: glovar.MODE_USER,
        mode_user_web: glovar.MODE_USER_WEB
        });
      //console.log('addfavoriteNO_OK_event:'+VALOR.symbol);
      }*/
      }
      catch(Exxxx) { }

     }
    
   }
    //Remove from favorites list
    else {
      console.log("Remove from favorites list")
      try 
      {
         var array = glovar.CURRENT_FAVORITOS // make a separate copy of the array
       
         for (let i = 0; i < array.length; i++) 
         {
           if(array[i].symbol==VALOR.symbol)
           {
            //const MIERDA = AsyncStorage.getItem('favoritoslocal')
            //console.log('favoritos:'+JSON.stringify(MIERDA));

            console.log('lo quito de fav VALOR SYMBOL:'+VALOR.symbol);
            console.log("NUMFAVORITOS ANTES:"+glovar.CURRENT_FAVORITOS.length)
            var index=i; 
            array.splice(index, 1);
            glovar.CURRENT_FAVORITOS=array;
            i=array.length;
            glovar.NUM_FAVORITOS=glovar.NUM_FAVORITOS-1;
            glovar.LOCAL_lastDataUpdateFavoritos=timemilisFavoritos;

            console.log("NUMFAVORITOS DESDPUES BORRAR:"+glovar.CURRENT_FAVORITOS.length)
            //setFavoritos(glovar.CURRENT_FAVORITOS);
            /*
            try { AsyncStorage.setItem('LOCAL_lastDataUpdateFavoritos',JSON.stringify(glovar.LOCAL_lastDataUpdateFavoritos)); } catch(exxxxx) {}
            //falta salvar cartera local en AsyncStorage
            AsyncStorage.setItem('favoritoslocal', JSON.stringify(glovar.CARTERA_FAVORITOS))
            //console.log(JSON.stringify('FAVORITE REMOVERD:'+JSON.stringify(glovar.CARTERA_FAVORITOS)));
            glovar.REFRESH_HOMESCREEN=true;
            */
            MUST_REFRESH_SERVER=1;
           }
           }
        } 
        catch(err1){
        //console.log('There was an error saving the product:'+err1)
        }

        try { 
          //async () => 
          /*{ analytics().logEvent('remfavoriteOK_event', {
          id: 'remfavoriteOK',
          value: 'symbol_favorite',
          variable: VALOR.symbol,
          num_favs: glovar.NUM_FAVORITOS,
          mode_user: glovar.MODE_USER,
          mode_user_web: glovar.MODE_USER_WEB
          });
        //console.log('remfavoriteOK_event variable:'+VALOR.symbol);
        }*/
        }
        catch(Exxxx) { }

    }

    /*try 
    {
      this.props.navigation.setOptions({
       title:TEXT_TITLE_HEADER,//VALOR_NOM,
       headerRight: () => (
     <View style={{
             alignItems: 'center',
             flexDirection: 'row',
             marginTop: 0,
             marginBottom: 0,
             marginRight: 20
           }}>
             {!VALOR_FAVORITO&&<Icon name="heart-outline" color="#000000" size={30-(glovar.SIZE_LESS*3)}  
             style={{  marginTop: 0, marginRight: 24}}
    onPress={() => this.addRemoveFavorite()}/>}
     {VALOR_FAVORITO&&<Icon name="heart" color="#000000" size={30-(glovar.SIZE_LESS*3)}  style={{  marginTop: 0, marginRight: 24}}
    onPress={() => this.addRemoveFavorite()}/>}  
       <Icon name="briefcase-plus" color="#000000" size={30-(glovar.SIZE_LESS*3)}  style={{  marginTop: 0, marginRight: 0}}
    onPress={() => this.preafegirValor()}/>
            </View>
        
             ),	
     })
   }
   catch(Exxxxl) {}*/


   if(MUST_REFRESH_SERVER)//&& glovar.MODE_USER>=glovar.MODEUSERMIN_FOR_FB)
   {
    try {
      
      console.log(" 0 UPDATEO SERVER:");
      //useEffect(() => 
      {

        //MODES_FETCH
        //1->Favoritos //2->Cartera //3->Historial //4->Cartera && Historial
        var MODE_FECTH="1";
   
        let headers = new Headers(); 
        headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API_COTIS + ":" + glovar.PASS_API_COTIS));
        //headers.set('Parameters', 'Basic ' + btoa(glovar.USER_USERNAME + ":" + MODE_FECTH + ":" +JSON.stringify(glovar.CURRENT_FAVORITOS) + ":" + "null"+ ":" +"null"));
        //headers.set('Parameters', 'Basic ' + btoa("1"+ "%3¿7&" +glovar.USER_USERNAME + "%3¿7&" +JSON.stringify(glovar.CURRENT_FAVORITOS)));

        console.log(" 1 UPDATEO SERVER:");
        //headers.set('Parameters', 'Basic ' + btoa(username + ":" +email + ":" + password+ ":" +newsletter));
        
        //headers.set('Parameters', 'Basic ' + btoa(MODE_FECTH+ "%3¿7&" +glovar.USER_USERNAME + "%3¿7&" +JSON.stringify(glovar.CURRENT_FAVORITOS)));

        headers.set('Parameters', 'Basic ' + btoa(MODE_FECTH+ "%?z&" +glovar.USER_USERNAME + "%?z&" +JSON.stringify(glovar.CURRENT_FAVORITOS)));


        //headers.set('Username', 'Basic ' + btoa(glovar.USER_USERNAME));
        //headers.set('Parameters', 'Basic ' + btoa(glovar.CURRENT_FAVORITOS));
    
        console.log("ABANS DEL FETCH")
        fetch(glovar.URL_BACKEND_PROD+'/api/updatesuser',{ method:'POST', //mode: 'no-cors',
        headers: headers,
        })
       
       .then(res => res.json())
       .then(res => {
         console.log("res:"+res)
        if(res=="auth/user-not-found" || res=="auth/wrong-password") {
          return;
        }
    
        if(res=="auth/Unauthorized" || res=="auth/too-many-requests") {
          return;
        }
    
        if(res=="ok_updated")
        {
          
          setFavoritos(glovar.CURRENT_FAVORITOS);
        }
      })
      .catch((e) => {
        //console.log(e);
       })
      }//,[]);


      /*var db = firebase.firestore();

      let NOM_USER_FB=glovar.USER_USERNAME;
      let COLLECTION_FB="users";
      
      if(glovar.MODE_USER==0) {NOM_USER_FB=glovar.ANOM_USER_USERNAME; COLLECTION_FB='users_anom';} 
      
      firebase.firestore().collection(COLLECTION_FB).doc(NOM_USER_FB+'').update({
        favoritos: glovar.CARTERA_FAVORITOS,
        numFavoritos: glovar.NUM_FAVORITOS,
        lastDataUpdateFavoritos: glovar.LOCAL_lastDataUpdateFavoritos
    })
    .catch(function(error) {
      //console.log('ERROR UPLOAD FAVORITOS SERVER:'+error);  
    }).then(function() {
      //console.log("###UUPLOAD FAVORITOS SERVER");
      //console.log('glovar.NUM_FAVORITOS:'+ glovar.NUM_FAVORITOS);
      //console.log('glovar.CARTERA_FAVORITOS:'+ glovar.CARTERA_FAVORITOS);
      //console.log('lastToken:'+glovar.TOKEN+'',);
      //console.log('checkPREMIUM:'+glovar.NUM_CHECK_PREMIUM);
      //console.log(' checkPAY:'+glovar.NUM_CHECK_PAY);
      //console.log('CURRENT_VERSION:'+glovar.APP_VERSION);
      }
     );*/
    }
    catch(exxx) {
      console.log('Petada ERROR UPLOAD FAVORITOS SERVER:'+exxx);  
    }
   }

  }

export var isValorFAV = (symbol) => {

  if(!glovar.CURRENT_FAVORITOS || glovar.MODE_USER==0) {
    return false;
  }

  //return true;
  console.log("isValorFAV:"+symbol)

  try {
  //We must check if this stock is in favorite local list
  for (let i = 0; i < glovar.CURRENT_FAVORITOS.length; i++) 
  {
   console.log("lovar.CARTERA_FAVORITOS[i].symbol:"+glovar.CURRENT_FAVORITOS[i].symbol)
   if(glovar.CURRENT_FAVORITOS[i].symbol==symbol)
   {
    console.log("VALOR EN FAVORITOS:")
    //console.log(JSON.stringify(glovar.CURRENT_FAVORITOS[i]))
    return true;
   }
  }
  return false;
  }
  catch(Exx) {
    return false;
  }
 }

export function AdComponent320() {
  const banner = useRef();

  const atOptions = {
    //key: "aeb5262a7b5a337ae81789065fd4b56a",
    'key' : glovar.KEY_ADCOMP_320_OK,
    format: "iframe",
    height: 50,
    width: 320,
  };

      useEffect(() => {
        if (!banner.current.firstChild) {
          const conf = document.createElement("script");
          const script = document.createElement("script");
          script.type = "text/javascript";
          script.src = `//www.effectivecreativeformat.com/${atOptions.key}/invoke.js`;
          conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;

          if (banner.current) {
            banner.current.append(conf);
            banner.current.append(script);
          }
        }
      }, []);
  

  return <div ref={banner} />;
}

export function AdComponent600() {
  const banner = useRef();

  const atOptions = {
    key: glovar.KEY_ADCOMP_600,
    format: "iframe",
    height: 600,
    width: 160,
  };

      useEffect(() => {
        if (!banner.current.firstChild) {
          const conf = document.createElement("script");
          const script = document.createElement("script");
          script.type = "text/javascript";
          script.src = `//www.effectivecreativeformat.com/${atOptions.key}/invoke.js`;
          conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;

          if (banner.current) {
            banner.current.append(conf);
            banner.current.append(script);
          }
        }
      }, []);
  

  return <div ref={banner} />;
}


export function AdComponent468() {
  const banner = useRef();

  //console.log("glovar.KEY_ADCOMP_468_OK:"+glovar.KEY_ADCOMP_468_OK)
  const atOptions = {
    //key: "f29a724eac91492e19bb63a483d5527b",
    key: glovar.KEY_ADCOMP_468_OK, //
    format: "iframe",
    height: 60,
    width: 468,
  };

  //console.log("atOptions.key:"+atOptions.key)

      useEffect(() => {
        if (!banner.current.firstChild) {
          const conf = document.createElement("script");
          const script = document.createElement("script");
          script.type = "text/javascript";
          script.src = `//www.effectivecreativeformat.com/${atOptions.key}/invoke.js`;
          conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;

          if (banner.current) {
            banner.current.append(conf);
            banner.current.append(script);
          }
        }
      }, []);
  

  return <div ref={banner} />;
}

export function AdComponent728() {
  const banner = useRef();

  const atOptions = {
    key: glovar.KEY_ADCOMP_728, //"0359f5870e85e20b331cf32880735bbe",
    format: "iframe",
    height: 90,
    width: 728,
    //params : {}
  };

      useEffect(() => {
        if (!banner.current.firstChild) {
          const conf = document.createElement("script");
          const script = document.createElement("script");
          script.type = "text/javascript";
          script.src = `//www.effectivecreativeformat.com/${atOptions.key}/invoke.js`;
          conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;

          if (banner.current) {
            banner.current.append(conf);
            banner.current.append(script);
          }
        }
      }, []);
  

  return <div ref={banner} />;
}

export const setElementData = (element,ticker) => {
  sessionStorage.setItem('elementdata_'+ticker, JSON.stringify(element));
}

export const getElementData = (ticker) => {
  const userStr = sessionStorage.getItem('elementdata_'+ticker);
  if (userStr) return JSON.parse(userStr);
  else return null;
}

// return the user data from the session storage
export const getUser = () => {
  const userStr = sessionStorage.getItem('user');
  if (userStr) return JSON.parse(userStr);
  else return null;
}
 
// return the token from the session storage
export const getToken = () => {
  return sessionStorage.getItem('token') || null;
}

// return the token from the session storage
export const getEmail = () => {
  return sessionStorage.getItem('email') || null;
}
 
export const getModeUser = () => {
  return sessionStorage.getItem('modeuser') || null;
}

// remove the token and user from the session storage
export const removeUserSession = () => {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('user');
  sessionStorage.removeItem('email');
  sessionStorage.removeItem('modeuser');
  sessionStorage.removeItem('portfolioed');
  sessionStorage.removeItem('favoritos');
  sessionStorage.removeItem('portfolio');
  sessionStorage.removeItem('historial');
}
 
// set the token and user from the session storage
export const setUserSession = (token, user, email,modeuser) => {
  sessionStorage.setItem('token', token);
  sessionStorage.setItem('user', JSON.stringify(user));
  sessionStorage.setItem('email', JSON.stringify(email));
  sessionStorage.setItem('modeuser', JSON.stringify(modeuser));
}

export const setModeUser = (modeuser) => {
  sessionStorage.setItem('modeuser', JSON.stringify(modeuser));
}

export const setCurrent_porfolio_ED = (portfolio_ED) => {

  var portfolio_ED_PARSED=JSON.stringify(portfolio_ED);
  //console.log("guardo portfolioED:"+portfolio_ED_PARSED)
  sessionStorage.setItem('portfolioed', portfolio_ED_PARSED);
  //console.log("ed guardadoooooooooo!")
}

export const setTOPTRADERSLIST = (toptradersList) => {

  var toptradersList_PARSED=JSON.stringify(toptradersList);
  sessionStorage.setItem('toptradersList', toptradersList_PARSED);
  sessionStorage.setItem("lasttime_toptraderlist",Date.now())
}

export const getTOPTRADERSLIST = () => {
  return sessionStorage.getItem('toptradersList') || null;
                               
}

export const getLastTimeTOPTRADERSLIST = () => {
  return sessionStorage.getItem('lasttime_toptraderlist') || null;
                               
}

export const setMOREPOPULAR = (morepopularList) => {
  var morepopularList_PARSED=JSON.stringify(morepopularList);
  sessionStorage.setItem('morepopularList', morepopularList_PARSED);
  sessionStorage.setItem("lasttime_morepopularlist",Date.now())
  //console.log("guardoo setmorepopulaaaaaaaaaaaar")
}

export const getMOREPOPULAR = () => { 
  return sessionStorage.getItem('morepopularList') || null;                            
}

export const getLastTimeMOREPOPULAR = () => {
  return sessionStorage.getItem('lasttime_morepopularlist') || null;
}

export const setMYWATCHLIST = (mywatchlistList) => {
  var mywatchlistList_PARSED=JSON.stringify(mywatchlistList);
  sessionStorage.setItem('mywatchlistList', mywatchlistList_PARSED);
  sessionStorage.setItem("lasttime_mywatchlistList",Date.now())
}
export const getMYWATCHLIST = () => { 
  return sessionStorage.getItem('mywatchlistList') || null;                            
}
export const getLastTimeMYWATCHLIST = () => {
  return sessionStorage.getItem('lasttime_mywatchlistList') || null;
}



export const setMYPORTFOLIO = (myportfolioList) => {
  var myportfolioList_PARSED=JSON.stringify(myportfolioList);
  sessionStorage.setItem('myportfolioList', myportfolioList_PARSED);
  sessionStorage.setItem("lasttime_myportfolioList",Date.now())
}
export const getMYPORTFOLIO = () => { 
  return sessionStorage.getItem('myportfolioList') || null;                            
}

export const getLastTimeMYPORTFOLIO = () => {
  return sessionStorage.getItem('lasttime_myportfolioList') || null;
}

export const getporfolio_ED = () => {
  
  return sessionStorage.getItem('portfolioed') || null;
                               
}

export const setUserSessionStocks = (user,favoritos, historial, portfolio) => {
  //glovar.CURRENT_FAVORITOS, glovar.CURRENT_HISTORIAL,glovar.CURRENT_PORTFOLIO

  var favoritosPARSED=JSON.stringify(favoritos);
      //favoritosPARSED = favoritosPARSED.replace('[', '');
      //favoritosPARSED = favoritosPARSED.replace(']', '');

  var portfolioPARSED=JSON.stringify(portfolio);
      //portfolioPARSED = portfolioPARSED.replace('[', '');
      //portfolioPARSED = portfolioPARSED.replace(']', '');

  sessionStorage.setItem('favoritos', favoritosPARSED);
  sessionStorage.setItem('portfolio', portfolioPARSED);
  sessionStorage.setItem('historial', JSON.stringify(historial));
}

export const setFavoritos = (favoritos) => {
  var favoritosPARSED=JSON.stringify(favoritos);
  sessionStorage.setItem('favoritos', favoritosPARSED);
  sessionStorage.setItem("lasttime_favoritos",Date.now())
}

export const getFavoritos = () => {
  return sessionStorage.getItem('favoritos') || null;
}

export const getLastTimeFavoritos = () => {
  return sessionStorage.getItem('lasttime_favoritos') || null;
}
export const getHistorial = () => {
  return sessionStorage.getItem('historial') || null;
}

export const getPortfolio = () => {
  return sessionStorage.getItem('portfolio') || null;
}

export function getMarketCAP(marketcap,locale) 
{
 var money=marketcap;
 var MAGNITUD='M';
 if(money>1000000000) { MAGNITUD='B'; }
 if(money>1000000000000) { MAGNITUD='T'; }

 

 if(MAGNITUD=='M') //150 000 000
 {
  money=money/1000000;
  return(money.toFixed(3)+MAGNITUD);
 }
 else if(MAGNITUD=='B') //150 000 000 000
 {
  money=money/1000000000;
  return(money.toFixed(3)+MAGNITUD);
 }
 else if(MAGNITUD=='T') //150 000 000 000 000
 {
  money=money/1000000000000;
  return(money.toFixed(3)+MAGNITUD);
 }
}

function parseData(parse) {
  //console.log("parse:"+parse)
	return function(d) 
  {
		d.date = parse(d.date);
		d.open = +d.open;
		d.high = +d.high;
		d.low = +d.low;
		d.close = +d.close;
		d.volume = +d.volume;
    //console.log("row:"+d.date)
		return d;
	};
}

const parseDate = timeParse("%Y-%m-%d");

//const parseDate = timeParse("%Y-%m-%d");
//const parseDateTime = timeParse("%Y-%m-%d %H:%M:%S");

export function getDataCSV(TICKER) {
  

const link = glovar.LINK_PROXY_COTIS_DOWNLOAD+TICKER+
"?period1=1613992913&period2=1695615313&interval=1d&events=history"


try { 
  
Papa.parse(link, {
	download: true,
  header: true,
      skipEmptyLines: true,
	step: function(row) {
    var rowx = {
      date:"",
    open:"",
    high:"",
    low:"",
    close:"",
    volume:"",
    split:"",
    dividend:"",
    absoluteChange:"",
    percentChange:""
    }
    //{"date":"2010-01-04T23:00:00.000Z","open":25.627344939513726,"high":25.83502196495549,
    //"low":25.452895407434543,"close":25.718722,"volume":49749600,"split":"","dividend":"","absoluteChange":"","percentChange":""}
    //{"Date":"2019-07-25","Open":"15.566667","High":"15.633333","Low":"15.036667","Close":"15.254667","Adj Close":"15.254667","Volume":"336274500"}
    //rowx.date=parseDate(rowx.date)
    rowx.date=row.data.Date//+"T23:00:00.000Z";
    rowx.date=parseDate(rowx.date);
    rowx.open=+row.data.Open;
    rowx.high=+row.data.High;
    rowx.low=+row.data.Low;
    rowx.close=+row.data.Close;
    rowx.volume=+row.data.Volume;
    rowx.split="";
    rowx.dividend="";
    rowx.absoluteChange="";
    rowx.percentChange="";

    //console.log(parseDate(rowx.date))
    //console.log("Row:", JSON.stringify(rowx));
    glovar.DATA_COTIS2.push(rowx)
		//console.log("Row:", JSON.stringify(row.data));
    //console.log(parseData(parseDate))
	},
	complete: results => { 
    /*results.data.map((d) => {
      //console.log("d")
    });*/
		//console.log("All done!");
    return glovar.DATA_COTIS2;
    //console.log(JSON.stringify(glovar.DATA_COTIS2))
	}
});

  /*var results =Papa.parse(link, {
    download: true,
    complete: results => { 
      console.log(results.data)
      glovar.DATA_COTIS2=results.data;
      //return results;
    }
})*/
}
catch(Exx) {}
//return results;

}

