
import React from "react";
import PropTypes from "prop-types";

import { scaleTime } from "d3-scale";
import { curveMonotoneX } from "d3-shape";
import { utcDay } from "d3-time";
import { format } from "d3-format";	

import { ChartCanvas, Chart } from "react-stockcharts";
import { AreaSeries } from "react-stockcharts/lib/series";
import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import { fitWidth } from "react-stockcharts/lib/helper";
import { createVerticalLinearGradient, hexToRGBA } from "react-stockcharts/lib/utils";


import { CandlestickSeries } from "react-stockcharts/lib/series";

import { last, timeIntervalBarWidth } from "react-stockcharts/lib/utils";
import { discontinuousTimeScaleProvider } from "react-stockcharts/lib/scale";



import {
	CrossHairCursor,
	MouseCoordinateX,
	MouseCoordinateY
} from "react-stockcharts/lib/coordinates";


export default  (props) => {
  


	//const { type, data: initialData, width, ratio } = this.props;

		return (
			<ChartCanvas 
			    height={100}
			    ratio={props.ratio} 
				width={props.width}
				margin={{ left: -6, right: -6, top: 6, bottom: 0 }}
				padding={{ left: 20, right:14, top: 0, bottom: 0 }}
				
				mouseMoveEvent={true}
				panEvent={false}
				zoomEvent={false}
				clamp={false}

				seriesName=""
				data={props.data} type={props.type}

				xScale={props.xScale}
				xAccessor={props.xAccessor}
				displayXAccessor={props.displayXAccessor}
				xExtents={props.xExtents}

				
			>
				<Chart id={1} yExtents={d => [d.high, d.low]}>
				<XAxis axisAt="bottom" orient="bottom" ticks={6}/>
					<YAxis axisAt="left" orient="left" ticks={5} />
				<MouseCoordinateY
						at="bottom"
						orient="right"
						displayFormat={format(".2f")}
					/>
					<CandlestickSeries/>
				</Chart>
				<CrossHairCursor />
			</ChartCanvas>
		);
		}
		