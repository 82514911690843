
import glovar from './glovar';
import React, { useState } from 'react';
import CookieConsentPopup from './CookieConsentPopup';



const  CookiePolicy = ({ }) => {

  const [showConfigPopup, setShowConfigPopup] = useState(false);

  const handleConfigPopupClick = () => {

    
    localStorage.setItem(glovar.COOKIE_KEY_ACCEPTED, 'false'); 
    localStorage.setItem(glovar.COOKIE_KEY_REJECTED, 'false'); 
    
    
    window.location.replace('/')
    //glovar.FORCE_COOKIES_CONFIG=true;
    //setShowConfigPopup(true);
    //this.setState({ });
  };

  const headerStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    margin: '20px 0',
  };

  const paragraphStyle = {
    margin: '0 0 16px', // Ajusta el margen según tus necesidades
  };

  const introStyle = {
    margin: '10px 0',
  };

  const linkStyle = {
    padding:5,
    color: '#007bff',
    textDecoration: 'none',
  };

  const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '10px',
  };

  const tableHeaderStyle = {
    backgroundColor: '#f0f0f0',
  };

  const tableCellStyle = {
    padding: '8px',
    borderBottom: '1px solid #e0e0e0',
  };

  const buttonStyle = {
    backgroundColor: '#2196F3',
    color: 'white',
    padding: '10px 20px',
    borderRadius: '4px',
    textDecoration: 'none',
  };

  return (
  
    <div className="cookie-policy">
      {showConfigPopup==0&&<CookieConsentPopup/>}
    <h1 style={headerStyle}>Cookies Policy</h1>
    <p style={paragraphStyle}>
      This Cookies Policy ("Cookies Policy") describes cookies and other technologies that N81, SL ("Pro1X", "we", "us" or "our") uses on its website ("Site") and the choices that users have. This Cookies Policy is a part of Pro1X’s Privacy Policy.
    </p>

    <p style={paragraphStyle}>
      When you first visit the Site, you will be asked to consent to the use of cookies in accordance with this Cookies Policy. Note that if you accept, we will store them on your computer.
    </p>

    <h2 style={headerStyle}>What is a cookie?</h2>
    <p style={paragraphStyle}>
      A 'cookie' is a piece of information that is sent to your browser by a website you visit. The Site uses first-party cookies (those set by a website that is being visited by the user at the time, for example, cookies via www.Pro1x.com) as well as third-party cookies (set by a different domain), as described below.
    </p>

    <p style={paragraphStyle}>
      Cookies can be stored on your computer for various periods of time. They can be in the form of either "session cookies" or "persistent cookies." A session cookie only lasts as long as the browser session and is automatically deleted when you close your browser. A persistent cookie lasts long after your browser is closed and will remain until it expires (as determined by the third party in charge of placing it) or until you delete the cookie. Persistent cookies are used to help sites recognize and identify your computer when you open your browser and surf the Internet again.
    </p>

    <p style={paragraphStyle}>
      The data collected through cookies may include information about the IP (Internet Protocol) address of your device, browser type, language, operating system, the state or country from which you have accessed the Site, the date and the time of your visit(s), the number of links you click on this Site, the functions you use, the searches you request, and the data you have saved while on this Site. Pro1X may use the information collected for a variety of necessary, legitimate purposes, including user authentication, user interface customization, security, research and analysis to improve the functionality of our Site, and advertising (for more information on how we use your data, please read our Privacy Policy).
    </p>

    <p style={paragraphStyle}>
      You can find out more about cookies (including how to see or manage them) at <a href="https://www.allaboutcookies.org/" style={linkStyle}>All About Cookies</a> or <a href="https://www.aboutcookies.org/" style={linkStyle}>AboutCookies.org</a> websites.
    </p>

    <h2 style={headerStyle}>How do we use cookies?</h2>
    <p style={paragraphStyle}>
      <strong>Necessary cookies:</strong> These cookies are used to provide users with services available through a Site and to use some of its features, such as the ability to log-in and access secure areas, provide pop-up notices, and accept language from sign-up forms. These cookies are essential for using and navigating a Site. Without them, basic functions of our Site would not work. Because these cookies are strictly necessary to deliver our Site’s essential services, you cannot refuse them.
    </p>

    <p style={paragraphStyle}>
      <strong>Performance/Analytics cookies:</strong> These cookies are used to recognize and count the number of Site visitors, gather statistics regarding how visitors move around the Site (including the number of page views and the amount of time spent on each page) and for conversion tracking and click hotspots. This helps us improve the way our Site works and general user experience. For example, these cookies allow us to ensure that users are able to find what they need easily.
    </p>

    <p style={paragraphStyle}>
      <strong>Advertising cookies (ad cookies):</strong> These cookies are used to show advertising that is relevant to you.
    </p>

    <p style={paragraphStyle}>
      <strong>Cookies used to integrate third-party services:</strong> These cookies are used to integrate third-party functions on the Site, such as videos, maps, or social network plug-ins.
    </p>

    <p style={paragraphStyle}>
      <strong>Other third-party cookies:</strong> On some parts of the Site, including, but not limited to, news widgets, we use content provided by other websites (third parties). These third-party sites are able to set their own cookies in some cases. Note that we have no control over these third-party cookies. You can turn them off by disabling them on your browser. You can find more information on the most popular web-browser help pages:
    </p>

    <ul style={paragraphStyle}>
      <a href="https://support.google.com/chrome/answer/95647" style={linkStyle}>Google Chrome</a>
      <a href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox" style={linkStyle}>Mozilla Firefox</a>
      <a href="https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09" style={linkStyle}>Microsoft Edge</a>
      <a href="https://support.apple.com/en-us/HT201265" style={linkStyle}>Safari (macOS)</a>
      <a href="https://support.apple.com/en-us/HT201265" style={linkStyle}>Safari (iOS)</a>
    </ul>

    <p style={paragraphStyle}>For other browsers, please consult the documentation that your browser manufacturer provides.</p>

    <h2 style={headerStyle}>More information on our use of cookies can be found in the table below:</h2>

    <table style={tableStyle}>
      <thead>
        <tr>
          <th style={tableHeaderStyle}>Source</th>
          <th style={tableHeaderStyle}>Categories of Use</th>
          <th style={tableHeaderStyle}>Domain</th>
          <th style={tableHeaderStyle}>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={tableCellStyle}>Pro1X</td>
          <td style={tableCellStyle}>Necessary cookies</td>
          <td style={tableCellStyle}>.Pro1x.com</td>
          <td style={tableCellStyle}>We use these cookies for user authentication and identification, in order to make our features and functions more readily available. In addition, we use necessary cookies for user interface customization and security features such as detecting fraud and abuse. We also use Web Storage and IndexedDB technologies to store user preferences and information that is necessary for providing our services. We use evercookie technology to implement security features and detect violations of our Terms of Use. Examples of the specific cookies used for this purpose are cachec, sessionid, and tv_ecuid.</td>
        </tr>
        <tr>
          <td style={tableCellStyle}>Google Analytics</td>
          <td style={tableCellStyle}>Performance and ad cookies</td>
          <td style={tableCellStyle}>.Pro1x.com</td>
          <td style={tableCellStyle}>These cookies are used to collect and analyze information about how you use the Site. We use this information to analyze and improve our services. These cookies collect information pseudonymously. Examples of cookies used for this purpose are __ga, __utma, and __git.</td>
        </tr>
        
        <tr>
          <td style={tableCellStyle}>Google Advertising</td>
          <td style={tableCellStyle}>Ad cookies</td>
          <td style={tableCellStyle}>.Pro1x.com</td>
          <td style={tableCellStyle}>These cookies are used to show you advertisements and for remarketing and tracking purposes.</td>
        </tr>
        
        <tr>
          <td style={tableCellStyle}>X</td>
          <td style={tableCellStyle}>Ad cookies</td>
          <td style={tableCellStyle}>.x.com</td>
          <td style={tableCellStyle}>These cookies are used for remarketing and tracking purposes.</td>
        </tr>
      </tbody>
    </table>

    <h2 style={headerStyle}>Manage your privacy preferences</h2>

    
    <button style={buttonStyle} onClick={handleConfigPopupClick}>
        Manage your privacy preferences
      </button>
    
    <h2 style={headerStyle}>Changes</h2>
    <p style={paragraphStyle}>
    We may change the type of third party service providers that place cookies on our Site and amend this Cookies Policy at any time by posting the amended version on our Site. Unless additional notice or consent is required by applicable laws, this will serve as your official notification of these changes.
      </p>
      </div>

  );
  }

  
  
  export default CookiePolicy;