
export default 
{

IBEX35_STOCKS:[1,
1,
1,
1,
'€',
'IBEX 35','^IBEX',
'Acciona','ANA.MC',
'Acerinox','ACX.MC',	
'ACS','ACS.MC',
'AENA','AENA.MC',
'Amadeus IT','AMS.MC',
'ArcelorMittal','MTS.MC',	
'Banco Sabadell','SAB.MC',	
'Banco Santander','SAN.MC',	
'Bankinter','BKT.MC',
'BBVA','BBVA.MC',
'CaixaBank','CABK.MC',	
'Cellnex Telecom','CLNX.MC',	
'CIE Automotive','CIE.MC',	
'Enagás','ENG.MC',	
'Ence','ENC.MC',	
'Endesa','ELE.MC',	
'Ferrovial','FER.MC',
'Fluidra','FDR.MC',
'Grifols','GRF.MC',	
'IAG','IAG.MC',	
'Iberdrola','IBE.MC',	
'Inditex','ITX.MC',	
'Indra','IDR.MC',
'Inmo. Colonial','COL.MC',
'Mapfre','MAP.MC',	
//'Mediaset Esp','TL5.MC',
'Meliá Hotels','MEL.MC',
'Merlin Properties','MRL.MC',
'Naturgy','NTGY.MC',
'PharmaMar','PHM.MC',			
'REE','RED.MC',
'Repsol','REP.MC',	
'Lab. Rovi','ROVI.MC',	
'Sacyr','SCYR.MC',
'Siemens Gamesa','SGRE.MC',	
'Solaria','SLR.MC',		
//'Técnicas Reunidas','TRE.MC',	
'Telefónica','TEF.MC'],
//'Viscofan','VIS.MC'],



NIKKEI225_STOCKS:[1,
  1,
  1,
  1,
  '¥',
  'NIKKEI 225','^N225',
  
  'Advantest Corp','6857.T',
'Aeon','8267.T',
'AGC','5201.T',
'Ajinomoto Co.','2802.T',
'Alps Electric','6770.T',
'Amada','6113.T',
'ANA Holdings','9202.T',
'Aozora Bank','8304.T',
'Asahi Group Holdings','2502.T',
'Asahi Kasei Corp.','3407.T',
'Astellas Pharma Inc.','4503.T',
'Bridgestone Corp.','5108.T',
'Canon','7751.T',
'Casio Computer','6952.T',
'Central Japan Railway Co.','9022.T',
'Chiba Bank','8331.T',
'Chiyoda Corp.','6366.T',
'Chubu Electric Power','9502.T',
'Chugai Pharmaceutical','4519.T',
'Citizen Holdings','7762.T',
'Comsys Holdings Corp.','1721.T',
'Concordia Financial Group','7186.T',
'Credit Saison','8253.T',
'CyberAgent Inc','4751.T',
'Dai Nippon Printing','7912.T',
'Dai-ichi Life','8750.T',
'Daiichi Sankyo','4568.T',
'Daikin Industries','6367.T',
//'Dainippon Screen Mfg.','4506.T',
'Daiwa House Industry','1925.T',
'Daiwa Securities Group.','8601.T',
'DeNA Co','2432.T',
'Denki Kagaku Kogyo K.K.','6706.T',
'Denso Corp.','6902.T',
'Dentsu Inc.','4324.T',
'DIC Corp','4631.T',
'DOWA Holdings','5714.T',
'East Japan Railway Co.','9020.T',
'Ebara Corp.','6361.T',
'Eisai','4523.T',
'Eneos Holdings','5020.T',
'Fanuc Corp.','6954.T',
'Fast Retailing','9983.T',
'Fuji Electric','6504.T',
'Fujifilm Holdings Corp.','4901.T',
'Fujikura','5803.T',
'Fujitsu','6702.T',
'Fukuoka Financial Group','8354.T',
'Furukawa Electric','5801.T',
'GS Yuasa Corp.','6674.T',
'Haseko','1808.T',
'Hino Motors','7205.T',
'Hitachi','6501.T',
'Hitachi Construction','6305.T',
'Hitachi Zosen Corp.','7004.T',
'Honda Motor','7267.T',
'Idemitsu Kosan Co Ltd','5019.T',
'IHI Corp.','7013.T',
'Inpex Corp.','1605.T',
'Isetan Mitsukoshi Holdings','3099.T',
'Isuzu Motors','7202.T',
'Itochu Corp.','8001.T',
'J.Front Retailing','3086.T',
'Japan Post Holdings','6178.T',
'Japan Steel Works','5631.T',
'Japan Tobacco','2914.T',
'JFE Holdings','5411.T',
'JGC Corp.','1963.T',
'JTEKT Corp.','6473.T',
'Kajima Corp.','1812.T',
'Kansai Electric Power','9503.T',
'Kao Corp.','4452.T',
'Kawasaki Heavy Indus','7012.T',
'Kawasaki Kisen Kaisha','9107.T',
'KDDI Corp.','9433.T',
'Keio Corp.','9008.T',
'Keisei Electric Railway','9009.T',
'Kikkoman Corp.','2801.T',
'Kirin Holdings','2503.T',
'Kobe Steel','5406.T',
'Komatsu','6301.T',
'Konami Corp.','9766.T',
'Konica Minolta','4902.T',
'Kubota Corp.','6326.T',
'Kuraray','3405.T',
'Kyocera Corp.','6971.T',
'Kyowa Hakko Kirin','4151.T',
'Marubeni Corp.','8002.T',
'Maruha Nichiro Corp','1333.T',
'Marui Group','8252.T',
'Matsui Securities','8628.T',
'Mazda Motor','7261.T',
'Meiji Holdings','2269.T',
'Minebea Mitsumi','6479.T',
'Mitsubishi Chemical','4188.T',
'Mitsubishi Corp.','8058.T',
'Mitsubishi Electric','6503.T',
'Mitsubishi Estate','8802.T',
'Mitsubishi Heavy Indu','7011.T',
'Mitsubishi Logistics','9301.T',
'Mitsubishi Materials','5711.T',
'Mitsubishi Motors Corp.','7211.T',
'Mitsubishi UFJ Financial','8306.T',
'Mitsui','8031.T',
'Mitsui Chemicals','4183.T',
'Mitsui Engineering','7003.T',
'Mitsui Fudosan','8801.T',
'Mitsui Mining Smelting','5706.T',
'Mitsui O.S.K. Lines','9104.T',
'Mizuho Financial','8411.T',
'MS&AD Insurance Group','8725.T',
'NEC Corp.','6701.T',
'NGK Insulators','5333.T',
'NH Foods','2282.T',
'Nichirei Corp.','2871.T',
'Nikon Corp.','7731.T',
'Nippon Electric Glass','5214.T',
'Nippon Express','9147.T',
'Nippon Kayaku','4272.T',
'Nippon Light Metal','5703.T',
'Nippon Paper Indust','3863.T',
'Nippon Sheet Glass','5202.T',
'Nippon Suisan Kaisha','1332.T',
'Nippon Telegraph','9432.T',
'Nippon Yusen K.K','9101.T',
'Nissan Chemical Industr','4021.T',
'Nissan Motor','7201.T',
'Nisshin Seifun Group Inc.','2002.T',
'Nisshinbo Holdings Inc.','3105.T',
'Nitto Denko Co','6988.T',
'Nomura','8604.T',
'NSK','6471.T',
'NTN Corp.','6472.T',
'NTT Data Corp.','9613.T',
'Obayashi Corp.','1802.T',
'Odakyu Electric Railway','9007.T',
'Oji Holdings Corp.','3861.T',
'Oki Electric Industry','6703.T',
'Okuma Corp.','6103.T',
'Olympus Corp.','7733.T',
'Omron Cor','6645.T',
'Osaka Gas','9532.T',
'Otsuka Holdings Ltd','4578.T',
'Pacific Metals','5541.T',
'Panasonic','6752.T',
'Rakuten Inc','4755.T',
'Recruit Holdings','6098.T',

'Resona Holdings','8308.T',
'Ricoh','7752.T',
'Sapporo Holdings','2501.T',
'Secom','9735.T',
'Seiko Epson Cor','6724.T',
'Sekisui House','1928.T',
'Seven & i Holdings','3382.T',
'Shimizu Corp.','1803.T',
'Shin-Etsu Chemical','4063.T',
'Shinsei Bank','8303.T',
'Shionogi','4507.T',
'Shiseido','4911.T',
'Shizuoka Bank','8355.T',
'Showa Denko K.K.','4004.T',
'SKY Perfect JSAT Hdg.','9412.T',
'Softbank Group Corp.','9984.T',
'Sojitz Corp.','2768.T',
'Sompo Holdings Inc','8630.T',
'Sony','6758.T',
'Subaru Corp','7270.T',
'SUMCO Corp.','3436.T',
'Sumitomo Chemical','4005.T',
'Sumitomo Corp.','8053.T',
'Sumitomo Dainippon ','4506.T',
'Sumitomo Electric Indu','5802.T',
'Sumitomo Heavy Indus','6302.T',
'Sumitomo Metal Mining','5713.T',
'Sumitomo Mitsui','8309.T',
'Sumitomo Mitsui Financial','8316.T',
'Sumitomo Osaka Cement','5232.T',
'Sumitomo Realty & Dev','8830.T',
'Suzuki Motor Corp.','7269.T',
'T&D Holdings','8795.T',
'Taiheiyo Cement Corp.','5233.T',
'Taisei Corp.','1801.T',
'Taiyo Yuden','6976.T',
'Takara Holdings Inc.','2531.T',
'Takashimaya','8233.T',
'Takeda Pharmaceutical','4502.T',
'TDK','6762.T',
'Teijin','3401.T',
'Terumo Corp.','4543.T',
'Tobu Railway','9001.T',
'Toho','9602.T',
'Toho Zinc','5707.T',
'Tokai Carbon','5301.T',
'Tokio Marine Holdings','8766.T',
'Tokuyama Corp.','4043.T',
//'Tokyo Dome Corp.','9681.T',
'Tokyo Electric Power','9501.T',
'Tokyo Electron','8035.T',
'Tokyo Gas','9531.T',
'Tokyo Tatemono','8804.T',
'Tokyu Corp.','9005.T',
'Tokyu Fudosan','3289.T',
'Toppan Printing','7911.T',
'Toray Industries','3402.T',
'Tosoh Corp.','4042.T',
'TOTO','5332.T',
'Toyo Seikan Group','5901.T',
'Toyobo','3101.T',
'Toyota Motor','7203.T',
'Toyota Tsusho Corp.','8015.T',
'Trend Micro Inc.','4704.T',
'Ube Industries','4208.T',
'Unitika','3103.T',
'West Japan Railway','9021.T',
'Yamaha Corp.','7951.T',
'Yamaha Motor Co','7272.T',
'Yamato Holdings','9064.T',
'Yaskawa Electric','6506.T',
'Yokogawa Electric','6841.T',
'Yokohama Rubber','5101.T',
'Z Holdings','4689.T'
],


DOW_STOCKS:[1,
  1,
  1,
  1,
  '$',
  'Dow Jones','^DJI',
  '3M','MMM',
'American Express','AXP',
'Amgen','AMGN',
'Apple','AAPL',
'Boeing','BA',
'Caterpillar','CAT',
'Chevron','CVX',
'Cisco','CSCO',
'Coca-Cola','KO',
'Dow','DOW',
'Goldman Sachs','GS',
'Home Depot','HD',
'Honeywell','HON',
'IBM','IBM',
'Intel','INTC',
'J&J','JNJ',
'JP Morgan','JPM',
'McDonald´s','MCD',
'Merck&Co','MRK',
'Microsoft','MSFT',
'Nike','NKE',
'Procter&Gamble','PG',
'Salesforce.com','CRM',
'Travelers','TRV',
'UnitedHealth','UNH',
'Verizon','VZ',
'Visa A','V',
'Walgreens Boots','WBA',
'Walmart','WMT',
'Walt Disney','DIS'],

BEL_20_STOCKS:[1,
  1,
  1,
  1,
  '€',
  'BEL 20', '^BFX',
'Ackermans','ACKB.BR',
'Aedifica','AED.BR',
'Ageas','AGS.BR',
'Anheuser Busch Inbev','ABI.BR',
'Aperam','APAM.AS',
'Argen-X','ARGX.BR',
'Cofinimmo','COFB.BR',
'Elia','ELI.BR',
'Etablissementen Franz Colruyt','COLR.BR',
'Galapagos','GLPG.AS',
'GBL','GBLB.BR',
'KBC Groep','KBC.BR',
'Melexis NV','MELE.BR',
'Proximus','PROX.BR',
'Sofina','SOF.BR',
'Solvay','SOLB.BR',
'Telenet','TNET.BR',
'UCB','UCB.BR',
'Umicore','UMI.BR',
'Warehouses de Pauw','WDP.BR'],


BEL_MID_STOCKS:[1,
  1,
  1,
  1,
  '€',
  'BEL Mid', 'BELM.BR',
  'AGFA Gevaert','AGFB.BR',
  'Ascencio','ASC.BR',
  'Atenor','ATEB.BR',
  'Barco','BAR.BR',
  'Befimmo-Sicafi','BEFB.BR',
  'Bekaert','BEKB.BR',
  'Biocartis Group NV','BCART.BR',
  'bpost NV','BPOST.BR',
  'Brederode','BREB.BR',
  'CFE SA','CFEB.BR',
  'Cie Bois Sauvage','COMB.BR',
  'CP Invest','CPINV.BR',
  'D´Ieteren','DIE.BR',
  'Econocom','ECONB.BR',
  'Euronav','EURN.BR',
  'EVS Broadcast','EVS.BR',
  'Fagron','FAGR.BR',
  'GIMV','GIMB.BR',
  'Home Invest','HOMI.BR',
  'Immobel','IMMO.BR',
  'Intervest Offices','INTO.BR',
  'Ion Beam Applications','IBAB.BR',
  'Kinepolis NV','KIN.BR',
  //'Leasinvest','LEAS.BR',
  'Lotus Bakeries','LOTB.BR',
  'Mithra Pharmaceuticals Sa','MITRA.BR',
  'Montea','MONT.BR',
  'National Bank of Belgium','BNB.BR',
  'Ontex Group','ONTEX.BR',
  'Orange Belgium','OBEL.BR',
  'Recticel','REC.BR',
  'Retail Estates','RET.BR',
  'Shurgard Self Storage','SHUR.BR',
  'Sipef NV','SIP.BR',
  'Tessenderlo','TESB.BR',
  'Tinc Comm','TINC.BR',
  'Titan Cement','TITC.BR',
  'VGP','VGP.BR',
  'Xior Student Housing BVBA','XIOR.BR'],

  BEL_SMALL_STOCKS:[1,
    1,
    1,
    1,
    '€',
    'BEL Small', 'BELS.BR',
    //'Acacia Pharma','ACPH.BR',
'Accentis','ACCB.BR',
'Balta','BALTA.BR',
'Bone Therapeutics SA','BOTHE.BR',
'Celyad','CYAD.BR',
'Crescent','OPTI.BR',
'Deceuninck','DECB.BR',
'Exmar','EXM.BR',
//'Global Graphics','GLOG.BR',
'Greenyard','GREEN.BR',
'Hyloris','HYL.BR',
'Iep Invest','IEP.BR',
'Jensen-Group','JEN.BR',
'Mdxhealth','MDXH.BR',
'Nyrstar','NYR.BR',
'Nyxoah','NYXH.BR',
'Oxurion','OXUR.BR',
'Qrf','QRF.BR',
'Questfor','QFG.BR',
//'Resilux NV','RES.BR',
'Roularta','ROU.BR',
'Sequana Medical','SEQUA.BR',
'Smartphoto','SMAR.BR',
'Ter Beke','TERB.BR',
'Texaf SA','TEXF.BR',
'Van De Velde','VAN.BR',
'Vastned Retail','VASTB.BR',
'Warehouses Estates','WEB.BR',
'Wereldhav B-Sicafi','WEHB.BR'],

CAC_ALL_SHARE_STOCKS:[1,
  1,
  1,
  1,
  '€',
  'CAC ALL SHARE', 'PAX.PA', 
  '2Crsi','2CRSI.PA',
//'A.S.T. Groupe','ASP.PA',
'AB Science','AB.PA',
'ABC Arbitrage','ABCA.PA',
'Abeo SAS','ABEO.PA',
'Abionyx Pharma SA','ABNX.PA',
'Abivax SA','ABVX.PA',
'Acanthe','ACAN.PA',
'Accor','AC.PA',
'Acteos','EOS.PA',
'Actia','ATI.PA',
//'ADL Partner','ALP.PA',
'Adocia','ADOC.PA',
//'Adux SA','ADUX.PA',
'Advenis','ALADV.PA',
'Advicenne','ALDVI.PA',
'Advini','ADVI.PA',
'Aeroports Paris','ADP.PA',
'Air France KLM','AF.PA',
'Air Liquide','AI.PA',
'Airbus Group','AIR.PA',
//'Akka','AKA.PA',
'Akwel','AKW.PA',
'Albioma','ABIO.PA',
'ALD','ALD.PA',
'Alliance Develop','ALDV.PA',
'Alpes','CDA.PA',
//'Alpha Mos','ALM.PA',
'Alstom','ALO.PA',
'Altamir','LTA.PA',
'Altarea','ALTA.PA',
'Altareit','AREIT.PA',
'Alten','ATE.PA',
'Altur Inv','ALTUR.PA',
'Amoeba SA','ALMIB.PA',
'Amplitude Surgical SAS','AMPLI.PA',
'Amundi','AMUN.PA',
'Aperam','APAM.AS',
'ArcelorMittal','MT.AS',
'Archos','ALJXR.PA',
'Argan','ARG.PA',
'Arkema','AKE.PA',
'Artea SA','ARTE.PA',
'Artmarket.com','PRC.PA',
'Artois Nom.','ARTO.PA',
//'Asit Biotech SA','ASIT.BR',
'Assystem','ASY.PA',
'Atari','ATA.PA',
'Ateme','ATEME.PA',
'Atos','ATO.PA',
'Aubay','AUB.PA',
'Augros','AUGR.PA',
'Aurea','AURE.PA',
'Aures Tech','AURS.PA',
'AXA','CS.PA',
'Axway','AXW.PA',
//'Baccarat','BCRA.PA',
'Bains Mer Monaco','BAIN.PA',
'Balyo','BALYO.PA',
'Barbara Bui','BUI.PA',
'Bassac','BASS.PA',
'Bastide Le Confort','BLC.PA',
'Beneteau','BEN.PA',
'BIC','BB.PA',
'Bigben','BIG.PA',
'Biomerieux','BIM.PA',
'Bleecker','BLEE.PA',
'BNP Paribas','BNP.PA',
'Boiron','BOI.PA',
'Bollore','BOL.PA',
'Bonduelle','BON.PA',
'Bone Therapeutics SA','BOTHE.BR',
//'Boostheat','BOOST.PA',
'Bourse Direct','BSD.PA',
'Bouygues','EN.PA',
'Bureau Veritas','BVI.PA',
'Burelle','BUR.PA',
'Ca Toulouse 31 CCI','CAT31.PA',
'Cafom','CAFO.PA',
'Cambodge Nom.','CBDG.PA',
//'Capelli','CAPLI.PA',
'Capgemini','CAP.PA',
'Carmila','CARM.PA',
'Carpinienne Part.','CARP.PA',
'Carrefour','CA.PA',
'Casino Guichard','CO.PA',
'Cast','CAS.PA',
'Catana Group','CATG.PA',
'CBO Territoria','CBOT.PA',
'Cegedim','CGM.PA',
//'CeGeREAL.','CGR.PA',
'Celyad','CYAD.BR',
'CFI','CFI.PA',
'CGG','CGG.PA',
'Chargeurs','CRI.PA',
'Chausseria','CHSR.PA',
'Christian Dior','CDI.PA',
'Cibox Inter A','CIB.PA',
'Cie Industrielle Financiere','INFE.PA',
'CIS','CTRG.PA',
'Claranova','CLA.PA',
'CNIM Constr.','COM.PA',
'CNP Assurances','CNP.PA',
'Coface','COFA.PA',
'Cogelec','ALLEC.PA',
'Coheris','COH.PA',
'Colas','RE.PA',
'Courtois','COUR.PA',
'Covivio','COV.PA',
'Covivio Hotels','COVH.PA',
'Crcam Alp.Prov','CRAP.PA',
'CRCAM Atlantique','CRAV.PA',
'CRCAM Brie Picardie 2','CRBP2.PA',
'Crcam Ille-Vil','CIV.PA',
'CRCAM Langued','CRLA.PA',
'Crcam Loire Ht','CRLO.PA',
'Crcam Morbihan','CMO.PA',
'CRCAM Nord','CNF.PA',
'Crcam Norm.Sei','CCN.PA',
'Crcam Paris Et','CAF.PA',
'Crcam Sud RA','CRSU.PA',
'Crcam Touraine','CRTO.PA',
'Credit Agricole','ACA.PA',
'Crosswood','CROS.PA',
'CS Communication','SX.PA',
'Danone','BN.PA',
'Dassault Aviation','AM.PA',
'Dassault Systemes','DSY.PA',
'DBV Technologies','DBV.PA',
//'Delta Plus','DLTA.PA',
'Derichebourg','DBG.PA',
//'Devoteam','DVT.PA',
//'Diagnostic Medical','DGM.PA',
'Docks des Petroles dAmbes','DPAM.PA',
'Edenred','EDEN.PA',
'Egide','GID.PA',
'Eiffage','FGR.PA',
'Ekinops SA','EKI.PA',
'Electricite de France','EDF.PA',
'Electricite de Strasbourg','ELEC.PA',
'Electricite et Eaux De Madagascar SA','EEM.PA',
'Elior Group','ELIOR.PA',
'Elis Services SA','ELIS.PA',
'Engie','ENGI.PA',
//'ENGIE Eps','EPS.PA',
//'EOS Imaging','EOSI.PA',
'Eramet','ERA.PA',
'Erytech Pharma','ERYP.PA',
'ESI Group','ESI.PA',
'Esperite','ESP.AS',
'EssilorLuxottica','EL.PA',
'ESSO','ES.PA',
'Eurasia Fonciere','EFI.PA',
'Eurazeo','RF.PA',
'Euro Ressources','EUR.PA',
'Eurofins Scientific','ERF.PA',
'Euronext','ENX.PA',
//'Europacorp','ECP.PA',
'Europcar Groupe SA','EUCAR.PA',
'Eutelsat','ETL.PA',
'Evergreen','EGR.PA',
//'Exacompta Clairefontaine','EXAC.PA',
'Exel Industries','EXE.PA',
'Explosifs Prod Chimiques','EXPL.PA',
'Faurecia','EO.PA',
//'Fauvet Girel','FAUV.PA',
'Fayenc. Sarreguemi.','FAYE.PA',
'Ferm. Casino Mun. Canne','FCMC.PA',
'Fermentalg','FALG.PA',
'Fiducial Office','SACI.PA',
'Fiducial RE','ORIA.PA',
'Figeac Aero','FGA.PA',
'Fin. Etang Berre','BERR.PA',
'Fin. Ouest Africain','FOAF.PA',
'Financiere Marjos','FINM.PA',
'Finatis','FNTS.PA',
'FIPP','FIPP.PA',
'Fleury Michon','ALFLE.PA',
'Fnac Darty SA','FNAC.PA',
'Fonciere 7 Invest','LEBL.PA',
//'Fonciere Atland','FATL.PA',
'Fonciere Euris','EURS.PA',
'Fonciere Inea','INEA.PA',
'Fonciere Lyonnaise','FLY.PA',
//'Fonciere Paris Nord','FPN.PA',
'Fonciere Volta','SPEL.PA',
'Forestiere Equatoriale','FORE.PA',
'Frey','FREY.PA',
//'Fromageries Bel','FBEL.PA',
'Galimmo','GALIM.PA',
'Gaumont','GAM.PA',
'Gaztransport et Technigaz SA','GTT.PA',
'GEA','GEA.PA',
'GECI International','ALGEC.PA',
'Gecina','GFC.PA',
'Generix','GENX.PA',
'Geneuro','GNRO.PA',
'Genfit','GNFT.PA',
//'GenKyoTex','GKTX.PA',
'Genomicvision','GV.PA',
'Gensight Biologics','SIGHT.PA',
'Getlink','GET.PA',
'Gl Events','GLO.PA',
'GPE Group Pizzorno','GPE.PA',
'Graines Voltz','GRVO.PA',
'Groupe Crit','CEN.PA',
'Groupe Flo','FLO.PA',
'Groupe Gorge','GOE.PA',
'Groupe IRD','IRD.PA',
'Groupe JAJ SA','GJAJ.PA',
//'Groupe Open','OPN.PA',
'Groupe Partouche','PARP.PA',
'Groupe SEB','SK.PA',
'Groupe Sfpi','SFPI.PA',
'Guerbet','GBT.PA',
'Guillemot','GUI.PA',
'Haulotte','PIG.PA',
'Hermes International','RMS.PA',
'Hexaom','HEXA.PA',
//'HF','HF.PA',
'High Co','HCO.PA',
//'Hipay Group SA','HIPAY.PA',
'Hopscotch Groupe SA','HOP.PA',
'Hotels De Paris','HDP.PA',
'ICADE','ICAD.PA',
'ID Logistics','IDL.PA',
'IDI','IDIP.PA',
//'IGE + XAO','IGE.PA',
//'Iliad','ILD.PA',
'Imerys','NK.PA',
'Immob. Dassault','IMDA.PA',
'Infotel','INF.PA',
'Innate Pharma','IPH.PA',
'Innelec','ALINN.PA',
'Installux','ALLUX.PA',
//'Inter Parfums','IPAR.PA',
'Intexa','ITXT.PA',
'Inventiva','IVA.PA',
'Ipsen','IPN.PA',
'Ipsos','IPS.PA',
'IT Link','ALITL.PA',
'Itesoft','ITE.PA',
'Jacques Bogart','JBOG.PA',
'Jacquet Metal','JCQ.PA',
'JC Decaux','DEC.PA',
'Kaufman Et Broad','KOF.PA',
'Kering','KER.PA',
'Keyrus','ALKEY.PA',
'Klepierre','LI.PA',
'Korian Medica','KORI.PA',
'La Francaise','FDJ.PA',
//'La Francaise de l´Energie','LFDE.PA',
'L´acroix Group','LACR.PA',
'Lagardere','MMB.PA',
'Latecoere','LAT.PA',
'Laurent-Perrier','LPE.PA',
'LDC','LOUP.PA',
'Lebon','LBON.PA',
'Lectra','LSS.PA',
'Legrand','LR.PA',
'Linedata Services','LIN.PA',
'Lisi','FII.PA',
'LNA Sante SA','LNA.PA',
'L´Oreal','OR.PA',
'Louis Vuitton','MC.PA',
'Lumibird SA','LBIRD.PA',
'Lysogene','LYS.PA',
'M.R.M','MRM.PA',
'Maisons du Monde','MDM.PA',
'Malteries FCO-Belges','MALT.PA',
'Manitou BF','MTU.PA',
'Manutan','MAN.PA',
'Marie Brizard Wine & Spirits','MBWS.PA',
'Mauna Kea Tech','MKEA.PA',
'Maurel et Prom','MAU.PA',
'Mcphy Energy','MCPHY.PA',
'Media 6','EDI.PA',
'Medincell','MEDCL.PA',
'Memscap','MEMS.PA',
'Mercialys','MERY.PA',
'Mersen','MRN.PA',
'Metabolic Explorer','METEX.PA',
'Metropole TV','MMT.PA',
'Michelin','ML.PA',
'Micropole','MUN.PA',
'Moncey','MONC.PA',
//'Montea','MONT.BR',
//'Musee Grevin','GREV.PA',
'Nacon','NACON.PA',
'Nanobiotix','NANO.PA',
//'Natixis','KN.PA',
'Navya','NAVYA.PA',
'Neoen','NEOEN.PA',
'Netgem','ALNTG.PA',
'Neurones','NRO.PA',
'Nexans','NEX.PA',
'Nexity','NXI.PA',
'Nexstage Am','NEXTS.PA',
'Nicox','COX.PA',
'NR 21','NR21.PA',
'NRJ Group','NRG.PA',
'Oeneo','SBT.PA',
'Olympique Lyonnais','OLG.PA',
'Onxeo','ALONX.PA',
'Orange','ORA.PA',
'Orapi','ORAP.PA',
//'Orchestra Premaman','KAZI.PA',
'Orege','OREGE.PA',
'Orpea','ORP.PA',
'Ose Pharma International SA','OSE.PA',
'Paragon Id','PID.PA',
'Paris Realty Fund SA','PAR.PA',
'Parrot','PARRO.PA',
'Passat','PSAT.PA',
'Patrimoine Et Comm','PAT.PA',
'Pcas','PCA.PA',
'Pernod Ricard','RI.PA',
'Perrier Gerard','PERR.PA',
'Peugeot Invest','PEUG.PA',
//'Pharmagest Interactive','PHA.PA',
'Pierre Vacances','VAC.PA',
'Plastic Omnium','POM.PA',
'Plastiques du Val de Loire','PVL.PA',
'Poxel SA','POXEL.PA',
'Precia','PREC.PA',
'Proactis SA','PROAC.PA',
'Prodways','PWG.PA',
'Prologue','PROL.PA',
//'PSB Industries','PSB.PA',
'Publicis Groupe','PUB.PA',
'Quadient','QDT.PA',
'Rallye','RAL.PA',
'Ramsay Generale De Sante','GDS.PA',
'Recylex','RX.PA',
'Remy Cointreau','RCO.PA',
'Renault','RNO.PA',
'Rexel','RXL.PA',
'Riber','ALRIB.PA',
'Robertet','RBT.PA',
'Roche Bobois','RBO.PA',
'Rothschild & Co','ROTH.PA',
'Rubis','RUI.PA',
'Safran','SAF.PA',
'Saint Gobain','SGO.PA',
'Saint Jean Groupe','SABE.PA',
'Samse','SAMS.PA',
'Sanofi','SAN.PA',
'Sartorius Stedim','DIM.PA',
'Savencia','SAVE.PA',
'SCBSM','CBSM.PA',
'Schneider Electric','SU.PA',
'SCOR','SCR.PA',
'Seche','SCHP.PA',
'Selectirente','SELER.PA',
'Sergeferrari G','SEFER.PA',
'SES','SESG.PA',
'SES Imagotag','SESL.PA',
//'Signaux Girod','GIRO.PA',
'Smcp','SMCP.PA',
'SMTPC','SMTPC.PA',
'Soc Franc Casinos','SFCA.PA',
'Societe de la Tour Eiffel','EIFF.PA',
'Societe Generale','GLE.PA',
'Sodexo','SW.PA',
'Soditech Ingenierie','SEC.PA',
'Sogeclair','SOG.PA',
'Soitec','SOI.PA',
'Solocal','LOCAL.PA',
'Solvay','SOLB.BR',
'Somfy','SO.PA',
'Sopra Steria','SOP.PA',
'Spie','SPIE.PA',
'SPII','SII.PA',
'SQLi','SQI.PA',
'SRP Groupe SA','SRP.PA',
'ST Dupont','DPT.PA',
'Stef','STF.PA',
'Stellantis NV','STLA.PA',
'STMicroelectronics','STM.PA',
//'Suez','SEV.PA',
'Sword','SWP.PA',
'Synergie','SDG.PA',
'Tarkett','TKTT.PA',
'Tayninh','TAYN.PA',
'Technicolor','TCH.PA',
'Technip Energies BV','TE.PA',
//'TechnipFMC','FTI.PA',
'Teleperformance','TEP.PA',
'Televerbier','TVRB.PA',
//'Tessi','TES.PA',
'TF1','TFI.PA',
'Thales','HO.PA',
'Thermador','THEP.PA',
'Tikehau Capital Partners','TKO.PA',
'Tipiak','TIPI.PA',
'Tivoly','ALTIV.PA',
'Tonnellerie Francois Freres','TFF.PA',
'TotalEnergies','TTE.PA',//'Total','FP.PA',
'Total Gabon','EC.PA',
'Touax','TOUP.PA',
'Transgene','TNG.PA',
'Trigano','TRI.PA',
'Ubisoft','UBI.PA',
'Unibel','UNBL.PA',
'Union Financiere','UFF.PA',
'Union Technologies','FPG.PA',
'Valeo','FR.PA',
'Vallourec','VK.PA',
'Valneva','VLA.PA',
'Veolia Environnement','VIE.PA',
'Verallia','VRLA.PA',
'Verimatrix','VMX.PA',
//'Verneuil Finance','AAA.PA',
'Vetoquinol','VETO.PA',
'Vicat','VCT.PA',
//'Videlio SA','VDLO.PA',
'Viel Et Compagnie','VIL.PA',
'Vilmorin & Cie','RIN.PA',
'Vinci','DG.PA',
'Virbac','VIRP.PA',
//'Visiodent','SDT.PA',
'Vivendi','VIV.PA',
'Voltalia SA','VLTSA.PA',
//'Voluntis','ALVTX.PA',
'Vranken-Pommery','VRAP.PA',
'Wavestone','WAVE.PA',
'Wendel','MF.PA',
'WFD Unibail Rodamco','URW.AS',
'Worldline SA','WLN.PA',
'X Fab Silicon','XFAB.PA',
'Xilam','XIL.PA',
'Xpo Logistics Europe','XPO.PA'],

CAC40_STOCKS:[1,
  1,
  1,
  1,
  '€',
 'CAC 40','^FCHI',
 'Air Liquide','AI.PA',
'Airbus Group','AIR.PA',
'Alstom','ALO.PA',
'ArcelorMittal','MT.AS',
'Atos','ATO.PA',
'AXA','CS.PA',
'BNP Paribas','BNP.PA',
'Bouygues','EN.PA',
'Capgemini','CAP.PA',
'Carrefour','CA.PA',
'Credit Agricole','ACA.PA',
'Danone','BN.PA',
'Dassault Systemes','DSY.PA',
'Engie','ENGI.PA',
'EssilorLuxottica','EL.PA',
'Hermes International','RMS.PA',
'Kering','KER.PA',
'L´Oreal','OR.PA',
'Legrand','LR.PA',
'Louis Vuitton','MC.PA',
'Michelin','ML.PA',
'Orange','ORA.PA',
'Pernod Ricard','RI.PA',
'Publicis Groupe','PUB.PA',
'Renault','RNO.PA',
'Safran','SAF.PA',
'Saint Gobain','SGO.PA',
'Sanofi','SAN.PA',
'Schneider Electric','SU.PA',
'Societe Generale','GLE.PA',
'Stellantis NV','STLA.PA',
'STMicroelectronics','STM.PA',
'Teleperformance','TEP.PA',
'Thales','HO.PA',
'TotalEnergies','TTE.PA',//'Total','FP.PA',
'Veolia Environnement','VIE.PA',
'Vinci','DG.PA',
'Vivendi','VIV.PA',
'WFD Unibail Rodamco','URW.AS',
'Worldline SA','WLN.PA'],

SBF120_STOCKS:[1,
  1,
  1,
  1,
  '€',
  'CAC SBF 120', '^SBF120',
  'Accor','AC.PA',
'Aeroports Paris','ADP.PA',
'Air France KLM','AF.PA',
'Air Liquide','AI.PA',
'Airbus Group','AIR.PA',
'Albioma','ABIO.PA',
'ALD','ALD.PA',
'Alstom','ALO.PA',
'Alten','ATE.PA',
'Amundi','AMUN.PA',
'Aperam','APAM.AS',
'ArcelorMittal','MT.AS',
'Arkema','AKE.PA',
'Atos','ATO.PA',
'AXA','CS.PA',
'BIC','BB.PA',
'Biomerieux','BIM.PA',
'BNP Paribas','BNP.PA',
'Bollore','BOL.PA',
'Bouygues','EN.PA',
'Bureau Veritas','BVI.PA',
'Capgemini','CAP.PA',
'Carrefour','CA.PA',
'Casino Guichard','CO.PA',
'CGG','CGG.PA',
'CNP Assurances','CNP.PA',
'Coface','COFA.PA',
'Covivio','COV.PA',
'Credit Agricole','ACA.PA',
'Danone','BN.PA',
'Dassault Aviation','AM.PA',
'Dassault Systemes','DSY.PA',
'DBV Technologies','DBV.PA',
'Edenred','EDEN.PA',
'Eiffage','FGR.PA',
'Electricite de France','EDF.PA',
'Elior Group','ELIOR.PA',
'Elis Services SA','ELIS.PA',
'Engie','ENGI.PA',
'Eramet','ERA.PA',
'EssilorLuxottica','EL.PA',
'Eurazeo','RF.PA',
'Eurofins Scientific','ERF.PA',
'Euronext','ENX.PA',
'Eutelsat','ETL.PA',
'Faurecia','EO.PA',
'Fnac Darty SA','FNAC.PA',
'Gaztransport Technigaz','GTT.PA',
'Gecina','GFC.PA',
'Getlink','GET.PA',
'Groupe SEB','SK.PA',
'Hermes International','RMS.PA',
'ICADE','ICAD.PA',
//'Iliad','ILD.PA',
'Imerys','NK.PA',
'Ipsen','IPN.PA',
'Ipsos','IPS.PA',
'JC Decaux','DEC.PA',
'Kering','KER.PA',
'Klepierre','LI.PA',
'Korian Medica','KORI.PA',
'La Francaise','FDJ.PA',
'Lagardere','MMB.PA',
'Legrand','LR.PA',
'L´Oreal','OR.PA',
'Louis Vuitton','MC.PA',
'Maisons du Monde','MDM.PA',
'Mcphy Energy','MCPHY.PA',
'Mercialys','MERY.PA',
'Metropole TV','MMT.PA',
'Michelin','ML.PA',
//'Natixis','KN.PA',
'Neoen','NEOEN.PA',
'Nexans','NEX.PA',
'Nexity','NXI.PA',
'Orange','ORA.PA',
'Orpea','ORP.PA',
'Pernod Ricard','RI.PA',
'Plastic Omnium','POM.PA',
'Publicis Groupe','PUB.PA',
'Remy Cointreau','RCO.PA',
'Renault','RNO.PA',
'Rexel','RXL.PA',
'Rubis','RUI.PA',
'Safran','SAF.PA',
'Saint Gobain','SGO.PA',
'Sanofi','SAN.PA',
'Sartorius Stedim','DIM.PA',
'Schneider Electric','SU.PA',
'SCOR','SCR.PA',
'SES','SESG.PA',
'Societe Generale','GLE.PA',
'Sodexo','SW.PA',
'Soitec','SOI.PA',
'Solutions 30','S30.PA',
'Solvay','SOLB.BR',
'Sopra Steria','SOP.PA',
'Spie','SPIE.PA',
'Stellantis NV','STLA.PA',
'STMicroelectronics','STM.PA',
//'Suez','SEV.PA',
'Technip Energies BV','TE.PA',
//'TechnipFMC','FTI.PA',
'Teleperformance','TEP.PA',
'TF1','TFI.PA',
'Thales','HO.PA',
'TotalEnergies','TTE.PA',//'Total','FP.PA',
'Trigano','TRI.PA',
'Ubisoft','UBI.PA',
'Valeo','FR.PA',
'Vallourec','VK.PA',
'Valneva','VLA.PA',
'Veolia Environnement','VIE.PA',
'Verallia','VRLA.PA',
'Vinci','DG.PA',
'Virbac','VIRP.PA',
'Vivendi','VIV.PA',
'Wendel','MF.PA',
'WFD Unibail Rodamco','URW.AS',
'Worldline SA','WLN.PA'],
 

MIB30_STOCKS:[1,
  1,
  1,
  1,
  '€',
  'MIBTEL 30','FTSEMIB.MI',
 'A2A','A2A.MI',
'Amplifon','AMP.MI',
'Assicurazioni Generali','G.MI',
'Atlantia','ATL.MI',
'Azimut Holding','AZM.MI',
'Banca Generali','BGN.MI',
'Banca Mediolanum','BMED.MI',
'Banco Bpm','BAMI.MI',
'Bper Banca','BPE.MI',
'Buzzi Unicem','BZU.MI',
'Campari','CPR.MI',
'CNH Industrial NV','CNHI.MI',
'Diasorin','DIA.MI',
'Enel','ENEL.MI',
'Eni SpA','ENI.MI',
'Exor','EXO.MI',
'Ferrari NV','RACE.MI',
'FinecoBank','FBK.MI',
'Hera','HER.MI',
'Interpump','IP.MI',
'Intesa Sanpaolo','ISP.MI',
'Inwit','INW.MI',
'Italgas','IG.MI',
'Leonardo','LDO.MI',
'Mediobanca','MB.MI',
'Moncler SpA','MONC.MI',
'Nexi','NEXI.MI',
'Pirelli & C','PIRC.MI',
'Poste Italiane','PST.MI',
'Prysmian','PRY.MI',
'Recordati','REC.MI',
'Saipem','SPM.MI',
'Snam','SRG.MI',
'Stellantis NV','STLA.MI',
'STMicroelectronics','STM.MI',
'Telecom Italia','TIT.MI',
'Tenaris','TEN.MI',
'Terna','TRN.MI',
'UniCredit','UCG.MI',
'Unipol Gruppo','UNI.MI'],
  

DAX30_STOCKS:[1,
  1,
  1,
  1,
  '€',
  //'DAX 30','^GDAXI',
  'DAX','^GDAXI',
 'Adidas','ADS.DE',
'Allianz','ALV.DE',
'BASF','BAS.DE',
'Bayer','BAYN.DE',
'Beiersdorf','BEI.DE',
'BMW ST','BMW.DE',
'Brenntag','BNR.DE',
'Continental AG','CON.DE',
'Covestro','1COV.DE',
//'Daimler','DAI.DE',
'Daimler Truck Holding','DTG.DE',
//'Delivery Hero','DHER.DE',
'Deutsche Bank AG','DBK.DE',
'Deutsche Boerse','DB1.DE',
'Deutsche Post','DPW.DE',
'Deutsche Telekom AG','DTE.DE',
'Deutsche Wohnen','DWNI.DE',
'E.ON SE','EOAN.DE',
'Fresenius Medical Care','FME.DE',
'Fresenius SE','FRE.DE',
'Hannover Rück','HNR1.DE',
'Heidelbergcement','HEI.DE',
'HelloFresh','HFG.DE',
'Henkel VZO','HEN3.DE',
'Infineon','IFX.DE',
'Linde PLC','LIN.DE',
'Merck','MRK.DE',
'MTU Aero','MTX.DE',
'Muench. Rueckvers.','MUV2.DE',
'Porsche','PAH3.DE',
'PUMA SE','PUM.DE',
'Qiagen','QIA.DE',
'RWE AG ST','RWE.DE',
'SAP','SAP.DE',
'Sartorius','SRT3.DE',
'Siemens AG','SIE.DE',
'Siemens Healthineers','SHL.DE',
'Symrise','SY1.DE',
//'Siemens Energy AG','ENR.DE',
'Volkswagen VZO','VOW3.DE',
'Vonovia','VNA.DE',
'Zalando SE','ZAL.DE'],
  


SDAX_STOCKS:[1,
  1,
  1,
  1,
  '€',
  'SDAX','^SDAXI',
  'Aareal Bank AG','ARL.DE',
'ADO Properties SARL','ADJ.DE',
'Adva Optical','ADV.DE',
'Amadeus Fire AG','AAD.DE',
'Atoss AG','AOF.DE',
'Auto1','AG1.DE',
'Baywa Vink AG','BYW6.DE',
'Befesa','BFSA.DE',
'Bertrandt AG','BDT.DE',
'Bilfinger SE','GBF.DE',
'Borussia Dortmund','BVB.DE',
//'Ceconomy','CEC1.DE',
'Cewe Color','CWC.DE',
'Corestate','CCAP.DE',
'Dermapharm','DMP.DE',
'Deutsche Beteiligungs AG','DBAN.DE',
'Deutsche Euroshop AG','DEQ.DE',
'Deutsche Pfandbriefbank AG','PBB.DE',
'Deutz AG','DEZ.DE',
'DIC Asset AG','DIC.DE',
'Draegerwerk VZO','DRW3.DE',
//'Drillisch AG','DRI.DE',
'DWS Group','DWS.DE',
'Eckert&Ziegler AG','EUZ.DE',
'Elringklinger AG','ZIL2.DE',
'Fielmann AG','FIE.DE',
'flatexDEGIRO AG','FTK.DE',
'Hamborner AG','HABA.DE',
'Hamburger Hafen A-SP','HHFA.DE',
'home24','H24.DE',
'Hornbach','HBH.DE',
'Hypoport AG','HYQ.DE',
'Indus AG','EVK.DE',
'Instone Real Estate','INS.DE',
'Jenoptik','JEN.DE',
'JOST Werke','JST.DE',
'Jungheinrich AG','JUN3.DE',
'Kloeckner SE','KCO.DE',
'Koenig&Bauer AG','SKB.DE',
'Krones AG','KRN.DE',
'KWS SAAT AG','KWS.DE',
'Leoni AG','LEO.DE',
'LPKF','LPK.DE',
'Metro Wholesale','B4B.DE',
'New Work','NWO.DE',
'Norma AG','NOEJ.DE',
//'Osram Licht','OSR.DE',
'Patrizia Immobilien','PAT.DE',
'Pfeiffer','PFV.DE',
'S&T AG','SANT.DE',
'SAF Holland','SFQ.DE',
'Salzgitter AG','SZG.DE',
'Schaeffler AG','SHA.DE',
'Shop Apotheke Europe','SAE.DE',
'Sixt AG ST','SIX2.DE',
'SMA Solar AG','S92.DE',
'Stabilus','STM.DE',
'Steinhoff Int','SNH.DE',
'STRATEC Biomedical','SBS.DE',
'Suedzucker','SZU.DE',
'Takkt AG','TTK.DE',
'Talanx AG','TLX.DE',
'Traton','8TRA.DE',
'Verbio Vereinigte','VBK.DE',
'Vossloh AG','VOS.DE',
'Wacker Neuson','WAC.DE',
'Westwing Group','WEW.DE',
'ZEAL Network SE','TIMA.DE',
'Zooplus AG','ZO1.DE'],

AEX20_STOCKS:[1,
  1,
  1,
  1,
  '€',
'AEX 20','^AEX', 
'Adyen','ADYEN.AS',
'Aegon','AGN.AS',
'Ahold Delhaize','AD.AS',
'Akzo Nobel','AKZA.AS',
'ArcelorMittal','MT.AS',
'ASM','ASM.AS',
'ASML Holding','ASML.AS',
'ASR Nederland','ASRNL.AS',
'BE Semiconductor','BESI.AS',
'Heineken','HEIA.AS',
'IMCD NV','IMCD.AS',
'ING Groep','INGA.AS',
'Just Eat Takeaway','TKWY.AS',
'Koninklijke DSM','DSM.AS',
'Koninklijke KPN','KPN.AS',
'NN Group NV','NN.AS',
'Philips','PHIA.AS',
'Prosus','PRX.AS',
'Randstad','RAND.AS',
'Relx','REN.AS',
//'Royal Dutch Shell A','RDSA.AS',
'Signify','LIGHT.AS',
'Unilever','UNA.AS',
'WFD Unibail Rodamco','URW.AS',
'Wolters Kluwer','WKL.AS'],

  AMX_STOCKS:[1,
  1,
  1,
  1,
  '€',
  'AMX','^AMX', 
  'Aalberts Industries','AALB.AS',
  'ABN AMRO','ABN.AS',
  'Air France KLM','AF.PA',
  'Alfen Beheer','ALFEN.AS',
  'AMG','AMG.AS',
  'Aperam','APAM.AS',
  'Arcadis','ARCAD.AS',
  'Basic Fit','BFIT.AS',
  'Boskalis Westminster','BOKA.AS',
  'Corbion','CRBN.AS',
  'Eurocommercial Properties','ECMPA.AS',
  'Fagron','FAGR.BR',
  'Flow Traders NV','FLOW.AS',
  'Fugro','FUR.AS',
  'Galapagos','GLPG.AS',
  //'Grandvision BV','GVNV.AS',
  'Intertrust Group Holding SA','INTER.AS',
  'Jde Peets','JDEP.AS',
  'OCI NV','OCI.AS',
  'Pharming Group','PHARM.AS',
  'PostNL','PNL.AS',
  'SBM Offshore','SBMO.AS',
  'TKH Group','TWEKA.AS',
  'Vopak','VPK.AS',
  'Warehouses de Pauw','WDP.BR'],

  AEX_SMALL_STOCKS:[1,
    1,
    1,
    1,
    '€',
    'AEX Small','ASCX.AS', 
    'Accell','ACCEL.AS',
    'Accsys','AXS.AS',
    'Ajax','AJAX.AS',
    'Amsterdam Commodities','ACOMO.AS',
    'Avantium','AVTX.AS',
    'B&S Group','BSGR.AS',
    'BAM Groep','BAMNB.AS',
    'Brunel','BRNL.AS',
    'CM.com','CMCOM.AS',
    'ForFarmers','FFARM.AS',
    'Heijmans','HEIJM.AS',
    'Hunter Douglas','HDG.AS',
    'Kendrion','KENDR.AS',
    'Lucas Bols','BOLS.AS',
    'Nedap','NEDAP.AS',
    'NSI','NSI.AS',
    'Ordina','ORDI.AS',
    'Sif Holding NV','SIFG.AS',
    'Sligro Food','SLIGR.AS',
    'TomTom','TOM2.AS',
    'Van Lanschot','VLK.AS',
    //'Vastned Retail','VSTN.AS',
    'Vivoryon Therapeutics','VVY.AS'],

    AEX_ALLSHARE_STOCKS:[1,
      1,
      1,
      1,
      '€',
      'AEX All Share','AAX.AS', 
    'Aalberts Industries','AALB.AS',
'ABN AMRO','ABN.AS',
'Accell','ACCEL.AS',
'Accsys','AXS.AS',
'Adyen','ADYEN.AS',
'Aedifica','AED.BR',
'Aegon','AGN.AS',
'Ahold Delhaize','AD.AS',
'Air France KLM','AF.PA',
'Ajax','AJAX.AS',
'Akzo Nobel','AKZA.AS',
'Alfen Beheer','ALFEN.AS',
'Alumexx NV','ALX.AS',
'AMG','AMG.AS',
'Amsterdam Commodities','ACOMO.AS',
'AND','GOJXN.AS',
'Aperam','APAM.AS',
'Arcadis','ARCAD.AS',
'ArcelorMittal','MT.AS',
'ASM','ASM.AS',
'ASML Holding','ASML.AS',
'ASR Nederland','ASRNL.AS',
'Avantium','AVTX.AS',
'B&S Group','BSGR.AS',
'BAM Groep','BAMNB.AS',
'Basic Fit','BFIT.AS',
'BE Semiconductor','BESI.AS',
'Beter Bed','BBED.AS',
'Bever Holding NV','BEVER.AS',
'Boskalis Westminster','BOKA.AS',
'Boussard Gavaudan','BGHL.AS',
'Brill','BRILL.AS',
'Brunel','BRNL.AS',
'CM.com','CMCOM.AS',
'Corbion','CRBN.AS',
'Core Laboratories','CLB.AS',
'CTAC','CTAC.AS',
'DGB Group','DGB.AS',
'DPA Group','DPA.AS',
'Ease2pay','EAS2P.AS',
'Envipco','ENVI.AS',
'Esperite','ESP.AS',
'Eurocastle Investment','ECT.AS',
'Eurocommercial Properties','ECMPA.AS',
'Euronext','ENX.PA',
'Fagron','FAGR.BR',
'Fastned BV','FAST.AS',
'Flow Traders NV','FLOW.AS',
//'Fng','FNG.BR',
'ForFarmers','FFARM.AS',
'Fugro','FUR.AS',
'Galapagos','GLPG.AS',
//'Grandvision BV','GVNV.AS',
'Hal Trust','HAL.AS',
'Heijmans','HEIJM.AS',
'Heineken','HEIA.AS',
'Heineken','HEIO.AS',
'Holland Colours','HOLCO.AS',
'Hunter Douglas','HDG.AS',
'Hydratec','HYDRA.AS',
//'ICT Group','ICT.AS',
'Iex Group','IEX.AS',
'IMCD NV','IMCD.AS',
'ING Groep','INGA.AS',
'Intertrust Group Holding SA','INTER.AS',
'Just Eat Takeaway','TKWY.AS',
'Kendrion','KENDR.AS',
'Koninklijke DSM','DSM.AS',
'Koninklijke KPN','KPN.AS',
'Lavide','LVIDE.AS',
'Lucas Bols','BOLS.AS',
'Morefield Group NV','MORE.AS',
'Nedap','NEDAP.AS',
'NedSense','NEDSE.AS',
'New Sources Energy','NSE.AS',
'Neways Electronics','NEWAY.AS',
'NN Group NV','NN.AS',
//'Novisource','NOVI.AS',
'NSI','NSI.AS',
'OCI NV','OCI.AS',
//'Oranjewoud NV','ORANW.AS',
'Ordina','ORDI.AS',
'Pershing Square','PSH.AS',
'Pharming Group','PHARM.AS',
'Philips','PHIA.AS',
'Porceleyne Fles','PORF.AS',
'PostNL','PNL.AS',
'Prosus','PRX.AS',
'Randstad','RAND.AS',
'Relx','REN.AS',
'Retail Estates','RET.BR',
'Roodmicrotec','ROOD.AS',
//'Royal Dutch Shell A','RDSA.AS',
'Saint Gobain','SGO.PA',
'SBM Offshore','SBMO.AS',
'Sif Holding NV','SIFG.AS',
'Signify','LIGHT.AS',
'Sligro Food','SLIGR.AS',
'SnowWorld NV','SNOW.AS',
//'Stern Groep','STRN.AS',
'Tetragon','TFG.AS',
'Thunderbird Resorts','TBIRD.AS',
'Tie Holding','TIE.AS',
'TKH Group','TWEKA.AS',
'TomTom','TOM2.AS',
'Value8','VALUE.AS',
'Van Lanschot','VLK.AS',
'Vastned Retail','VASTN.AS',
'Vivoryon Therapeutics','VVY.AS',
'Volta Finance','VTA.AS',
'Vopak','VPK.AS',
'Warehouses de Pau','WDP.BR',
'Wereldhave','WHA.AS',
'WFD Unibail Rodamco','URW.AS',
'Wolters Kluwer','WKL.AS',
'Yatra Capital','YATRA.AS'],


SMI_STOCKS:[1,
  1,
  1,
  1,
  'Fr',
  'SMI','^SSMI', 
  'ABB','ABBN.SW',
'Alcon','ALC.SW',
'Credit Suisse','CSGN.SW',
'Geberit','GEBN.SW',
'Givaudan','GIVN.SW',
'Holcim','HOLN.SW',
'Lonza Group','LONN.SW',
'Nestle','NESN.SW',
'Novartis','NOVN.SW',
'Partners Group','PGHN.SW',
'Richemont','CFR.SW',
'Roche Holding Participation','ROG.SW',
'SGS','SGSN.SW',
'Sika','SIKA.SW',
'Swatch Group','UHR.SW',
'Swiss Life Holding','SLHN.SW',
'Swiss Re','SREN.SW',
'Swisscom','SCMN.SW',
'UBS Group','UBSG.SW',
'Zurich Insurance Group','ZURN.SW'],

SMI_MID_STOCKS:[1,
  1,
  1,
  1,
  'Fr',
  'SMI Mid','FSMM.EX', 
  'Adecco N','ADEN.SW',
  'AMS','AMS.SW',
  'Baloise Holding','BALN.SW',
  'Barry Callebaut','BARN.SW',
  'BB Biotech','BION.SW',
  'Cembra Money Bank AG','CMBN.SW',
  'Clariant','CLN.SW',
  'Dufry','DUFN.SW',
  'Ems Chemie Hld','EMSN.SW',
  'Flughafen Zurich','FHZN.SW',
  'Galenica Sante','GALE.SW',
  'Georg Fischer','0QP4.IL',
  'Helvetia','HELN.SW',
  'Julius Baer','BAER.SW',
  'Kuehne & Nagel','KNIN.SW',
  'Lindt & Spruengli N','LISN.SW',
  'Lindt & Spruengli Part','LISP.SW',
  'Logitech','LOGN.SW',
  'OC Oerlikon Corp','OERL.SW',
  'PSP Swiss Property','PSPN.SW',
  'Schindler Holding','SCHN.SW',
  'Schindler Ps','SCHP.SW',
  'SIG Combibloc','SIGN.SW',
  'Sonova H Ag','SOON.SW',
  'Straumann Holding AG','STMN.SW',
  'Swiss Prime Site','SPSN.SW',
  'Tecan Group','TECN.SW',
  'Temenos Group AG','TEMN.SW',
  'VAT Group','VACN.SW',
  'Vifor Pharma','VIFN.SW'],

  SWISS_ALLSHARE_STOCKS:[1,
    1,
    1,
    1,
    'Fr',
    'SWISS All Share','SSIP.SW', 
    '3M','MMM.SW',
'ABB','ABBN.SW',
'Abbott Labs','ABT.SW',
'Achiko','ACHI.SW',
'Addex Therapeutics Ltd','ADXN.SW',
'Adecco N','ADEN.SW',
'Adval Tech Holding AG','ADVN.SW',
'Aevis Victoria','AEVS.SW',
'Airesis SA','AIRE.SW',
'Alcon','ALC.SW',
'Allreal Holding','ALLN.SW',
'Alpine Select AG','ALPN.SW',
'Also Holding AG','ALSN.SW',
'Aluflexpack','AFP.SW',
'AMS','AMS.SW',
'Anglo American','AAM.SW',
'APG SGA SA','APGN.SW',
'Arbonia','ARBN.SW',
'Arundel','ARON.SW',
'Aryzta','ARYN.SW',
'Ascom Holding AG','ASCN.SW',
'Asmallworld','ASWN.SW',
'Autoneum Holding AG','AUTN.SW',
'BACHEM HOLDING AG','BANB.SW',
'Baloise Holding','BALN.SW',
'Bank Linth LLB AG','LINN.SW',
'Banque Cantonale','BCGE.SW',
'Banque Cantonale de Geneve','BCGE.SW',
'Banque Cantonale Du Jura','BCJ.SW',
'Banque Cantonale du Valais','WKBN.SW',
//'Banque Profil Gestion','BPDG.SW',
'Barry Callebaut','BARN.SW',
'Basellandschaftliche Kantonalbank','BLKB.SW',
'Basilea Pharmaceutica AG','BSLN.SW',
'Basler Kantonalbank','BSKP.SW',
'BB Biotech','BION.SW',
'Belimo Holding','BEAN.SW',
'Bell AG','BELL.SW',
'Bellevue Group AG','BBN.SW',
'Bergbahnen Engelberg Truebsee','TIBN.SW',
'Berner Kantonalbank AG','BEKN.SW',
'BKW AG','BKW.SW',
'Blackstone Resources','BLS.SW',
'Bobst Group SA','BOBNN.SW',
'Bonhote Immobilier','2672561.SW',
'Bossard Holding AG','BOSN.SW',
'Bucher Industries','BUCN.SW',
'Burckhardt Compression','BCHN.SW',
'Burkhalter Holding AG','BRKN.SW',
'BVZ Holding AG','BVZN.SW',
'Bystronic AG','BYS.SW',
'Calida Holding AG','CALN.SW',
'Carlo Gavazzi Holding AG','GAV.SW',
//'Cassiopea SpA','SKIN.SW',
//'Castle Alternative Invest AG','CASN.SW',
'Castle Private Equity AG','CPEN.SW',
'Cembra Money Bank AG','CMBN.SW',
'CI Com SA','CIE.SW',
'Cicor Technologies Ltd','CICN.SW',
'Clariant','CLN.SW',
'Coltene Holding AG','CLTN.SW',
'Comercial del Plata N','CADN.SW',
'Comet','COTN.SW',
'Compagnie Financiere Tradition','CFT.SW',
'COSMO Pharma','COPN.SW',
'CPH Chemie und Papier Holding','CPHN.SW',
'Crealogix Holding AG','CLXN.SW',
'Credit Suisse','CSGN.SW',
/*
'Credit Suisse Global','',
'Credit Suisse Green Property','',
'CS RE Siat','',
'CS Real Estate Fund Interswiss','',
'CS Real Estate Fund LivingPlus','',
'CS Real Estate Hospitality','',
*/
'Daetwyl I','DAE.SW',
'Datacolor AG','DCN.SW',
'DKSH Holding','DKSH.SW',
'Dorma Kaba Holding','DOKA.SW',
'Dottikon Es Holding AG','DESN.SW',
'Dufry','DUFN.SW',
'Eastern Property Holdings','EPH.SW',
'Edisun Power Europe AG','ESUN.SW',
//'Edmond de Rothschild RE','',
'Eeii AG','EEII.SW',
'EFG International AG','EFGN.SW',
'Emmi AG','EMMN.SW',
'Ems Chemie Hld','EMSN.SW',
'Energiedienst Holding AG','EDHN.SW',
'ENR Russia Invest','RUS.SW',
'Evolva Holding','EVE.SW',
'Feintool International Holding','FTON.SW',
//'FIR','',
'Flughafen Zurich','FHZN.SW',
'Forbo Holding AG','FORN.SW',
'Fundamenta Real Estate','FREN.SW',
'Galenica Sante','GALE.SW',
'GAM Holding','GAM.SW',
'Geberit','GEBN.SW',
'General Electric','GE.SW',
'Georg Fischer','0QP4.IL',
'Givaudan','GIVN.SW',
'Glarner Kantonalbank','GLKBN.SW',
'Graubuendner Kantonalbank','GRKP.SW',
'Groupe Minoteries SA','GMI.SW',
//'Gurit Holding AG','GUR.SW',
'HBM Healthcare Investments','HBMN.SW',
'Helvetia','HELN.SW',
'HIAG Immobilien Holding AG','HIAG.SW',
'Highlight Event Entertainment','HLEE.SW',
'Hochdorf Holding AG','HOCN.SW',
'Holcim','HOLN.SW',
'Huber+suhner AG','HUBN.SW',
'Hypothekarbank Lenzburg AG','HBLN.SW',
'Idorsia','IDIA.SW',
'IGEA Pharma','IGPH.SW',
//'Immo Helvetic','IMHE1.SW',
//'Immofonds Immobilien','',
'Implenia','IMPN.SW',
'Inficon Holding','IFCN.SW',
'Interroll Holding AG','INRN.SW',
'Intershop Holding AG','ISN.SW',
'Investis','IREN.SW',
'IVF Hartmann Holding AG','VBSN.SW',
'Julius Baer','BAER.SW',
'Jungfraubahn','JFN.SW',
'Kardex','KARN.SW',
'Klingelnberg','KLIN.SW',
'Komax Holding','KOMN.SW',
'Kudelski','KUD.SW',
'Kuehne & Nagel','KNIN.SW',
'Kuros Biosciences','KURN.SW',
'La Fonciere','278226.SW',
'Lalique','LLQ.SW',
'Landis&Gyr','LAND.SW',
'Lastminute.com','LMN.SW',
'Leclanche SA','LECN.SW',
'Lem Holding SA','LEHN.SW',
'Leonteq AG','LEON.SW',
'Liechtensteinische Landesbank','LLBN.SW',
'Lindt & Spruengli N','LISN.SW',
'Lindt & Spruengli Part','LISP.SW',
'Logitech','LOGN.SW',
'Lonza Group','LONN.SW',
'Luzerner Kantonalbank AG','LUKN.SW',
'MCH Group AG','MCHN.SW',
'Medacta','MOVE.SW',
'Medartis','MED.SW',
'Metall Zug AG','METN.SW',
'Meyer Burger Tech AG','MBTN.SW',
'Mikron Holding AG','MIKN.SW',
'mobilezone ag','MOZN.SW',
'Mobimo Hldg','MOBN.SW',
'Molecular Partners AG','MOLN.SW',
'Montana Aerospace AG','AERO.SW',
//'Native','NTIV.SW',
'Nebag ag','NBEN.SW',
'Nestle','NESN.SW',
//'Nestle','NESN.SW',
//'New Value AG','NEWN.SW',
'Newron Pharmaceuticals','NWRN.SW',
'Novartis','NOVN.SW',
'Novavest','NREN.SW',
'ObsEva','OBSN.SW',
'OC Oerlikon Corp','OERL.SW',
'Orascom Development','ODHN.SW',
'Orell Fuessli Holding AG','OFN.SW',
'Orior AG','ORON.SW',
'Partners Group','PGHN.SW',
//'Patrimonium Swiss RE','',
'Peach Property Group AG','PEAN.SW',
'PepsiCo','PEP.SW',
//'Perfect Holding SA','PRFN.SW',
'Perrot Duval Holding SA','PEDU.SW',
'Philip Morris','PMI.SW',
'Phoenix Mecano AG','PM.SW',
'PIERER Mobility AG','PMAG.SW',
'Plazza Immobilien AG','PLAN.SW',
'Poenina','PNHO.SW',
'PolyPeptide Group AG','PPGN.SW',
//'Polyphor','POLN.SW',
'Private Equity Holding AG','PEHN.SW',
//'Procimmo Swiss Commercial','PSCF2.SW',
'PSP Swiss Property','PSPN.SW',
//'Realstone Swiss Pro','RTS',
'Relief Therapeutics','RLF.SW',
'RESIDENTIA CHF','10061233.SW',
'Richemont','CFR.SW',
'Rieter Holding','RIEN.SW',
//'Robeco Global Stars','',
'Roche Holding','RO.SW',
'Roche Holding Participation','ROG.SW',
//'Rolinco','',
//'Romande Energie Holding SA','',
//'S H L Telemedicine Ltd','',
'Santhera Pharmaceuticals Holding','SANN.SW',
'Schaffner Holding AG','SAHN.SW',
'Schindler Holding','SCHN.SW',
'Schindler Ps','SCHP.SW',
'Schlatter Industries AG','STRN.SW',
'Schmolz & Bickenbach AG','0QPH.L',
//'Schroder Immoplus','',
'Schweiter Tech','SWTQ.SW',
'Schweizerische Nationalbank','SNBN.SW',
//'SCOR','',
'Sensirion','SENS.SW',
/*
'SF Commercial Properties Fund','',
'SF Retail Properties Fund',
'SF Sustainable Property Fund',
*/
'SFS Group AG','SFSN.SW',
'SGS','SGSN.SW',
'Siegfried Holding Ltd','SFZN.SW',
'SIG Combibloc','SIGN.SW',
'Sika','SIKA.SW',
'Softwareone','SWON.SW',
//'Solvalor 61','278545.SW',
'Sonova H Ag','SOON.SW',
'Spice Priv AG','SPCE.SW',
'St Galler Kantonalbank AG','SGKN.SW',
'Stadler Rail','SRAIL.SW',
'Starrag Group Holding AG','STGN.SW',
'Straumann Holding AG','STMN.SW',
'Sulzer','SUN.SW',
//'Sunrise Communications AG',
'Swatch Group','UHR.SW',
'Swatch Group N','UHRN.SW',
//'Swiss Finance & Property Invest','',
'Swiss Life Holding','SLHN.SW',
'Swiss Prime Site','SPSN.SW',
'Swiss Re','SREN.SW',
'Swisscanto CH RE Ifca','JBSICA.SW',
'Swisscom','SCMN.SW',
'Swissinvest RE','SREF.SW',
'Swissquote Group Holding SA','SQN.SW',
'Tecan Group','TECN.SW',
'Temenos Group AG','TEMN.SW',
'Thurgauer Kantonalbank','TKBP.SW',
'Tornos','TOHN.SW',
'TX Group','TXGN.SW',
'U Blox Holding AG','UBXN.SW',
/*
'UBS CH Property Direct Residential','',
'UBS CH Property Fund Leman',
'UBS CH Property Swiss Mixed Sima',
*/
'UBS Group','UBSG.SW',
//'Ubs Property Fd Leman Residential',
//'UBS SW Swissreal',
'V Zug','VZUG.SW',
'Valartis','VLRT.SW',
'Valiant','VATN.SW',
'Valora Holding','VALN.SW',
'Varia US','VARN.SW',
'VAT Group','VACN.SW',
'Vaudoise Assurances Holding SA','VAHN.SW',
'Vetropack Holding SA','VETN.SW',
'Vifor Pharma','VIFN.SW',
'Villars Holding SA','VILN.SW',
'Von Roll Holding AG','ROL.SW',
'Vontobel Holding','VONN.SW',
'VP Bank AG','VPBN.SW',
'VZ Holding AG','VZN.SW',
'Walter Meier','0QQK.L',
'Warteck Invest Ltd','WARN.SW',
'Wisekey International','WIHN.SW',
'Ypsomed Holding AG','YPSN.SW',
'Zehnder','ZEHN.SW',
'Zimmer Biomet','ZBH.SW',
//'Zueblin Immobilien Holding','AGZUBN.SW',
'Zug Estates Holding AG','ZUGN.SW',
'Zuger Kantonalbank','ZUGER.SW',
'Zur Rose','ROSE.SW',
'Zurich Insurance Group','ZURN.SW',
'Zwahlen et Mayr SA','ZWM.SW'],

DAX_ALL_SHARE_STOCKS:[1,
  1,
  1,
  1,
  '€',
  'DAX ALL Share','^CLALL', 
'Aareal Bank AG','ARL.DE',
'Accentro Real Estate','A4Y.DE',
'AD Pepper Media','APM.DE',
'Adler Modemaerkte AG','ADD.DE',
'Adler Real Estate','ADL.DE',
'ADO Properties SARL','ADJ.DE',
'Ahlers','AAH.F',
'Airbus Group','AIR.DE',
'Alstria','AOX.DE',
'Amadeus Fire AG','AAD.DE',
'Artnet AG','ART.DE',
'AS Creation Tapeten','ACWN.DE',
'Aurubis AG','NDA.DE',
'Bastei Lueb','BST.DE',
'Bauer AG','B5A.DE',
'Baywa AG','BYW.DE',
'Baywa Vink AG','BYW6.DE',
'Bertrandt AG','BDT.DE',
'bet at homem','ACX.DE',
'Bilfinger SE','GBF.DE',
'Biotest AG ST','BIO.DE',
'Biotest AG VZ','BIO3.DE',
'BMW Pref','BMW.DE',
'Borussia Dortmund','BVB.DE',
'Brain','BNN.DE',
'Brenntag AG','BNR.DE',
//'Ceconomy','CEC.DE',
//'Ceconomy Pref','CEC1.DE',
'Cewe Color','CWC.DE',
//'Constantin Medien AG','EV4.DE',
'Covestro','1COV.DE',
'CTS Eventim AG','EVD.DE',
'DEAG AG','LOUD.DE',
'Delticom AG','DEX.DE',
'Demire Deutsche Mittelstand','DMRE.DE',
'Deutsche Beteiligungs AG','DBAN.DE',
'Deutsche Euroshop AG','DEQ.DE',
'Deutsche Pfandbriefbank AG','PBB.DE',
'Deutsche Wohnen','DWNI.DE',
'Deutz AG','DEZ.DE',
'DF Deutsche Forfait AG','DFTK.DE',
'DIC Asset AG','DIC.DE',
'DMG Mori Seiki','GIL.DE',
'Duerr AG','DUE.DE',
'EDAG Engineering Group','ED4.DE',
'Einhell VZO','EIN3.DE',
'Elringklinger AG','ZIL2.DE',
'Elumeo SE','ELB.DE',
'Encavis','ECV.DE',
'Evonik','EVK.DE',
'Fair Value AG','FVI.F',
'Ferratum Oyj','FRU.DE',
'Fielmann AG','FIE.DE',
'Francotyp-Postalia','FPH.DE',
'Fraport AG','FRA.DE',
'Fuchs Petrolub AG','FPE.DE',
'Fuchs Petrolub VZO Pref','FPE3.DE',
'GEA Group AG','G1A.DE',
'Gerresheimer AG','GXI.DE',
'Gerry Weber','GWI2.DE',
'Gesco AG','GSC1.DE',
'Grammer AG','GMM.DE',
'Grenke','GLJ.DE',
'H&R','2HRA.DE',
'Hamborner AG','HABA.DE',
'Hamburger Hafen A-SP','HHFA.DE',
'Hannover Rueckversicherung SE','HNR1.DE',
'Hapag Lloyd AG','HLAG.DE',
'Hawesko AG','HAW.DE',
'Heidelberger Druckmaschinen','HDD.DE',
'Hella KGaA Hueck & Co','HLE.DE',
'Henkel ST','HEN.DE',
'Highlight Communications','HLG.DE',
'Hochtief AG','HOT.DE',
'Hornbach','HBH.DE',
'Hornbach-Baumarkt','HBM.DE',
'Hugo Boss AG','BOSS.DE',
'Hypoport AG','HYQ.DE',
'Indus AG','INH.DE',
'Jungheinrich AG','JUN3.DE',
'K&S AG','SDF.DE',
'Kion Group AG','KGX.DE',
'Kloeckner SE','KCO.DE',
'Koenig&Bauer AG','SKB.DE',
'Kromi','K1R.DE',
'Krones AG','KRN.DE',
'Kuka AG','KU2.DE',
'KWS SAAT AG','KWS.DE',
'Lanxess AG','LXS.DE',
'LEG Immobilien AG','LEG.DE',
'Leifheit AG','LEI.DE',
'Leoni AG','LEO.DE',
'Logwin','TGHN.DE',
'Lotto24 AG','LO24.DE',
'Ludwig Beck','ECK.DE',
'Masterflex','MZX.DE',
'Max Automation','MXHN.DE',
'MBB Industries AG','MBB.DE',
'MediClin AG','MED.DE',
'MLP AG','MLP.DE',
'MTU Aero','MTX.DE',
'MVV Energie AG','MVV1.DE',
'Norma AG','NOEJ.DE',
//'Osram Licht','OSR.DE',
'OVB Holding AG','O4B.DE',
'Patrizia Immobilien','PAT.DE',
'Petro Welt Tech','O2C.DE',
'Progress Werk Oberkirch','PWO.DE',
'Puma SE','PUM.DE',
'R. Stahl AG','RSL2.DE',
'Rational AG','RAA.DE',
'Rheinmetall AG','RHM.DE',
'Rhoen-Klinikum','RHK.DE',
'Roy Ceramics','RY8.DE',
'RTL Group','RRTL.DE',
'SAF Holland','SFQ.DE',
'Salzgitter AG','SZG.DE',
'Schaeffler AG','SHA.DE',
'Schaltbau','SLT.DE',
'Scout24 AG','G24.DE',
'SGL Carbon SE','SGL.DE',
'Shop Apotheke Europe','SAE.DE',
'SHW AG','SW1.DE',
'Sixt AG ST','SIX2.DE',
'Sixt AG VZO','SIX3.DE',
'Sixt Leasing AG','LNSX.DE',
'SLEEPZ','BTBB.DE',
'SMT Scharf AG','S4A.DE',
'Stabilus','STM.DE',
'Steinhoff Int','SNH.DE',
'Stroeer','SAX.DE',
'Suedzucker','SZU.DE',
'Surteco SE','SUR.DE',
'Symrise AG','SY1.DE',
'Tag Immobilien','TEG.DE',
'Takkt AG','TTK.DE',
'Talanx AG','TLX.DE',
//'Tele Columbus AG','TC1.DE',
//'TLG Immobilien AG','TLG.DE',
'Uniper SE','UN01.DE',
'United Labels','ULC.DE',
'Villeroy&Boch AG','VIB3.DE',
'Volkswagen ST','VOW3.DE',
'Vossloh AG','VOS.DE',
'Wacker Chemie','WCH.DE',
'Wacker Neuson','WAC.DE',
'WashTec AG','WSU.DE',
'WCM Beteiligungs Grundbesitz','WCMK.DE',
'Westag&Getalit ST','WUG.DE',
'Westag&Getalit VZO','WUG3.DE',
//'Windeln de','WDL.DE',
'Wuestenrot Wuerttembergische','WUW.DE',
'Zalando SE','ZAL.DE',
'ZEAL Network SE','TIMA.DE',
'Zhongde Waste','ZEF.DE',
'Zooplus AG','ZO1.DE'],

  TECDAX_SHARE_STOCKS:[1,
    1,
    1,
    1,
    '€',
    '11880 Solutions','TGT.DE',
'4 SC AG','VSC.DE',
'4Basebio AG','2INV.DE',
'Aap Implantate AG','AAQ1.DE',
'Adva Optical','ADV.DE',
'Aixtron','AIXA.DE',
'All For One Steeb AG','A1OS.DE',
'Atoss AG','AOF.DE',
'Basler AG','BSL.DE',
'BB Biotech','BBZA.DE',
'Bechtle AG','BC8.DE',
'Biofrontera AG','B8F.DE',
'Cancom SE','COK.DE',
'Carl Zeiss AG','AFX.DE',
'Cenit AG','CSH.DE',
'CompuGroup AG','COP.DE',
'Cropenergies AG','CE2.DE',
'Data Modul AG','DAM.DE',
//'Dialog','DLG.DE',
'Diebold','DBD.DE',
'Dr. Hoenle AG','HNL.DE',
'Draegerwerk ST','DRW8.DE',
'Draegerwerk VZO','DRW3.DE',
//'Drillisch AG','DRI.DE',
'Eckert&Ziegler AG','EUZ.DE',
'Ecotel AG','E4C.DE',
'Elmos AG','ELG.DE',
'Epigenomics AG','ECX.DE',
'Euromicron AG','EUCA.DE',
'Evotec AG','EVT.DE',
'exceet Group','EXC.DE',
'Fabasoft AG','FAA.DE',
'First Sensor AG','SIS.DE',
'Fortec','FEV.DE',
'Freenet AG','FNTN.DE',
//'Fyber NV','FBEN.DE',
'Geratherm','GME.DE',
'GFT Technologies AG','GFT.DE',
'Gigaset AG','GGS.DE',
'GK Software','GKS.DE',
'Heidelberg Pharma AG','HPHA.DE',
'HolidayCheck','HOC.DE',
'Init Innovation','IXX.DE',
'Intershop','ISHA.DE',
'Intica Systems AG','IS7.DE',
'Invision AG','IVX.DE',
//'Isra Vision','ISR.HM',
'IVU Traffic','IVU.DE',
'Jenoptik','JEN.DE',
'KPS','KSC.DE',
'LPKF','LPK.DE',
'Manz AG','M5Z.DE',
'Medigene','MDG1.DE',
'MeVis','M3V.DE',
'Morphosys AG','MOR.DE',
'Nemetschek AG','NEM.DE',
'New Work','NWO.DE',
'Nexus','NXU.DE',
'NFON','NFN.DE',
'Nordex SE','NDX1.DE',
'OHB AG','OHB.DE',
'Paion','PA8.DE',
'Pfeiffer','PFV.DE',
'PNE Wind AG','PNE3.DE',
'PSI AG','PSAN.DE',
'PVA Tepla AG','TPE.DE',
'q.beyond','QBY.DE',
'Qiagen','QIA.DE',
//'RIB Software AG','RIB.DE',
'S&T AG','SANT.DE',
'Sartorius AG','SRT.DE',
'Sartorius AG VZO','SRT3.DE',
'Secunet AG','YSN.DE',
//'Senvion','SEN.F',
'Serviceware','SJJ.DE',
'SFC Energy AG','F3C.DE',
'Siemens Healthineers','SHL.DE',
'Siltronic AG','WAF.DE',
'Singulus Tech','SNG.DE',
'SLM Solutions','AM3D.DE',
'SMA Solar AG','S92.DE',
'SNP Schneider','SHF.DE',
'Softing AG','SYT.DE',
'Software AG','SOW.DE',
'STRATEC Biomedical','SBS.DE',
'Suess Microtec','SMHN.DE',
'Syzygy AG','SYZ.DE',
'Technotrans AG','TTR1.DE',
'Telefonica Deutsch','O2D.DE',
'Teles AG','TLIK.DE',
'United Internet AG','UTDI.DE',
'USU Software AG','OSP2.DE',
'Varta','VAR1.DE',
'Verbio Vereinigte','VBK.DE',
'Viscom AG','V6C.DE',
'Voltabox AG','VBX.DE',
'Yoc AG','YOC.DE'],

MDAX_STOCKS:[1,
  1,
  1,
  1,
  '€',
  'MDAX','^MDAXI',
  
'Airbus Group','AIR.DE',
'Aixtron','AIXA.DE',
'Alstria','AOX.DE',
'Aroundtown','AT1.DE',
'Aurubis AG','NDA.DE',
'Bechtle AG','BC8.DE',
'Beiersdorf AG','BEI.DE',
'Brenntag AG','BNR.DE',
'Cancom SE','COK.DE',
'Carl Zeiss AG','AFX.DE',
'Commerzbank','CBK.DE',
'CompuGroup AG','COP.DE',
'CTS Eventim AG','EVD.DE',
'Duerr AG','DUE.DE',
'Encavis','ECV.DE',
'Evonik','EVK.DE',
'Evotec AG','EVT.DE',
'Fraport AG','FRA.DE',
'Freenet AG','FNTN.DE',
'Fuchs Petrolub','FPE3.DE',
'GEA Group AG','G1A.DE',
'Gerresheimer AG','GXI.DE',
'Grand City','GYC.DE',
'Hannover Rueckvers','HNR1.DE',
'Hella KGaA Hueck ','HLE.DE',
'HelloFresh','HFG.DE',
'Hochtief AG','HOT.DE',
'Hugo Boss AG','BOSS.DE',
'K&S AG','SFD.DE',
'Kion Group AG','KGX.DE',
'Knorr-Bremse','KBX.DE',
'Lanxess AG','LXS.DE',
'LEG Immobilien','LEG.DE',
'Lufthansa','LHA.DE',
'Morphosys AG','MOR.DE',
'Nemetschek AG','NEM.DE',
'Nordex SE','NDX1.DE',
'Porsche','PAH3.DE',
'Prosiebensat','PSM.DE',
'Puma SE','PUM.DE',
'Qiagen','QIA.DE',
'Rational AG','RAA.DE',
'Rheinmetall AG','RHM.DE',
'RTL Group','RRTL.DE',
'Sartorius AG VZO','SRT3.DE',
'Scout24 AG','G24.DE',
'Siemens Healthineers','SHL.DE',
'Siltronic AG','WAF.DE',
'Software AG','SOW.DE',
'Stroeer','SAX.DE',
'Symrise AG','SY1.DE',
'Tag Immobilien','TEG.DE',
'TeamViewer','TMV.DE',
'Telefonica Deutschland','O2D.DE',
'Thyssenkrupp AG','TKA.DE',
'Uniper SE','UN01.DE',
'United Internet AG','UTDI.DE',
'Varta','VAR1.DE',
'Wacker Chemie','WCH.DE',
'Zalando SE','ZAL.DE',
'Vonovia','VNA.DE'],


TECDAX_STOCKS:[1,
  1,
  1,
  1,
  '€',
  'TecDAX','^TECDAX',
  'Telefonica Deutschland','O2D.DE',
'Aixtron','AIXA.DE',
'Bechtle AG','BC8.DE',
'Cancom SE','COK.DE',
'Carl Zeiss AG','AFX.DE',
'CompuGroup AG','COP.DE',
'Deutsche Telekom AG','DTE.DE',
'Draegerwerk VZO','DRW3.DE',
//'Drillisch AG','DRI.DE',
'Eckert&Ziegler AG','EUZ.DE',
'Evotec AG','EVT.DE',
'Freenet AG','FNTN.DE',
'Infineon','IFX.DE',
'Jenoptik','JEN.DE',
'LPKF','LPK.DE',
'Morphosys AG','MOR.DE',
'Nemetschek AG','NEM.DE',
'Nordex SE','NDX1.DE',
'Pfeiffer','PFV.DE',
'Qiagen','QIA.DE',
'S&T AG','SANT.DE',
'SAP','SAP.DE',
'Sartorius AG VZO','SRT3.DE',
'Siemens Healthineers','SHL.DE',
'Siltronic AG','WAF.DE',
'SMA Solar AG','S92.DE',
'Software AG','SOW.DE',
'TeamViewer','TMV.DE',
'United Internet AG','UTDI.DE',
'Varta','VAR1.DE',
    'Vonovia','VNA.DE'],

EURO50_STOCKS:[1,
  1,
  1,
  1,
  '€',
 'EuroStoxx 50','^STOXX50E',
'Adidas','ADS.DE',
'Adyen','ADYEN.AS',
'Ahold Delhaize','AD.AS',
'Air Liquide','AI.PA',
'Airbus Group','AIR.PA',
'Allianz','ALV.DE',
'Amadeus','AMS.MC',
'Anheuser Busch Inbev','ABI.BR',
'ASML Holding','ASML.AS',
'AXA','CS.PA',
'BASF','BAS.DE',
'Bayer','BAYN.DE',
'BMW ST','BMW.DE',
'BNP Paribas','BNP.PA',
'CRH','CRG.IR',
'Mercedes-Benz','MBG.DE',
'Danone','BN.PA',
'Deutsche Boerse','DB1.DE',
'Deutsche Post','DPW.DE',
'Deutsche Telekom AG','DTE.DE',
'Enel','ENEL.MI',
'Engie','ENGI.PA',
'Eni SpA','ENI.MI',
'EssilorLuxottica','EL.PA',
'Flutter Entertainment','FLTR.L',
'Hermès Inter.','RMS.PA',
'Iberdrola','IBE.MC',
'Inditex','ITX.MC',
'Infineon','IFX.DE',
'ING Groep','INGA.AS',
'Intesa Sanpaolo','ISP.MI',
'Kering','KER.PA',
'KONE Oyj','KNEBV.HE',
'L´Oreal','OR.PA',
'Linde PLC','LIN.DE',
'Louis Vuitton','MC.PA',
'Muench. Rueckvers','MUV2.DE',
'Pernod Ricard','RI.PA',
'Philips','PHIA.AS',
'Prosus','PRX.AS',
'Safran','SAF.PA',
'Sanofi','SAN.PA',
'Santander','SAN.MC',
'SAP','SAP.DE',
'Schneider Electric','SU.PA',
'Siemens AG','SIE.DE',
'Stellantis','STLA.MI',
'TotalEnergies','TTE.PA',//'Total','FP.PA',
'Vinci','DG.PA',
'Vivendi','VIV.PA',
'Volkswagen VZO','VOW3.DE',
'Vonovia','VNA.DE'],
  

MADRID_STOCKS:[1,
  1,
  1,
  1,
  '€',
'General Madrid','INDC.MC',
'Acciona','ANA.MC',
'Acerinox','ACX.MC',		 
'ACS','ACS.MC',		 
'Adolfo Domínguez','ADZ.MC',	 
'Aedas Homes','AEDAS.MC',	
'Aena','AENA.MC',		 
'Airbus Group','AIR.MC',	 
'Airtificial Intelligence','AI.MC',		 
'Alantra Partners','ALNT.MC',	 
'Alba SA','ALB.MC',	
'Almirall','ALM.MC',		 
'Amadeus','AMS.MC', 
'Amper','AMP.MC',		 
'Amrest','EAT.MC',		 
'Applus','APPS.MC',
'ArcelorMittal','MTS.MC',		 
'Arima Real Estate','ARM.MC',		 
'Atresmedia','A3M.MC', 
'Audax Renovables','ADX.MC', 
'Azkoyen','AZK.MC',		 
'Banco de Sabadell','SAB.MC',		 
'Bankinter','BKT.MC',	 
//'Barón de Ley','BDL.MC',		 
'BBVA','BBVA.MC',	 
'Berkeley Energy','BKY.MC',		 
//'Biosearch','BIO.MC',		 
'Bodegas Riojanas','RIO.MC',	 
'CaixaBank','CABK.MC',		 
'Catalana Occidente','GCO.MC',	 
'Cellnex Telecom','CLNX.MC',	 
'Cie Automotive','CIE.MC',	 
'Clínica Baviera','CBAV.MC',		 
'Coca-Cola European','CCEP.MC',	
//'Codere','CDR.MC',	 
'Colonial','COL.MC',	
'CAF','CAF.MC', 
'Deoleo','OLE.MC',
'DIA','DIA.MC',	 
'Duro Felguera','MDF.MC',		 
'Ebro Foods','EBRO.MC', 
'eDreams','EDR.MC',	
'Elecnor','ENO.MC',
'Grupo San Jose','GSJ.MC', 
'Enagás','ENG.MC',		 
'ENCE','ENC.MC',	
'Endesa','ELE.MC',		 
'Ercros','ECR.MC',		 
//'Euskaltel','EKT.MC', 
'Ezentis','EZE.MC',
'Faes Farma','FAE.MC',		 
'FCC','FCC.MC',	
'Ferrovial','FER.MC',	 
'Fluidra','FDR.MC', 
'GAM','GALQ.MC',	 
'Gestamp','GEST.MC',	 
'Global Dominion','DOM.MC',	 
'Grenergy Renovables SA','GRE.MC',	 
'Grifols','GRF.MC',	 
'Grifols Pref','GRF-P.MC',		 
'IAG','IAG.MC',	 
'Iberdrola','IBE.MC', 
'Iberpapel','IBG.MC',	 
'Inditex','ITX.MC', 
'Indra','IDR.MC', 
'Insur','ISUR.MC',		 
'Lar España','LRE.MC',	 
//'Liberbank','LBK.MC',	 
'Línea Directa','LDA.MC',
'Lingotes Especiales','LGT.MC',	 
'Logista','LOG.MC',	 
'Mapfre','MAP.MC',		 
'Mediaset','TL5.MC',		 
'Meliá Hotels','MEL.MC',	 
'Merlin Properties','MRL.MC',	 
'Metrovacesa','MVC.MC',	 
'Miquel y Costas','MCM.MC',		 
'Montebalito','MTB.MC',		 
'Naturgy Energy','NTGY.MC',	 
'Naturhouse','NTH.MC',
'Neinor Homes','HOME.MC',		 
'NH Hoteles','NHH.MC',		 
'Nicolás Correa','NEA.MC',	 
'Nueva Expresion','NXT.MC',		 
'Nyesa Valores','NYE.MC',		 
'OHLA','OHLA.MC',	 
'Oryzon Genomics','ORY.MC',	 
'Pescanova','PVA.MC',		 
'PharmaMar','PHM.MC',			 
'Prim','PRM.MC',		 
'Prisa','PRS.MC',		 
'Prosegur','PSG.MC',	 
'Prosegur Cash','CASH.MC',	 
//'Quabit','QBT.MC',	 
'Realia','RLIA.MC',		 
'Red Eléctrica','RED.MC',	
'Reig Jofre','RJF.MC',			 
'Renta 4','R4.MC',		 
'Renta Corp.','REN.MC',		 
'Repsol','REP.MC', 
'Rovi','ROVI.MC', 
'Sacyr','SCYR.MC',		 
'Santander','SAN.MC',		 
//'Service P.S.','SPS.MC',		 
'Siemens Gamesa','SGRE.MC',		 
'Solaria','SLR.MC',		 
//'Solarpack','SPK.MC',		 
'Talgo','TLGO.MC',		 
'Técnicas Reunidas','TRE.MC', 
'Telefónica','TEF.MC',		 
'Tubacex','TUB.MC', 
'Tubos Reunidos','TRG.MC',	 
'Unicaja Banco','UNI.MC', 
'Urbas','UBS.MC',		 
//'Vértice 360','VER.MC',	 
'Vidrala','VID.MC',		 
'Viscofan','VIS.MC',	 
'Vocento','VOC.MC'],
//'Zardoya','ZOT.MC'],

TSX_VENTURE_STOCKS:[1,
  1,
  1,
  1,
  '$',
  'TSX Venture','^SPCDNX', 
  '01 Communique Lab','ONE.V',
//'48North Cannabis','NRTH.V',
'A2Z Tech','AZ.V',
'Abcourt Mines Inc.','ABI.V',
//'Abitibi Royalties Inc','RZZ.V',
'AbraPlata Resource','ABRA.V',
'Acasti Pharma','ACST.V',
'Acceleware','AXE.V',
'Adventus Mining Corp','ADZN.V',
'Aequus Pharmaceuticals','AQS.V',
'AEX Gold','AEX.V',
'Africa Energy','AFE.V',
'Aftermath Silver','AAG.V',
'Aldrin Resource','PWM.V',
'Alpha Lithium','ALLI.V',
'Altamira Gold','ALTA.V',
'Alvopetro Energy','ALV.V',
'Amador Gold','MENE.V',
'Amarillo Gold','AGC.V',
'American Creek Resources','AMK.V',
'American Lithium','LI.V',
'American Manganese','AMY.V',
'Amex Exploration','AMX.V',
'AnalytixInsight','ALY.V',
'Arch Biopartners','ARCH.V',
'Arena Minerals','AN.V',
'ARHT Media','ART.V',
'Arianne Phosphate','DAN.V',
'Arizona Metals','AMC.V',
'Artemis Gold','ARTG.V',
'Atico Mining Corporation','ATY.V',
'Aurcana','AUN.V',
'Aurion Resources','AU.V',
'Aurora Solar Technologies','ACU.V',
'Aurora Spine Corporation','ASG.V',
'Avante Logixx','XX.V',
'Avanti Energy','AVN.V',
'Azimut Exploration Inc.','AZM.V',
'Azincourt Uranium','AAZ.V',
'Banyan Gold','BYN.V',
'Barksdale Resources','BRO.V',
'Barsele Minerals','BME.V',
'Bayhorse Silver','BHS.V',
'Bear Creek Mining','BCM.V',
'BeMetals','BMET.V',
'Benchmark Metals','BNCH.V',
'Benz Mining','BZ.V',
'biOasis Technologies Inc','BTI.V',
'Biosyent Inc.','RX.V',
'Bitfarms','BITF.V',
//'Blackline Gps','BLN.V',
'Blackrock Gold','BRC.V',
'Blue Sky Uranium','BSK.V',
'Bluestone Resources','BSR.V',
'Bonterra Resources','BTR.V',
'Braille Energy','BES.V',
'Brixton Metals Corporation','BBB.V',
'C3 Metals Inc','CCCM.V',
'Cabral Gold','CBR.V',
'Callinex Mines','CNX.V',
'Camino Minerals Corporation','COR.V',
'Canada Carbon Inc.','CCB.V',
'Canada Nickel Company','CNC.V',
'Canada Silver Cobalt','CCW.V',
'CanAlaska Uranium','CVV.V',
'Candelaria Mining','CAND.V',
'Canuc Resources','CDA.V',
'Cartier Resources Inc','ECR.V',
'C-Com Satellite Systems Inc.','CMI.V',
'Ceapro','CZO.V',
'Cematrix Corporation','CVX.V',
'Ceylon Graphite','CYL.V',
'CGX Energy Inc.','OYL.V',
'Chakana Copper','PERU.V',
'CHAR Tech','YES.V',
'Chesapeake Gold','CKG.V',
'Clean Seed Capital Group','CSX.V',
'Colonial Coal International Corp.','CAD.V',
'Commerce Resources','CCE.V',
'Copper Fox Metals','CUU.V',
'Cornerstone Capital Resources Inc.','CGP.V',
'Critical Elements','CRE.V',
'CryptoStar','CSTR.V',
'Cubicfarm','CUB.V',
'CUV Ventures','REVO.V',
'Cypress Development Corp.','CYP.V',
'Datametrex AI','DM.V',
'Decibel Cannabis','DB.V',
'Decisive Dividend Corp','DE.V',
'Defense Metals','DEFN.V',
'Defiance Silver','DEF.V',
'Desert Mountain Energy','DME.V',
'Diagnos','ADK.V',
'Digihost Technology','DGHI.V',
'Discovery Metals','DSV.V',
'Dmg Blockchain','DMGI.V',
'Dolly Varden Silver Corporation','DV.V',
'Dominion Lending Centres','DLCG.V',
'Doubleview Capital Corp.','DBG.V',
'Drone Delivery','FLT.V',
'E3 Metals','ETMC.V',
'East Africa Metals Inc.','EAM.V',
'Eastwood Bio Medical Canada Inc','EBM.V',
'Eco Atlantic','EOG.V',
'Edgewater Wireless Systems Inc','YFI.V',
'Eguana Tech','EGT.V',
'Eloro Resources','ELO.V',
'Else Nutrition','BABY.V',
'Ely Gold Royalties','ELY.V',
'Emerita Resources','EMO.V',
'Eminent Gold','EMNT.V',
'Empire Industries','DTG.V',
'Empire Metals','EP.V',
'EMX Royalty','EMX.V',
'enCore Energy Corp','EU.V',
'Enduro Metals Corp','ENDR.V',
'Enforcer Gold Corp','VEIN.V',
'Engine Media Holdings Inc','GAME.V',
'Environmental Waste','EWS.V',
'EnWave Corporation','ENW.V',
'EQ Inc.','EQ.V',
'Eskay Mining Corp','ESK.V',
'Euro Manganese','EMN.V',
'Exro Tech','EXRO.TO',
'Facedrive','FD.V',
'Falco Resources','FP.V',
'Falcon Oil & Gas','FO.V',
'Filo Mining','FIL.V',
'Fiore Gold','F.V',
'Fireweed Zinc','FWZ.V',
'Firm Capital Property','FCD-UN.V',
'First Cobalt','FCC.V',
//'First Vanadium','FVAN.V',
'Flowr','FLWR.V',
'Flurotech','TEST.V',
'Focus Graphite','FMS.V',
'Foran Mining','FOM.V',
'Fortress Blockchain','FORT.V',
'Fortune Bay','FOR.V',
'Forum Energy Metals','FMC.V',
'FPX Nickel','FPX.V',
//'Fronsac REIT','FRO-UN.V',
'Frontier Lithium','FL.V',
'G2 Goldfields','GTWO.V',
'Gabriel Resources','GBU.V',
'Galane Gold','GG.V',
'Galleon Gold Corp','GGO.V',
'Galway Metals Inc.','GWM.V',
'Garibaldi Resources Corp.','GGI.V',
'Gatekeeper Systems Inc','GSI.V',
'Gen III Oil','GIII.V',
'Gensource Potash Corporation','GSP.V',
'Geomega Resources Inc','GMA.V',
'Giga Metals Corp','GIGA.V',
'Giyani Metals','EMM.V',
'Gold Reserve Inc','GRZ.V',
'Gold Terra Resource','YGT.V',
//'Gold X','GLDX.V',
'Golden Predator','GPY.V',
'Golden Tag Resources','GOG.V',
'Golden Valley Mines','GZZ.V',
'GoldQuest Mining','GQC.V',
'Goldsource Mines','GXS.V',
'Goldspot Discoveries','SPOT.V',
'Goliath Resources','GOT.V',
'Good Natured Products','GDNP.V',
'GoviEx Uranium','GXU.V',
'GR Silver Mining','GRSL.V',
'Granite Creek Copper','GCX.V',
'Graphite One','GPH.V',
'Gratomic Inc','GRAT.V',
'Great Bear Resources','GBR.V',
'Greenbriar Capital Corp','GRB.V',
'GreenPower Motor','GPV.V',
'Group Ten Metals','PGE.V',
'GTEC Holdings','GTEC.V',
'H2O Innovation Inc','HEO.V',
'Halmont Properties Corp','HMT.V',
'Hamilton Thorne','HTL.V',
'Hannan Metals Ltd','HAN.V',
'Harfang Exploration','HAR.V',
'Heliostar Metals','HSTR.V',
'Helix Apps','HELX.V',
'Hemisphere Energy Corporation','HME.V',
'Highgold','HIGH.V',
'HIVE Blockchain','HIVE.V',
'HPQ Silicon','HPQ.V',
'Hudson Resources','HUD.V',
'Iconic Minerals','ICM.V',
'Imaflex','IFX.V',
'Immunoprecise Antibodies','IPA.V',
'IMPACT Silver','IPT.V',
'Indiva','NDVA.V',
'Input Capital Corp.','INP.V',
'Integra Resources','ITR.V',
'Intema Solutions','ITM.V',
'Isoenergy','ISO.V',
'Jade Power Trust','JPWR-UN.V',
'Japan Gold','JG.V',
'Jericho Oil','JEV.V',
'Kalytera Therapeutics','CLAS.V',
'Khiron Life Sciences','KHRN.V',
'Klondike Gold Corp','KG.V',
'Kneat.com','KSI.V',
'Kodiak Copper','KDK.V',
'Kootenay Silver','KTN.V',
'Kore Mining','KORE.V',
'Kraken Robotics','PNG.V',
'Kutcho Copper','KC.V',
'Labrador Gold','LAB.V',
'Lara Exploration','LRA.V',
'Laurion Mineral Exploration Inc','LME.V',
'Leading Edge Materials','LEM.V',
'Legend Power Systems','LPS.V',
'Leucrotta Exploration','LXE.V',
'Lexagene Holdings','LXG.V',
'Lion One Metals','LIO.V',
'Lithium Chile','LITH.V',
'Lithium Energy Products','ARS.V',
'Lomiko Metals','LMR.V',
'Loop Insights','MTRX.V',
'Los Andes Copper','LA.V',
'Lumina Gold','LUM.V',
'Luminex','LR.V',
'Macro Enterprises Inc.','MCR.V',
'Magna Gold','MGR.V',
'Majestic Gold','MJS.V',
'Mako Mining','MKO.V',
'Manganese X Energy','MN.V',
'Maple Gold Mines','MGM.V',
'Maritime Resources Corp.','MAE.V',
'Martello Tech','MTLO.V',
'Mason Graphite Inc.','LLG.V',
'Medallion Resources','MDL.V',
'Medexus Pharma','MDP.V',
'MediaValet','MVP.V',
'MedMira Inc','MIR.V',
'MedX Health Corp','MDX.V',
'Metalla Royalty Streaming','MTA.V',
'Metallic Minerals','MMG.V',
'Midland Exploration','MD.V',
'Midnight Sun Mining Corp','MMA.V',
'Millennial Lithium','ML.V',
'Minaurum Gold Inc','MGG.V',
'Minera Alamos','MAI.V',
'Mirasol Resources','MRZ.V',
'Mission Ready','MRS.V',
'Mk2 Ventures','DMX.V',
'Mkango Resources','MKA.V',
'Monument Mining','MMY.V',
'Moovly Media','MVY.V',
'Mundoro Capital Inc','MUN.V',
'Namaste Techn','N.V',
'Nanalysis Scientific','NSCI.V',
'Nano One Materials','NNO.V',
'NanoTech Security Corp','NTS.V',
'NanoXplore','GRA.V',
'NEO Lithium','NLC.V',
'NervGen Pharma','NGEN.V',
'NeutriSci International','NU.V',
'New Found Gold','NFG.V',
'New Oroperu Resources','ORO.V',
'Newcore Gold Ltd','NCAU.V',
'Newport Exploration','NWX.V',
'Nexoptic Technology','NXO.V',
'Ngex Minerals','NGEX.V',
'Ngx Energy International','GASX.V',
'Nickel 28 Capital','NKL.V',
'Niobay Metals','NBY.V',
'Noram Ventures','NRM.V',
'Noront Resources','NOT.V',
'Northern Graphite Corporation','NGC.V',
'Northern Superior','SUP.V',
'Northern Vertex Mining','NEE.V',
'Northfield Capital','NFD-A.V',
'NorthIsle Copper and Gold Inc','NCX.V',
'Nouveau Monde Graphite','NOU.V',
'Nova Leap Health','NLH.V',
'Nova Royalty','NOVR.V',
'Nubeva Tech','NBVA.V',
'Nulegacy Gold Corporation','NUG.V',
'Numinus Wellness Inc','NUMI.V',
'O3 Mining','OIII.V',
'OneSoft Solutions','OSS.V',
'Orca Gold','ORG.V',
'Orezone Gold','ORE.V',
'Organic Garage','OG.V',
'Organto Foods','OGO.V',
'Oroco Resource Corp','OCO.V',
'Orogen Royalties Inc','OGN.V',
'Osino Resources','OSI.V',
'Osisko Metals','OM.V',
'Pacific Booker Minerals','BKM.V',
'Palladium One','PDM.V',
'Pan Global Resources Inc','PGZ.V',
'Pan Orient Energy','POE.V',
'Pancontinental Resources','PUC.V',
'Panoro Minerals','PML.V',
'Parkit Enterprise','PKT.V',
'Parlane Resource','IMIN.V',
'Perisson Petroleum','POG.V',
'Petromaroc','WEII.V',
'Petrotal','TAL.V',
'Pharmacielo','PCLO.V',
'Playgon Games','DEAL.V',
'POET Technologies','PTK.V',
'PowerBand Solutions','PBX.V',
'Prime Mining','PRYM.V',
'Probe Metals','PRB.V',
'ProntoForms Corporation','PFM.V',
'Pure Gold Mining','PGM.V',
'Purepoint Uranium Group Inc','PTU.V',
'QMC Quantum Minerals','QMC.V',
'Questor Technology Inc.','QST.V',
'Quipt Home Medical','QIPT.V',
'Quisitive Tech','QUIS.V',
'Quorum Information Technologies Inc','QIS.V',
'Qyou Media','QYOU.V',
'Radient Tech','RTI.V',
'RE Royalties','RE.V',
'Real Brokerage','REAX.V',
'Reconnaissance Energy Africa','RECO.V',
'Redishred Capital','KUT.V',
'Regulus Resources','REG.V',
'Reliq Health Tech','RHT.V',
'Renaissance Oil','ROE.V',
'Res Robex Inc','RBX.V',
'Resaas Services','RSS.V',
'Ressources Minieres Radisson','RDS.V',
'Reunion Gold','RGD.V',
'Revival Gold','RVG.V',
'Rhyolite Resources','RYE.V',
'Rio2','RIO.V',
'Rock Tech Lithium','RCK.V',
'ROKMASTER Resources','RKR.V',
'Roscan Gold','ROS.V',
'Route1 Inc','ROI.V',
'Royal Helium','RHC.V',
'Rupert Resources','RUP.V',
'Sable Resources','SAE.V',
'Salazar Resources','SRL.V',
'Sama Resources Inc.','SME.V',
'Sandfire Resources America','SFR.V',
'Sangoma Technologies Corporation','STC.V',
'Santacruz Silv','SCZ.V',
'Saturn Oil','SOIL.V',
'Search Minerals','SMY.V',
'Serengeti Resources Inc.','NWST.V',
'Sernova Corp','SVA.V',
'ShaMaran Petroleum','SNM.V',
'Sigma Lithium Resources','SGMA.V',
'Signature Resources','SGU.V',
'Silver One Resources','SVE.V',
'Silver Tiger Metals Inc','SLVR.V',
'Silver Viper Minerals','VIPR.V',
'Sintana Energy','SEI.V',
'Sirona Biochem Corp','SBM.V',
'Skyharbour Resources','SYH.V',
'Sokoman Minerals','SIC.V',
'Solar Alliance Energy','SOLR.V',
'Southern Silver Exploration','SSV.V',
'Spanish Mountain Gold','SPA.V',
'Spartan Delta Corp','SDE.V',
'SQI Diagnostics','SQD.V',
'SRG Graphite','SRG.V',
'Standard Lithium','SLL.V',
'Starr Peak Exploration','STE.V',
'Storage Vault Canada','SVI.V',
'Strategic Metals','SMD.V',
'Stratton Resources','TORQ.V',
'Strikepoint Gold','SKP.V',
'SugarBud Craft','SUGR.V',
'Superior Gold','SGI.V',
'Surge Copper','SURG.V',
'Telo Genomics','TELO.V',
'Telson Mining','TSN.V',
'Terra Firma Capital Corporation','TII.V',
'Teuton Resources Corp.','TUO.V',
'Theralase Technologies','TLT.V',
'Therma Bright','THRM.V',
'Thermal Energy International Inc.','TMG.V',
'Thor Explorations','THX.V',
'Thunderbird Entertainment','TBRD.V',
'Tinka Resources','TK.V',
'Titanium Inc.','TIC.V',
'Titanium Transportation','TTR.V',
'Tristar Gold','TSG.V',
'Tsodilo Resources','TSD.V',
'Tudor Gold Corp','TUD.V',
'Turmalina Metals','TBX.V',
'TVI Pacific','TVI.V',
'Ucore Rare Metals','UCU.V',
'UGE International','UGE.V',
'Unigold Inc.','UGD.V',
'Universal mCloud','MCLD.V',
'Uranium Royalty','URC.V',
'ValOre Metals','VO.V',
'Vangold Mining','VGLD.V',
'Vanstar Mining Resources Inc','VSR.V',
'Vela Minerals','ABR.V',
'VentriPoint Diagnostics','VPT.V',
'Venzee Tech','VENZ.V',
'Vicinity Motor','VMC.V',
'Viridium Pacific','EXP.V',
'Viscount Mining Corp.','VML.V',
'Vision Lithium','VLI.V',
'Vitalhub','VHI.V',
'Vizsla','VZLA.V',
'VR Resources','VRR.V',
'VVC Exploration Corp.','VVC.V',
'Wealth Minerals','WML.V',
'WeedMD','WMD.V',
'West Vault','WVM.V',
'Westaim','WED.V',
'Westhaven Ventures','WHN.V',
'White Gold','WGO.V',
'Wilton Resources Inc','WIL.V',
'Windfall Geotek Inc','WIN.V',
'Wolfden Resources Corporation','WLF.V',
'Ynvisible Interactive','YNV.V',
'ZEN Graphene','ZEN.V',
'Zoomd Technologies','ZOMD.V'],

TSX_MIDCAPS_STOCKS:[1,
  1,
  1,
  1,
  '$',
  'TSX MidCap','^SPTSEM', 
  'AcuityAds Holdings Inc','AT.TO',
'Aecon Group Inc.','ARE.TO',
'Air Canada','AC.TO',
'Alamos Gold','AGI.TO',
'Allied Properties','AP-UN.TO',
'AltaGas','ALA.TO',
'Altus Group','AIF.TO',
'ARC Resources','ARX.TO',
'Aritzia','ATZ.TO',
'Artis REIT','AX-UN.TO',
'Atco','ACO-X.TO',
'ATS Automation Tooling','ATA.TO',
//'Aurinia Pharma','AUP.TO',
'Aurora Cannabis','ACB.TO',
'B2Gold','BTO.TO',
'Badger Infrastructure Solutions','BDGI.TO',
'Ballard','BLDP.TO',
'BlackBerry','BB.TO',
'Boardwalk REIT','BEI-UN.TO',
'Boralex','BLX.TO',
'Boyd Group IF','BYD.TO',
'Brookfield Business','BBU-UN.TO',
'Brookfield Renewable','BEP-UN.TO',
'BRP Inc','DOO.TO',
'CAE Inc.','CAE.TO',
'Canaccord Genuity','CFT.TO',
'Canada Goose','GOOS.TO',
'Canadian Utilities','CU.TO',
'Canadian Western Bank','CWB.TO',
'Canfor Corporation','CFP.TO',
'Capital Power','CPX.TO',
'Cargojet','CJT.TO',
'Cascades Inc.','CAS.TO',
'Celestica Inc.','CLS.TO',
'Centerra Gold','CG.TO',
'Chartwell Retirement Residences','CSH-UN.TO',
'Choice Properties REIT','CHP-UN.TO',
'CI Financial Corp','CIX.TO',
'Cogeco Communications','CCA.TO',
'Colliers International','CIGI.TO',
//'Cominar Real Estate Invest','CUF-UN.TO',
'Corus Entertainment','CJR-B.TO',
'Crescent Point Energy','CPG.TO',
'Crombie REIT','CRR-UN.TO',
'Cronos','CRON.TO',
'CT Real Estate','CRT-UN.TO',
'Denison Mines','DML.TO',
'Descartes Systems','DSG.TO',
'Dream Industrial REIT','DIR-UN.TO',
'Dream Office REIT','D-UN.TO',
'Dundee Precious Metals','DPM.TO',
'Dye & Durham','DND.TO',
'ECN Capital','ECN.TO',
'Eldorado Gold','ELD.TO',
'Element Fleet','EFN.TO',
'Empire Company','EMP-A.TO',
'Endeavour Mining','EDV.TO',
'Endeavour Silver','EDR.TO',
'Enerplus','ERF.TO',
'Enghouse Systems','ENGH.TO',
'Equinox Gold','EQX.TO',
'Equitable Group Inc.','EQB.TO',
'Ero Copper','ERO.TO',
'Exchange Income','EIF.TO',
'Fairfax Financial','FFH.TO',
'Finning International','FTT.TO',
'First Capital Realty','FCR-UN.TO',
'First Majestic Silver','FR.TO',
'FirstService','FSV.TO',
'Fortuna Silver','FVI.TO',
'Gfl Environmental','GFL.TO',
'Gibson Energy Inc.','GEI.TO',
'goeasy','GSY.TO',
'Granite REIT','GRT-UN.TO',
//'Great Canadian Gaming','GC.TO',
'Great-West Lifeco','GWO.TO',
'H&R Real Estate','HR-UN.TO',
'Home Capital Group','HCG.TO',
'HudBay Minerals','HBM.TO',
'Hydro One Limited','H.TO',
'iA Financial','IAG.TO',
'IAMGold','IMG.TO',
'IGM Financial Inc.','IGM.TO',
'Innergex Renewable Energy','INE.TO',
'Intact Financial','IFC.TO',
'Interfor Corp','IFP.TO',
'InterRent REIT','IIP-UN.TO',
'Intertape Polymer','ITP.TO',
'Ivanhoe Mines','IVN.TO',
'Jamieson Wellness','JWEL.TO',
'Keyera Corp.','KEY.TO',
'Killam Properties','KMP-UN.TO',
'Kinaxis Inc','KXS.TO',
'Labrador Iron Ore Royalty','LIF.TO',
'Laurentian Bank Of Canada','LB.TO',
'LifeWorks','LWRK.TO',
'Lightspeed POS','LSPD.TO',
'Linamar','LNR.TO',
'Lithium Americas','LAC.TO',
'Lundin Mining','LUN.TO',
'MAG Silver','MAG.TO',
'Maple Leaf Foods','MFI.TO',
'Martinrea','MRE.TO',
'MEG Energy Corp','MEG.TO',
'Methanex','MX.TO',
'Mullen Group','MTL.TO',
'New Gold','NGD.TO',
'NexGen Energy','NXE.TO',
'NFI Group','NFI.TO',
'North West','NWC.TO',
'Northland Power','NPI.TO',
'Northwest Healthcare','NWH-UN.TO',
'Novagold','NG.TO',
'OceanaGold','OGC.TO',
'Onex Corp','ONEX.TO',
'OrganiGram Holdings Inc','OGI.TO',
'Osisko Gold Ro','OR.TO',
'Osisko Mining','OSK.TO',
'Pan American Silver','PAAS.TO',
'Parex Resources','PXT.TO',
'Parkland Fuel','PKI.TO',
'PrairieSky Royalty','PSK.TO',
'Premium Brands','PBH.TO',
//'Pretium Resources','PVG.TO',
'Primo Water','PRMW.TO',
'Quebecor B','QBR-B.TO',
'Real Matters','REAL.TO',
'Richelieu Hardware','RCH.TO',
'Riocan REIT','REI-UN.TO',
'Ritchie Bros Auctioneers','RBA.TO',
'Russel Metals Inc.','RUS.TO',
'Sandstorm Gold Ltd N','SSL.TO',
'Seabridge Gold','SEA.TO',
'Sienna Senior Living','SIA.TO',
'Silvercorp Metals','SVM.TO',
'SilverCrest Metals','SIL.TO',
'Sleep Country Canada','ZZZ.TO',
'Smart REIT','SRU-UN.TO',
'Spin Master Corp','TOY.TO',
'Sprott Inc.','SII.TO',
'SSR Mining','SSRM.TO',
'Stantec','STN.TO',
'Stella-Jones Inc.','SJ.TO',
'Summit Industrial Income REIT','SMU-UN.TO',
'SunOpta Inc.','SOY.TO',
'Superior Plus Corp','SPB.TO',
'TFI Intl','TFII.TO',
'Tilray','TLRY.TO',
'TMX Group','X.TO',
'Torex Gold','TXG.TO',
'Toromont Industries','TIH.TO',
'Tourmaline Oil','TOU.TO',
'TransAlta Corp','TA.TO',
'TransAlta Renewables Inc.','RNW.TO',
'Transcontinental','TCL-A.TO',
'Tricon Capital Group Inc','TCN.TO',
//'Trillium Therapeutics','TRIL.TO',
'Turquoise Hill Resources','TRQ.TO',
'Vermilion Energy','VET.TO',
//'Village Farms International Inc','VFF.TO',
'Wesdome Gold Mines','WDO.TO',
'West Fraser Timber','WFG.TO',
'Westport Fuel','WPRT.TO',
'Westshore Terminals','WTE.TO',
'Whitecap Resources','WCP.TO',
'Winpak','WPK.TO',
'WPT Industrial RE','WIR-UN.TO',
'WSP Global Inc','WSP.TO',
'Yamana Gold','YRI.TO'],
  
TSX_COMPOSITE_STOCKS:[1,
  1,
  1,
  1,
  '$',
  'TSX Composite','^GSPTSE', 
  'AcuityAds Holdings Inc','AT.TO',
'Aecon Group Inc.','ARE.TO',
'Agnico Eagle Mines','AEM.TO',
'Air Canada','AC.TO',
'Alamos Gold','AGI.TO',
'Algonquin Power','AQN.TO',
'Alimentation Couche-Tard','ATD.TO',
'Allied Properties','AP-UN.TO',
'AltaGas','ALA.TO',
'Altus Group','AIF.TO',
'ARC Resources','ARX.TO',
'Aritzia','ATZ.TO',
'Artis REIT','AX-UN.TO',
'Atco','ACO-X.TO',
'ATS Automation Tooling','ATA.TO',
//'Aurinia Pharma','AUP.TO',
'Aurora Cannabis','ACB.TO',
'B2Gold','BTO.TO',
'Badger Infrastructure Solutions','BDGI.TO',
'Ballard','BLDP.TO',
'Bank of Montreal','BMO.TO',
'Bank of Nova Scotia','BNS.TO',
'Barrick Gold','ABX.TO',
'Bausch Health','BHC.TO',
'BCE Inc','BCE.TO',
'BlackBerry','BB.TO',
'Boardwalk REIT','BEI-UN.TO',
'Boralex','BLX.TO',
'Boyd Group IF','BYD.TO',
'Brookfield Asset Management','BAM-A.TO',
'Brookfield Business','BBU-UN.TO',
'Brookfield Infrastructure Partners','BIP-UN.TO',
'Brookfield Property','BPYP-PA.TO',
'Brookfield Renewable','BEP-UN.TO',
'BRP Inc','DOO.TO',
'CAE Inc.','CAE.TO',
'Cameco','CCO.TO',
'Canaccord Genuity','CF.TO',
'Canada Goose','GOOS.TO',
'Canadian Apartment Properties','CAR-UN.TO',
'Canadian Imperial Bank','CM.TO',
'Canadian National Railway','CNR.TO',
'Canadian Natural','CNQ.TO',
'Canadian Pacific Railway','CP.TO',
'Canadian Tire Ltd','CTC-A.TO',
'Canadian Utilities','CU.TO',
'Canadian Western Bank','CWB.TO',
'Canfor Corporation','CFP.TO',
'Canopy Growth','WEED.TO',
'Capital Power','CPX.TO',
'Cargojet','CJT.TO',
'Cascades Inc.','CAS.TO',
'CCL Industries Inc','CCL-B.TO',
'Celestica Inc.','CLS.TO',
'Cenovus Energy Inc','CVE.TO',
'Centerra Gold','CG.TO',
'CGI Inc','GIB-A.TO',
'Chartwell Retirement Residences','CSH-UN.TO',
'Choice Properties REIT','CHP-UN.TO',
'CI Financial Corp','CIX.TO',
'Cogeco Communications','CCA.TO',
'Colliers International','CIGI.TO',
//'Cominar Real Estate Invest','CUF-UN.TO',
'Constellation Software','CSU.TO',
'Corus Entertainment','CJR-B.TO',
'Crescent Point Energy','CPG.TO',
'Crombie REIT','CRR-UN.TO',
'Cronos','CRON.TO',
'CT Real Estate','CRT-UN.TO',
'Denison Mines','DML.TO',
'Descartes Systems','DSG.TO',
'Dollarama','DOL.TO',
'Dream Industrial REIT','DIR-UN.TO',
'Dream Office REIT','D-UN.TO',
'Dundee Precious Metals','DPM.TO',
'Dye & Durham','DND.TO',
'ECN Capital','ECN.TO',
'Eldorado Gold','ELD.TO',
'Element Fleet','EFN.TO',
'Emera Incorporated','EMA.TO',
'Empire Company','EMP-A.TO',
'Enbridge','ENB.TO',
'Endeavour Mining','EDV.TO',
'Endeavour Silver','EDR.TO',
'Enerplus','ERF.TO',
'Enghouse Systems','ENGH.TO',
'Equinox Gold','EQX.TO',
'Equitable Group Inc.','EQB.TO',
'Ero Copper','ERO.TO',
'EExchange Income','EIF.TO',
'Fairfax Financial','FFH.TO',
'Finning International','FTT.TO',
'First Capital Realty','FCR-UN.TO',
'First Majestic Silver','FR.TO',
'First Quantum Minerals','FM.TO',
'FirstService','FSV.TO',
'Fortis Inc','FTS.TO',
'Fortuna Silver','FVI.TO',
'Franco-Nevada','FNV.TO',
'George Weston','WN.TO',
'Gfl Environmental','GFL.TO',
'Gibson Energy Inc.','GEI.TO',
'Gildan Activewear','GIL.TO',
'Goeasy','GSY.TO',
'Granite REIT','GRT-UN.TO',
//'Great Canadian Gaming','GC.TO',
'Great-West Lifeco','GWO.TO',
'H&R Real Estate','HR-UN.TO',
'Home Capital Group','HCG.TO',
'HudBay Minerals','HBM.TO',
'Hydro One Limited','H.TO',
'iA Financial','IAG.TO',
'IAMGold','IMG.TO',
'IGM Financial Inc.','IGM.TO',
'Imperial Oil','IMO.TO',
'Innergex Renewable Energy','INE.TO',
'Intact Financial','IFC.TO',
'Inter Pipeline','IPL.TO',
'IInterfor Corp','IFP.TO',
'InterRent REIT','IIP-UN.TO',
'Intertape Polymer','ITP.TO',
'Ivanhoe Mines','IVN.TO',
'Jamieson Wellness','JWEL.TO',
'Keyera Corp.','KEY.TO',
'Killam Properties','KMP-UN.TO',
'Kinaxis Inc','KXS.TO',
'Kinross Gold','K.TO',
//'Kirkland Lake Gold','KL.TO',
'Labrador Iron Ore Royalty','LIF.TO',
'Laurentian Bank Of Canada','LB.TO',
'LifeWorks','LWRK.TO',
'Lightspeed POS','LSPD.TO',
'Linamar','LNR.TO',
'Lithium Americas','LAC.TO',
'Loblaw Companies','L.TO',
'Lundin Mining','LUN.TO',
'MAG Silver','MAG.TO',
'Magna Intl','MG.TO',
'Manulife Financial','MFC.TO',
'Maple Leaf Foods','MFI.TO',
'Martinrea','MRE.TO',
'MEG Energy Corp','MEG.TO',
'Methanex','MX.TO',
'Metro Inc.','MRU.TO',
'Mullen Group','MTL.TO',
'National Bank of Canada','NA.TO',
'New Gold','NGD.TO',
'NexGen Energy','NXE.TO',
'NFI Group','NFI.TO',
'North West','NWC.TO',
'Northland Power','NPI.TO',
'Northwest Healthcare','NWH-UN.TO',
'Novagold','NG.TO',
'Nutrien','NTR.TO',
'OceanaGold','OGC.TO',
'Onex Corp','ONEX.TO',
'Open Text','OTEX.TO',
'OrganiGram Holdings Inc','OGI.TO',
'Osisko Gold Ro','OR.TO',
'Osisko Mining','OSK.TO',
'Pan American Silver','PAAS.TO',
'Parex Resources','PXT.TO',
'Parkland Fuel','PKI.TO',
'Pembina Pipeline','PPL.TO',
'Power Corporation','POW.TO',
'PrairieSky Royalty','PSK.TO',
'Premium Brands','PBH.TO',
//'Pretium Resources','PVG.TO',
'Primo Water','PRMW.TO',
'Quebecor B','QBR-B.TO',
'RBC','RY.TO',
'Real Matters','REAL.TO',
'Restaurant Brands Int','QSR.TO',
'Richelieu Hardware','RCH.TO',
'Riocan REIT','REI-UN.TO',
'Ritchie Bros Auctioneers','RBA.TO',
'Rogers Communications','RCI-B.TO',
'Russel Metals Inc.','RUS.TO',
'Sandstorm Gold Ltd N','SSL.TO',
'Saputo','SAP.TO',
'Seabridge Gold','SEA.TO',
'Shaw B','SJR-B.TO',
'Shopify Inc','SHOP.TO',
'Sienna Senior Living','SIA.TO',
'Silvercorp Metals','SVM.TO',
'SilverCrest Metals','SIL.TO',
'Sleep Country Canada','ZZZ.TO',
'Smart REIT','SRU-UN.TO',
'SNC-Lavalin Group','SNC.TO',
'Spin Master Corp','TOY.TO',
'Sprott Inc.','SII.TO',
'SSR Mining','SSRM.TO',
'Stantec','STN.TO',
'Stella-Jones Inc.','SJ.TO',
'Summit Industrial Income REIT','SMU-UN.TO',
'Sun Life Financial','SLF.TO',
'Suncor Energy','SU.TO',
'SunOpta Inc.','SOY.TO',
'Superior Plus Corp','SPB.TO',
'TC Energy','TRP.TO',
'Teck Resources B','TECK-B.TO',
'TELUS','T.TO',
'TFI Intl','TFII.TO',
'Thomson Reuters','TRI.TO',
'Tilray','TLRY.TO',
'TMX Group','X.TO',
'Torex Gold','TXG.TO',
'Toromont Industries','TIH.TO',
'Toronto Dominion Bank','TD.TO',
'Tourmaline Oil','TOU.TO',
'TransAlta Corp','TA.TO',
'TransAlta Renewables Inc.','RNW.TO',
'Transcontinental','TCL-A.TO',
'Tricon Capital Group Inc','TCN.TO',
//'Trillium Therapeutics','TRIL.TO',
'Turquoise Hill Resources','TRQ.TO',
'Vermilion Energy','VET.TO',
//'Village Farms International Inc','VFF.TO',
'Waste Connections','WCN.TO',
'Wesdome Gold Mines','WDO.TO',
'West Fraser Timber','WFG.TO',
'Westport Fuel','WPRT.TO',
'Westshore Terminals','WTE.TO',
'Wheaton Precious Metals','WPM.TO',
'Whitecap Resources','WCP.TO',
'Winpak','WPK.TO',
//'WPT Industrial RE','WIR-UN.TO',
'WSP Global Inc','WSP.TO',
 'Yamana Gold','YRI.TO'],

TSX_SMALLCAPS_STOCKS:[1,
  1,
  1,
  1,
  '$',
  'TSX SmallCap','^SPTSES',  
'5N Plus Inc.','VNP.TO',
'A&W Revenue Royalties Income Fund','AW-UN.TO',
'Absolute Software','ABST.TO',
'Acadian Timber Corp','ADN.TO',
'Advantage Oil&Gas','AAV.TO',
'Aecon Group Inc.','ARE.TO',
'Aeterna Zentaris','AEZS.TO',
'Ag Growth Int','AFN.TO',
'AGF Management','AGF-B.TO',
'Aimia Inc','AIM.TO',
'AirBoss of America Corp.','BOS.TO',
'Alaris Royalty','AD-UN.TO',
'Alcanna','CLIQ.TO',
'Alexco Resource','AXU.TO',
'Alithya A','ALYA.TO',
'Altius Minerals','ALS.TO',
'American Hotel Income Prop','HOT-UN.TO',
'Americas Silver','USA.TO',
'Andlauer','AND.TO',
'Andrew Peller A','ADW-A.TO',
'Apollo Healthcare','AHC.TO',
'Aptose Biosciences','APS.TO',
'Argonaut Gold Inc','AR.TO',
'Artis REIT','AX-UN.TO',
'Atrium Mortgage Investment','AI.TO',
'Autocanada Inc','ACQ.TO',
'Automotive Properties RE','APR-UN.TO',
'Avino Silver Gold','ASM.TO',
'Aya Gold & Silver','AYA.TO',
'Badger Infrastructure Solutions','BDGI.TO',
'BELLUS Health Inc.','BLU.TO',
'Birchcliff Energy','BIR.TO',
'Bird Construction','BDT.TO',
'Boston Pizza Royalties','BPF-UN.TO',
'Brookfield Real Estate','BRE.TO',
'BTB REIT','BTB-UN.TO',
'Burcon NutraScience','BU.TO',
'Calian Technologies','CGY.TO',
'Calibre Mining','CXB.TO',
'Canaccord Genuity','CF.TO',
'Canacol Energy','CNE.TO',
'Canfor Pulp Products','CFX.TO',
'Canopy Rivers','WEED.TO',
'Capstone Mining','CS.TO',
'Cara Operations','RECP.TO',
'Cardinal Energy','CJ.TO',
'Cardiol Therapeutics','CRDL.TO',
'Cascades Inc.','CAS.TO',
'Celestica Inc.','CLS.TO',
'Cervus Equipment','CERV.TO',
'CES Energy','CEU.TO',
'Charlotte´s Web','CWEB.TO',
'Chemtrade Logistics','CHE-UN.TO',
'Chorus Aviation','CHR.TO',
'Cineplex Inc.','CGX.TO',
'Cogeco Inc.','CGO.TO',
//'Cominar Real Estate Invest','CUF-UN.TO',
'Computer Modelling Group','CMG.TO',
'Copper Mountain Mining','CMMC.TO',
'Corby Spirit and Wine A','CSW-A.TO',
'Corus Entertainment','CJR-B.TO',
'Corvus Gold Inc','KOR.TO',
'Denison Mines','DML.TO',
'Dexterra Group','DXT.TO',
'Dirtt Environmen','DRT.TO',
'Diversified Royalty Corp','DIV.TO',
'Doman Building Materials','DBM.TO',
'Dorel Industries','DII-B.TO',
'Dream Impact Trust','MPCT-UN.TO',
'Dream Office REIT','D-UN.TO',
'Dream Unlimited','DRM.TO',
'Dundee Precious Metals','DPM.TO',
'EcoSynthetix Inc','ECO.TO',
'Electrovaya Inc.','EFL.TO',
'Endeavour Silver','EDR.TO',
'Enerflex','EFX.TO',
'Energy Fuels Inc','EFR.TO',
'Enerplus','ERF.TO',
'Ensign Energy Services','ESI.TO',
'Enthusiast Gaming','EGLX.TO',
'European Commercial','ERE-UN.TO',
'Evertz Technologies','ET.TO',
'Excellon Resources Inc.','EXN.TO',
'Exchange Income','EIF.TO',
'Exco Technologies','XTC.TO',
'Extendicare Inc','EXE.TO',
'Fiera Capital Corporation','FSZ.TO',
'Fire Flower','FAF.TO',
'Firm Capital Mortgage Invest Corp','FC.TO',
'Freehold Royalties','FRU.TO',
'Frontera Energy','FEC.TO',
'Fury Gold Mines','FURY.TO',
'Galiano Gold','GAU.TO',
'GDI Integrated','GDI.TO',
'Global Atomic','GLO.TO',
'GoGold Resources Inc.','GGD.TO',
'Golden Star','GSC.TO',
'GoldMining','GOLD.TO',
'GoldMoney','XAU.TO',
'Goodfood Market','FOOD.TO',
'Gran Colombia Gold','GCM.TO',
'Great Panther Mining','GPR.TO',
'Greenbrook TMS','GTMS.TO',
'Hardwoods Distribution','HDI.TO',
'Headwater Exploration Inc','HWX.TO',
'Heroux-Devtek Inc.','HRX.TO',
'Hexo','HEXO.TO',
'High Liner Foods','HLF.TO',
'HLS Therapeutics','HLS.TO',
'Home Capital Group','HCG.TO',
'Hut 8 Mining','HUT.TO',
'IBI Group Inc.','IBG.TO',
'Imv Inc','IMV.TO',
'Inovalis REIT','INO-UN.TO',
'Intertape Polymer','ITP.TO',
'Jaguar Mining','JAG.TO',
'Jamieson Wellness','JWEL.TO',
'Karora Resources Inc','KRR.TO',
'K-Bro Linen Inc.','KBL.TO',
'Kelt Exploration','KEL.TO',
'Knight Therapeutics Inc','GUD.TO',
'KP Tissue Inc','KPT.TO',
'Largo Resources','LGO.TO',
'Laurentian Bank Of Canada','LB.TO',
'Liberty Gold','LGD.TO',
'Magellan Aerospace Corporation','MAL.TO',
'Major Drilling','MDI.TO',
'Marathon Gold Corporation','MOZ.TO',
'Martinrea','MRE.TO',
'Maverix Metals','MMX.TO',
'Mediagrif Interactive Technologies','MDF.TO',
'Medical Facilities','DR.TO',
'Medicenna','MDNA.TO',
'Midas Gold Corp.','PPTA.TO',
'Minto Apartment REIT','MI-UN.TO',
'Mogo','MOGO.TO',
'Morguard Corporation','MRC.TO',
'Morguard North American Residential','MRG-UN.TO',
'Morguard REIT','MRT-UN.TO',
'MTY Food','MTY.TO',
'Mullen Group','MTL.TO',
'Neo Performance','NEO.TO',
'Neovasc Inc','NVCN.TO',
'Neptune Wellness Solutions','NEPT.TO',
'New Gold','NGD.TO',
'New Pacific','NUAG.TO',
'North American Construction','NOA.TO',
'North West','NWC.TO',
'NuVista Energy','NVA.TO',
'Obsidian Energy','OBE.TO',
'OceanaGold','OGC.TO',
'Oncolytics Biotech','ONC.TO',
'OrganiGram Holdings Inc','OGI.TO',
'Orla Mining','OLA.TO',
'Osisko Mining','OSK.TO',
'Paramount Resources','POU.TO',
'Park Lawn','PLC.TO',
'Pason Systems Inc.','PSI.TO',
'Peyto Exploration&Develop','PEY.TO',
'Photon Control','PHO.TO',
'PHX Energy Services','PHX.TO',
'Pizza Pizza Royalty Corp.','PZA.TO',
'Platinum Group Metals','PTM.TO',
'Plaza Retail REIT','PLZ-UN.TO',
'Polaris Infrastructure','PIF.TO',
'Pollard Banknote','PBL.TO',
'Precision Drilling','PD.TO',
'PRO Real Estate','PRV-UN.TO',
'Profound Medical','PRN.TO',
'Quarterhill','QTRH.TO',
'Real Matters','REAL.TO',
'RF Capital Group','RCG.TO',
'Richards Packaging Income Fund','RPI-UN.TO',
'Rogers Sugar Inc.','RSI.TO',
//'Roxgold','ROXG.TO',
'Russel Metals Inc.','RUS.TO',
'Sabina Gold&Silver','SBB.TO',
'Savaria Corporation','SIS.TO',
'Seabridge Gold','SEA.TO',
'Secure Energy Svcs','SES.TO',
'ShawCor','SCL.TO',
'Sienna Senior Living','SIA.TO',
'Sierra Metals Inc.','SMT.TO',
'Sierra Wireless','SW.TO',
'SilverCrest Metals','SIL.TO',
'Skeena Resources','SKE.TO',
'Slate Grocery REIT','SGR-UN.TO',
'Slate Office','SOT-UN.TO',
'Sleep Country Canada','ZZZ.TO',
'Sprott Inc.','SII.TO',
'Steppe Gold','STGO.TO',
'Stingray','RAY-A.TO',
'Storm Resources','SRX.TO',
'Tamarack Valley Energy','TVE.TO',
'Taseko Mines','TKO.TO',
'TECSYS Inc.','TCS.TO',
//'Tervita','TEV.TO',
'Theratechnologies','TH.TO',
'Timbercreek Financial','TF.TO',
'Titan Medical','TMD.TO',
'Torex Gold','TXG.TO',
'Total Energy Svcs','TOT.TO',
'Touchstone Exploration','TXP.TO',
'Transat AT Inc','TRZ.TO',
'TransGlobe Energy','TGL.TO',
'Trican Well Service','TCW.TO',
//'Trillium Therapeutics','TRIL.TO',
'Trisura','TSU.TO',
'Troilus Gold','TLG.TO',
'True North Commercial REIT','TNT-UN.TO',
'TWC Enterprises','TWC.TO',
'Uni-Select Inc.','UNS.TO',
'Ur Energy','URE.TO',
//'Uranium Participationoration','U.TO',
'Valens Company','VLNS.TO',
'Vermilion Energy','VET.TO',
'VersaBank','VB.TO',
'Victoria Gold Corp','VGCX.TO',
'Viemed Healthcare','VMD.TO',
//'Village Farms International Inc','VFF.TO',
'Wajax Corporation','WJX.TO',
'WELL Health','WELL.TO',
'Western Copper Gold','WRN.TO',
'Western Forest Products Inc','WEF.TO',
'Westport Fuel','WPRT.TO',
'Westshore Terminals','WTE.TO',
'WildBrain','WILD.TO'],
//'WPT Industrial RE','WIR-UN.TO'],

TSX60_STOCKS:[1,
  1,
  1,
  1,
  '$',
  'TSX 60','TX60.TS',
  //'Barrick Gold Corp','ABX.TO',
  'Agnico Eagle Mines','AEM.TO',
  'Algonquin Power','AQN.TO',
  'Alimentation Couche-Tard','ATD.TO',
  'Bank of Montreal','BMO.TO',
  'Bank of Nova Scotia','BNS.TO',
  'Barrick Gold','ABX.TO',
  'Bausch Health','BHC.TO',
  'BCE Inc','BCE.TO',
  'Brookfield Asset Manage.','BAM-A.TO',
  'Brookfield Infrastructure','BIP-UN.TO',
  'Brookfield Property','BPYP-PA.TO',
  'Cameco','CCO.TO',
  'Canadian Apartment Prop.','CAR-UN.TO',
  'Canadian Imperial Bank','CM.TO',
  'Canadian National Railway','CNR.TO',
  'Canadian Natural','CNQ.TO',
  'Canadian Pacific Railway','CP.TO',
  'Canadian Tire Ltd','CTC-A.TO',
  'Canopy Growth','WEED.TO',
  'CCL Industries Inc','CCL-B.TO',
  'Cenovus Energy Inc','CVE.TO',
  'CGI Inc','GIB-A.TO',
  'Constellation Software','CSU.TO',
  'Dollarama','DOL.TO',
  'Emera Incorporated','EMA.TO',
  'Enbridge','ENB.TO',
  'First Quantum Minerals','FM.TO',
  'Fortis Inc','FTS.TO',
  'Franco-Nevada','FNV.TO',
  'George Weston','WN.TO',
  'Gildan Activewear','GIL.TO',
  'Imperial Oil','IMO.TO',
  'Inter Pipeline','IPL.TO',
  'Kinross Gold','K.TO',
  //'Kirkland Lake Gold','KL.TO',
  'Loblaw Companies','L.TO',
  'Magna Intl','MG.TO',
  'Manulife Financial','MFC.TO',
  'Metro Inc.','MRU.TO',
  'National Bank of Canada','NA.TO',
  'Nutrien','NTR.TO',
  'Open Text','OTEX.TO',
  'Pembina Pipeline','PPL.TO',
  'Power Corporation','POW.TO',
  'RBC','RY.TO',
  'Restaurant Brands Int','QSR.TO',
  'Rogers Communications','RCI-B.TO',
  'Saputo','SAP.TO',
  'Shaw B','SJR-B.TO',
  'Shopify Inc','SHOP.TO',
  'SNC-Lavalin Group','SNC.TO',
  'Sun Life Financial','SLF.TO',
  'Suncor Energy','SU.TO',
  'TC Energy','TRP.TO',
  'Teck Resources B','TECK-B.TO',
  'TELUS','T.TO',
  'Thomson Reuters','TRI.TO',
  'Toronto Dominion Bank','TD.TO',
  'Waste Connections','WCN.TO',
  'Wheaton Precious Metals','WPM.TO'],

BEL20_STOCKS:[1,
  1,
  1,
  1,
  '€',
  'BEL 20','^BFX',
  'Ackermans','ACKB.BR',
'Aedifica','AED.BR',
'Ageas','AGS.BR',
'Anheuser Busch Inbev','ABI.BR',
'Aperam','APAM.AS',
'Argen-X','ARGX.BR',
'Cofinimmo','COFB.BR',
'Elia','ELI.BR',
'Etablissementen Franz Colruyt','COLR.BR',
'Galapagos','GLPG.AS',
'GBL','GBLB.BR',
'KBC Groep','KBC.BR',
'Melexis NV','MELE.BR',
'Proximus','PROX.BR',
'Sofina','SOF.BR',
'Solvay','SOLB.BR',
'Telenet','TNET.BR',
'UCB','UCB.BR',
'Umicore','UMI.BR',
'Warehouses de Pauw','WDP.BR'],

ASX20_STOCKS:[1,
  1,
  1,
  1,
  '$',
  //'Afterpay Touch','APT.AX',
  //'AMP Limited','AMP.AX',
'ANZ Banking Group','ANZ.AX',
'Aristocrat Leisure','ALL.AX',
'BHP Billiton Ltd','BHP.AX',
//'Brambles','BXB.AX',
'Coles Group','COL.AX',
'Commonwealth Bank Australia','CBA.AX',
'CSL','CSL.AX',
'Fortescue Metals','FMG.AX',
'Goodman Group','GMG.AX',
'Macquarie','MQG.AX',
'National Australia Bank','NAB.AX',
'Newcrest Mining','NCM.AX',
'Rio Tinto Ltd','RIO.AX',
'Santos Ltd','STO.AX',
'SOUTH32', 'S32.AX',
'Telstra Corporation.','TLS.AX',
'Transurban Group','TCL.AX',
'Wesfarmers','WES.AX',
'Westpac Banking','WBC.AX',
'Woodside Petroleum','WDS.AX',
'Woolworths','WOW.AX'],


ASX100_STOCKS:[1,
  1,
  1,
  1,
  '$',
  'AMP','AMP.AX',
  'A2 Milk','A2M.AX',
  //'Afterpay Touch','APT.AX',
  'AGL Energy','AGL.AX',
  'ALS','ALQ.AX',
  'Altium','ALU.AX',
  'Alumina','AWC.AX',
  'Amcor','AMC.AX',
  'Ampol','ALD.AX',
  'Ansell','ANN.AX',
  'ANZ Banking Group','ANZ.AX',
  'APA Group','APA.AX',
  'Appen Ltd','APX.AX',
  'Aristocrat Leisure','ALL.AX',
  'ASX','ASX.AX',
  'Atlas Arteria','ALX.AX',
  'Aurizon Holdings','AZJ.AX',
  //'AusNet Services','AST.AX',
  'Bank Of Queensland.','BOQ.AX',
  'Beach Energy','BPT.AX',
  'Bendigo & Adel Bank','BEN.AX',
  'BHP Billiton Ltd','BHP.AX',
  'Bluescope Steel','BSL.AX',
  'Boral','BLD.AX',
  'Brambles','BXB.AX',
  'Carsales.Com','CAR.AX',
  'Challenger','CGF.AX',
  'Charter Hall Edu','CQE.AX',
  'Cleanaway Waste','CWY.AX',
  //'Coca-Cola Amatil','CCL.AX',
  'Cochlear','COH.AX',
  'Coles Group','COL.AX',
  'Commonwealth Bank','CBA.AX',
  'Computershare','CPU.AX',
  'Crown','CWN.AX',
  'CSL','CSL.AX',
  'Dexus Property','DXS.AX',
  'Domino´s Pizza','DMP.AX',
  'Downer Edi','DOW.AX',
  'Evolution Mining','EVN.AX',
  'Fisher&Paykel Health','FPH.AX',
  'Fortescue Metals','FMG.AX',
  'Goodman Group','GMG.AX',
  'GPT Group','GPT.AX',
  'Idp Education Ltd','IEL.AX',
  'IGO Ltd','IGO.AX',
  'Incitec Pivot','IPL.AX',
  'Insurance Australia','IAG.AX',
  'James Hardie Indust','JHX.AX',
  'JB Hi-Fi','JBH.AX',
  'Lend Lease Group','LLC.AX',
  'Link Administration','LNK.AX',
  'Macquarie','MQG.AX',
  'Magellan Financial','MFG.AX',
  'Medibank Private Ltd','MPL.AX',
  'Mineral Resources','MIN.AX',
  'Mirvac Group','MGR.AX',
  'National Austr Bank','NAB.AX',
  'Newcrest Mining','NCM.AX',
  'Nextdc','NXT.AX',
  'Nine Entertainment','NEC.AX',
  'Northern Star Res','NST.AX',
  //'Oil Search','OSH.AX',
  'Orica','ORI.AX',
  'Origin Energy','ORG.AX',
  'Orora','ORA.AX',
  'OZ Minerals','OZL.AX',
  'Qantas Airways','QAN.AX',
  'Qbe Insurance Grp','QBE.AX',
  'Qube','QUB.AX',
  'Ramsay Health Care','RHC.AX',
  'Rea Group','REA.AX',
  'Reece','REH.AX',
  'Reliance Worldwide','RWC.AX',
  'Resmed DRC','RMD.AX',
  'Rio Tinto Ltd','RIO.AX',
  'Santos','STO.AX',
  'Scentre','SCG.AX',
  'Seek','SEK.AX',
  'Sonic Healthcare','SHL.AX',
  'South32','S32.AX',
  'Spark Infrastr','SKI.AX',
  'Star Entertainment','SGR.AX',
  'Stockland Corp','SGP.AX',
  'Suncorp','SUN.AX',
  //'Sydney Airport Ltd','SYD.AX',
  'Tabcorp Holdings','TAH.AX',
  'Telstra Corporation','TLS.AX',
  'TPG Telecom','TPG.AX',
  'Transurban Group','TCL.AX',
  'Treasury Wine Estates','TWE.AX',
  'Vicinity Centres','VCX.AX',
  'Washington H Soul','SOL.AX',
  'Wesfarmers','WES.AX',
  'Westpac Banking','WBC.AX',
  'Wisetech Global','WTC.AX',
  'Woodside Petroleum','WDS.AX',
  'Woolworths','WOW.AX',
  'Worley Ltd','WOR.AX',
  'Xero','XRO.AX'],

  ASX_ALLORD_STOCKS:[1,
    1,
    1,
    1,
    '$',
  'LIFE360 Inc','360.AX',
	'Pointerra Ltd','3DP.AX',
	'Betashares Aust 200 ETF','A200.AX',
	'The a2 Milk Company','A2M.AX',
	'Alpha Hpa Ltd','A4N.AX',  
	'Betashares Aus High Interest ETF','AAA.AX',
	'Australian Agricultural','AAC.AX',
	'Aussie Broadband Ltd','ABB.AX',
	'Adbri Ltd','ABC.AX',
	'Abacus Property Group','ABP.AX',
	'American Pacific Borates','ABR.AX',
	'Adore Beauty Group Ltd','ABY.AX',
	'Audinate Group Ltd','AD8.AX',
	'Adairs Ltd','ADH.AX',
	//'Apn Industria REIT','ADI.AX',
	'Andromeda Metals Ltd','ADN.AX',
	'Anteotech Ltd','ADO.AX',
	'Adriatic Metals Plc','ADT.AX',
	'Australian Ethical Invest','AEF.AX',
	'Australian Finance','AFG.AX',
	'Australian Foundation Invest','AFI.AX',
	'Aft Pharmaceuticals','AFP.AX',
	'Anglogold Ashanti','AGG.AX',
	'AGL Energy','AGL.AX',
	//'Asaleo Care','AHY.AX',
	'Auckland Intern Airport','AIA.AX',
	'Air New Zealand','AIZ.AX',
	'Audio Pixels','AKP.AX',
	'Alcidion Group','ALC.AX',
	'Ampol','ALD.AX',
	'Ardent Leisure','ALG.AX',
	'Alkane Resources','ALK.AX',
	'Aristocrat Leisure','ALL.AX',
	'Als Ltd','ALQ.AX',
	'Altium Ltd','ALU.AX',
	'Atlas Arteria','ALX.AX',
	'AMA Group Ltd','AMA.AX',
	'Amcor Plc','AMC.AX',
	'Aurelia Metals Ltd','AMI.AX',
	'AMP Ltd','AMP.AX',
	'Ansell Ltd', 'ANN.AX',
	'Australia and NZ Banking','ANZ.AX',
	'Australian Unity Office','AOF.AX',
	'APA Group','APA.AX',
	'Eagers Automotive','APE.AX',
	//'Australian Pharma Industries','API.AX',
	'Antipodes Global Investment','APL.AX',
	//'Afterpay','APT.AX',
	'Appen','APX.AX',
	'Apn Convenience Retail','AQR.AX',
	'Alliance Aviation Services','AQZ.AX',
	'ARB Corporation','ARB.AX',
	'Arena REIT','ARF.AX',
	'Argo Investments','ARG.AX',
	'Airtasker','ART.AX',
	'Austal','ASB.AX',
	'Autosports Group','ASG.AX',
	'Betashares Asia Technology Tigers','ASIA.AX',
	'Australian Strategic Materials','ASM.AX',
	//'Ausnet Services','AST.AX',
	'ASX','ASX.AX',
	'AUB Group','AUB.AX',
	'Australian United Investment','AUI.AX',
	'Aventus Group','AVN.AX',
	'AVZ Minerals','AVZ.AX',
	'Alumina','AWC.AX',
	'Accent Group','AX1.AX',
	'Aurizon Holdings','AZJ.AX',
	'Bapcor','BAP.AX',
	'Baby Bunting Group','BBN.AX',
	'Brockman Mining','BCK.AX',
	'Bendigo and Adelaide Bank','BEN.AX',
	'Betmakers Technology Group','BET.AX',
	'Bell Financial Group','BFG.AX',
	'Bega Cheese','BGA.AX',
	'Bellevue Gold','BGL.AX',
	'Briscoe Group Australasia','BGP.AX',
	'BHP Group','BHP.AX',
	'Ishares Core Cash ETF','BILL.AX',
	//'Bingo Industries','BIN.AX',
	'BKI Investment Company','BKI.AX',
	'Blackmores','BKL.AX',
	'Brickworks','BKW.AX',
	'Boral','BLD.AX',
	'Beacon Lighting Group','BLX.AX',
	'Bank of Queensland','BOQ.AX',
	'Beach Energy','BPT.AX',
	'Breville Group','BRG.AX',
	'Brainchip Holdings','BRN.AX', 
	'Bluescope Steel','BSL.AX',
	'Bigtincan Holdings','BTH.AX',
	'Bravura Solutions','BVS.AX',
	'BWP Trust','BWP.AX',
	'BWX','BWX.AX',
	'Brambles','BXB.AX',
	'Capitol Health','CAJ.AX',
	'Carsales.com','CAR.AX',
	'Catapult Group International','CAT.AX',
	'Commonwealth Bank of Australia','CBA.AX',
	'Credit Corp Group','CCP.AX',
	'City Chic Collective','CCX.AX',
	'Codan','CDA.AX',
	'Contact Energy','CEN.AX',
	'Costa Group Holdings','CGC.AX',
	'Challenger','CGF.AX',
	'Charter Hall Group','CHC.AX',
	'Chalice Mining','CHN.AX',
	'Champion Iron','CIA.AX',
	//'Cimic Group','CIM.AX',
	'Carlton Investments','CIN.AX',
	'Centuria Industrial REIT','CIP.AX',
	'Collins Foods','CKF.AX',
	'Charter Hall Long Wale REIT','CLW.AX',
	'Capricorn Metals','CMM.AX',
	'Cromwell Property Group','CMW.AX',
	'Centuria Capital Group','CNI.AX',
	'Chorus','CNU.AX',
	'Cooper Energy','COE.AX',
	'Centuria Office REIT','COF.AX',
	'Cochlear','COH.AX',
	'Coles Group','COL.AX',
	'Computershare','CPU.AX',
	'Charter Hall Social Infrastructure','CQE.AX',
	'Charter Hall Retail REIT','CQR.AX',
  'Betashares Aus Invest Grade Corp Bond','CRED.AX',
  'Coronado Global Resources Inc','CRN.AX',
  'CSL','CSL.AX',
  'CSR','CSR.AX',
  'Corporate Travel Management','CTD.AX',
'Cettire','CTT.AX',
'Clinuvel Pharmaceuticals','CUV.AX',
'Carnarvon Petroleum','CVN.AX',
'Crown Resorts','CWN.AX',
'Cedar Woods Properties','CWP.AX',
'Cleanaway Waste Management','CWY.AX',
'CALIX','CXL.AX',
'Dalrymple Bay Infrastructure','DBI.AX',
'DDH1','DDH.AX',
//'Dicker Data','DDR.AX',
//'De Grey Mining','DEG.AX',
'Domain Holdings Australia','DHG.AX',
'SPDR Dow Jones Global Real Estate','DJRE.AX',
'Djerriwarrh Investments','DJW.AX',
'Domino´s PIZZA Enterprises','DMP.AX',
'Downer Edi','DOW.AX',
'Deterra Royalties','DDR.AX',
'Data#3','DTL.AX',
'Dubber Corporation','DUB.AX',
'Diversified United Investment','DUI.AX',
'Dexus','DXS.AX',
'Ebos Group','EBO.AX',
'Eclipx Group','ECX.AX',
'Estia Health','EHE.AX',
'Emeco Holdings','EHL.AX',
'Elders','ELD.AX',
'Elmo Software','ELO.AX',
'EML Payments','EML.AX',
'Emerald Resources NL','EMR.AX',
'Ecofibre','EOF.AX',
'Electro Optic Systems Holdings','EOS.AX',
'EQT Holdings','EQT.AX',
'Energy Resources of Australia','ERA.AX',
'Eroad','ERD.AX',
'Betashares Global Sustainab Leaders','ETHI.AX',
'Evolution Mining','EVN.AX',
'Event Hospitality and Entertainment','EVN.AX',
'Betashares Aus Sustainab Leaders','FAIR.AX',
'Fletcher Building','FBU.AX',
'Fineos Corporation Holdings','FCL.AX',
'Frontier Digital Ventures','FDV.AX',
'Future Generation Global Invest','FGG.AX',
'Future Generation Invest Company','FGX.AX',
'Freelancer','FLN.AX',
'Flight Centre Travel Group','FLT.AX',
'Fortescue Metals Group','FMG.AX',
'Fisher & Paykel Healthcare Corp','FPH.AX',
'Fonterra Shareholders Fund','FSF.AX',
'Gryphon Capital Income Trust','CGI.AX',
'GDI Property Group','GDI.AX',
'Vaneck Vectors Gold Miners','GDX.AX',
'G8 Education','GEM.AX',
//'Genworth Mortgage Insurance Aus','GMA.AX',
'Goodman Group','GMG.AX',
'Graincorp','GNC.AX',
'Genesis Energy','GNE.AX',
//'ETFs Metal Securities Australia','GOLD.AX',
'Gold Road Resources','GOR.AX',
'Growthpoint Properties Australia','GOZ.AX',
'GPT Group','GPT.AX',
'Grange Resources','GRR.AX',
'G.U.D. Holdings','GUD.AX',
'GWA Group','GWA.AX',
'Galaxy Resources','GXY.AX',
'Betashares Global Cybersecurity','HACK.AX',
'Betashares Active Aus Hybrids Fund','HBRD.AX',
'Homeco Daily Needs REIT','HDN.AX',
'Heartland Group Holdings','HGH.AX',
'Healius','HLS.AX',
'Hearts and Minds Investments','HM1.AX',
'Home Consortium','HMC.AX',
'Hotel Property Investments','HPI.AX',
'Hansen Technologies','HSN.AX',
'HT&E','HT1.AX',
'Hutchison Telecomm (Aus)','HTA.AX',
'HUB24','HUB.AX',
'Humm Group','HUM.AX',
'Harvey Norman Holdings','HVN.AX',
'Hyperion GBL Growth Comp','HYGG.AX',
'Ishares Asia 50 ETF','IAA.AX',
'Ishares Core Composite Bond','IAF.AX',
'Insurance Australia Group','IAG.AX',
'Irongate Group','IAP.AX',
'Integral Diagnostics','IDX.AX',
'Idp Education','IEL.AX',
'Ishares MSCI Emerging Mark','IEM.AX',
'Ishares Europe ETF','IEU.AX',
'IOOF Holdings','IFL.AX',
'Infomedia','IFM.AX',
'Vaneck Vectors Global Infrastr','IFRA.AX',
'Infratil','IFT.AX',
'IGO','IGO.AX',
'Ishares S&P 500 Aud','IHVV.AX',
'Ishares MSCI Japan ETF','IJP.AX',
'Ishares S&P/ASX 20 ETF','ILC.AX',
'Iluka Resources','ILU.AX',
'IMDEX','IMD.AX',
'Imugene','IMU.AX',
'Ingenia Communities Group','INA.AX',
'Inghams Group','ING.AX',
'Ioneer','INR.AX',  
'Ishares Global 100','IOO.AX',
'Ishares Core ASX 200','IOZ.AX',
'IPH','IPH.AX',
'Incitec Pivot','IPL.AX',
'Iress','IRE.AX',
'Integrated Research','IRI.AX',
'Invocare','IVC.AX',
'Ishares MSCI Eafe ETF','IVE.AX',
'Ishares S&P 500 ETF','IVV.AX',
'Ishares Global Healthcare','IXJ.AX',
'JB Hi-Fi','JBH.AX',  
'Janus Henderson Group','JHG.AX',
'James Hardie Industries Plc','JHX.AX',
'Jumbo Interactive','JIN.AX',
'Johns LYNG Group','JLG.AX',
'Jupiter Mines','JMS.AX',
'Kogan.com','KGN.AX',  
'KKR Credit Income Fund','KKC.AX',
'Kathmandu Holdings','KMD.AX',
'Ale Property Group','LEP.AX',
'Liberty Financial Group','LFG.AX',
'Latitude Group Holdings','LFS.AX',
'Lynch Group Holdings','LGL.AX',
'Lifestyle Communities','LIC.AX',
'Lendlease Group','LLC.AX',
'Link Administration Holdings','LNK.AX',  
'Lovisa Holdings','LOV.AX',
'L1 Long Short Fund','LSF.AX',
'Liontown Resources','LTR.AX',
'Lynas Rare EARTHS','LTR.AX',
'Macmahon Holdings','MAH.AX',
'Macquarie Telecom Group','MAQ.AX',
'Mincor Resources NL','MCR.AX',
'Mercury NZ','MCY.AX',
'Meridian Energy','MEZ.AX',
'MFF Capital Investments','MFF.AX',
'Magellan Financial Group','MFG.AX',
'Magellan Global Fund','MGF.AX',
'Maas Group Holdings','MGH.AX',
'Magellan Global Fund','MGOC.AX',
'Mirvac Group','MGR.AX',
'Mount Gibson Iron','MGX.AX',
'Magellan High Conviction','MHH.AX',
'Magellan Infrastructure','MICH.AX',
'Mineral Resources','MIN.AX',
'Mirrabooka Investments','MIR.AX',
'Milton Corporation','MLT.AX',
'Marley Spoon AG','MMM.AX',
'Mcmillan Shakespeare','MMS.AX',
'Monadelphous Group','MND.AX',
'MNF Group','MNF.AX',
'MONEY3 Corporation','MNY.AX',
//'Moelis Australia','MOE.AX',
'Megaport','MP1.AX',
'Medibank Private','MPL.AX',
'Macquarie Group','MQG.AX',
'Mesoblast','MSB.AX',
'Metcash','MTS.AX',
'Vaneck Vectors Aus','MVA.AX',
'Medical Developments','MVP.AX',
'Vaneck Vectors Aus','MVW.AX',
'MCP Master Income Trust','MXT.AX',
'Mystate','MYS.AX',
'Mayne Pharma Group','MYX.AX',
'National Australia Bank','NAB.AX',
'Nanosonics','NAN.AX',
'NB Global Corporate','NBI.AX',
'Nick Scali','NCK.AX',
'Newcrest Mining','NCM.AX',
'Betashares Nasdaq 100','NDQ.AX',
'Nearmap','NEA.AX',
'Nine Entertainment','NEC.AX',
'New Hope Corporation','NHC.AX',
'Nib Holdings','NHF.AX',
'Nickel Mines','NIC.AX',
'National Storage REIT','NSR.AX',
'Northern Star Res','NST.AX',
'Nitro Software','NTO.AX',
'Nufarm','NUF.AX',
'Novonix','NVX.AX',
'NRW Holdings','NWH.AX',
'Netwealth Group','NWL.AX',
'News Corporation','NWS.AX',
'NUIX','NXL.AX',
'NEXTDC','NXT.AX',
'Omni Bridgeway','OBL.AX',
'Oceania Healthcare','OCA.AX',
'Objective Corporation','OCL.AX',
'Om Holdings','OMH.AX',
'Ooh!Media','OML.AX',
'Ophir High Conviction','OPH.AX',
'Opthea','OPT.AX',
'Orora','ORA.AX',
'Orocobre','ORE.AX',
'Origin Energy','ORG.AX',
'Orica','ORI.AX',
//'Oil Search','OSH.AX',
'OZ Minerals','OZL.AX',
'Platinum Asia Inv','PAI.AX',
'Paradigm Biopharma','PAR.AX',
'Pointsbet Holdings','PBH.AX',
'Perpetual Credit','PCI.AX',
'Pendal Group','PDL.AX',
'Paladin Energy','PDN.AX',
'PM Capital Global','PGF.AX',
'Partners Group','PGG.AX',
'Pact Group Holdings','PGH.AX',
'Plato Income Maxim','PL8.AX',
'Piedmont Lithium','PLL.AX',
'Pilbara Minerals','PLS.AX',
'Platinum Capital','PMC.AX',
'Pro Medicus','PME.AX',
'Gold','PMGOLD.AX',
'Premier Investments','PMV.AX',
'Pinnacle Investment','PNI.AX',
'Polynovo','PNV.AX',
'Peet','PPC.AX',
'People Infrastructure','PPE.AX',
'Pushpay Holdings','PPH.AX',
'PPK Group','PPK.AX',
'Praemium','PPS.AX',
'Perpetual','PPT.AX',
'Perenti Global','PRN.AX',
'Perseus Mining','PRU.AX',
'PSC Insurance Group','PSI.AX',
'Pacific Smiles Group','PSQ.AX',
'Platinum Asset Management','PTM.AX',
'Primewest','PWG.AX',
'PWR Holdings','PWH.AX',
'PYC Therapeutics','PYC.AX',
'Qantas Airways','QAN.AX',
'QBE Insurance','QBE.AX',
'Betashares Aus Bank Senior','QPON.AX',
'Qualitas Real Estate Fund','QRI.AX',
'Vaneck Vectors MSCI World Ex Aus','QUAL.AX',
'QUBE Holdings','QUB.AX',
'Race Oncology','RAC.AX',
'Restaurant Brands NZ','RBD.AX',
'Redbubble','RBL.AX',
'Redcape Hotel Group','RDC.AX',
'REA Group','REA.AX',
'RED 5','RED.AX',
'Regis Healthcare','REG.AX',
'Reece','REH.AX',
'Regal Investment Fund','RF1.AX',
'Rural Funds Group','RFF.AX',
'Ramsay Health Care','RHC.AX',
'RIO Tinto','RIO.AX',
'Resimac Group','RMC.AX',
'Resmed Inc','RMD.AX',
'Ramelius Resources','RMS.AX',  
'Regis Resources','RRL.AX',
'Resolute Mining','RSG.AX',
'Reliance Worldwide Corp','RWC.AX',
'SOUTH32','S32.AX',
'ST Barbara','SBM.AX',  
'Scentre Group','SCG.AX',
'Shopping Centres Aus','SCP.AX',
'Steadfast Group','SDF.AX',
'Seek','SEK.AX',
'Sandfire Resources','SFR.AX',  
'SPDR S&P/ASX 50 Fund','SFY.AX',
'SG Fleet Group','SGF.AX',
'Ricegrowers','SGLLV.AX',
'Sims','SGM.AX',
'Stockland','SGP.AX',
'The Star Entertainment Group','SGR.AX',
'Sonic Healthcare','SHL.AX',
'Select Harvests','SHV.AX',
'Sigma Healthcare','SIG.AX',
'Smartgroup Corporation','SIQ.AX',
'Skycity Entertainment','SKC.AX',
//'Spark Infrastructure','SKI.AX',
'Serko','SKO.AX',
'Superloop','SLC.AX',
'SPDR S&P/ASX 200 Listed Prop','SLF.AX',
'Sealink Travel Group','SLK.AX',
'Silver Lake Resources','SLR.AX',
'Synlait Milk','SM1.AX',
'Summerset Group Holdings','SNZ.AX',
'Washington H Soul Patt','SOL.AX',
'Spark New Zealand','SPK.AX',
'Starpharma Holdings','SPL.AX',
'Service Stream','SSM.AX',
'SSR Mining Inc','SSR.AX',
'Santos','STO.AX',
'SPDR S&P/ASX 200 Fund','STW.AX',
'Strike Energy','STX.AX',
'Super Retail Group','SUL.AX',
'Suncorp Group','SUN.AX',
'Seven Group Holdings','SVM.AX',
'Seven West Media','SWM.AX',
'Southern Cross Media','SXL.AX',
'SENEX Energy','SXY.AX',
//'Sydney Airport','SYD.AX',
'Syrah Resources','SYR.AX',
'Sezzle Inc','SZL.AX',
'Tabcorp Holdings','TAH.AX',
'Transurban Group','TCL.AX',
'Tassal Group','TGR.AX',
'Talga Group','TLG.AX',
'Telstra Corporation','TLS.AX',
'Tilt Renewables','TLT.AX',
'TELIX Pharmaceuticals','TLX.AX',
'Technology One','TNE.AX',
'TPG Telecom','TPG.AX',
'Temple & Webster Group','TPW.AX',
'Treasury Wine Estates','TWE.AX',
'Tyro Payments','TYR.AX',
'United Malt Group','UMG.AX',
'Universal Store','UNI.AX',
'United Overseas Aus','UOS.AX',
'Unibail-Rodamco Westfield','URW.AX',
'Uniti Group','UWL.AX',
'Vanguard Aus Corp Fixed Interest','VACF.AX',
'Vanguard Aus Fixed Interest','VAF.AX',
'Vanguard Aus Property Securities','VAP.AX',
'Vanguard Aus Shares INDEX ETF','VAS.AX',
'Vicinity Centres','VCX.AX',
'Vanguard Diversified Balanced','VDBA.AX',
'Vanguard Diversified Growth','VDGR.AX',
'Vanguard Diversified High Growth','VDHG.AX',
'Viva Energy Group','VEA.AX',
'Vanguard All-World Ex-US','VEU.AX',
'Vgi Partners Global Invest','VG1.AX',
'Vgi Partners Asian Invest','VG8.AX',
'Vanguard MSCI INDEX Int Shares','VGAD.AX',
'Vanguard Australian Gov Bond','VGB.AX',
'Vanguard Ftse Emerging Markets','VGE.AX',
'VGI Partners','VGI.AX',
'Vista Group International','VGL.AX',
'Vanguard MSCI INDEX Int Shares','VGS.AX',
'Vanguard Aus Shares High Yield ','VHY.AX',
'Vanguard Int Fixed Interest INDEX','VIF.AX',
//'Vocus Group','VOC.AX',
'Virtus Health','VRT.AX',
'Vanguard MSCI Aus Small Companies','VSO.AX',
'Vanguard US Total Market Shares','VTS.AX',
'Virgin Money Uk Plc','VUK.AX',
'Vulcan Energy Resources','VUL.AX',
'West African Resources','WAF.AX',
'WAM Capital','WAM.AX',
'Westpac Banking Corporation','WBC.AX',
'Webjet','WEB.AX',
'Wesfarmers','WES.AX',
'Wam Global','WGB.AX',
'Wagners Holding Company','WGN.AX',
'Westgold Resources','WGX.AX',
'Whitehaven Coal','WHC.AX',
'Whitefield','WHF.AX',
'Wam Leaders','WLE.AX',
'Wam Microcap','WMI.AX',
'Worley','WOR.AX',
'Woolworths Group','WOW.AX',
'Woodside Petroleum','WDS.AX',
'Waypoint REIT','WPR.AX',
'Western Areas','WSA.AX',
'Whispir','WSP.AX',
'Wisetech Global','WTC.AX',
'Activex Ardea Real Outcome','XARO.AX',
'Xero','XRO.AX',
'Yancoal Australia','YAL.AX',
'ZIP Co','Z1P.AX',
'Z Energy','ZEL.AX',
'Zimplats Holdings','ZIM.AX'],

ASX50_STOCKS:[1,
  1,
  1,
  1,
  '$',
'A2 Milk','A2M.AX',
//'Afterpay Touch','APT.AX',
'AGL Energy','AGL.AX',
'Amcor','AMC.AX',
'Ampol','ALD.AX',
'ANZ Banking Group','ANZ.AX',
'APA Group','APA.AX',
'Aristocrat Leisure','ALL.AX',
'ASX','ASX.AX',
'Aurizon Holdings','AZL.AX',
'BHP Billiton Ltd','BHP.AX',
'Brambles','BXB.AX',
'Cochlear','COH.AX',
'Coles Group','COL.AX',
'Commonwealth Bank Aus.','CBA.AX',
'Computershare','CPU.AX',
'CSL','CSL.AX',
'Dexus Property','DXS.AX',
'Fortescue Metals','FMG.AX',
'Goodman Group','GMG.AX',
'GPT Group','GPT.AX',
'Insurance Australia Grp','IAG.AX',
'James Hardie Industries','JHX.AX',
'Lend Lease Group','LLC.AX',
'Macquarie','MQG.AX',
'Medibank Private','MPL.AX',
'Mirvac Group','MRG.AX',
'National Australia Bank','NAB.AX',
'Newcrest Mining','NCM.AX',
'Orica','ORI.AX',
'Origin Energy','ORG.AX',
'Qantas Airways','QAN.AX',
'Qbe Insurance Group','QBE.AX',
'Ramsay Health Care','RHC.AX',
'Rio Tinto Ltd','RIO.AX',
'Santos','STO.AX',
'Scentre','SCG.AX',
'Sonic Healthcare','SLH.AX',
'South32','S32.AX',
'Stockland Corp','SGP.AX',
'Suncorp','SUN.AX',
//'Sydney Airport Ltd','SYD.AX',
'Telstra Corporation.','TLS.AX',
'Transurban Group','TCL.AX',
'Treasury Wine Estates','TWE.AX',
'Wesfarmers','WES.AX',
'Westpac Banking','WBC.AX',
'Woodside Petroleum','WDS.AX',
'Woolworths','WOW.AX',
'Xero','XRO.AX'],

ASX200_STOCKS:[1,
  1,
  1,
  1,
  '$',
  'ASX 200','^AXJO',
  'AMP','AMP.AX',
'360 Capital Industrial','TGP.AX',
'A2 Milk','A2M.AX',
'Abacus Property','ABP.AX',
'Adelaide Brighton','ABC.AX',
//'Afterpay Touch','APT.AX',
'AGL Energy','AGL.AX',
'ALS','ALQ.AX',
'Altium','ALU.AX',
'Alumina','AWC.AX',
'Amcor','AMC.AX',
'Ampol','ALD.AX',
'Ansell','ANN.AX',
'ANZ Banking Group','ANZ.AX',
'AP Eagers','APE.AX',
'APA Group','APA.AX',
'Appen Ltd','APX.AX',
'ARB Corporation','ARB.AX',
'Aristocrat Leisure','ALL.AX',
'ASX','ASX.AX',
'Atlas Arteria','ALX.AX',
'AUB Group Ltd','AUB.AX',
'Auckland Airport','AIA.AX',
'Aurizon Holdings','AZJ.AX',
//'AusNet Services','AST.AX',
'Austal','ASB.AX',
'Bank Of Queensland.','BOQ.AX',
'Bapcor','BAP.AX',
'Beach Energy','BPT.AX',
'Bega Cheese','BGA.AX',
'Bendigo Adelaide Bank','BEN.AX',
'BHP Billiton Ltd','BHP.AX',
//'Bingo Industries','BIN.AX',
'Blackmores','BKL.AX',
'Bluescope Steel','BSL.AX',
'Boral','BLD.AX',
'Brambles','BXB.AX',
'Breville Group','BRG.AX',
'Brickworks','BKW.AX',
'BWP Trust','BWP.AX',
'Carsales.Com','CAR.AX',
'Challenger','CGF.AX',
'Champion Iron Ltd','CIA.AX',
'Charter Hall Group','CHC.AX',
'Charter Hall Long','CLW.AX',
'Charter Hall Retail','CQR.AX',
'Chorus','CNU.AX',
//'Cimic Group','CIM.AX',
'Cleanaway Waste','CWY.AX',
'Clinuvel Pharmaceu','CUV.AX',
//'Coca-Cola Amatil','CCL.AX',
'Cochlear','COH.AX',
'Codan','CDA.AX',
'Coles Group','COL.AX',
'Collins Foods','CKF.AX',
'Commonwealth Bank Austr','CBA.AX',
'Computershare','CPU.AX',
'Corporate Travel','CTD.AX',
'Costa Group Holdings Ltd','CGC.AX',
'Credit Corp Group','CCP.AX',
'Cromwell Property','CMW.AX',
'Crown','CWN.AX',
'CSL','CSL.AX',
'CSR','CSR.AX',
'Deterra Royalties','DRR.AX',
'Dexus Property','DXS.AX',
'Domain Australia','DHG.AX',
'Domino´s Pizza Enterp','DMP.AX',
'Downer Edi','DOW.AX',
'Elders','ELD.AX',
'EML Payments','EML.AX',
'Evolution Mining','EVN.AX',
'Fisher&Paykel Health','FPH.AX',
'Fletcher Building','FBU.AX',
'Flight Centre','FLT.AX',
'Fortescue Metals','FMG.AX',
'G8 Education','GEM.AX',
'Gold Road Resources','GOR.AX',
'Goodman Group','GMG.AX',
'GPT Group','GPT.AX',
'Graincorp','GNC.AX',
'Growthpoint Properties','GOZ.AX',
'GUD Holdings','GUD.AX',
'Harvey Norman Holdings','HVN.AX',
'Hub24 Ltd','HUB.AX',
'Idp Education Ltd','IEL.AX',
'IGO Ltd','IGO.AX',
'Iluka Resources','ILU.AX',
'Incitec Pivot','IPL.AX',
'Ingenia Communities','INA.AX',
'Inghams Group','ING.AX',
'Insurance Australia Group','IAG.AX',
'Invocare','IVC.AX',
'Ioof Holdings','IFL.AX',
'IPH','IPH.AX',
'Iress','IRE.AX',
'James Hardie Industries','JHX.AX',
'Janus Henderson DRC','JHG.AX',
'JB Hi-Fi','JBH.AX',
'Kogan.com','KGN.AX',
'Lend Lease Group','LLC.AX',
'Link Administration','LNK.AX',
'Lynas Rare Earths','LYC.AX',
'Macquarie','MQG.AX',
'Magellan Financial','MFG.AX',
'Medibank Private Ltd','MPL.AX',
'Megaport Ltd','MP1.AX',
'Mesoblast','MSB.AX',
'Metcash','MTS.AX',
'Mineral Resources','MIN.AX',
'Mirvac Group','MGR.AX',
'Monadelphous','MND.AX',
'Nanosonics','NAN.AX',
'National Australia Bank','NAB.AX',
'National Storage','NSR.AX',
'Nearmap','NEA.AX',
'Netwealth Group','NWL.AX',
'Newcrest Mining','NCM.AX',
'News Corp B DRC','NWS.AX',
'Nextdc','NXT.AX',
'NIB Holdings','NHF.AX',
'Nickel Mines','NIC.AX',
'Nine Entertainment','NEC.AX',
'Northern Star Res','NST.AX',
'NRW Holdings','NWH.AX',
'Nufarm','NUF.AX',
'Nuix','NXL.AX',
//'Oil Search','OSH.AX',
'Omni Bridgeway Ltd','OBL.AX',
'Orica','ORI.AX',
'Origin Energy','ORG.AX',
'Orora','ORA.AX',
'OZ Minerals','OZL.AX',
'Pendal','PDL.AX',
'Perenti Global Ltd','PRN.AX',
'Perpetual','PPT.AX',
'Perseus Mining','PRU.AX',
'Pilbara Minerals Ltd','PLS.AX',
'Platinum Asset Management','PTM.AX',
'Pointsbet Holdings','PBH.AX',
'Polynovo','PNV.AX',
'Premier Investments','PMV.AX',
'Primary Health Care','HLS.AX',
'Pro Medicus Ltd','PME.AX',
'Qantas Airways','QAN.AX',
'Qbe Insurance Group','QBE.AX',
'Qube','QUB.AX',
'Ramelius Resources','RMS.AX',
'Ramsay Health Care','RHC.AX',
'Rea Group','REA.AX',
'Reece','REH.AX',
'Regis Resources','RRL.AX',
'Reliance Worldwide','RWC.AX',
'Resmed DRC','RMD.AX',
'Resolute Mining','RSG.AX',
'Rio Tinto Ltd','RIO.AX',
'Santos','STO.AX',
'Scentre','SCG.AX',
'Seek','SEK.AX',
'Seven Group Holdings','SVW.AX',
'Shopping Centres Austr','SCP.AX',
'Silver Lake Resources','SLR.AX',
'Sims Metal Management','SGM.AX',
'Skycity Entertainment','SKC.AX',
'Sonic Healthcare','SHL.AX',
'South32','S32.AX',
//'Spark Infrastructure','SKI.AX',
'Spark New Zealand','SPK.AX',
'St Barbara','SBM.AX',
'Star Entertainment','SGR.AX',
'Steadfast Group','SDF.AX',
'Stockland Corp','SGP.AX',
'Suncorp','SUN.AX',
'Super Retail Group','SUL.AX',
//'Sydney Airport Ltd','SYD.AX',
'Tabcorp Holdings','TAH.AX',
'Technology One','TNE.AX',
'Telstra Corporation.','TLS.AX',
'TPG Telecom','TPG.AX',
'Transurban Group','TCL.AX',
'Treasury Wine Estates','TWE.AX',
'Unibail Rodamco West','URW.AX',
'United Malt','UMG.AX',
'Vicinity Centres','VCX.AX',
'Virgin Money','VUK.AX',
'Viva Energy','VEA.AX',
//'Vocus Group','VOC.AX',
'Washington H Soul Pattinson','SOL.AX',
'Waypoint REIT','WPR.AX',
'Webjet','WEB.AX',
'Wesfarmers','WES.AX',
'Westgold Resources','WGX.AX',
'Westpac Banking','WBC.AX',
'Whitehaven Coal','WHC.AX',
'Wisetech Global','WTC.AX',
'Woodside Petroleum','WDS.AX',
'Woolworths','WOW.AX',
'Worley Ltd','WOR.AX',
'Xero','XRO.AX',
'Zip','Z1P.AX'],

ASX300_STOCKS:[1,
  1,
  1,
  1,
  '$',
  'ASX 300','^AXKO',
  'AMP','AMP.AX',
'360 Capital Industrial','TGP.AX',
'A2 Milk','A2M.AX',
'Abacus Property','ABP.AX',
'Accent Group Ltd','AX1.AX',
'Adairs Ltd','ADH.AX',
'Adelaide Brighton','ABC.AX',
//'Afterpay Touch','APT.AX',
'AGL Energy','AGL.AX',
'Alkane Resources','ALK.AX',
'ALS','ALQ.AX',
'Altium','ALU.AX',
'Alumina','AWC.AX',
'AMA Group','AMA.AX',
'Amcor','AMC.AX',
'Ampol','ALD.AX',
'Ansell','ANN.AX',
'ANZ Banking Group','ANZ.AX',
'AP Eagers','APE.AX',
'APA Group','APA.AX',
//'APN Industria','ADI.AX',
'Appen Ltd','APX.AX',
'ARB Corporation','ARB.AX',
'Arena Group','ARF.AX',
'Aristocrat Leisure','ALL.AX',
//'Asaleo Care','AHY.AX',
'ASX','ASX.AX',
'Atlas Arteria','ALX.AX',
'AUB Group Ltd','AUB.AX',
'Auckland Airport','AIA.AX',
'Audinate','AD8.AX',
'Aurelia Metals','AMI.AX',
'Aurizon Holdings','AZJ.AX',
//'AusNet Services','AST.AX',
'Austal','ASB.AX',
'Australian Agricultural','AAC.AX',
'Australian Ethical Invest','AEF.AX',
'Australian Finance Group','AFG.AX',
//'Australian Pharma Ind','API.AX',
//'Aventus Retail','AVN.AX',
'Avita Therapeutics','AVH.AX',
'Baby Bunting Group Ltd','BBN.AX',
'Bank Of Queensland.','BOQ.AX',
'Bapcor','BAP.AX',
'Beach Energy','BPT.AX',
'Bega Cheese','BGA.AX',
'Bellevue Gold','BGL.AX',
'Bendigo & Adelaide Bank','BEN.AX',
'BHP Billiton Ltd','BHP.AX',
//'Bingo Industries','BIN.AX',
'Blackmores','BKL.AX',
'Bluescope Steel','BSL.AX',
'Boral','BLD.AX',
'Brainchip Holdings','BRN.AX',
'Brambles','BXB.AX',
'Bravura Solutions','BVS.AX',
'Breville Group','BRG.AX',
'Brickworks','BKW.AX',
'Bubs Australia','BUB.AX',
'BWP Trust','BWP.AX',
'BWX Ltd','BWX.AX',
'Capricorn Metals','CMM.AX',
'Carnarvon Petroleum','CVN.AX',
'Carsales.Com','CAR.AX',
'Cedar Woods Properties','CWP.AX',
'Centuria Capital','CNI.AX',
'Centuria Metropolitan','COF.AX',
'Chalice Gold Mines','CHN.AX',
'Challenger','CGF.AX',
'Champion Iron Ltd','CIA.AX',
'Charter Hall Education','CQE.AX',
'Charter Hall Group','CHC.AX',
'Charter Hall Long','CLW.AX',
'Charter Hall Retail Reit','CQR.AX',
'Chorus','CNU.AX',
//'Cimic Group','CIM.AX',
'City Chic Collective','CCX.AX',
'Cleanaway Waste','CWY.AX',
'Clinuvel Pharmaceuticals','CUV.AX',
//'Coca-Cola Amatil','CCL.AX',
'Cochlear','COH.AX',
'Codan','CDA.AX',
'Coles Group','COL.AX',
'Collins Foods','CKF.AX',
'Commonwealth Bank Austr','CBA.AX',
'Computershare','CPU.AX',
'Cooper Energy','COE.AX',
'Coronado Global','CRN.AX',
'Corporate Travel','CTD.AX',
'Costa Group Holdings','CGC.AX',
'Credit Corp Group','CCP.AX',
'Cromwell Property','CMW.AX',
'Crown','CWN.AX',
'CSL','CSL.AX',
'CSR','CSR.AX',
'Dalrymple Bay Infrastr','DBI.AX',
'Data3','DTL.AX',
'De Grey Mining','DEG.AX',
'Deterra Royalties','DRR.AX',
'Dexus Property','DXS.AX',
'Dicker Data','DDR.AX',
'Domain Australia','DHG.AX',
'Domino´S Pizza Enterprises','DMP.AX',
'Downer Edi','DOW.AX',
'Eclipx Group Ltd','ECX.AX',
'Elders','ELD.AX',
'Electro Optic Systems','EOS.AX',
'Emeco','EHL.AX',
'EML Payments','EML.AX',
'Estia Health Ltd','EHE.AX',
'Event Hospitality','EVT.AX',
'Evolution Mining','EVN.AX',
'Fineos Corp Holdings','FCL.AX',
'Fisher&Paykel Health','FPH.AX',
'Fletcher Building','FBU.AX',
'Flight Centre','FLT.AX',
'Fortescue Metals','FMG.AX',
'G8 Education','GEM.AX',
'Galaxy Resources','GXY.AX',
'GDI Property Group','GDI.AX',
'Genworth Mortgage','GMA.AX',
'Gold Road Resources','GOR.AX',
'Goodman Group','GMG.AX',
'GPT Group','GPT.AX',
'Graincorp','GNC.AX',
'Growthpoint Properties','GOZ.AX',
'GUD Holdings','GUD.AX',
'GWA Group Ltd','GWA.AX',
'Hansen Tech','HSN.AX',
'Harvey Norman Holdings','HVN.AX',
'Home Consortium','HMC.AX',
'Hotel Property','HPI.AX',
'Hub24 Ltd','HUB.AX',
'Humm Group Ltd','HUM.AX',
'Idp Education Ltd','IEL.AX',
'IGO Ltd','IGO.AX',
'Iluka Resources','ILU.AX',
'Imdex','IMD.AX',
'Incitec Pivot','IPL.AX',
'Infomedia','IFM.AX',
'Ingenia Communities','INA.AX',
'Inghams Group','ING.AX',
'Insurance Australia','IAG.AX',
'Integral Diagnostics','IDX.AX',
'Integrated Research','IR.AX',
'Investec Australia','IAP.AX',
'Invocare','IVC.AX',
'Ioneer','INR.AX',
'Ioof Holdings','IFL.AX',
'IPH','IPH.AX',
'Iress','IRE.AX',
'James Hardie Industries','JHX.AX',
'Janus Henderson DRC','JHG.AX',
'JB Hi-Fi','JBH.AX',
'Jumbo Interactive','JIN.AX',
'Jupiter Mines','JMS.AX',
'Karoon Gas Australia','KAR.AX',
'Kogan.com','KGN.AX',
'Lend Lease Group','LLC.AX',
'Life360','360.AX',
'Lifestyle Comnts','LIC.AX',
'Link Administration','LNK.AX',
'Lovisa Holdings Ltd','LOV.AX',
'Lynas Rare Earths','LYC.AX',
'Maca','MLD.AX',
'Macquarie','MQG.AX',
'Magellan Financial Group','MFG.AX',
'Marley Spoon','MMM.AX',
'Mayne Pharma','MYX.AX',
'Mcmillan Shakespeare','MMS.AX',
'Medibank Private Ltd','MPL.AX',
'Medical Developments','MVP.AX',
'Megaport Ltd','MP1.AX',
'Mesoblast','MSB.AX',
'Metcash','MTS.AX',
'Mineral Resources','MIN.AX',
'Mirvac Group','MGR.AX',
'Monadelphous','MND.AX',
'Money3 Corp','MNY.AX',
'Mount Gibson Iron','MGX.AX',
'Mystate','MYS.AX',
'Nanosonics','NAN.AX',
'National Australia Bank','NAB.AX',
'National Storage','NSR.AX',
'Nearmap','NEA.AX',
'Netwealth Group','NWL.AX',
'New Hope Corporation','NHC.AX',
'Newcrest Mining','NCM.AX',
'News Corp B DRC','NWS.AX',
'Nextdc','NXT.AX',
'NIB Holdings','NHF.AX',
'Nick Scali','NCK.AX',
'Nickel Mines','NIC.AX',
'Nine Entertainment','NEC.AX',
'Northern Star Resources','NST.AX',
'NRW Holdings','NWH.AX',
'Nufarm','NUF.AX',
'Nuix','NXL.AX',
//'Oil Search','OSH.AX',
'Omni Bridgeway Ltd','OBL.AX',
'oOh!Media Ltd','OML.AX',
'Opthea','OPT.AX',
'Orica','ORI.AX',
'Origin Energy','ORG.AX',
'Orocobre','ORE.AX',
'Orora','ORA.AX',
'OZ Minerals','OZL.AX',
'Pact Group Holdings','PGH.AX',
'Paradigm Biopharma','PAR.AX',
'Pendal','PDL.AX',
'Perenti Global Ltd','PRN.AX',
'Perpetual','PPT.AX',
'Perseus Mining','PRU.AX',
'Piedmont Lithium','PLL.AX',
'Pilbara Minerals Ltd','PLS.AX',
'Pinnacle Investment','PNI.AX',
'Platinum Asset Management','PTM.AX',
'Pointsbet Holdings','PBH.AX',
'Polynovo','PNV.AX',
'Premier Investments','PMV.AX',
'Primary Health Care','HLS.AX',
'Pro Medicus Ltd','PME.AX',
'Qantas Airways','QAN.AX',
'Qbe Insurance Group','QBE.AX',
'Qube','QUB.AX',
'Ramelius Resources','RMS.AX',
'Ramsay Health Care','RHC.AX',
'Rea Group','REA.AX',
'Red 5','RED.AX',
'Redbubble Ltd','RBL.AX',
'Reece','REH.AX',
'Regis Resources','RRL.AX',
'Reliance Worldwide','RWC.AX',
'Resmed DRC','RMD.AX',
'Resolute Mining','RSG.AX',
'Rio Tinto Ltd','RIO.AX',
'Rural Funds Group','RFF.AX',
'Sandfire Resources','SFR.AX',
'Santos','STO.AX',
'Scentre','SCG.AX',
'Sealink Travel','SLK.AX',
'Seek','SEK.AX',
'Select Harvests','SHV.AX',
'Senex Energy','SXY.AX',
'Service Stream','SSM.AX',
'Seven Group Holdings','SVW.AX',
'Shopping Centres Australasia','SCP.AX',
'Sigma Pharma','SIG.AX',
'Silver Lake Resources','SLR.AX',
'Sims Metal Management','SGM.AX',
'Skycity Entertainment','SKC.AX',
'Smartgroup Corporation Ltd','SIQ.AX',
'Sonic Healthcare','SHL.AX',
'South32','S32.AX',
'Southern Cross Media','SXL.AX',
//'Spark Infrastructure','SKI.AX',
'Spark New Zealand','SPK.AX',
'Ssr','SSR.AX',
'St Barbara','SBM.AX',
'Star Entertainment','SGR.AX',
'Starpharma Holdings','SPL.AX',
'Steadfast Group','SDF.AX',
'Stockland Corp','SGP.AX',
'Suncorp','SUN.AX',
'Super Retail Group','SUL.AX',
//'Sydney Airport Ltd','SYD.AX',
'Synlait Milk','SM1.AX',
'Tabcorp Holdings','TAH.AX',
'Tassal Group','TGR.AX',
'Technology One','TNE.AX',
'Telix Pharma','TLX.AX',
'Telstra Corporation.','TLS.AX',
'Temple & Webster Group Ltd','TPW.AX',
'TPG Telecom','TPG.AX',
'Transurban Group','TCL.AX',
'Treasury Wine Estates','TWE.AX',
'Tyro Payments','TYR.AX',
'Unibail Rodamco Westfield','URW.AX',
'United Malt','UMG.AX',
'Uniti Wireless','UWL.AX',
'Vicinity Centres','VCX.AX',
'Virgin Money','VUK.AX',
'Virtus Health','VRT.AX',
'Viva Energy','VEA.AX',
//'Vocus Group','VOC.AX',
'Washington H Soul Patt','SOL.AX',
'Waypoint REIT','WPR.AX',
'Webje','WEB.AX',
'Wesfarmers','WES.AX',
'West African Resources','WAF.AX',
'Western Areas','WSA.AX',
'Westgold Resources','WGX.AX',
'Westpac Banking','WBC.AX',
'Whitehaven Coal','WHC.AX',
'Wisetech Global','WTC.AX',
'Woodside Petroleum','WDS.AX',
'Woolworths','WOW.AX',
'Worley Ltd','WOR.AX',
'Xero','XRO.AX',
'Zip','Z1P.AX'],



SENSEX50_STOCKS:[1,
  1,
  1,
  1,
  '₹',
  'BSE SENSEX 50','SNSX50.BO',
  'Adani Port & SEZ','ADANIPORTS.NS',
  'Asian Paints','ASIANPAINT.NS',
  'AXIS Bank','AXISBANK.NS',
  'Bajaj Auto','BAJAJ-AUTO.NS',
  'Bajaj Finance','BAJFINANCE.NS',
  'Bajaj Finserv','BAJAJFINSV.NS',
  'Bharat Petroleum','BPCL.NS',
  'Bharti Airtel','BHARTIARTL.NS',
  'Britannia Industries','BRITANNIA.NS',
  'Cipla','CIPLA.NS',
  'Coal India','COALINDIA.NS',
  'Dabur India','DABUR.NS',
  'Divi´s Labs','DIVISLAB.NS',
  'Dr. Reddyâ€™s Labs','DRREDDY.NS',
  'Eicher Motors','EICHERMOT.NS',
  'Godrej Consumer','GODREJCP.NS',
  'Grasim Industries','GRASIM.NS',
  'HCL Tech','HCLTECH.NS',
  'HDFC Bank','HDFCBANK.NS',
  'HDFC Life','HDFCLIFE.NS',
  'Hero MotoCorp','HEROMOTOCO.NS',
  'Hindalco Industries','HINDALCO.NS',
  'Hindustan Unilever','HINDUNILVR.NS',
  'Housing Development Finance','HDFC.NS',
  'ICICI Bank','ICICIBANK.NS',
  'IndusInd Bank','INDUSINDBK.NS',
  'Infosys','INFY.NS',
  'ITC','ITC.NS',
  'JSW Steel','JSWSTEEL.NS',
  'Kotak Mahindra Bank','KOTAKBANK.NS',
  'Larsen & Toubro','LT.NS',
  // falla el and en el ticker. CAN BE FIXED!!! 'Mahindra & Mahindra','M&M.NS',
  'Maruti Suzuki','MARUTI.NS',
  'Nestle India','NESTLEIND.NS',
  'NTPC','NTPC.NS',
  'Oil & Natural Gas','ONGC.NS',
  'Power Grid','POWERGRID.NS',
  'Reliance Industries','RELIANCE.NS',
  'SBI Life Insurance','SBILIFE.NS',
  'SBI','SBIN.NS',
  'Sun Pharma','SUNPHARMA.NS',
  'Tata Consultancy','TCS.NS',
  'Tata Consumer Products','TATACONSUM.NS',
  'Tata Motors','TATAMOTORS.NS',
  'Tata Steel Ltd','TATASTEEL.NS',
  'Tech Mahindra','TECHM.NS',
  'Titan Company','TITAN.NS',
  'UltraTech Cement','ULTRACEMCO.NS',
  'UPL','UPL.NS',
  'Wipro','WIPRO.NS'],

  
  
SENSEX30_STOCKS:[1,
  1,
  1,
  1,
  '₹',             
  'BSE SENSEX','^BSESN',
  'Asian Paints','ASIANPAINT.BO',
'AXIS Bank','AXISBANK.BO',
'Bajaj Auto','BAJAJ-AUTO.BO',
'Bajaj Finance','BAJFINANCE.BO',
'Bajaj Finserv','BAJAJFINSV.BO',
'Bharti Airtel','BHARTIARTL.BO',
'Dr. Reddy´s Labs','DRREDDY.BO',
'HCL Tech','HCLTECH.BO',
'HDFC Bank','HDFCBANK.BO',
'Hindustan Unilever','HINDUNILVR.BO',
'Housing Development Finance','HDFC.BO',
'ICICI Bank','ICICIBANK.BO',
'IndusInd Bank','INDUSINDBK.BO',
'Infosys','INFY.BO',
'ITC','ITC.BO',
'Kotak Mahindra Bank','KOTAKBANK.BO',
'Larsen & Toubro','LT.BO',
// falla el and en el ticker. CAN BE FIXED!!! 'Mahindra & Mahindra','M&M.BO',
'Maruti Suzuki','MARUTI.BO',
'Nestle India','NESTLEIND.BO',
'NTPC','NTPC.BO',
'Power Grid','POWERGRID.BO',
'Reliance Industries','RELIANCE.BO',
'SBI','SBIN.BO',
'Sun Pharma','SUNPHARMA.BO',
'Tata Consultancy','TCS.BO',
'Tata Steel Ltd','TATASTEEL.BO',
'Tech Mahindra','TECHM.BO',
'Titan Company','TITAN.BO',
'UltraTech Cement','ULTRACEMCO.BO'],
 
BSE_100_STOCKS:[1,
  1,
  1,
  1,
  '₹',
  'BSE 100','BSE-100.BO',
  'ACC','ACC.BO',
'Adani Enterprises','ADANIENT.BO',
'Adani Green Energy','ADANIGREEN.BO',
'Adani Ports & SEZ','ADANIPORTS.BO',
'Ambuja Cements','AMBUJACEM.BO',
'Apollo Hospitals','APOLLOHOSP.BO',
'Ashok Leyland','ASHOKLEY.BO',
'Asian Paints','ASIANPAINT.BO',
'AU Small Finance Bank','AUBANK.BO',
'Aurobindo Pharma','AUROPHARMA.BO',
'Avenue Supermarts','DMART.BO',
'AXIS Bank','AXISBANK.BO',
'Bajaj Auto','BAJAJ-AUTO.BO',
'Bajaj Finance','BAJFINANCE.BO',
'Bajaj Finserv','BAJAJFINSV.BO',
'Bajaj Holdings','BAJAJHLDNG.BO',
'Bandhan Bank','BANDHANBNK.BO',
'Berger Paints','BERGEPAINT.BO',
'Bharat Forge','BHARATFORG.BO',
'Bharat Petroleum','BPCL.BO',
'Bharti Airtel','BHARTIARTL.BO',
'Biocon','BIOCON.BO',
'Britannia Industries','BRITANNIA.BO',
'Cholamandalam','CHOLAFIN.BO',
'Cipla','CIPLA.BO',
'Coal India','COALINDIA.BO',
'Colgate-Palmolive India','COLPAL.BO',
'Container India','CONCOR.BO',
'Crompton Greaves Consumer','CROMPTON.BO',
'Dabur India','DABUR.BO',
'Divi´s Labs','DIVISLAB.BO',
'DLF','DLF.BO',
'Dr. Reddy´s Labs','DRREDDY.BO',
'Eicher Motors','EICHERMOT.BO',
'Federal Bank','FEDERALBNK.BO',
'GAIL Ltd','GAIL.BO',
'Godrej Consumer','GODREJCP.BO',
'Grasim Industries','GRASIM.BO',
'Havells India','HAVELLS.BO',
'HCL Tech','HCLTECH.BO',
'HDFC Bank','HDFCBANK.BO',
'HDFC Life','HDFCLIFE.BO',
'Hero MotoCorp','HEROMOTOCO.BO',
'Hindalco Industries','HINDALCO.BO',
'Hindustan Petroleum','HINDPETRO.BO',
'Hindustan Unilever','HINDUNILVR.BO',
'Housing Development Finance','HDFC.BO',
'ICICI Bank','ICICIBANK.BO',
'ICICI Lombard','ICICIGI.BO',
'ICICI Prudential Life','ICICIPRULI.BO',
'Indian Oil','IOC.BO',
'Indraprastha Gas','IGL.BO',
'IndusInd Bank','INDUSINDBK.BO',
'Info Edge India','NAUKRI.BO',
'Infosys','INFY.BO',
'Interglobe Aviation','INDIGO.BO',
'ITC','ITC.BO',
'JSW Steel','JSWSTEEL.BO',
'Jubilant Foodworks','JUBLFOOD.BO',
'Kotak Mahindra Bank','KOTAKBANK.BO',
'Larsen & Toubro','LT.BO',
'Lupin','LUPIN.BO',
'M.R.F.','MRF.BO',
// falla el and en el ticker. CAN BE FIXED!!! 'Mahindra & Mahindra','M&M.BO',
'Marico','MARICO.BO',
'Maruti Suzuki','MARUTI.BO',
'Motherson Sumi Systems','MOTHERSUMI.BO',
'Nestle India','NESTLEIND.BO',
'NTPC','NTPC.BO',
'Oil & Natural Gas','ONGC.BO',
'Page Industries','PAGEIND.BO',
'Petronet LNG','PETRONET.BO',
'PI Industries','PIIND.BO',
'Pidilite Industries','PIDILITIND.BO',
'Piramal Enterprises','PEL.BO',
'Power Finance','PFC.BO',
'Power Grid','POWERGRID.BO',
'REC','RECLTD.BO',
'Reliance Industries','RELIANCE.BO',
'SBI','SBIN.BO',
'SBI Life Insurance','SBILIFE.BO',
'Shree Cements','SHREECEM.BO',
'Shriram Transport','SRTRANSFIN.BO',
'Siemens Ltd','SIEMENS.BO',
'Sun Pharma','SUNPHARMA.BO',
'Tata Consultancy','TCS.BO',
'Tata Consumer Products','TATACONSUM.BO',
'Tata Motors','TATAMOTORS.BO',
'Tata Motors DV Ltd','TATAMTRDVR.BO',
'Tata Power Co.','TATAPOWER.BO',
'Tata Steel Ltd','TATASTEEL.BO',
'Tech Mahindra','TECHM.BO',
'Titan Company','TITAN.BO',
'Torrent Pharma','TORNTPHARM.BO',
'UltraTech Cement','ULTRACEMCO.BO',
'UPL','UPL.BO',
'Vedanta','VEDL.BO',
'Voltas','VOLTAS.BO',
'Wipro','WIPRO.BO',
'Zee Entertainment','ZEEL.BO'],

BSE_200_STOCKS:[1,
  1,
  1,
  1,
  '₹',
  'BSE 200','BSE-200.BO',
  '3M India','3MINDIA.BO',
'Aarti Industries','AARTIIND.BO',
'ABB India','ABB.BO',
'Abbott India','ABBOTINDIA.BO',
'ACC','ACC.BO',
'Adani Enterprises','ADANIENT.BO',
'Adani Green Energy','ADANIGREEN.BO',
'Adani Ports & SEZ','ADANIPORTS.BO',
'Adani Power','ADANIPOWER.BO',
'Adani Total Gas','ATGL.BO',
'Adani Transmission Ltd','ADANITRANS.BO',
'Aditya Birla Capital','ABCAPITAL.BO',
'Aditya Birla Fashion','ABFRL.BO',
'AIA Engineering','AIAENG.BO',
'Ajanta Pharma Ltd','AJANTPHARM.BO',
'Alembic Pharmaceuticals','APLLTD.BO',
'Alkem Laboratories Ltd','ALKEM.BO',
'Amara Raja Batteries','AMARAJABAT.BO',
'Ambuja Cements','AMBUJACEM.BO',
'Apollo Hospitals','APOLLOHOSP.BO',
'Ashok Leyland','ASHOKLEY.BO',
'Asian Paints','ASIANPAINT.BO',
'Astral Poly Technik','ASTRAL.BO',
'Atul','ATUL.BO',
'AU Small Finance Bank','AUBANK.BO',
'Aurobindo Pharma','AUROPHARMA.BO',
'Avenue Supermarts','DMART.BO',
'AXIS Bank','AXISBANK.BO',
'Bajaj Auto','BAJAJ-AUTO.BO',
'Bajaj Finance','BAJFINANCE.BO',
'Bajaj Finserv','BAJAJFINSV.BO',
'Bajaj Holdings','BAJAJHLDNG.BO',
'Balkrishna Industries','BALKRISIND.BO',
'Bandhan Bank','BANDHANBNK.BO',
'Bank Of Baroda','BANKBARODA.BO',
//'Bank of India','SBIN.BO',
'State Bank of India','SBIN.NS',
'Bata India','BATAINDIA.BO',
'Bayer Cropscience','BAYERCROP.BO',
'Berger Paints','BERGEPAINT.BO',
'Bharat Electronics','BEL.BO',
'Bharat Forge','BHARATFORG.BO',
'Bharat Heavy Electricals','BHEL.BO',
'Bharat Petroleum','BPCL.BO',
'Bharti Airtel','BHARTIARTL.BO',
'Biocon','BIOCON.BO',
'Bosch','BOSCHLTD.BO',
'Britannia Industries','BRITANNIA.BO',
'Cadila Healthcare','CADILAHC.BO',
'Canara Bank','CANBK.BO',
'Castrol India','CASTROLIND.BO',
'Cholamandalam','CHOLAFIN.BO',
'Cipla','CIPLA.BO',
'City Union Bank','CUB.BO',
'Coal India','COALINDIA.BO',
'Colgate-Palmolive India','COLPAL.BO',
'Container India','CONCOR.BO',
'Coromandel Int','COROMANDEL.BO',
'Crompton Greaves Consumer','CROMPTON.BO',
'Cummins India Ltd','CUMMINSIND.BO',
'Dabur India','DABUR.BO',
'Dalmia Bharat B','DALBHARAT.BO',
'Divi´s Labs','DIVISLAB.BO',
'DLF','DLF.BO',
'Dr Lal PathLabs Ltd','LALPATHLAB.BO',
'Dr. Reddy´s Labs','DRREDDY.BO',
'Eicher Motors','EICHERMOT.BO',
'Emami','EMAMILTD.BO',
'Endurance Technologies','ENDURANCE.BO',
'Exide Industries','EXIDEIND.BO',
'Federal Bank','FEDERALBNK.BO',
'GAIL Ltd','GAIL.BO',
'General Insurance India','GICRE.BO',
'Gillette India','GILLETTE.BO',
'Gland','GLAND.BO',
'GlaxoSmithkline Pharma','GLAXO.BO',
'Glenmark Pharma','GLENMARK.BO',
'GMR Infrastructure','GMRINFRA.BO',
'Godrej Consumer','GODREJCP.BO',
'Godrej Industries','GODREJIND.BO',
'Godrej Properties','GODREJPROP.BO',
'Grasim Industries','GRASIM.BO',
'Gujarat Gas','GUJGAS.BO',
'Gujarat State Petronet','GSPL.BO',
'Havells India','HAVELLS.BO',
'HCL Tech','HCLTECH.BO',
'HDFC Asset Management','HDFCAMC.BO',
'HDFC Bank','HDFCBANK.BO',
'HDFC Life','HDFCLIFE.BO',
'Hero MotoCorp','HEROMOTOCO.BO',
'Hindalco Industries','HINDALCO.BO',
'Hindustan Aeronautics','HAL.BO',
'Hindustan Petroleum','HINDPETRO.BO',
'Hindustan Unilever','HINDUNILVR.BO',
'Hindustan Zinc','HINDZINC.BO',
'Honeywell Automation','HONAUT.BO',
'Housing Development Finance','HDFC.BO',
'ICICI Bank','ICICIBANK.BO',
'ICICI Lombard','ICICIGI.BO',
'ICICI Prudential Life Ins','ICICIPRULI.BO',
'IDBI Bank','IDBI.BO',
'IDFC First Bank','IDFCFIRSTB.BO',
'Indian Hotels Co.','INDHOTEL.BO',
'Indian Oil','IOC.BO',
'Indian Overseas Bank','IOB.BO',
'Indian Railway Catering','IRCTC.BO',
'Indraprastha Gas','IGL.BO',
'IndusInd Bank','INDUSINDBK.BO',
'Info Edge India','NAUKRI.BO',
'Infosys','INFY.BO',
'Interglobe Aviation','INDIGO.BO',
'Ipca Laboratories','IPCALAB.BO',
'ITC','ITC.BO',
'Jindal Steel&Power','JINDALSTEL.BO',
'JSW Energy','JSWENERGY.BO',
'JSW Steel','JSWSTEEL.BO',
'Jubilant Foodworks','JUBLFOOD.BO',
'Kansai Nerolac Paints','KANSAINER.BO',
'Kotak Mahindra Bank','KOTAKBANK.BO',
// falla el and en el ticker. CAN BE FIXED!!! 'L&T Finance Hld','L&TFH.BO',
'L&T Technology Services','LTTS.BO',
'Larsen & Toubro','LT.BO',
'Larsen & Toubro Infotech','LTI.BO',
'Lic Housing Finance','LICHSGFIN.BO',
'Lupin','LUPIN.BO',
'M.R.F.','MRF.BO',
// falla el and en el ticker. CAN BE FIXED!!! 'Mahindra & Mahindra','M&M.BO',
// falla el and en el ticker. CAN BE FIXED!!! 'Mahindra & Mahindra Fin','M&MFIN.BO',
'Marico','MARICO.BO',
'Maruti Suzuki','MARUTI.BO',
'Max Financial','MFSL.BO',
'MindTree','MINDTREE.BO',
'Motherson Sumi Systems','MOTHERSUMI.BO',
'Mphasis','MPHASIS.BO',
'Muthoot Finance','MUTHOOTFIN.BO',
'Natco Pharma','NATCOPHARM.BO',
'Nestle India','NESTLEIND.BO',
'New India Assurance','NIACL.BO',
'NHPC','NHPC.BO',
'Nippon India','NAM-INDIA.BO',
'NMDC','NMDC.BO',
'NTPC','NTPC.BO',
'Oberoi Realty','OBEROIRLTY.BO',
'Oil & Natural Gas','ONGC.BO',
'Oil India','OIL.BO',
'Oracle','OFSS.BO',
'Page Industries','PAGEIND.BO',
'Petronet LNG','PETRONET.BO',
'Pfizer Ltd','PFIZER.BO',
'PI Industries','PIIND.BO',
'Pidilite Industries','PIDILITIND.BO',
'Piramal Enterprises','PEL.BO',
'Polycab India','POLYCAB.BO',
'Power Finance','PFC.BO',
'Power Grid','POWERGRID.BO',
'Procter & Gamble Hygiene','PGHH.BO',
'Punjab National Bank','PNB.BO',
'Rajesh Exports','RAJESHEXPO.BO',
'RBL Bank','RBLBANK.BO',
'REC','RECLTD.BO',
'Relaxo Footwears Ltd','RELAXO.BO',
'Reliance Industries','RELIANCE.BO',
'Sanofi India','SANOFI.BO',
'SBI','SBIN.BO',
'SBI Cards','SBICARD.BO',
'SBI Life Insurance','SBILIFE.BO',
'Shree Cements','SHREECEM.BO',
'Shriram Transport','SRTRANSFIN.BO',
'Siemens Ltd','SIEMENS.BO',
'SRF','SRF.BO',
'Steel Authority','SAIL.BO',
'Sun Pharma','SUNPHARMA.BO',
'Sun TV Network Ltd','SUNTV.BO',
'Supreme Industries','SUPREMEIND.BO',
'Syngene International Ltd','SYNGENE.BO',
'Tata Communications','TATACOMM.BO',
'Tata Consultancy','TCS.BO',
'Tata Consumer Products','TATACONSUM.BO',
'Tata Motors','TATAMOTORS.BO',
'Tata Motors DV Ltd','TATAMTRDVR.BO',
'Tata Power Co.','TATAPOWER.BO',
'Tata Steel Ltd','TATASTEEL.BO',
'Tech Mahindra','TECHM.BO',
'The Ramco Cements','RAMCOCEM.BO',
'Thermax','THERMAX.BO',
'Titan Company','TITAN.BO',
'Torrent Pharma','TORNTPHARM.BO',
'Torrent Power Ltd','TORNTPOWER.BO',
'Trent','TRENT.BO',
'TVS Motor Company','TVSMOTOR.BO',
'UltraTech Cement','ULTRACEMCO.BO',
'Union Bank of India','UNIONBANK.BO',
'United Breweries Ltd','UBL.BO',
'UPL','UPL.BO',
'Varun Beverages','VBL.BO',
'Vedanta','VEDL.BO',
'Vodafone Idea','IDEA.BO',
'Voltas','VOLTAS.BO',
'Whirlpool of India','WHIRLPOOL.BO',
'Wipro','WIPRO.BO',
'Zee Entertainment','ZEEL.BO'],




BSE_500_STOCKS:[1,
  1,
  1,
  1,
  '₹',
  'BSE 500','BSE-500.BO',
  '3M India','3MINDIA.BO',
'Aarti Drugs Ltd','AARTIDRUGS.BO',
'Aarti Industries','AARTIIND.BO',
'Aavas Financiers','AAVAS.BO',
'ABB India','ABB.BO',
'Abb Power','POWERINDIA.BO',
'Abbott India','ABBOTINDIA.BO',
'ACC','ACC.BO',
'Adani Enterprises','ADANIENT.BO',
'Adani Green Energy','ADANIGREEN.BO',
'Adani Ports & SEZ','ADANIPORTS.BO',
'Adani Power','ADANIPOWER.BO',
'Adani Total Gas','ATGL.BO',
'Adani Transmission Ltd','ADANITRANS.BO',
'Aditya Birla Capital','ABCAPITAL.BO',
'Aditya Birla Fashion','ABFRL.BO',
'Advanced Enzyme Tech','ADVENZYMES.BO',
'Aegis Logistics Ltd','AEGISLOG.BO',
'Affle India','AFFLE.BO',
'AIA Engineering','AIAENG.BO',
'Ajanta Pharma Ltd','AJANTPHARM.BO',
'Akzo Nobel India','AKZOINDIA.BO',
'Alembic Pharmaceuticals Ltd','APLLTD.BO',
'Alkem Laboratories Ltd','ALKEM.BO',
'Alkyl Amines Chemicals Ltd','ALKYLAMINE.BO',
'Allcargo Logistics','ALLCARGO.BO',
'Alok Industries','ALOKTEXT.BO',
'Amara Raja Batteries','AMARAJABAT.BO',
'Amber Enterprises India','AMBER.BO',
'Ambuja Cements','AMBUJACEM.BO',
'APL Apollo Tubes Ltd','APLAPOLLO.BO',
'Apollo Hospitals','APOLLOHOSP.BO',
'Apollo Tyres','APOLLOTYRE.BO',
'Ashok Leyland','ASHOKLEY.BO',
'Ashoka Buildcon Ltd','ASHOKA.BO',
'Asian Paints','ASIANPAINT.BO',
'Aster DM Healthcare','ASTERDM.BO',
'Astral Poly Technik Ltd','ASTRAL.BO',
'AstraZeneca Pharma','ASTRAZEN.BO',
'Atul','ATUL.BO',
'AU Small Finance Bank','AUBANK.BO',
'Aurobindo Pharma','AUROPHARMA.BO',
'Avanti Feeds','AVANTI.BO',
'Avenue Supermarts','DMART.BO',
'AXIS Bank','AXISBANK.BO',
'Bajaj Auto','BAJAJ-AUTO.BO',
'Bajaj Consumer Care','BAJAJCON.BO',
'Bajaj Electricals','BAJAJELEC.BO',
'Bajaj Finance','BAJFINANCE.BO',
'Bajaj Finserv','BAJAJFINSV.BO',
'Bajaj Holdings','BAJAJHLDNG.BO',
'Balaji Amines Ltd','BALAMINES.BO',
'Balkrishna Industries','BALKRISIND.BO',
'Balmer Lawrie&Co.','BALMLAWRIE.BO',
'Balrampur Chini Mills','BALRAMCHIN.BO',
'Bandhan Bank','BANDHANBNK.BO',
'Bank Of Baroda','BANKBARODA.BO',
'Bank of India','SBIN.NS',
'Bank of maharashtra','MAHABANK.BO',
'BASF India','BASF.BO',
'Bata India','BATAINDIA.BO',
'Bayer Cropscience','BAYERCROP.BO',
'BEML','BEML.BO',
'Berger Paints','BERGEPAINT.BO',
'Bharat Dynamics','BDL.BO',
'Bharat Electronics','BEL.BO',
'Bharat Forge','BHARATFORG.BO',
'Bharat Heavy Electricals','BHEL.BO',
'Bharat Petroleum','BPCL.BO',
'Bharti Airtel','BHARTIARTL.BO',
'Biocon','BIOCON.BO',
'Birla Corporation','BIRLACORPN.BO',
'Birlasoft','BSOFT.BO',
'Bliss GVS Pharma Ltd','BLISSGVS.BO',
'Blue Dart Express','BLUEDART.BO',
'Blue Star','BLUESTARCO.BO',
'Bombay Burmah Trading','BBTC.BO',
'Bosch','BOSCHLTD.BO',
'Brigade Enterprises','BRIGADE.BO',
'Britannia Industries','BRITANNIA.BO',
'Burger King','BURGERKING.BO',
'Cadila Healthcare','CADILAHC.BO',
'Can Fin Homes','CANFINHOME.BO',
'Canara Bank','CANBK.BO',
'Caplin Point Laboratories Ltd','CAPPL.BO',
'Capri Global Capital','CGCL.BO',
'Carborundum Universal','CARBORUNIV.BO',
'Castrol India','CASTROLIND.BO',
'CBI','CENTRALBK.BO',
'CCL Products India Ltd','CCL.BO',
'CEAT Ltd','CEATLTD.BO',
'Century Plyboards','CENTURYPLY.BO',
'Century Textiles','CENTURYTEX.BO',
'Cera Sanitaryware Ltd','CERA.BO',
'CESC','CESC.BO',
'Chalet Hotels','CHALET.BO',
'Chambal Fertilisers','CHAMBLFERT.BO',
'Cholamandalam','CHOLAFIN.BO',
'Cholamandalam Financial','CHOLAHLDNG.BO',
'Cipla','CIPLA.BO',
'City Union Bank','CUB.BO',
'Coal India','COALINDIA.BO',
'Cochin Shipyard','COCHINSHIP.BO',
'Coforge','COFORGE.BO',
'Colgate-Palmolive India','COLPAL.BO',
'Computer Age','CAMS.BO',
'Container India','CONCOR.BO',
'Coromandel Int','COROMANDEL.BO',
'CreditAccess Grameen','CREDITACC.BO',
'CRISIL','CRISIL.BO',
'Crompton Greaves Consumer Electricals Ltd','CROMPTON.BO',
'Csb Bank','CSBBANK.BO',
'Cummins India Ltd','CUMMINSIND.BO',
'Cyient Ltd-B','CYIENT.BO',
'Dabur India','DABUR.BO',
'Dalmia Bharat B','DALBHARAT.BO',
'DCB Bank Ltd','DCBBANK.BO',
'DCM Shriram','DCMSHRIRAM.BO',
'Deepak','DEEPAKFERT.BO',
'Deepak Nitrite Ltd','DEEPAKNI.BO',
'Delta Corp','DELTACORP.BO',
'Dhanuka Agritech Ltd','DHANUKA.BO',
'Dilip Buildcon','DBL.BO',
'Dish TV India','DISHTV.BO',
'Dishman Carbogen Amcis','DCAL.BO',
'Divi´s Labs','DIVISLAB.BO',
'Dixon Tech','DIXON.BO',
'DLF','DLF.BO',
'Dr Lal PathLabs Ltd','LALPATHLAB.BO',
'Dr. Reddy´s Labs','DRREDDY.BO',
'E.I.D-Parry','EIDPARRY.BO',
'eClerx Services','ECLERX.BO',
'Edelweiss Financial','EDELWEISS.BO',
'Eicher Motors','EICHERMOT.BO',
'EIH','EIHOTEL.BO',
'Emami','EMAMILTD.BO',
'Endurance Technologies Cn','ENDURANCE.BO',
'Engineers India','ENGINERSIN.BO',
'EPL','EPL.BO',
'Equitas Holdings','EQUITAS.BO',
'Equitas Small Finance','EQUITASBNK.BO',
'Eris Lifesciences','ERIS.BO',
'Esab India','ESABINDIA.BO',
'Escorts','ESCORTS.BO',
'Exide Industries','EXIDEIND.BO',
'FDC','FDC.BO',
'Federal Bank','FEDERALBNK.BO',
'Fine Organic','FINEORG.BO',
'Finolex Cables','FINCABLES.BO',
'Finolex Industries','FINOLEXIND.BO',
'Firstsource Solutions','FSL.BO',
'Force Motors','FORCEMOT.BO',
'Fortis Healthcare','FORTIS.BO',
'Future Consumer','FCONSUMER.BO',
'Future Retail','FRETAIL.BO',
'GAIL Ltd','GAIL.BO',
'Galaxy Surfactants','GALAXYSURF.BO',
'Garden Reach','GRSE.BO',
'Garware Wall Ropes Ltd','GARFIBRES.BO',
//'Gateway Distriparks','GDL.BO',
'GE Power India','GEPIL.BO',
'General Insurance India','GICRE.BO',
'GHCL','GHCL.BO',
'Gillette India','GILLETTE.BO',
'Gland','GLAND.BO',
'GlaxoSmithkline Pharma','GLAXO.BO',
'Glenmark Pharma','GLENMARK.BO',
'GMM Pfaudler','GMM.BO',
'GMR Infrastructure','GMRINFRA.BO',
'Godfrey Phillips India','GODFRYPHLP.BO',
'Godrej Agrovet','GODREJAGRO.BO',
'Godrej Consumer','GODREJCP.BO',
'Godrej Industries','GODREJIND.BO',
'Godrej Properties','GODREJPROP.BO',
'Granules India','GRANULES.BO',
'Graphite India','GRAPHITE.BO',
'Grasim Industries','GRASIM.BO',
'Great Eastern Shipping','GESHIP.BO',
'Greaves Cotton','GREAVESCOT.BO',
'Grindwell Norton Ltd','GRINDWELL.BO',
'Gujarat Alkalis&Chemicals','GUJALKALI.BO',
'Gujarat Fluorochemicals','FLUOROCHEM.BO',
'Gujarat Gas','GUJGAS.BO',
'Gujarat Mineral','GMDCLTD.BO',
'Gujarat Narmada Valley Fert.','GNFC.BO',
'Gujarat Pipavav Port','GPPL.BO',
'Gujarat State Fertilizers','GSFC.BO',
'Gujarat State Petronet','GSPL.BO',
'Gulf Oil Lubricants India Ltd','GULFOILLUB.BO',
'Happiest Minds','HAPPSTMNDS.BO',
'Hathway Cable','HATHWAY.BO',
'Havells India','HAVELLS.BO',
'Hawkins Cookers Ltd-BO','HAWKINCOOK.BO',
'HCL Tech','HCLTECH.BO',
'HDFC Asset Management','HDFCAMC.BO',
'HDFC Bank','HDFCBANK.BO',
'HDFC Life','HDFCLIFE.BO',
'HEG','HEG.BO',
'HeidelbergCement India','HEIDELBERG.BO',
'Hero MotoCorp','HEROMOTOCO.BO',
'HFCL','HFCL.BO',
'Himadri Speciality Chem','HSCL.BO',
'Hindalco Industries','HINDALCO.BO',
'Hindustan Aeronautics','HAL.BO',
'Hindustan Copper','HINDCOPPER.BO',
'Hindustan Petroleum','HINDPETRO.BO',
'Hindustan Unilever','HINDUNILVR.BO',
'Hindustan Zinc','HINDZINC.BO',
'Honeywell Automation','HONAUT.BO',
'Housing Development Finance','HDFC.BO',
'Housing Urban Develop','HUDCO.BO',
'ICICI Bank','ICICIBANK.BO',
'ICICI Lombard','ICICIGI.BO',
'ICICI Prudential Life Insurance','ICICIPRULI.BO',
'ICICI Securities','ISEC.BO',
'IDBI Bank','IDBI.BO',
'IDFC','IDFC.BO',
'IDFC First Bank','IDFCFIRSTB.BO',
'IFB Industries Ltd','IFBIND.BO',
'IFCI','IFCI.BO',
'IIFL Fiinance B','IIFL.BO',
'Iifl Wealth','IIFLWAM.BO',
'India Cements','INDIACEM.BO',
'India Tourism Development','ITDC.BO',
'Indiabulls','IBULHSGFIN.BO',
'Indiabulls RE','IBREALEST.BO',
'IndiaMART InterMESH','INDIAMART.BO',
'Indian Bank','INDIANB.BO',
'Indian Energy Exchange','IEX.BO',
'Indian Hotels Co.','INDHOTEL.BO',
'Indian Oil','IOC.BO',
'Indian Overseas Bank','IOB.BO',
'Indian Railway Catering','IRCTC.BO',
'Indoco Remedies Ltd','INDOCO.BO',
'IndoStar Capital','INDOSTAR.BO',
'Indraprastha Gas','IGL.BO',
'IndusInd Bank','INDUSINDBK.BO',
'Infibeam Avenues','INFIBEAM.BO',
'Info Edge India','NAUKRI.BO',
'Infosys','INFY.BO',
'Inox Leisure','INOXLEISUR.BO',
'Intellect Design Arena','INTELLECT.BO',
'Interglobe Aviation Ltd','INDIGO.BO',
'IOL Chemicals Pharmaceuticals','IOLCP.BO',
'Ipca Laboratories','IPCALAB.BO',
'IRB Infrastructure','IRB.BO',
'Ircon','IRCON.BO',
'ITC','ITC.BO',
'ITI Ltd','ITI.BO',
'J.B. Chemicals&Pharma','JBCHEPHARM.BO',
'J.K. Cement','JKCEMENT.BO',
'Jai Corp','JAICORPLTD.BO',
// falla el and en el ticker. CAN BE FIXED!!! 'Jammu and Kashmir Bank','J&KBANK.BO',
'Jamna Auto','JAMNAAUTO.BO',
'Jindal Saw','JINDALSAW.BO',
'Jindal Stainless','JSL.BO',
'Jindal Stainless','JSLHISAR.BO',
'Jindal Steel&Power','JINDALSTEL.BO',
'JK Lakshmi Cement','JKLAKSHMI.BO',
'JK Paper Ltd','JKPAPER.BO',
'JK Tyre&Industries','JKTYRE.BO',
'JM Financial','JMFINANCIL.BO',
'Johnson Controls-Hitachi Air','JCHAC.BO',
'JSW Energy','JSWENERGY.BO',
'JSW Steel','JSWSTEEL.BO',
'JTEKT India','JTEKTINDIA.BO',
'Jubilant Foodworks','JUBLFOOD.BO',
'Just Dial','JUSTDIAL.BO',
'Jyothy Labs','JYOTHYLAB.BO',
'Kajaria Ceramics','KAJARIACER.BO',
'Kalpataru Power','KALPATPOWR.BO',
'Kansai Nerolac Paints','KANSAINER.BO',
'Karnataka Bank','KTKBANK.BO',
'Kaveri Seed','KSCL.BO',
'KEC International','KEC.BO',
'KEI Industries Ltd','KEI.BO',
'KNR Constructions','KNRCON.BO',
'Kotak Mahindra Bank','KOTAKBANK.BO',
'KPIT Tech','KPITTECH.BO',
'KPR Mill','KPRMILL.BO',
'KRBL Ltd','KRBL.BO',
'KSB','KSB.BO',
// falla el and en el ticker. CAN BE FIXED!!! 'L&T Finance Hld','L&TFH.BO',
'L&T Technology Services','LTTS.BO',
'La Opala R G Ltd','LAOPALA.BO',
'Lakshmi Machine Works','LAXMIMACH.BO',
'Larsen & Toubro','LT.BO',
'Larsen & Toubro Infotech','LTI.BO',
'Laurus Labs','LAURUSLABS.BO',
'Lemon Tree Hotels','LEMONTREE.BO',
'Lic Housing Finance','LICHSGFIN.BO',
'Linde India','LINDEINDIA.BO',
'Lupin','LUPIN.BO',
'Lux Industries Ltd','LUXIND.BO',
'M.R.F.','MRF.BO',
'Mahanagar Gas','MGL.BO',
'Maharashtra Scooters','MAHSCOOTER.BO',
'Maharashtra Seamless','MAHSEAMLES.BO',
'Mahindra','MAHLOG.BO',
// falla el and en el ticker. CAN BE FIXED!!! 'Mahindra & Mahindra','M&M.BO',
// falla el and en el ticker. CAN BE FIXED!!! 'Mahindra & Mahindra Financial','M&MFIN.BO',
'Mahindra CIE Automotive Ltd','MAHINDCIE.BO',
'Mahindra Holidays','MHRIL.BO',
'Mahindra Lifespace','MAHLIFE.BO',
'Manappuram Finance','MANAPPURAM.BO',
'Mangalore','MRPL.BO',
'Marico','MARICO.BO',
'Maruti Suzuki','MARUTI.BO',
'MAS Financial','MASFIN.BO',
'Max Financial','MFSL.BO',
'Max Healthcare Institute','MAXHEALTH.BO',
'Mazagon Dock','MAZDOCK.BO',
'Metropolis Healthcare','METROPOLIS.BO',
'Minda Corp','MINDACORP.BO',
'Minda Industries','MINDAIND.BO',
'MindTree','MINDTREE.BO',
'Mishra Dhatu Nigam','MIDHANI.BO',
'MMTC','MMTC.BO',
'MOIL','MOIL.BO',
'Motherson Sumi Systems','MOTHERSUMI.BO',
'Motilal Oswal','M50.BO',
'Mphasis','MPHASIS.BO',
'Multi Commodity Exchange','MCX.BO',
'Muthoot Finance','MUTHOOTFIN.BO',
'Narayana Hrudayalaya','NH.BO',
'Natco Pharma','NATCOPHARM.BO',
'National Aluminum Co.','NATIONALUM.BO',
'Navin Fluorine','NAVINFLUOR.BO',
'Navneet Education','NAVNETEDUL.BO',
'NBCC India','NBCC.BO',
'NCC','NCC.BO',
'Nesco','NESCO.BO',
'Nestle India','NESTLEIND.BO',
'Network 18 Media','NETWORK18.BO',
'New India Assurance','NIACL.BO',
'NHPC','NHPC.BO',
'Nilkamal','NILKAMAL.BO',
'Nippon India','NAM-INDIA.BO',
'NLC India','NLCINDIA.BO',
'NMDC','NMDC.BO',
'Nocil Ltd','NOCIL.BO',
'NTPC','NTPC.BO',
'Oberoi Realty','OBEROIRLTY.BO',
'Oil & Natural Gas','ONGC.BO',
'Oil India','OIL.BO',
'Oracle','OFSS.BO',
'Orient Cement Ltd','ORIENTCEM.BO',
'Orient Electric','ORIENTELEC.BO',
'Orient Refractories Ltd','ORIENTREF.BO',
'Page Industries','PAGEIND.BO',
'Persistent Systems','PERSISTENT.BO',
'Petronet LNG','PETRONET.BO',
'Pfizer Ltd','PFIZER.BO',
//'Phillips Carbon Black','PHILIPCARB.BO',
'PI Industries','PIIND.BO',
'Pidilite Industries','PIDILITIND.BO',
'Piramal Enterprises','PEL.BO',
'PNB Housing Finance','PNBHOUSING.BO',
'PNC Infratech','PNCINFRA.BO',
'Poly Medicure Ltd','POLYMED.BO',
'Polycab India','POLYCAB.BO',
'Power Finance','PFC.BO',
'Power Grid','POWERGRID.BO',
'Prestige Estates','PRESTIGE.BO',
'Prism Johnson','PRSMJOHNSN.BO',
'Procter & Gamble Health','PGHL.BO',
'Procter & Gamble Hygiene','PGHH.BO',
'PTC India','PTC.BO',
'Punjab National Bank','PNB.BO',
'PVR','PVR.BO',
'Quess Corp','QUESS.BO',
'Radico Khaitan','RADICO.BO',
'Rail Vikas Nigam','RVNL.BO',
'Rain Industries','RAIN.BO',
'Rajesh Exports','RAJESHEXPO.BO',
'Rallis India','RALLIS.BO',
'Ratnamani Metals Tubes','RATNAMANI.BO',
'RattanIndia Power','RTNPOWER.BO',
'Raymond','RAYMOND.BO',
'RBL Bank','RBLBANK.BO',
'REC','RECLTD.BO',
'Redington India','REDINGTON.BO',
'Relaxo Footwears Ltd','RELAXO.BO',
'Reliance Industries','RELIANCE.BO',
'Responsive Industries','RESPONIND.BO',
'RITES','RITES.BO',
'Rossari Biotech','ROSSARI.BO',
'Route Mobile','ROUTE.BO',
'S H Kelkar And Company Ltd','SHK.BO',
'Sanofi India','SANOFI.BO',
'SBI','SBIN.BO',
'SBI Cards','SBICARD.BO',
'SBI Life Insurance','SBILIFE.BO',
'Schaeffler India','SCHAEFFLER.BO',
'Schneider Electric Infrastructure','SCHNEIDER.BO',
'SeQuent Scientific','SEQUENT.BO',
'Sheela Foam','SFL.BO',
'Shilpa Medicare Ltd','SHILPAMED.BO',
'Shipping Corporation','SCI.BO',
'Shoppers Stop','SHOPERSTOP.BO',
'Shree Cements','SHREECEM.BO',
'Shriram Transport','SRTRANSFIN.BO',
'Shriram-City Union','SHRIRAMCIT.BO',
'Siemens Ltd','SIEMENS.BO',
'SIS','SIS.BO',
'SJVN','SJVN.BO',
'SKF India','SKFINDIA.BO',
'Sobha Developers','SOBHA.BO',
'Solar Industries India Ltd','SOLARINDS.BO',
'Solara Active Pharma','SOLARA.BO',
'Sonata Software','SONATSOFTW.BO',
'South Indian Bank','SOUTHBANK.BO',
'Spandana Sphoorty Financial Ltd BO','SPANDANA.BO',
'Spicejet','SPICEJET.BO',
'SRF','SRF.BO',
'Star Cement','STARCEMENT.BO',
'Steel Authority','SAIL.BO',
'Sterling and Wilson Solar','SWSOLAR.BO',
'Sterlite Technologies','STLTECH.BO',
'Strides Pharma','STAR.BO',
'Sudarshan Chemical Industries','SUDARSCHEM.BO',
'Sumitomo Chemical','SUMICHEM.BO',
'Sun Pharma','SUNPHARMA.BO',
'Sun Pharma Adv. Research','SPARC.BO',
'Sun TV Network Ltd','SUNTV.BO',
'Sundram Fasteners','SUNDRMFAST.BO',
'Sunteck Realty','SUNTECK.BO',
'Supreme Industries','SUPREMEIND.BO',
'Suven Pharma','SUVENPHAR.BO',
'Suzlon Energy','SUZLON.BO',
'Swan Energy','SWANENERGY.BO',
'Symphony Ltd','SYMPHONY.BO',
'Syngene International Ltd','SYNGENE.BO',
'Tanla Solutions','TANLA.BO',
'Tasty Bite Eatables','TASTYBIT.BO',
'Tata Chemicals','TATACHEM.BO',
'Tata Coffee','TATACOFFEE.BO',
'Tata Communications','TATACOMM.BO',
'Tata Consultancy','TCS.BO',
'Tata Consumer Products','TATACONSUM.BO',
'Tata Elxsi','TATAELXSI.BO',
'Tata Investment','TATAINVEST.BO',
'Tata Metaliks','TATAMETALI.BO',
'Tata Motors','TATAMOTORS.BO',
'Tata Motors DV Ltd','TATAMTRDVR.BO',
'Tata Power Co.','TATAPOWER.BO',
'Tata Steel Ltd','TATASTEEL.BO',
'TCI Express','TCIEXP.BO',
'TCNS Clothing','TCNSBRANDS.BO',
'Teamlease Services','TEAMLEASE.BO',
'Tech Mahindra','TECHM.BO',
'The Phoenix Mills','PHOENIXLTD.BO',
'The Ramco Cements','RAMCOCEM.BO',
'Thermax','THERMAX.BO',
'Thyrocare Technologies Ltd','THYROCARE.BO',
'Timken India','TIMKEN.BO',
'Titan Company','TITAN.BO',
'Torrent Pharma','TORNTPHARM.BO',
'Torrent Power Ltd','TORNTPOWER.BO',
'Trent','TRENT.BO',
'Trident','TRIDENT.BO',
'Triveni Turbine','TRITURBINE.BO',
'TTK Prestige','TTKPRESTIG.BO',
'Tube Invest India','TIINDIA.BO',
'TV18 Broadcast','TV18BRDCST.BO',
'TVS Motor Company','TVSMOTOR.BO',
'UCO Bank','UCOBANK.BO',
'Uflex','UFLEX.BO',
'Ujjivan Financial Services Ltd','UJJIVAN.BO',
'Ujjivan Small Fin','UJJIVANSFB.BO',
'UltraTech Cement','ULTRACEMCO.BO',
'Union Bank of India','UNIONBANK.BO',
'United Breweries Ltd','UBL.BO',
'UPL','UPL.BO',
'Uti Asset','UTIAMC.BO',
'V Guard Industries','VGUARD.BO',
'V Mart Retail Ltd','VMART.BO',
'Vaibhav Global','VAIBHAVGBL.BO',
'Vakrangee Softwares','VAKRANGEE.BO',
'Vardhman Textiles','VTL.BO',
'Varroc Engineering','VARROC.BO',
'Varun Beverages','VBL.BO',
'Vedanta','VEDL.BO',
'Venkys India','VENKYS.BO',
'Vinati Organics Ltd','VINATIORGA.BO',
'VIP Industries','VIPIND.BO',
'Vodafone Idea','IDEA.BO',
'Voltas','VOLTAS.BO',
'VRL Logistics Ltd','VRLLOG.BO',
'VST Industries','VSTIND.BO',
'WABCO India','WABCOINDIA.BO',
'Welspun','WELSPUNIND.BO',
'Welspun Corp','WELCORP.BO',
'Westlife Development','WESTLIFE.BO',
'Whirlpool of India','WHIRLPOOL.BO',
'Wipro','WIPRO.BO',
'Wockhardt','WOCKPHARMA.BO',
'Zee Entertainment','ZEEL.BO',
'Zensar Tech','ZENSARTECH.BO',
'Zydus Wellness','ZYDUSWELL.BO'],

/*
BSE_ALLCAPS_STOCKS:[1,
  1,
  1,
  1,
  '₹',
  'BSE ALLCAPS','ALLCAP.BO',
  '3i Infotech','3IINFOTECH.BO',
'3M India','3MINDIA.BO',
'63 Moons Tech','63MOONS.BO',
'Aarti Drugs Ltd','AARTIDRUGS.BO',
'Aarti Industries','AARTIIND.BO',
'Aarti Surfactants','AARTISURF.BO',
'Aavas Financiers','AAVAS.BO',
'ABB India','ABB.BO',
'Abb Power','POWERINDIA.BO',
'Abbott India','ABBOTINDIA.BO',
'ACC','ACC.BO',
'Accelya Solutions','ACCELYA.BO',
'Action Construction Equipment','ACE.BO',
'Adani Enterprises','ADANIENT.BO',
'Adani Green Energy','ADANIGREEN.BO',
'Adani Ports & SEZ','ADANIPORTS.BO',
'Adani Power','ADANIPOWER.BO',
'Adani Total Gas','ATGL.BO',
'Adani Transmission Ltd','ADANITRANS.BO',
'Aditya Birla Capital','ABCAPITAL.BO',
'Aditya Birla Fashion','ABFRL.BO',
'Aditya Birla Money Ltd','BIRLAMONEY.BO',
'Advanced Enzyme Tech','ADVENZYMES.BO',
'Aegis Logistics Ltd','AEGISLOG.BO',
'Affle India','AFFLE.BO',
'Agro Tech Foods','ATFL.BO',
'AIA Engineering','AIAENG.BO',
'Ajanta Pharma Ltd','AJANTPHARM.BO',
'Ajmera Realty Infra India','AJMERA.BO',
'Akzo Nobel India','AKZOINDIA.BO',
'Alankit Ltd','ALANKIT.BO',
'Albert David','ALBERTDA.BO',
'Alembic Ltd','ALEMBICLTD.BO',
'Alembic Pharmaceuticals Ltd','APLLTD.BO',
'Alkem Laboratories Ltd','ALKEM.BO',
'Alkyl Amines Chemicals Ltd','ALKYLAMINE.BO',
'Allcargo Logistics','ALLCARGO.BO',
'Alok Industries','ALOKTEXT.BO',
'Amara Raja Batteries','AMARAJABAT.BO',
'Amber Enterprises India','AMBER.BO',
'Ambika Cotton Mills Ltd','AMBIKCO.BO',
'Ambuja Cements','AMBUJACEM.BO',
'Anant Raj','ANANTRAJ.BO',
'Angel Broking','ANGELBRKG.BO',
'Anup Engineering','ANUP.BO',
'Apar Industries Ltd','APARINDS.BO',
'Apcotex Industries Ltd','APCOTEXIND.BO',
'Apex Frozen Foods','APEX.BO',
'APL Apollo Tubes Ltd','APLAPOLLO.BO',
'Apollo Hospitals','APOLLOHOSP.BO',
'Apollo Micro Systems','APOLLO.BO',
'Apollo Pipes','APOLLOPIPES.BO',
'Apollo Tricoat Tubes','APOLLOTRI.BO',
'Apollo Tyres','APOLLOTYRE.BO',
'Aptech','APTECHT.BO',
'Arman Financial','ARMANFIN.BO',
'Arvind','ARVIND.BO',
'Arvind Fashions','ARVINDFASN.BO',
'Ashiana Housing Ltd','ASHIANA.BO',
'Ashok Leyland','ASHOKLEY.BO',
'Ashoka Buildcon Ltd','ASHOKA.BO',
'Asian Granito India Ltd','ASIANTILES.BO',
'Asian Paints','ASIANPAINT.BO',
'Associated Alcohols & Breweries','ASALCBR.BO',
'Astec Lifesciences Ltd','ASTEC.BO',
'Aster DM Healthcare','ASTERDM.BO',
'Astra Micro Wave Products Ltd','ASTRAMICRO.BO',
'Astral Poly Technik Ltd','ASTRAL.BO',
'AstraZeneca Pharma','ASTRAZEN.BO',
'Astron Paper','ASTRON.BO',
'Atul','ATUL.BO',
'Atul Auto Ltd','ATULAUTO.BO',
'AU Small Finance Bank','AUBANK.BO',
'Aurobindo Pharma','AUROPHARMA.BO',
'Automotive Axles','AUTOAXLES.BO',
'Avadh Sugar Energy','AVADHSUGAR.BO',
'Avanti Feeds','AVANTI.BO',
'Avenue Supermarts','DMART.BO',
'AVT Natural Products Ltd','AVTNPL.BO',
'AXIS Bank','AXISBANK.BO',
'AXISCADES Engineering Tech','AXISCADES.BO',
'Bajaj Auto','BAJAJ-AUTO.BO',
'Bajaj Consumer Care','BAJAJCON.BO',
'Bajaj Electricals','BAJAJELEC.BO',
'Bajaj Finance','BAJFINANCE.BO',
'Bajaj Finserv','BAJAJFINSV.BO',
'Bajaj Hindustan','BAJAJHIND.BO',
'Bajaj Holdings','BAJAJHLDNG.BO',
'Balaji Amines Ltd','BALAMINES.BO',
'Balaji Telefilms','BALAJITELE.BO',
'Balkrishna Industries','BALKRISIND.BO',
'Balmer Lawrie Investments Ltd','BLIL.BO',
'Balmer Lawrie&Co.','BALMLAWRIE.BO',
'Balrampur Chini Mills','BALRAMCHIN.BO',
'Banco Products India','BANCOINDIA.BO',
'Bandhan Bank','BANDHANBNK.BO',
'Bank Of Baroda','BANKBARODA.BO',
'Bank of India','SBIN.BO',
'Bank of maharashtra','MAHABANK.BO',
'BASF India','BASF.BO',
'Bata India','BATAINDIA.BO',
'Bayer Cropscience','BAYERCROP.BO',
'BEML','BEML.BO',
'Berger Paints','BERGEPAINT.BO',
'BF Investment Ltd','BFINVEST.BO',
'BF Utilities','BFUTILITIE.BO',
'BGR Energy Systems','BGRENERGY.BO',
'Bhageria Industries','BHAGIL.BO',
'Bhansali Eng Polymers','BEPL.BO',
'Bharat Bijlee Ltd','BBL.BO',
'Bharat Dynamics','BDL.BO',
'Bharat Electronics','BEL.BO',
'Bharat Forge','BHARATFORG.BO',
'Bharat Heavy Electricals','BHEL.BO',
'Bharat Petroleum','BPCL.BO',
'Bharti Airtel','BHARTIARTL.BO',
'Biocon','BIOCON.BO',
'Birla Corporation','BIRLACORPN.BO',
'Birlasoft','BSOFT.BO',
'Black Rose Ind','BLACKROSE.BO',
'Bliss GVS Pharma Ltd','BLISSGVS.BO',
'BLS International Services','BLS.BO',
'Blue Dart Express','BLUEDART.BO',
'Blue Star','BLUESTARCO.BO',
'Bodal Chemicals Ltd','BODALCHEM.BO',
'Bombay Burmah Trading','BBTC.BO',
'Bombay Dyeing&Mfg.','BOMDYEING.BO',
'Borosil','BOROLTD.BO',
'Borosil Renewables','BORORENEW.BO',
'Bosch','BOSCHLTD.BO',
'Brigade Enterprises','BRIGADE.BO',
'Brightcom','BCG.BO',
'Britannia Industries','BRITANNIA.BO',
'Burger King','BURGERKING.BO',
'Butterfly Gandhimathi','BUTTERFLY.BO',
'Cadila Healthcare','CADILAHC.BO',
'Camlin Fine Sciences Ltd','CAMLINFINE.BO',
'Can Fin Homes','CANFINHOME.BO',
'Canara Bank','CANBK.BO',
'Cantabil Retail India Ltd','CANTABIL.BO',
'Capacite Infraprojects','CAPACITE.BO',
'Caplin Point Laboratories Ltd','CAPPL.BO',
'Capri Global Capital','CGCL.BO',
'Carborundum Universal','CARBORUNIV.BO',
'CARE Ratings','CARERATING.BO',
'Career Point Ltd','CAREERP.BO',
'Castrol India','CASTROLIND.BO',
'CBI','CENTRALBK.BO',
'CCL Products India Ltd','CCL.BO',
'CEAT Ltd','CEATLTD.BO',
'Centrum Capital','CENTRUM.BO',
'Century Enka','CENTENKA.BO',
'Century Plyboards','CENTURYPLY.BO',
'Century Textiles','CENTURYTEX.BO',
'Cera Sanitaryware Ltd','CERA.BO',
'Cerebra Integrated Technolog','CEREBRAINT.BO',
'CESC','CESC.BO',
'Chalet Hotels','CHALET.BO',
'Chambal Fertilisers','CHAMBLFERT.BO',
'Chemcon','CHEMCON.BO',
'Chennai Petroleum','CHENNPETRO.BO',
'Cheviot Co','CHEVIOT.BO',
'Cholamandalam','CHOLAFIN.BO',
'Cholamandalam Financial','CHOLAHLDNG.BO',
'Cigniti Technologies','CIGNITI.BO',
'Cipla','CIPLA.BO',
'City Union Bank','CUB.BO',
'Clariant Chemicals India','CLNINDIA.BO',
'Coal India','COALINDIA.BO',
'Cochin Shipyard','COCHINSHIP.BO',
'Coforge','COFORGE.BO',
'Colgate-Palmolive India','COLPAL.BO',
'Computer Age','CAMS.BO',
'Confidence Petroleum India','CONFIPET.BO',
'Container India','CONCOR.BO',
'Coromandel Int','COROMANDEL.BO',
'Cosmo Films Ltd','COSMOFILMS.BO',
'CreditAccess Grameen','CREDITACC.BO',
'CRISIL','CRISIL.BO',
'Crompton Greaves Consumer','CROMPTON.BO',
'Csb Bank','CSBBANK.BO',
'Cummins India Ltd','CUMMINSIND.BO',
'Cupid','CUPID.BO',
'Cyient Ltd-B','CYIENT.BO',
'D Link India Ltd','DLINKINDIA.BO',
'D.B.Corp','DBCORP.BO',
'Dabur India','DABUR.BO',
'Dalmia Bharat B','DALBHARAT.BO',
'Dalmia Bharat Sugar','DALMIASUG.BO',
'Datamatics Global Services','DATAMATICS.BO',
'DCB Bank Ltd','DCBBANK.BO',
'DCM Shriram','DCMSHRIRAM.BO',
'DCW','DCW.BO',
'Deepak','DEEPAKFERT.BO',
'Deepak Nitrite Ltd','DEEPAKNI.BO',
'Delta Corp','DELTACORP.BO',
'Den Networks','DEN.BO',
'DFM Foods','DFM.BO',
'Dhanlaxmi Bank','DHANBANK.BO',
'Dhanuka Agritech Ltd','DHANUKA.BO',
'Diamines and Chemicals','DIAMINESQ.BO',
'Dilip Buildcon','DBL.BO',
'Dish TV India','DISHTV.BO',
'Dishman Carbogen Amcis','DCAL.BO',
'Divis Labs','DIVISLAB.BO',
'Dixon Tech','DIXON.BO',
'DLF','DLF.BO',
'Dolat Investments','DOLAT.BO',
'Dollar Industries','DOLLAR.BO',
'Dr Lal PathLabs Ltd','LALPATHLAB.BO',
'Dr. Reddy´s Labs','DRREDDY.BO',
'Dredging Corporation','DREDGECORP.BO',
'Dwarikesh Sugar Industries','DWARKESH.BO',
'E.I.D-Parry','EIDPARRY.BO',
'eClerx Services','ECLERX.BO',
'Edelweiss Financial','EDELWEISS.BO',
'Eicher Motors','EICHERMOT.BO',
'EIH','EIHOTEL.BO',
'Electrosteel Castings','ELECTCAST.BO',
'Emami','EMAMILTD.BO',
'Endurance Technologies Cn','ENDURANCE.BO',
'Engineers India','ENGINERSIN.BO',
'EPL','EPL.BO',
'Equinaire Chemtech Ltd','',
'Equitas Holdings','EQUITAS.BO',
'Equitas Small Finance','EQUITASBNK.BO',
'Eris Lifesciences','ERIS.BO',
'Esab India','ESABINDIA.BO',
'Escorts','ESCORTS.BO',
'Eveready Industries India','EVEREADY.BO',
'Everest Industries Ltd','EVERESTIND.BO',
'Excel Industries Ltd','EXCELINDUS.BO',
'Exide Industries','EXIDEIND.BO',
'Fairchem Organics','FAIRCHEMOR.BO',
'FDC','FDC.BO',
'Federal Bank','FEDERALBNK.BO',
'Fermenta Biotech','FERMENTA.BO',
'Fiem Industries Ltd','FIEMIND.BO',
'Filatex India','FILATEX.BO',
'Fine Organic','FINEORG.BO',
'Fineotex Chemical Ltd','FCL.BO',
'Finolex Cables','FINCABLES.BO',
'Finolex Industries','FINOLEXIND.BO',
'Firstsource Solutions','FSL.BO',
'Force Motors','FORCEMOT.BO',
'Fortis Healthcare','FORTIS.BO',
'Future Consumer','FCONSUMER.BO',
'Future Enterprises A','FEL.BO',
'Future Enterprises R','FELDVR.BO',
'Future Lifestyle Fashions Ltd','FLFL.BO',
'Future Retail','FRETAIL.BO',
'Future Supply','FSC.BO',
'Gabriel','GABRIEL.BO',
'GAIL Ltd','GAIL.BO',
'Galaxy Surfactants','GALAXYSURF.BO',
'Garden Reach','GRSE.BO',
'Garware Wall Ropes Ltd','GARFIBRES.BO',
'Gateway Distriparks','GDL.BO',
'Gati','GATI.BO',
'Gayatri Projects','GAYAPROJ.BO',
'GE Power India','GEPIL.BO',
'Ge T&D India','GET&D.BO',
'General Insurance India','GICRE.BO',
'Genus Power Infrastruct','GENUSPOWER.BO',
'Geojit BNP Paribas','GEOJITFSL.BO',
'GHCL','GHCL.BO',
'GIC Housing Finance Ltd','GICHSGFIN.BO',
'Gillette India','GILLETTE.BO',
'Gland','GLAND.BO',
'GlaxoSmithkline Pharma','GLAXO.BO',
'Glenmark Pharma','GLENMARK.BO',
'Globus Spirits Ltd','GLOBUSSPR.BO',
'GMM Pfaudler','GMM.BO',
'GMR Infrastructure','GMRINFRA.BO',
'GNA Axles','GNA.BO',
'Goa Carbon Ltd','GOACARBON.BO',
'Godawari Power Ispat','GPIL.BO',
'Godfrey Phillips India','GODFRYPHLP.BO',
'Godrej Agrovet','GODREJAGRO.BO',
'Godrej Consumer','GODREJCP.BO',
'Godrej Industries','GODREJIND.BO',
'Godrej Properties','GODREJPROP.BO',
'Gokaldas Exports Ltd','GOKEX.BO',
'Goldiam International','GOLDIAM.BO',
'Goodyear India','GOODYEAR.BO',
'Granules India','GRANULES.BO',
'Graphite India','GRAPHITE.BO',
'Grasim Industries','GRASIM.BO',
'Grauer And Weil India','GRAUWEIL.BO',
'Gravita India','GRAVITA.BO',
'Great Eastern Shipping','GESHIP.BO',
'Greaves Cotton','GREAVESCOT.BO',
'Greenpanel Ind','GREENPANEL.BO',
'Greenply Industries Ltd','GREENPLY.BO',
'Grindwell Norton Ltd','GRINDWELL.BO',
'GTPL Hathway','GTPL.BO',
'Gufic Biosciences Ltd','GUFICBIO.BO',
'Gujarat Alkalis&Chemicals','GUJALKALI.BO',
'Gujarat Ambuja Exports','GAEL.BO',
'Gujarat Fluorochemicals','FLUOROCHEM.BO',
'Gujarat Gas','GUJGAS.BO',
'Gujarat Industries','GIPCL.BO',
'Gujarat Mineral','GMDCLTD.BO',
'Gujarat Narmada Valley Fert.','GNFC.BO',
'Gujarat Pipavav Port','GPPL.BO',
'Gujarat State Fertilizers','GSFC.BO',
'Gujarat State Petronet','GSPL.BO',
'Gulf Oil Lubricants India Ltd','GULFOILLUB.BO',
'H.G.Infra Engineering','HGINFRA.BO',
'Happiest Minds','HAPPSTMNDS.BO',
'Hathway Cable','HATHWAY.BO',
'Havells India','HAVELLS.BO',
'Hawkins Cookers Ltd-BO','HAWKINCOOK.BO',
'HBL Power Systems Ltd','HBLPOWER.BO',
'HCL Infosystems','HCL-INSYS.BO',
'HCL Tech','HCLTECH.BO',
'HDFC Asset Management','HDFCAMC.BO',
'HDFC Bank','HDFCBANK.BO',
'HDFC Life','HDFCLIFE.BO',
'Healthcare Global','HCG.BO',
'HEG','HEG.BO',
'HeidelbergCement India','HEIDELBERG.BO',
'Hemisphere Properties India','HEMIPROP.BO',
'Heritage Foods','HERITGFOOD.BO',
'Hero MotoCorp','HEROMOTOCO.BO',
'Hester Biosciences Ltd','HESTERBIO.BO',
'HFCL','HFCL.BO',
'Hikal Ltd','HIKAL.BO',
'HIL Ltd','HIL.BO',
'Himadri Speciality Chem','HSCL.BO',
'Himatsingka Seide','HIMATSEIDE.BO',
'Hind Rectifiers Ltd','HIRECT.BO',
'Hindalco Industries','HINDALCO.BO',
'Hinduja Global Solutions Ltd','HGS.BO',
'Hindustan Aeronautics','HAL.BO',
'Hindustan Construction','HCC.BO',
'Hindustan Copper','HINDCOPPER.BO',
'Hindustan Foods','HNDFDS.BO',
'Hindustan Oil','HINDOILEXP.BO',
'Hindustan Petroleum','HINDPETRO.BO',
'Hindustan Unilever','HINDUNILVR.BO',
'Hindustan Zinc','HINDZINC.BO',
'HLE Glascoat','HLEGLAS.BO',
'Honda Siel Power Products','HONDAPOWER.BO',
'Honeywell Automation','HONAUT.BO',
'Housing Development Finance','HDFC.BO',
'Housing Urban Develop','HUDCO.BO',
'HSIL','HSIL.BO',
'Huhtamaki PPL','HUHTAMAKI.BO',
'I G Petrochemicals Ltd','IGPL.BO',
'ICICI Bank','ICICIBANK.BO',
'ICICI Lombard','ICICIGI.BO',
'ICICI Prudential Life Insur','ICICIPRULI.BO',
'ICICI Securities','ISEC.BO',
'IDBI Bank','IDBI.BO',
'IDFC','IDFC.BO',
'IDFC First Bank','IDFCFIRSTB.BO',
'IFB Industries Ltd','IFBIND.BO',
'IFCI','IFCI.BO',
'Igarashi Motors India Ltd','IGARASHI.BO',
'IIFL Finance B','IIFL.BO',
'Iifl Securities','IIFLSEC.BO',
'Iifl Wealth','IIFLWAM.BO',
'India Cements','INDIACEM.BO',
'India Glycols','INDIAGLYCO.BO',
'India Tourism Development','ITDC.BO',
'Indiabulls','IBULHSGFIN.BO',
'Indiabulls RE','IBREALEST.BO',
'IndiaMART InterMESH','INDIAMART.BO',
'Indian Bank','INDIANB.BO',
'Indian Energy Exchange','IEX.BO',
'Indian Hotels Co.','INDHOTEL.BO',
'Indian Hume Pipe Company Ltd','INDIANHUME.BO',
'Indian Met','IMFA.BO',
'Indian Oil','IOC.BO',
'Indian Overseas Bank','IOB.BO',
'Indian Railway Catering','IRCTC.BO',
'Indo Count Industries','ICIL.BO',
'Indoco Remedies Ltd','INDOCO.BO',
'IndoStar Capital','INDOSTAR.BO',
'Indraprastha Gas','IGL.BO',
'Indraprastha Medical','INDRAMEDCO.BO',
'IndusInd Bank','INDUSINDBK.BO',
'Infibeam Avenues','INFIBEAM.BO',
'Info Edge India','NAUKRI.BO',
'Infosys','INFY.BO',
'Ingersoll-Rand IN','INGERRAND.BO',
'Inox Leisure','INOXLEISUR.BO',
'Inox Wind Ltd','INOXWIND.BO',
'Insecticides India Ltd','INSECTICID.BO',
'Intellect Design Arena','INTELLECT.BO',
'Interglobe Aviation Ltd','INDIGO.BO',
'IOL Chemicals Pharmaceuticals','IOLCP.BO',
'Ion Exchange India','IONEXCHANG.BO',
'Ipca Laboratories','IPCALAB.BO',
'IRB Infrastructure','IRB.BO',
'Ircon','IRCON.BO',
'Isgec Heavy','ISGEC.BO',
'ITC','ITC.BO',
'ITD Cementation India Ltd','ITDCEM.BO',
'ITI Ltd','ITI.BO',
'J Kumar Infraprojects','JKIL.BO',
'J.B. Chemicals&Pharma','JBCHEPHARM.B',
'J.K. Cement','JKCEMENT.BO',
'Jagran Prakashan','JAGRAN.BO',
'Jai Corp','JAICORPLTD.BO',
'Jain Irrigation','JISLJALEQS.BO',
'Jain Irrigation Systems Ltd','JISLDVREQS.BO',
'Jaiprakash Associates','JPASSOCIAT.BO',
'Jaiprakash Power','JPPOWER.BO',
// falla el and en el ticker. CAN BE FIXED!!! 'Jammu and Kashmir Bank','J&KBANK.BO',
'Jamna Auto','JAMNAAUTO.BO',
'Jay Bharat Maruti Ltd','JAYBARMARU.BO',
'JBM Auto Ltd','JBMA.BO',
'Jindal Poly Films','JINDALPOLY.BO',
'Jindal Saw','JINDALSAW.BO',
'Jindal Stainless','JSL.BO',
'Jindal Stainless Hisar','JSLHISAR.BO',
'Jindal Steel&Power','JINDALSTEL.BO',
'Jindal Worldwide','JINDWORLD.BO',
'JK Lakshmi Cement','JKLAKSHMI.BO',
'JK Paper Ltd','JKPAPER.BO',
'JK Tyre&Industries','JKTYRE.BO',
'JM Financial','JMFINANCIL.BO',
'JMC Projects','JMCPROJECT.BO',
'Johnson Controls-Hitachi Air',
'JSW Energy','JSWENERGY.BO',
'JSW Steel','JSWSTEEL.BO',
'JTEKT India','JTEKTINDIA.BO',
'Jubilant Foodworks','JUBLFOOD.BO',
'Just Dial','JUSTDIAL.BO',
'Jyothy Labs','JYOTHYLAB.BO',
'Kajaria Ceramics','KAJARIACER.BO',
'Kalpataru Power','KALPATPOWR.BO',
'Kalyani Steels Ltd','KSL.BO',
'Kansai Nerolac Paints','KANSAINER.BO',
'Karda Constructions','KARDA.BO',
'Karnataka Bank','KTKBANK.BO',
'Kaveri Seed','KSCL.BO',
'Kaya Ltd','KAYA.BO',
'KEC International','KEC.BO',
'KEI Industries Ltd','KEI.BO',
'Kennametal India','KENNAMET.BO',
'Kesoram Industries','KESORAMIND.BO',
'Khadim India','KHADIM.BO',
'Kirloskar Oil Engines','KIRLOSENG.BO',
'Kitex Garments Ltd','KITEX.BO',
'KNR Constructions','KNRCON.BO',
'Kokuyo Camlin Ltd','KOKUYOCMLN.BO',
'Kolte Patil','KOLTEPATIL.BO',
'Kotak Mahindra Bank','KOTAKBANK.BO',
'KPIT Tech','KPITTECH.BO',
'KPR Mill','KPRMILL.BO',
'KRBL Ltd','KRBL.BO',
'KSB','KSB.BO',
// falla el and en el ticker. CAN BE FIXED!!! 'L&T Finance Hld','L&TFH.BO',
'L&T Technology Services','LTTS.BO',
'La Opala R G Ltd','LAOPALA.BO',
'Lakshmi Machine Works','LAXMIMACH.BO',
'Larsen & Toubro','LT.NS',
'Larsen & Toubro Infotech','LTI.NS',
'Laurus Labs','LAURUSLABS.BO',
'Lemon Tree Hotels','LEMONTREE.BO',
'LG Balakrishnan Bros Ltd','LGBBROSLTD.BO',
'Liberty Shoes Ltd','LIBERTSHOE.BO',
'Lic Housing Finance','LICHSGFIN.BO',
'Likhitha','LIKHITHA.BO',
'Lincoln Pharmaceuticals Ltd','LINCOPH.BO',
'Linde India','LINDEINDIA.BO',
'LT Foods','DAAWAT.BO',
'Lumax AutoTech','LUMAXTECH.BO',
'Lumax Industries Ltd','LUMAXIND.BO',
'Lupin','LUPIN.BO',
'Lux Industries Ltd','LUXIND.BO',
'M.R.F.','MRF.BO',
'Magma Fincorp','MAGMA.BO',
'Mahanagar Gas','MGL.BO',
'Mahanagar Telephone','MTNL.BO',
'Maharashtra Scooters','MAHSCOOTER.BO',
'Maharashtra Seamless','MAHSEAMLES.BO',
'Mahindra','MAHLOG.BO',
// falla el and en el ticker. CAN BE FIXED!!! 'Mahindra & Mahindra','M&M.BO',
// falla el and en el ticker. CAN BE FIXED!!! 'Mahindra & Mahindra Financial','M&MFIN.BO',
'Mahindra CIE Automotive Ltd','MAHINDCIE.BO',
'Mahindra Epc','MAHEPC.BO',
'Mahindra Holidays','MHRIL.BO',
'Mahindra Lifespace','MAHLIFE.BO',
'Majesco Ltd','MJCO.BO',
'Man Indus','MANINDS.BO',
'Man Infraconstruction','MANINFRA.BO',
'Manali Petrochemicals Ltd','MANALIPETC.BO',
'Manappuram Finance','MANAPPURAM.BO',
'Mangalam Cement Ltd','MANGLMCEM.BO',
'Mangalam Organics','MANORG.BO',
'Mangalore','MRPL.BO',
'Mangalore Chemicals','MANGCHEFER.BO',
'Marico','MARICO.BO',
'Marksans Pharma Ltd','MARKSANS.BO',
'Maruti Suzuki','MARUTI.BO',
'MAS Financial','MASFIN.BO',
'Mastek','MASTEK.BO',
'Max Financial','MFSL.BO',
'Max Healthcare Institute','MAXHEALTH.BO',
'Max India','MAXIND.BO',
'Max Ventures','MAXVIL.BO',
'Mayur Uniquoters Ltd','MAYURUNIQ.BO',
'Mazagon Dock','MAZDOCK.BO',
'MEP Infrastructure','MEP.BO',
'Metropolis Healthcare','METROPOLIS.BO',
'Minda Corp','MINDACORP.BO',
'Minda Industries','MINDAIND.BO',
'MindTree','MINDTREE.BO',
'Mirza International Ltd','MIRZAINT.BO',
'Mishra Dhatu Nigam','MIDHANI.BO',
'MMTC','MMTC.BO',
'MOIL','MOIL.BO',
'Mold Tek Packaging Ltd','MOLDTKPAC.BO',
'Monte Carlo Fashions','MONTECARLO.BO',
'Morepen Laboratories Ltd','MOREPENLAB.BO',
'Motherson Sumi Systems','MOTHERSUMI.BO',
'Motilal Oswal','M50.BO',
'Mphasis','MPHASIS.BO',
'Mrs Bectors Food','BECTORFOOD.BO',
'MSTC','MSTC.BO',
'Multi Commodity Exchange','MCX.BO',
'Munjal Auto Industries Ltd','MUNJALAU.BO',
'Munjal Showa Ltd','MUNJALSHOW.BO',
'Music Broadcast','RADIOCITY.BO',
'Muthoot Capital Services Ltd','MUTHTFN.BO',
'Muthoot Finance','MUTHOOTFIN.BO',
'N R Agarwal','NRAGRINDQ.BO',
'Narayana Hrudayalaya','NH.BO',
'Natco Pharma','NATCOPHARM.BO',
'Nath Bio Genes India Ltd','NATHBIOGEN.BO',
'National Aluminum Co.','NATIONALUM.BO',
'National Fertilizers','NFL.BO',
'National Peroxide','NATPEROX.BO',
'Nava Bharat Ventures','NBVENTURES.BO',
'Navin Fluorine','NAVINFLUOR.BO',
'Navkar Corporation Ltd','NAVKARCORP.BO',
'Navneet Education','NAVNETEDUL.BO',
'NBCC India','NBCC.BO',
'NCC','NCC.BO',
'Ncl Industries Ltd','NCLIND.BO',
'Nelcast Ltd','NELCAST.BO',
'Nelco','NELCO.BO',
'Neogen Chemicals','NEOGEN.BO',
'Nesco','NESCO.BO',
'Nestle India','NESTLEIND.BO',
'Network 18 Media','NETWORK18.BO',
'Neul Laboratories Ltd','NEULANDLAB.BO',
'New India Assurance','NIACL.BO',
'Newgen Software','NEWGEN.BO',
'NHPC','NHPC.BO',
'NIIT','NIITLTD.BO',
'Nilkamal','NILKAMAL.BO',
'Nippon Electricals','INDNIPPON.BO',
'Nippon India','NAM-INDIA.BO',
'NLC India','NLCINDIA.BO',
'NMDC','NMDC.BO',
'Nocil Ltd','NOCIL.BO',
'Novartis India','NOVARTIND.BO',
'NRB Bearings Ltd','NRBBEARING.BO',
'NTPC','NTPC.BO',
'Nucleus Software Exports Ltd','NUCLEUS.BO',
'Oberoi Realty','OBEROIRLTY.BO',
'Oil & Natural Gas','ONGC.BO',
'Oil India','OIL.BO',
'Olectra Greentech','OLECTRA.BO',
'Omaxe','OMAXE.BO',
'OnMobile Global','ONMOBILE.BO',
'Oracle','OFSS.BO',
'Orient Cement Ltd','ORIENTCEM.BO',
'Orient Electric','ORIENTELEC.BO',
'Orient Paper Industries','ORIENTPPR.BO',
'Orient Refractories Ltd','ORIENTREF.BO',
'Oriental Chemicals','ORIENTCQ.BO',
'Page Industries','PAGEIND.BO',
'Paisalo Digital','PAISALO.BO',
'Panacea Biotec','PANACEABIO.BO',
'Parag Milk Foods Ltd','PARAGMILK.BO',
'Patel Engineering','PATELENG.BO',
'PC Jeweller','PCJEWELLER.BO',
'Pennar Industries Ltd','PENIND.BO',
'Persistent Systems','PERSISTENT.BO',
'Petronet LNG','PETRONET.BO',
'Pfizer Ltd','PFIZER.BO',
'Phillips Carbon Black','PHILIPCARB.BO',
'PI Industries','PIIND.BO',
'Pidilite Industries','PIDILITIND.BO',
'Pilani Investment And Ind','PILANIINVS.BO',
'Piramal Enterprises','PEL.BO',
'PNB Gilts Ltd','PNBGILTS.BO',
'PNB Housing Finance','PNBHOUSING.BO',
'PNC Infratech','PNCINFRA.BO',
'Poly Medicure Ltd','POLYMED.BO',
'Polycab India','POLYCAB.BO',
'Polyplex','POLYPLEX.BO',
'Power Finance','PFC.BO',
'Power Grid','POWERGRID.BO',
'Power Mech Projects Ltd','POWERMECH.BO',
'Praj Industries','PRAJIND.BO',
'Prakash Industries','PRAKASH.BO',
'Prataap Snacks','DIAMONDYD.BO',
'Prestige Estates','PRESTIGE.BO',
'Pricol','PRICOLLTD.BO',
'Prince Pipes And Fittings','PRINCEPIPE.BO',
'Prism Johnson','PRSMJOHNSN.BO',
'Privi Speciality Chemicals','PRIVISCL.BO',
'Procter & Gamble Health','PGHL.BO',
'Procter & Gamble Hygiene','PGHH.BO',
'Psp Projects','PSPPROJECT.BO',
'PTC India','PTC.BO',
'PTC India Financial Services','PFS.BO',
'Punjab National Bank','PNB.BO',
'Puravankara Projects','PURVA.BO',
'PVR','PVR.BO',
'Quess Corp','QUESS.BO',
'Quick Heal Tech','QUICKHEAL.BO',
'Radico Khaitan','RADICO.BO',
'Rail Vikas Nigam','RVNL.BO',
'Rain Industries','RAIN.BO',
'Rajesh Exports','RAJESHEXPO.BO',
'Rallis India','RALLIS.BO',
'Ramco Industries','RAMCOIND.BO',
'Ramco Systems Ltd','RAMCOSYS.BO',
'Ramkrishna Forgings Ltd','RKFORGE.BO',
'Ramky Infrastructure Ltd','RAMKY.BO',
'Rane Brake Linings Ltd','RBL.BO',
'Rane Holdings Ltd','RANEHOLDIN.BO',
'Rane Madras Ltd','RML.BO',
'Rashtriya Chemicals','RCF.BO',
'Ratnamani Metals Tubes Ltd','RATNAMANI.BO',
'RattanIndia Power','RTNPOWER.BO',
'Raymond','RAYMOND.BO',
'RBL Bank','RBLBANK.BO',
'REC','RECLTD.BO',
'Redington India','REDINGTON.BO',
'Relaxo Footwears Ltd','RELAXO.BO',
'Reliance Capital','RELCAPITAL.BO',
'Reliance Communications','RCOM.BO',
'Reliance Industrial','RIIL.BO',
'Reliance Industries','RELIANCE.BO',
'Reliance Infrastructure','RELINFRA.BO',
'Reliance Power','RPOWER.BO',
'Religare Enterprises','RELIGARE.BO',
'Repco Home Finance Ltd','REPCOHOME.BO',
'Responsive Industries Ltd','RESPONIND.BO',
'Rico Auto Industries Ltd','RICOAUTO.BO',
'RITES','RITES.BO',
'Rossari Biotech','ROSSARI.BO',
'Route Mobile','ROUTE.BO',
'Royal Orchid Hotels Ltd','ROHLTD.BO',
'Rpg Life Sciences Ltd','RPGLIFE.BO',
'RPSG Ventures','RPSGVENT.BO',
'Ruchira Papers Ltd','RUCHIRA.BO',
'Rupa Company Ltd','RUPA.BO',
'S H Kelkar And Company Ltd','SHK.BO',
'Sadbhav Engineering','SADBHAV.BO',
'Safari Industries','SAFARIND.BO',
'Sagar Cements Ltd','SAGCEM.BO',
'Saint-Gobain Sekurit India','SAINTGOBAIN.BO',
'Salasar Techno','SALASAR.BO',
'Sandur Manganese Iron','SANDUMA.BO',
'Sanghi Industries Ltd','SANGHIIND.BO',
'Sanghvi Movers Ltd','SANGHVIMOV.BO',
'Sanofi India','SANOFI.BO',
'Sarda Energy Minerals Ltd','SARDAEN.BO',
'Saregama India Ltd','SAREGAMA.BO',
'Sasken Communication Tech','SASKEN.BO',
'Satia Industries Ltd','SATIA.BO',
'Satin Creditcare Network Ltd','SATIN.BO',
'SBI','SBIN.BO',
'SBI Cards','SBICARD.BO',
'SBI Life Insurance','SBILIFE.BO',
'Schaeffler India','SCHAEFFLER.BO',
'Schneider Electric Infr','SCHNEIDER.BO',
'Selan Exploration Tech','SELAN.BO',
'SeQuent Scientific','SEQUENT.BO',
'Seshasayee Paper Boards','SESHAPAPER.BO',
'Shakti Pumps India Ltd','SHAKTIPUMP.BO',
'Shalby','SHALBY.BO',
'Shalimar Paints Ltd','SHALPAINTS.BO',
'Shankara Building','SHANKARA.BO',
'Sharda Cropchem Ltd','SHARDACROP.BO',
'Sharda Motor Industries Ltd','SHARDA.BO',
'Sheela Foam','SFL.BO',
'Shilpa Medicare Ltd','SHILPAMED.BO',
'Shipping Corporation','SCI.BO',
'Shoppers Stop','SHOPERSTOP.BO',
'Shree Cements','SHREECEM.BO',
'Shree Digvijay Cement Co','SHREDIGCEM.BO',
'Shree Pushkar Chemicals','SHREEPUSHK.BO',
'Shree Renuka Sugars','RENUKA.BO',
'Shriram Transport','SRTRANSFIN.BO',
'Shriram-City Union','SHRIRAMCIT.BO',
'Siemens Ltd','SIEMENS.BO',
'Simplex Infrastructures','SIMPLEXINF.BO',
'SIS','SIS.BO',
'Siyaram Silk Mills','SIYSIL.BO',
'SJVN','SJVN.BO',
'SKF India','SKFINDIA.BO',
'SML Isuzu Ltd','SMLISUZU.BO',
'Snowman Log','SNOWMAN.BO',
'Sobha Developers','SOBHA.BO',
'Solar Industries India Ltd','SOLARINDS.BO',
'Solara Active Pharma','SOLARA.BO',
'Somany Ceramics Ltd','SOMANYCERA.BO',
'Somany Home','SHIL.BO',
'Sonata Software','SONATSOFTW.BO',
'Soril Infra Resources','SORILINFRA.BO',
'South Indian Bank','SOUTHBANK.BO',
'Spandana Sphoorty Financial Ltd BO','SPANDANA.BO',
'Spencer´s Retail','SPENCER.BO',
'Spicejet','SPICEJET.BO',
'SREI Infrastructure','SREINFRA.BO',
'SRF','SRF.BO',
'Srikalahasthi Pipes Ltd','SRIPIPES.BO',
'Star Cement','STARCEMENT.BO',
'Star Paper Mills Ltd','STARPAPER.BO',
'Steel Authority','SAIL.BO',
'Steel Strips Wheels Ltd','SSWL.BO',
'Sterling and Wilson Solar','SWSOLAR.BO',
'Sterlite Technologies','STLTECH.BO',
'Strides Pharma','STAR.BO',
'Subex Ltd','SUBEX.BO',
'Subros Ltd','SUBROS.BO',
'Sudarshan Chemical Industries','SUDARSCHEM.BO',
'Sumitomo Chemical','SUMICHEM.BO',
'Sun Pharma','SUNPHARMA.BO',
'Sun Pharma Adv. Research','SPARC.BO',
'Sun TV Network Ltd','SUNTV.BO',
'Sundram Fasteners','SUNDRMFAST.BO',
'Sunflag Iron Steel Co Ltd','SUNFLAG.BO',
'Sunteck Realty','SUNTECK.BO',
'Supreme Industries','SUPREMEIND.BO',
'Supreme Petrochem','SUPPETRO.BO',
'Surya Roshni','SURYAROSNI.BO',
'Suven Life Sciences Ltd','SUVEN.BO',
'Suven Pharma','SUVENPHAR.BO',
'Suzlon Energy','SUZLON.BO',
'Swan Energy','SWANENERGY.BO',
'Swaraj Engines','SWARAJENG.BO',
'Symphony Ltd','SYMPHONY.BO',
'Syngene International','SYNGENE.BO',
'T.V.Today Network','TVTODAY.BO',
'Taj GVK','TAJGVK.BO',
'Take Solutions Ltd','TAKE.BO',
'Tamil Nadu','TNPL.BO',
'Tamilnadu Petroproducts','TNPETRO.BO',
'Tanla Solutions','TANLA.BO',
'Tarc','TARC.BO',
'Tasty Bite Eatables','TASTYBIT.BO',
'Tata Chemicals','TATACHEM.BO',
'Tata Coffee','TATACOFFEE.BO',
'Tata Communications','TATACOMM.BO',
'Tata Consultancy','TCS.BO',
'Tata Consumer Products','TATACONSUM.BO',
'Tata Elxsi','TATAELXSI.BO',
'Tata Investment','TATAINVEST.BO',
'Tata Metaliks','TATAMETALI.BO',
'Tata Motors','TATAMOTORS.BO',
'Tata Motors DV Ltd','TATAMTRDVR.BO',
'Tata Power Co.','TATAPOWER.BO',
'Tata Steel BSL','TATASTLBSL.BO',
'Tata Steel Ltd','TATASTEEL.BO',
'Tata Steel Prod','TATASTLLP.BO',
'TCI Express','TCIEXP.BO',
'TCNS Clothing','TCNSBRANDS.BO',
'Teamlease Services','TEAMLEASE.BO',
'Tech Mahindra','TECHM.BO',
'Tejas Networks','TEJASNET.BO',
'Texmaco','TEXRAIL.BO',
'Thanga Mayil Jewellery','THANGAMAYL.BO',
'The Dhampur Sugar Mills','DHAMPURSUG.BO',
'The Phoenix Mills','PHOENIXLTD.BO',
'The Ramco Cements','RAMCOCEM.BO',
'Thermax','THERMAX.BO',
'Thirumalai Chemicals','TIRUMALCHM.BO',
'Thomas Cook India','THOMASCOOK.BO',
'Thyrocare Technologies','THYROCARE.BO',
'Time Technoplast','TIMETECHNO.BO',
'Timken India','TIMKEN.BO',
'Tinplate Company','TINPLATE.BO',
'Titagarh Wagons','TWL.BO',
'Titan Company','TITAN.BO',
'Torrent Pharma','TORNTPHARM.BO',
'Torrent Power Ltd','TORNTPOWER.BO',
'Tourism Finance Corp of India','TFCILTD.BO',
'Transpek Industry','TRANSPEK.BO',
'Transport Corp of India','TCI.BO',
'Trent','TRENT.BO',
'Tribhovandas Bhimji Zaveri','TBZ.BO',
'Trident','TRIDENT.BO',
'Triveni Engineering','TRIVENI.BO',
'Triveni Turbine','TRITURBINE.BO',
'TTK Prestige','TTKPRESTIG.BO',
'Tube Invest India','TIINDIA.BO',
'TV18 Broadcast','TV18BRDCST.BO',
'TVS Electronics Ltd','TVSELECT.BO',
'TVS Motor Company','TVSMOTOR.BO',
'TVS Srichakra Ltd','TVSSRICHAK.BO',
'UCAL Fuel Systems Ltd','UCALFUEL.BO',
'UCO Bank','UCOBANK.BO',
'Uflex','UFLEX.BO',
'UFO Moviez India Ltd','UFO.BO',
'Ujjivan Financial Services','UJJIVAN.BO',
'Ujjivan Small Fin','UJJIVANSFB.BO',
'UltraTech Cement','ULTRACEMCO.BO',
'Unichem Labs','UNICHEMLAB.BO',
'Union Bank of India','UNIONBANK.BO',
'United Breweries Ltd','UBL.BO',
'Universal Cables Ltd','UNIVCABLES.BO',
'UPL','UPL.BO',
'Usha Martin','USHAMART.BO',
'Uti Asset','UTIAMC.BO',
'Uttam Sugar Mills Ltd','UTTAMSUGAR.BO',
'V Guard Industries','VGUARD.BO',
'V Mart Retail Ltd','VMART.BO',
'V2 Retail Ltd','V2RETAIL.BO',
'Va Tech Wabag Ltd','WABAG.BO',
'Vadilal Industries Ltd','VADILALIND.BO',
'Vaibhav Global','VAIBHAVGBL.BO',
'Vakrangee Softwares','VAKRANGEE.BO',
'Vardhman Textiles','VTL.BO',
'Varroc Engineering','VARROC.BO',
'Varun Beverages','VBL.BO',
'Vascon Engineers Ltd','VASCONEQ.BO',
'Vedanta','VEDL.BO',
'Venkys India','VENKYS.BO',
'Vesuvius India','VESUVIUS.BO',
'Vikas WSP','VIKASWSP.BO',
'Vimta Labs Ltd','VIMTALABS.BO',
'Vinati Organics Ltd','VINATIORGA.BO',
'Vindhya Telelinks Ltd','VINDHYATEL.BO',
'VIP Industries','VIPIND.BO',
'Visaka Industries Ltd','VISAKAIND.BO',
'Vishwaraj Sugar Industries Ltd','VISHWARAJ.BO',
'Vodafone Idea','IDEA.BO',
'Voltamp Transformers Ltd','VOLTAMP.BO',
'Voltas','VOLTAS.BO',
'VRL Logistics Ltd','VRLLOG.BO',
'VST Industries','VSTIND.BO',
'VST Tillers Tractors Ltd','VSTTILLERS.BO',
'WABCO India','WABCOINDIA.BO',
'Waterbase','WATERBASE.BO',
'Welspun','WELSPUNIND.BO',
'Welspun Corp','WELCORP.BO',
'Welspun Enterprises','WELENT.BO',
'West Coast Paper Mills','WSTCSTPAPR.BO',
'Westlife Development Ltd-BO','WESTLIFE.BO',
'Whirlpool of India','WHIRLPOOL.BO',
'Wipro','WIPRO.BO',
'Wockhardt','WOCKPHARMA.BO',
'Wonderla Holidays Ltd','WONDERLA.BO',
'Zee Entertainment','ZEEL.BO',
'Zee Learn Ltd','ZEELEARN.BO',
'Zee Media Corp Ltd','ZEEMEDIA.BO',
'Zen Technologies Ltd','ZENTEC.BO',
'Zensar Tech','ZENSARTECH.BO',
'Zuari Agro Chemicals Ltd','ZUARI.BO',
'Zydus Wellness','ZYDUSWELL.BO'],
*/


NIFTY50_STOCKS:[1,
  1,
  1,
  1,
  '₹',
  'NIFTY 50','^NSEI',
'Adani Ports&SEZ','ADANIPORTS.NS',
'Asian Paints','ASIANPAINT.NS',
'AXIS Bank','AXISBANK.NS',
'Bajaj Auto','BAJAJ-AUTO.NS',
'Bajaj Finance','BAJFINANCE.NS',
'Bajaj Finserv','BAJAJFINSV.NS',
'Bharat Petroleum','BPCL.NS',
'Bharti Airtel','BHARTIARTL.NS',
'Britannia Industries','BRITANNIA.NS',
'Cipla','CIPLA.NS',
'Coal India','COALINDIA.NS',
'Divi´s Labs','DIVISLAB.NS',
'Dr. Reddy´s Labs','DRREDDY.NS',
'Eicher Motors','EICHERMOT.NS',
'Grasim Industries','GRASIM.NS',
'HCL Tech','HCLTECH.NS',
'HDFC Bank','HDFCBANK.NS',
'HDFC Life','HDFCLIFE.NS',
'Hero MotoCorp','HEROMOTOCO.NS',
'Hindalco Industries','HINDALCO.NS',
'Hindustan Unilever','HINDUNILVR.NS',
'Housing Development Finance','HDFC.NS',
'ICICI Bank','ICICIBANK.NS',
'Indian Oil','IOC.NS',
'IndusInd Bank','INDUSINDBK.NS',
'Infosys','INFY.NS',
'ITC','ITC.NS',
'JSW Steel','JSWSTEEL.NS',
'Kotak Mahindra Bank','KOTAKBANK.NS',
'Larsen & Toubro','LT.NS',
// falla el and en el ticker. CAN BE FIXED!!! 'Mahindra & Mahindra','M&M.NS',
'Maruti Suzuki','MARUTI.NS',
'Nestle India','NESTLEIND.NS',
'NTPC','NTPC.NS',
'Oil & Natural Gas','ONGC.NS',
'Power Grid','POWERGRID.NS',
'Reliance Industries','RELIANCE.NS',
'SBI','SBIN.NS',
'SBI Life Insurance','SBILIFE.NS',
'Shree Cements','SHREECEM.NS',
'Sun Pharma','SUNPHARMA.NS',
'Tata Consultancy','TCS.NS',
'Tata Consumer Products','TATACONSUM.NS',
'Tata Motors','TATAMOTORS.NS',
'Tata Steel Ltd','TATASTEEL.NS',
'Tech Mahindra','TECHM.NS',
'Titan Company','TITAN.NS',
'UltraTech Cement','ULTRACEMCO.NS',
'UPL','UPL.NS',
'Wipro','WIPRO.NS'],


NIFTY100_STOCKS:[1,
  1,
  1,
  1,
  '₹',
  'NIFTY 100','^CNX100',
  'Abbott India','ABBOTINDIA.NS',
  'ACC','ACC.NS',
  'Adani Enterprises','ADANIENT.NS',
  'Adani Green Energy','ADANIGREEN.NS',
  'Adani Ports & SEZ','ADANIPORTS.NS',
  'Adani Transmission Ltd','ADANITRANS.NS',
  'Alkem Laboratories Ltd','ALKEM.NS',
  'Ambuja Cements','AMBUJACEM.NS',
  'Apollo Hospitals','APOLLOHOSP.NS',
  'Asian Paints','ASIANPAINT.NS',
  'Aurobindo Pharma','AUROPHARMA.NS',
  'Avenue Supermarts','DMART.NS',
  'AXIS Bank','AXISBANK.NS',
  'Bajaj Auto','BAJAJ-AUTO.NS',
  'Bajaj Finance','BAJFINANCE.NS',
  'Bajaj Finserv','BAJAJFINSV.NS',
  'Bajaj Holdings','BAJAJHLDNG.NS',
  'Bandhan Bank','BANDHANBNK.NS',
  'Berger Paints','BERGEPAINT.NS',
  'Bharat Petroleum','BPCL.NS',
  'Bharti Airtel','BHARTIARTL.NS',
  'Biocon','BIOCON.NS',
  'Bosch','BOSCHLTD.NS',
  'Britannia Industries','BRITANNIA.NS',
  'Cadila Healthcare','CADILAHC.NS',
  'Cipla','CIPLA.NS',
  'Coal India','COALINDIA.NS',
  'Colgate-Palmolive India','COLPAL.NS',
  'Dabur India','DABUR.NS',
  'Divi´s Labs','DIVISLAB.NS',
  'DLF','DLF.NS',
  'Dr. Reddy´s Labs','DRREDDY.NS',
  'Eicher Motors','EICHERMOT.NS',
  'GAIL Ltd','GAIL.NS',
  'Godrej Consumer','GODREJCP.NS',
  'Godrej Industries','GODREJIND.NS',
  'Havells India','HAVELLS.NS',
  'HCL Tech','HCLTECH.NS',
  'HDFC Asset Management','HDFCAMC.NS',
  'HDFC Bank','HDFCBANK.NS',
  'HDFC Life','HDFCLIFE.NS',
  'Hero MotoCorp','HEROMOTOCO.NS',
  'Hindalco Industries','HINDALCO.NS',
  'Hindustan Petroleum','HINDPETRO.NS',
  'Hindustan Unilever','HINDUNILVR.NS',
  'Housing Development Finance','HDFC.NS',
  'ICICI Bank','ICICIBANK.NS',
  'ICICI Lombard','ICICIGI.NS',
  'ICICI Prudential Life Insurance','ICICIPRULI.NS',
  'Indian Oil','IOC.NS',
  'Indraprastha Gas','IGL.NS',
  'Indus Towers','INDUSTOWER.NS',
  'IndusInd Bank','INDUSINDBK.NS',
  'Info Edge India','NAUKRI.NS',
  'Infosys','INFY.NS',
  'Interglobe Aviation Ltd','INDIGO.NS',
  'ITC','ITC.NS',
  'JSW Steel','JSWSTEEL.NS',
  'Jubilant Foodworks','JUBLFOOD.NS',
  'Kotak Mahindra Bank','KOTAKBANK.NS',
  'Larsen & Toubro','LT.NS',
  'Larsen & Toubro Infotech','LTI.NS',
  'Lupin','LUPIN.NS',
  'M.R.F.','MRF.NS',
  // falla el and en el ticker. CAN BE FIXED!!! 'Mahindra & Mahindra','M&M.NS',
  'Marico','MARICO.NS',
  'Maruti Suzuki','MARUTI.NS',
  'Motherson Sumi Systems','MOTHERSUMI.NS',
  'Muthoot Finance','MUTHOOTFIN.NS',
  'Nestle India','NESTLEIND.NS',
  'NMDC','NMDC.NS',
  'NTPC','NTPC.NS',
  'Oil & Natural Gas','ONGC.NS',
  'Petronet LNG','PETRONET.NS',
  'Pidilite Industries','PIDILITIND.NS',
  'Piramal Enterprises','PEL.NS',
  'Power Grid','POWERGRID.NS',
  'Procter & Gamble Hygiene','PGHH.NS',
  'Punjab National Bank','PNB.NS',
  'Reliance Industries','RELIANCE.NS',
  'SBI','SBIN.NS',
  'SBI Cards','SBICARD.NS',
  'SBI Life Insurance','SBILIFE.NS',
  'Shree Cements','SHREECEM.NS',
  'Siemens Ltd','SIEMENS.NS',
  'Sun Pharma','SUNPHARMA.NS',
  'Tata Consultancy','TCS.NS',
  'Tata Consumer Products','TATACONSUM.NS',
  'Tata Motors','TATAMOTORS.NS',
  'Tata Steel Ltd','TATASTEEL.NS',
  'Tech Mahindra','TECHM.NS',
  'Titan Company','TITAN.NS',
  'Torrent Pharma','TORNTPHARM.NS',
  'UltraTech Cement','ULTRACEMCO.NS',
  'United Breweries Ltd','UBL.NS',
  'United Spirits','MCDOWELL-N.NS',
  'UPL','UPL.NS',
  'Vedanta','VEDL.NS',
  'Wipro','WIPRO.NS',
  'Yes Bank','YESBANK.NS'],

  NIFTY200_STOCKS:[1,
    1,
    1,
    1,
    '₹',
    'NIFTY 200','^CNX200',
    'Aarti Industries','AARTIIND.NS',
'Abbott India','ABBOTINDIA.NS',
'ACC','ACC.NS',
'Adani Enterprises','ADANIENT.NS',
'Adani Green Energy','ADANIGREEN.NS',
'Adani Ports & SEZ','ADANIPORTS.NS',
'Adani Total Gas','ATGL.NS',
'Adani Transmission Ltd','ADANITRANS.NS',
'Aditya Birla Capital','ABCAPITAL.NS',
'Aditya Birla Fashion','ABFRL.NS',
'Ajanta Pharma Ltd','AJANTPHARM.NS',
'Alembic Pharmaceuticals Ltd','APLLTD.NS',
'Alkem Laboratories Ltd','ALKEM.NS',
'Amara Raja Batteries','AMARAJABAT.NS',
'Ambuja Cements','AMBUJACEM.NS',
'Apollo Hospitals','APOLLOHOSP.NS',
'Apollo Tyres','APOLLOTYRE.NS',
'Ashok Leyland','ASHOKLEY.NS',
'Asian Paints','ASIANPAINT.NS',
'AU Small Finance Bank','AUBANK.NS',
'Aurobindo Pharma','AUROPHARMA.NS',
'Avenue Supermarts','DMART.NS',
'AXIS Bank','AXISBANK.NS',
'Bajaj Auto','BAJAJ-AUTO.NS',
'Bajaj Finance','BAJFINANCE.NS',
'Bajaj Finserv','BAJAJFINSV.NS',
'Bajaj Holdings','BAJAJHLDNG.NS',
'Balkrishna Industries','BALKRISIND.NS',
'Bandhan Bank','BANDHANBNK.NS',
'Bank Of Baroda','BANKBARODA.NS',
//'Bank of India','SBIN.NS',
//'State Bank of India','SBIN.NS',
'Bata India','BATAINDIA.NS',
'Berger Paints','BERGEPAINT.NS',
'Bharat Electronics','BEL.NS',
'Bharat Forge','BHARATFORG.NS',
'Bharat Heavy Electricals','BHEL.NS',
'Bharat Petroleum','BPCL.NS',
'Bharti Airtel','BHARTIARTL.NS',
'Biocon','BIOCON.NS',
'Bombay Burmah Trading','BBTC.NS',
'Bosch','BOSCHLTD.NS',
'Britannia Industries','BRITANNIA.NS',
'Cadila Healthcare','CADILAHC.NS',
'Canara Bank','CANBK.NS',
'Castrol India','CASTROLIND.NS',
'CESC','CESC.NS',
'Cholamandalam','CHOLAFIN.NS',
'Cipla','CIPLA.NS',
'City Union Bank','CUB.NS',
'Coal India','COALINDIA.NS',
'Coforge','COFORGE.NS',
'Colgate-Palmolive India','COLPAL.NS',
'Container India','CONCOR.NS',
'Coromandel Int','COROMANDEL.NS',
'Crompton Greaves Consumer','CROMPTON.NS',
'Cummins India Ltd','CUMMINSIND.NS',
'Dabur India','DABUR.NS',
'Dalmia Bharat B','DALBHARAT.NS',
'Deepak Nitrite Ltd','DEEPAKNTR.NS',
'Dhani Services','DHANI.NS',
'Divi´s Labs','DIVISLAB.NS',
'Dixon Tech','DIXON.NS',
'DLF','DLF.NS',
'Dr Lal PathLabs Ltd','LALPATHLAB.NS',
'Dr. Reddy´s Labs','DRREDDY.NS',
'Eicher Motors','EICHERMOT.NS',
'Emami','EMAMILTD.NS',
'Endurance Technologies Cn','ENDURANCE.NS',
'Escorts','ESCORTS.NS',
'Exide Industries','EXIDEIND.NS',
'Federal Bank','FEDERALBNK.NS',
'Fortis Healthcare','FORTIS.NS',
'GAIL Ltd','GAIL.NS',
'Glenmark Pharma','GLENMARK.NS',
'GMR Infrastructure','GMRINFRA.NS',
'Godrej Agrovet','GODREJAGRO.NS',
'Godrej Consumer','GODREJCP.NS',
'Godrej Industries','GODREJIND.NS',
'Godrej Properties','GODREJPROP.NS',
'Grasim Industries','GRASIM.NS',
'Gujarat Gas','GUJGASLTD.NS',
'Gujarat State Petronet','GSPL.NS',
'Havells India','HAVELLS.NS',
'HCL Tech','HCLTECH.NS',
'HDFC Asset Management','HDFCAMC.NS',
'HDFC Bank','HDFCBANK.NS',
'HDFC Life','HDFCLIFE.NS',
'Hero MotoCorp','HEROMOTOCO.NS',
'Hindalco Industries','HINDALCO.NS',
'Hindustan Aeronautics','HAL.NS',
'Hindustan Petroleum','HINDPETRO.NS',
'Hindustan Unilever','HINDUNILVR.NS',
'Hindustan Zinc','HINDZINC.NS',
'Housing Development Finance','HDFC.NS',
'ICICI Bank','ICICIBANK.NS',
'ICICI Lombard','ICICIGI.NS',
'ICICI Prudential Life Insurance','ICICIPRULI.NS',
'ICICI Securities','ISEC.NS',
'IDFC First Bank','IDFCFIRSTB.NS',
'Indiabulls','IBULHSGFIN.NS',
'IndiaMART InterMESH','INDIAMART.NS',
'Indian Hotels Co.','INDHOTEL.NS',
'Indian Oil','IOC.NS',
'Indian Railway Catering','IRCTC.NS',
'Indraprastha Gas','IGL.NS',
'Indus Towers','INDUSTOWER.NS',
'IndusInd Bank','INDUSINDBK.NS',
'Info Edge India','NAUKRI.NS',
'Infosys','INFY.NS',
'Interglobe Aviation Ltd','INDIGO.NS',
'Ipca Laboratories','IPCALAB.NS',
'ITC','ITC.NS',
'Jindal Steel&Power','JINDALSTEL.NS',
'JSW Energy','JSWENERGY.NS',
'JSW Steel','JSWSTEEL.NS',
'Jubilant Foodworks','JUBLFOOD.NS',
'Kotak Mahindra Bank','KOTAKBANK.NS',
// falla el and en el ticker. CAN BE FIXED!!! 'L&T Finance Hld','L&TFH.NS',
'L&T Technology Services','LTTS.NS',
'Larsen & Toubro','LT.NS',
'Larsen & Toubro Infotech','LTI.NS',
'Laurus Labs','LAURUSLABS.NS',
'Lic Housing Finance','LICHSGFIN.NS',
'Lupin','LUPIN.NS',
'M.R.F.','MRF.NS',
'Mahanagar Gas','MTNL.NS',
// falla el and en el ticker. CAN BE FIXED!!! 'Mahindra & Mahindra','M&M.NS',
// falla el and en el ticker. CAN BE FIXED!!! 'Mahindra & Mahindra Financial','M&MFIN.NS',
'Manappuram Finance','MANAPPURAM.NS',
'Marico','MARICO.NS',
'Maruti Suzuki','MARUTI.NS',
'Max Financial','MFSL.NS',
'MindTree','MINDTREE.NS',
'Motherson Sumi Systems','MOTHERSUMI.NS',
'Mphasis','MPHASIS.NS',
'Muthoot Finance','MUTHOOTFIN.NS',
'Natco Pharma','NATCOPHARM.NS',
'Navin Fluorine','NAVINFLUOR.NS',
'Nestle India','NESTLEIND.NS',
'Nippon India','NAM-INDIA.NS',
'NMDC','NMDC.NS',
'NTPC','NTPC.NS',
'Oberoi Realty','OBEROIRLTY.NS',
'Oil & Natural Gas','ONGC.NS',
'Oil India','OIL.NS',
'Page Industries','PAGEIND.NS',
'Petronet LNG','PETRONET.NS',
'Pfizer Ltd','PFIZER.NS',
'PI Industries','PIIND.NS',
'Pidilite Industries','PIDILITIND.NS',
'Piramal Enterprises','PEL.NS',
'Polycab India','POLYCAB.NS',
'Power Finance','PFC.NS',
'Power Grid','POWERGRID.NS',
'Prestige Estates','PRESTIGE.NS',
'Procter & Gamble Hygiene','PGHH.NS',
'Punjab National Bank','PNB.NS',
'RBL Bank','RBLBANK.NS',
'REC','RECLTD.NS',
'Reliance Industries','RELIANCE.NS',
'Sanofi India','SANOFI.NS',
'SBI','SBIN.NS',
'SBI Cards','SBICARD.NS',
'SBI Life Insurance','SBILIFE.NS',
'Shree Cements','SHREECEM.NS',
'Shriram Transport','SRTRANSFIN.NS',
'Siemens Ltd','SIEMENS.NS',
'SRF','SRF.NS',
'Steel Authority','SAIL.NS',
'Sun Pharma','SUNPHARMA.NS',
'Sun TV Network Ltd','SUNTV.NS',
'Syngene International Ltd','SYNGENE.NS',
'Tata Chemicals','TATACHEM.NS',
'Tata Consultancy','TCS.NS',
'Tata Consumer Products','TATACONSUM.NS',
'Tata Elxsi','TATAELXSI.NS',
'Tata Motors','TATAMOTORS.NS',
'Tata Power Co.','TATAPOWER.NS',
'Tata Steel Ltd','TATASTEEL.NS',
'Tech Mahindra','TECHM.NS',
'The Ramco Cements','RAMCOCEM.NS',
'Titan Company','TITAN.NS',
'Torrent Pharma','TORNTPHARM.NS',
'Torrent Power Ltd','TORNTPOWER.NS',
'Trent','TRENT.NS',
'TVS Motor Company','TVSMOTOR.NS',
'UltraTech Cement','ULTRACEMCO.NS',
'Union Bank of India','UNIONBANK.NS',
'United Breweries Ltd','UBL.NS',
'United Spirits','MCDOWELL-N.NS',
'UPL','UPL.NS',
'V Guard Industries','VGUARD.NS',
'Varun Beverages','VBL.NS',
'Vedanta','VEDL.NS',
'Vodafone Idea','IDEA.NS',
'Voltas','VOLTAS.NS',
'Whirlpool of India','WHIRLPOOL.NS',
'Wipro','WIPRO.NS',
'Yes Bank','YESBANK.NS',
'Zee Entertainment','ZEEL.NS'],
   

NIFTY500_STOCKS:[1,
  1,
  1,
  1,
  '₹',
  'NIFTY 500','^CRSLDX',
  '3M India','3MINDIA.NS',
'Aarti Drugs Ltd','AARTIDRUGS.NS',
'Aarti Industries','AARTIIND.NS',
'Aavas Financiers','AAVAS.NS',
'ABB India','ABB.NS',
'Abb Power','POWERINDIA.NS',
'Abbott India','ABBOTINDIA.NS',
'ACC','ACC.NS',
'Adani Enterprises','ADANIENT.NS',
'Adani Green Energy','ADANIGREEN.NS',
'Adani Ports & SEZ','ADANIPORTS.NS',
'Adani Total Gas','ATGL.NS',
'Adani Transmission Ltd','ADANITRANS.NS',
'Aditya Birla Capital','ABCAPITAL.NS',
'Aditya Birla Fashion','ABFRL.NS',
'Advanced Enzyme Tech','ADVENZYMES.NS',
'Aegis Logistics Ltd','AEGISCHEM.NS',
'Affle India','AFFLE.NS',
'AIA Engineering','AIAENG.NS',
'Ajanta Pharma Ltd','AJANTPHARM.NS',
'Akzo Nobel India','AKZOINDIA.NS',
'Alembic Ltd','ALEMBICLTD.NS',
'Alembic Pharmaceuticals Ltd','APLLTD.NS',
'Alkem Laboratories Ltd','ALKEM.NS',
'Alkyl Amines Chemicals Ltd','ALKYLAMINE.NS',
'Alok Industries','ALOKINDS.NS',
'Amara Raja Batteries','AMARAJABAT.NS',
'Amber Enterprises India','AMBER.NS',
'Ambuja Cements','AMBUJACEM.NS',
'Angel Broking','ANGELBRKG.NS',
'APL Apollo Tubes Ltd','APLAPOLLO.NS',
'Apollo Hospitals','APOLLOHOSP.NS',
'Apollo Tyres','APOLLOTYRE.NS',
'Asahi India Glass','ASAHIINDIA.NS',
'Ashok Leyland','ASHOKLEY.NS',
'Ashoka Buildcon Ltd','ASHOKA.NS',
'Asian Paints','ASIANPAINT.NS',
'Aster DM Healthcare','ASTERDM.NS',
'Astral Poly Technik Ltd','ASTRAL.NS',
'AstraZeneca Pharma','ASTRAZEN.NS',
'Atul','ATUL.NS',
'AU Small Finance Bank','AUBANK.NS',
'Aurobindo Pharma','AUROPHARMA.NS',
'Avanti Feeds','AVANTIFEED.NS',
'Avenue Supermarts','DMART.NS',
'AXIS Bank','AXISBANK.NS',
'Bajaj Auto','BAJAJ-AUTO.NS',
'Bajaj Consumer Care','BAJAJCON.NS',
'Bajaj Electricals','BAJAJELEC.NS',
'Bajaj Finance','BAJFINANCE.NS',
'Bajaj Finserv','BAJAJFINSV.NS',
'Bajaj Holdings','BAJAJHLDNG.NS',
'Balaji Amines Ltd','BALAMINES.NS',
'Balkrishna Industries','BALKRISIND.NS',
'Balmer Lawrie&Co.','BALMLAWRIE.NS',
'Balrampur Chini Mills','BALRAMCHIN.NS',
'Bandhan Bank','BANDHANBNK.NS',
'Bank Of Baroda','BANKBARODA.NS',
//'Bank of India','SBIN.NS',
'Bank of maharashtra','MAHABANK.NS',
'BASF India','BASF.NS',
'Bata India','BATAINDIA.NS',
'Bayer Cropscience','BAYERCROP.NS',
'BEML','BEML.NS',
'Berger Paints','BERGEPAINT.NS',
'Bharat Dynamics','BDL.NS',
'Bharat Electronics','BEL.NS',
'Bharat Forge','BHARATFORG.NS',
'Bharat Heavy Electricals','BHEL.NS',
'Bharat Petroleum','BPCL.NS',
'Bharat Rasayan Ltd','BHARATRAS.NS',
'Bharti Airtel','BHARTIARTL.NS',
'Biocon','BIOCON.NS',
'Birla Corporation','BIRLACORPN.NS',
'Birlasoft','BSOFT.NS',
'Bliss GVS Pharma Ltd','BLISSGVS.NS',
'Blue Dart Express','BLUEDART.NS',
'Blue Star','BLUESTARCO.NS',
'Bombay Burmah Trading','BBTC.NS',
'Bosch','BOSCHLTD.NS',
'Brigade Enterprises','BRIGADE.NS',
'Britannia Industries','BRITANNIA.NS',
'BSE','BSE.NS',
'Burger King','BURGERKING.NS',
'Cadila Healthcare','CADILAHC.NS',
'Can Fin Homes','CANFINHOME.NS',
'Canara Bank','CANBK.NS',
'Caplin Point Laboratories Ltd','CAPLIPOINT.NS',
'Capri Global Capital','CGCL.NS',
'Carborundum Universal','CARBORUNIV.NS',
'Castrol India','CASTROLIND.NS',
'CBI','CENTRALBK.NS',
'CCL Products India Ltd','CCL.NS',
'CEAT Ltd','CEATLTD.NS',
'Central Depository Services','CDSL.NS',
'Century Plyboards','CENTURYPLY.NS',
'Century Textiles','CENTURYTEX.NS',
'Cera Sanitaryware Ltd','CERA.NS',
'CESC','CESC.NS',
'Chalet Hotels','CHALET.NS',
'Chambal Fertilisers','CHAMBLFERT.NS',
'Cholamandalam','CHOLAFIN.NS',
'Cholamandalam Financial','CHOLAHLDNG.NS',
'Cipla','CIPLA.NS',
'City Union Bank','CUB.NS',
'Coal India','COALINDIA.NS',
'Cochin Shipyard','COCHINSHIP.NS',
'Coforge','COFORGE.NS',
'Colgate-Palmolive India','COLPAL.NS',
'Computer Age','CAMS.NS',
'Container India','CONCOR.NS',
'Coromandel Int','COROMANDEL.NS',
'CreditAccess Grameen','CREDITACC.NS',
'CRISIL','CRISIL.NS',
'Crompton Greaves Consumer Electricals Ltd','CROMPTON.NS',
'Csb Bank','CSBBANK.NS',
'Cummins India Ltd','CUMMINSIND.NS',
'Cyient Ltd-B','CYIENT.NS',
'Dabur India','DABUR.NS',
'Dalmia Bharat B','DALBHARAT.NS',
'DCB Bank Ltd','DCBBANK.NS',
'DCM Shriram','DCMSHRIRAM.NS',
'Deepak Nitrite Ltd','DEEPAKNTR.NS',
'Delta Corp','DELTACORP.NS',
'Dhani Services','DHANI.NS',
'Dhanuka Agritech Ltd','DHANUKA.NS',
'Dilip Buildcon','DBL.NS',
'Dish TV India','DISHTV.NS',
'Dishman Carbogen Amcis','DCAL.NS',
'Divi´s Labs','DIVISLAB.NS',
'Dixon Tech','DIXON.NS',
'DLF','DLF.NS',
'Dr Lal PathLabs Ltd','LALPATHLAB.NS',
'Dr. Reddy´s Labs','DRREDDY.NS',
'E.I.D-Parry','EIDPARRY.NS',
'eClerx Services','ECLERX.NS',
'Edelweiss Financial','EDELWEISS.NS',
'Eicher Motors','EICHERMOT.NS',
'EIH','EIHOTEL.NS',
'Elgi Equipments','ELGIEQUIP.NS',
'Emami','EMAMILTD.NS',
'Endurance Technologies Cn','ENDURANCE.NS',
'Engineers India','ENGINERSIN.NS',
'EPL','EPL.NS',
'Equitas Holdings','EQUITAS.NS',
'Eris Lifesciences','ERIS.NS',
'Escorts','ESCORTS.NS',
'Exide Industries','EXIDEIND.NS',
'FDC','FDC.NS',
'Federal Bank','FEDERALBNK.NS',
'Fine Organic','FINEORG.NS',
'Finolex Cables','FINCABLES.NS',
'Finolex Industries','FINPIPE.NS',
'Firstsource Solutions','FSL.NS',
'Fortis Healthcare','FORTIS.NS',
'Future Consumer','FCONSUMER.NS',
'Future Retail','FRETAIL.NS',
'GAIL Ltd','GAIL.NS',
'Galaxy Surfactants','GALAXYSURF.NS',
'Garden Reach','GRSE.NS',
'Garware Wall Ropes Ltd','GARFIBRES.NS',
'GE Power India','GENUSPOWER.NS',
'General Insurance India','GICRE.NS',
'Gillette India','GILLETTE.NS',
'GlaxoSmithkline Pharma','GLAXO.NS',
'Glenmark Pharma','GLENMARK.NS',
'GMM Pfaudler','GMMPFAUDLR.NS',
'GMR Infrastructure','GMRINFRA.NS',
'Godfrey Phillips India','GODFRYPHLP.NS',
'Godrej Agrovet','GODREJAGRO.NS',
'Godrej Consumer','GODREJCP.NS',
'Godrej Industries','GODREJIND.NS',
'Godrej Properties','GODREJPROP.NS',
'Granules India','GRANULES.NS',
'Graphite India','GRAPHITE.NS',
'Grasim Industries','GRASIM.NS',
'Great Eastern Shipping','GESHIP.NS',
'Greaves Cotton','GREAVESCOT.NS',
'Grindwell Norton Ltd','GRINDWELL.NS',
'Gujarat Alkalis&Chemicals','GUJALKALI.NS',
'Gujarat Ambuja Exports','GAEL.NS',
'Gujarat Fluorochemicals','FLUOROCHEM.NS',
'Gujarat Gas','GUJGASLTD.NS',
'Gujarat Narmada Valley Fert.','GNFC.NS',
'Gujarat Pipavav Port','GPPL.NS',
'Gujarat State Fertilizers','GSFC.NS',
'Gujarat State Petronet','GSPL.NS',
'Gulf Oil Lubricants India Ltd','GULFOILLUB.NS',
'Happiest Minds','HAPPSTMNDS.NS',
'Hatsun Agro Product Ltd','HATSUN.NS',
'Havells India','HAVELLS.NS',
'HCL Tech','HCLTECH.NS',
'HDFC Asset Management','HDFCAMC.NS',
'HDFC Bank','HDFCBANK.NS',
'HDFC Life','HDFCLIFE.NS',
'HEG','HEG.NS',
'HeidelbergCement India','HEIDELBERG.NS',
'Hemisphere Properties India','HEMIPROP.NS',
'Hero MotoCorp','HEROMOTOCO.NS',
'HFCL','HFCL.NS',
'Himadri Speciality Chem','HSCL.NS',
'Hindalco Industries','HINDALCO.NS',
'Hindustan Aeronautics','HAL.NS',
'Hindustan Copper','HINDCOPPER.NS',
'Hindustan Petroleum','HINDPETRO.NS',
'Hindustan Unilever','HINDUNILVR.NS',
'Hindustan Zinc','HINDZINC.NS',
'Honeywell Automation','HONAUT.NS',
'Housing Development Finance','HDFC.NS',
'Housing Urban Develop','HUDCO.NS',
'Huhtamaki PPL','HUHTAMAKI.NS',
'ICICI Bank','ICICIBANK.NS',
'ICICI Lombard','ICICIGI.NS',
'ICICI Prudential Life Insurance','ICICIPRULI.NS',
'ICICI Securities','ISEC.NS',
'IDBI Bank','IDBI.NS',
'IDFC','IDFC.NS',
'IDFC First Bank','IDFCFIRSTB.NS',
'IFB Industries Ltd','IFBIND.NS',
'IIFL Finance B','IIFL.NS',
'Iifl Wealth','IIFLWAM.NS',
'India Cements','INDIACEM.NS',
'Indiabulls','IBULHSGFIN.NS',
'Indiabulls RE','IBREALEST.NS',
'IndiaMART InterMESH','INDIAMART.NS',
'Indian Bank','INDIANB.NS',
'Indian Energy Exchange','IEX.NS',
'Indian Hotels Co.','INDHOTEL.NS',
'Indian Oil','IOC.NS',
'Indian Overseas Bank','IOB.NS',
'Indian Railway Catering','IRCTC.NS',
'Indo Count Industries','ICIL.NS',
'Indoco Remedies Ltd','INDOCO.NS',
'Indraprastha Gas','IGL.NS',
'Indus Towers','INDUSTOWER.NS',
'IndusInd Bank','INDUSINDBK.NS',
'Infibeam Avenues','INFIBEAM.NS',
'Info Edge India','NAUKRI.NS',
'Infosys','INFY.NS',
'Ingersoll-Rand IN','INGERRAND.NS',
'Inox Leisure','INOXLEISUR.NS',
'Intellect Design Arena','INTELLECT.NS',
'Interglobe Aviation Ltd','INDIGO.NS',
'IOL Chemicals Pharmaceuticals','IOLCP.NS',
'Ipca Laboratories','IPCALAB.NS',
'IRB Infrastructure','IRB.NS',
'Ircon','IRCON.NS',
'ITC','ITC.NS',
'ITI Ltd','ITI.NS',
'J.B. Chemicals&Pharma','JBCHEPHARM.NS',
'J.K. Cement','JKCEMENT.NS',
'Jamna Auto','JAMNAAUTO.NS',
'Jindal Saw','JINDALSAW.NS',
'Jindal Stainless','JSLHISAR.NS',
'Jindal Stainless','JSL.NS',
'Jindal Steel&Power','JINDALSTEL.NS',
'JK Lakshmi Cement','JKLAKSHMI.NS',
'JK Paper Ltd','JKPAPER.NS',
'JK Tyre&Industries','JKTYRE.NS',
'JM Financial','JMFINANCIL.NS',
'Johnson Controls-Hitachi Air','JCHAC.NS',
'JSW Energy','JSWENERGY.NS',
'JSW Steel','JSWSTEEL.NS',
'JTEKT India','JTEKTINDIA.NS',
'Jubilant Foodworks','JUBLFOOD.NS',
'Just Dial','JUSTDIAL.NS',
'Jyothy Labs','JYOTHYLAB.NS',
'Kajaria Ceramics','KAJARIACER.NS',
'Kalpataru Power','KALPATPOWR.NS',
'Kansai Nerolac Paints','KANSAINER.NS',
'Karur Vysya Bank','KARURVYSYA.NS',
'Kaveri Seed','KSCL.NS',
'KEC International','KEC.NS',
'KEI Industries Ltd','KEI.NS',
'KNR Constructions','KNRCON.NS',
'Kotak Mahindra Bank','KOTAKBANK.NS',
'KPIT Tech','KPITTECH.NS',
'KPR Mill','KPRMILL.NS',
'KRBL Ltd','KRBL.NS',
'KSB','KSB.NS',
// falla el and en el ticker. CAN BE FIXED!!! 'L&T Finance Hld','L&TFH.NS',
'L&T Technology Services','LTTS.NS',
'La Opala R G Ltd','LAOPALA.NS',
'Lakshmi Machine Works','LAXMIMACH.NS',
'Larsen & Toubro','LT.NS',
'Larsen & Toubro Infotech','LTI.NS',
'Laurus Labs','LAURUSLABS.NS',
'Lemon Tree Hotels','LEMONTREE.NS',
'Lic Housing Finance','LICHSGFIN.NS',
'Linde India','LINDEINDIA.NS',
'Lupin','LUPIN.NS',
'Lux Industries Ltd','LUXIND.NS',
'M.R.F.','MRF.NS',
'Mahanagar Gas','MTNL.NS',
'Maharashtra Scooters','MAHSCOOTER.NS',
'Maharashtra Seamless','MAHSEAMLES.NS',
'Mahindra','MAHLOG.NS',
// falla el and en el ticker. CAN BE FIXED!!! 'Mahindra & Mahindra','M&M.NS',
// falla el and en el ticker. CAN BE FIXED!!! 'Mahindra & Mahindra Financial','M&MFIN.NS',
'Mahindra CIE Automotive Ltd','MAHINDCIE.NS',
'Mahindra Holidays','MHRIL.NS',
'Manappuram Finance','MANAPPURAM.NS',
'Mangalore','MRPL.NS',
'Marico','MARICO.NS',
'Maruti Suzuki','MARUTI.NS',
'MAS Financial','MASFIN.NS',
'Max Financial','MFSL.NS',
'Max Healthcare Institute','MAXHEALTH.NS',
'Mazagon Dock','MAZDOCK.NS',
'Metropolis Healthcare','METROPOLIS.NS',
'Minda Corp','MINDACORP.NS',
'Minda Industries','MINDAIND.NS',
'MindTree','MINDTREE.NS',
'Mishra Dhatu Nigam','MIDHANI.NS',
'MMTC','MMTC.NS',
'MOIL','MOIL.NS',
'Motherson Sumi Systems','MOTHERSUMI.NS',
'Motilal Oswal','MOTILALOFS.NS',
'Mphasis','MPHASIS.NS',
'Multi Commodity Exchange','MCX.NS',
'Muthoot Finance','MUTHOOTFIN.NS',
'Narayana Hrudayalaya','NH.NS',
'Natco Pharma','NATCOPHARM.NS',
'National Aluminum Co.','NATIONALUM.NS',
'National Fertilizers','NFL.NS',
'Navin Fluorine','NAVINFLUOR.NS',
'NBCC India','NBCC.NS',
'NCC','NCC.NS',
'Nesco','NESCO.NS',
'Nestle India','NESTLEIND.NS',
'Network 18 Media','NETWORK18.NS',
'New India Assurance','NIACL.NS',
'NHPC','NHPC.NS',
'Nilkamal','NILKAMAL.NS',
'Nippon India','NAM-INDIA.NS',
'NLC India','NLCINDIA.NS',
'NMDC','NMDC.NS',
'Nocil Ltd','NOCIL.NS',
'NTPC','NTPC.NS',
'Oberoi Realty','OBEROIRLTY.NS',
'Oil & Natural Gas','ONGC.NS',
'Oil India','OIL.NS',
'Oracle','OFSS.NS',
'Orient Electric','ORIENTELEC.NS',
//'Orient Refractories Ltd','ORIENTREF.NS',
'Page Industries','PAGEIND.NS',
'Persistent Systems','PERSISTENT.NS',
'Petronet LNG','PETRONET.NS',
'Pfizer Ltd','PFIZER.NS',
//'Phillips Carbon Black','PHILIPCARB.NS',
'PI Industries','PIIND.NS',
'Pidilite Industries','PIDILITIND.NS',
'Piramal Enterprises','PEL.NS',
'PNB Housing Finance','PNBHOUSING.NS',
'PNC Infratech','PNCINFRA.NS',
'Poly Medicure Ltd','POLYMED.NS',
'Polycab India','POLYCAB.NS',
'Polyplex','POLYPLEX.NS',
'Power Finance','PFC.NS',
'Power Grid','POWERGRID.NS',
'Prestige Estates','PRESTIGE.NS',
'Prince Pipes And Fittings','PRINCEPIPE.NS',
'Prism Johnson','PRSMJOHNSN.NS',
'Procter & Gamble Health','PGHL.NS',
'Procter & Gamble Hygiene','PGHH.NS',
'Punjab National Bank','PNB.NS',
'PVR','PVR.NS',
'Quess Corp','QUESS.NS',
'Radico Khaitan','RADICO.NS',
'Rail Vikas Nigam','RVNL.NS',
'Rain Industries','RAIN.NS',
'Rajesh Exports','RAJESHEXPO.NS',
'Rallis India','RALLIS.NS',
'Rashtriya Chemicals','RCF.NS',
'Ratnamani Metals','RATNAMANI.NS',
'Raymond','RAYMOND.NS',
'RBL Bank','RBLBANK.NS',
'REC','RECLTD.NS',
'Redington India','REDINGTON.NS',
'Relaxo Footwears Ltd','RELAXO.NS',
'Reliance Industries','RELIANCE.NS',
'Responsive Industries','RESPONIND.NS',
'RITES','RITES.NS',
'Rossari Biotech','ROSSARI.NS',
'Route Mobile','ROUTE.NS',
'Sanofi India','SANOFI.NS',
'SBI','SBIN.NS',
'SBI Cards','SBICARD.NS',
'SBI Life Insurance','SBILIFE.NS',
'Schaeffler India','SCHAEFFLER.NS',
'Schneider Electric Infr','SCHNEIDER.NS',
'SeQuent Scientific','SEQUENT.NS',
'Sharda Cropchem Ltd','SHARDACROP.NS',
'Sheela Foam','SFL.NS',
'Shilpa Medicare Ltd','SHILPAMED.NS',
'Shipping Corporation','SCI.NS',
'Shoppers Stop','SHOPERSTOP.NS',
'Shree Cements','SHREECEM.NS',
'Shriram Transport','SRTRANSFIN.NS',
'Shriram-City Union','SHRIRAMCIT.NS',
'Siemens Ltd','SIEMENS.NS',
'SIS','SIS.NS',
'SJVN','SJVN.NS',
'SKF India','SKFINDIA.NS',
'Sobha Developers','SOBHA.NS',
'Solar Industries India Ltd','SOLARINDS.NS',
'Solara Active Pharma','SOLARA.NS',
'Sonata Software','SONATSOFTW.NS',
'Spandana Sphoorty Fin','SPANDANA.NS',
'Spicejet','SPICEJET.NS',
'SRF','SRF.NS',
'Star Cement','STARCEMENT.NS',
'Steel Authority','SAIL.NS',
'Sterling and Wilson Solar','SWSOLAR.NS',
'Sterlite Technologies','STLTECH.NS',
'Strides Pharma','STAR.NS',
'Sudarshan Chemical Ind','SUDARSCHEM.NS',
'Sumitomo Chemical','SUMICHEM.NS',
'Sun Pharma','SUNPHARMA.NS',
'Sun Pharma Adv. Research','SPARC.NS',
'Sun TV Network Ltd','SUNTV.NS',
'Sundaram Clayton Ltd','SUNCLAYLTD.NS',
'Sundaram Finance','SUNDARMFIN.NS',
'Sundram Fasteners','SUNDRMFAST.NS',
'Sunteck Realty','SUNTECK.NS',
'Suprajit Engineering Ltd','SUPRAJIT.NS',
'Supreme Industries','SUPREMEIND.NS',
'Supreme Petrochem','SUPPETRO.NS',
'Suven Pharma','SUVENPHAR.NS',
'Suzlon Energy','SUZLON.NS',
'Swan Energy','SWANENERGY.NS',
'Symphony Ltd','SYMPHONY.NS',
'Syngene International Ltd','SYNGENE.NS',
'Tanla Solutions','TANLA.NS',
'Tasty Bite Eatables','TASTYBITE.NS',
'Tata Chemicals','TATACHEM.NS',
'Tata Coffee','TATACOFFEE.NS',
'Tata Communications','TATACOMM.NS',
'Tata Consultancy','TCS.NS',
'Tata Consumer Products','TATACONSUM.NS',
'Tata Elxsi','TATAELXSI.NS',
'Tata Investment','TATAINVEST.NS',
'Tata Motors','TATAMOTORS.NS',
'Tata Motors DV Ltd','TATAMTRDVR.NS',
'Tata Power Co.','TATAPOWER.NS',
'Tata Steel Ltd','TATASTEEL.NS',
'TCI Express','TCIEXP.NS',
'TCNS Clothing','TCNSBRANDS.NS',
'Teamlease Services','TEAMLEASE.NS',
'Tech Mahindra','TECHM.NS',
'The Phoenix Mills','PHOENIXLTD.NS',
'The Ramco Cements','RAMCOCEM.NS',
'Thermax','THERMAX.NS',
'Thyrocare Technologies','THYROCARE.NS',
'Timken India','TIMKEN.NS',
'Titan Company','TITAN.NS',
'Torrent Pharma','TORNTPHARM.NS',
'Torrent Power Ltd','TORNTPOWER.NS',
'Trent','TRENT.NS',
'Trident','TRIDENT.NS',
'Triveni Turbine','TRITURBINE.NS',
'TTK Prestige','TTKPRESTIG.NS',
'Tube Invest India','TIINDIA.NS',
'TV18 Broadcast','TV18BRDCST.NS',
'TVS Motor Company','TVSMOTOR.NS',
'UCO Bank','UCOBANK.NS',
'Uflex','UFLEX.NS',
'Ujjivan Financial Services','UJJIVAN.NS',
'Ujjivan Small Fin','UJJIVANSFB.NS',
'UltraTech Cement','ULTRACEMCO.NS',
'Union Bank of India','UNIONBANK.NS',
'United Breweries Ltd','UBL.NS',
'United Spirits','MCDOWELL-N.NS',
'UPL','UPL.NS',
'Uti Asset','UTIAMC.NS',
'V Guard Industries','VGUARD.NS',
'V Mart Retail Ltd','VMART.NS',
'Vaibhav Global','VAIBHAVGBL.NS',
'Vakrangee Softwares','VAKRANGEE.NS',
'Vardhman Textiles','VTL.NS',
'Varroc Engineering','VARROC.NS',
'Varun Beverages','VBL.NS',
'Vedanta','VEDL.NS',
'Venkys India','VENKEYS.NS',
'Vinati Organics Ltd','VINATIORGA.NS',
'VIP Industries','VIPIND.NS',
'Vodafone Idea','IDEA.NS',
'Voltas','VOLTAS.NS',
'VST Industries','VSTIND.NS',
'WABCO India','WABCOINDIA.NS',
'Welspun','WELSPUNIND.NS',
'Welspun Corp','WELCORP.NS',
'Westlife Development Ltd-BO','WESTLIFE.NS',
'Whirlpool of India','WHIRLPOOL.NS',
'Wipro','WIPRO.NS',
'Wockhardt','WOCKPHARMA.NS',
'Yes Bank','YESBANK.NS',
'Zee Entertainment','ZEEL.NS',
'Zensar Tech','ZENSARTECH.NS',
'Zydus Wellness','ZYDUSWELL.NS'],

NIFTY_SMALLCAPS_250_STOCKS:[1,
  1,
  1,
  1,
  '₹',
  'NIFTY SMLCAP 250','NIFTYSMLCAP250.NS',
  'Aavas Financiers','AAVAS.NS',
'Adani Green Energy','ADANIGREEN.NS',
'Advanced Enzyme Tech','ADVENZYMES.NS',
'Aegis Logistics Ltd','AEGISCHEM.NS',
'Akzo Nobel India','AKZOINDIA.NS',
'Allcargo Logistics','ALLCARGO.NS',
'APL Apollo Tubes Ltd','APLAPOLLO.NS',
'Ashoka Buildcon Ltd','ASHOKA.NS',
'Aster DM Healthcare','ASTERDM.NS',
'AstraZeneca Pharma','ASTRAZEN.NS',
'Avanti Feeds','AVANTIFEED.NS',
'Bajaj Consumer Care','BAJAJCON.NS',
'Bajaj Electricals','BAJAJELEC.NS',
'Balmer Lawrie&Co.','BALMLAWRIE.NS',
'Balrampur Chini Mills','BALRAMCHIN.NS',
'Bank of maharashtra','MAHABANK.NS',
'BASF India','BASF.NS',
'BEML','BEML.NS',
'Bharat Dynamics','BDL.NS',
'Birla Corporation','BIRLACORPN.NS',
'Bliss GVS Pharma Ltd','BLISSGVS.NS',
'Blue Star','BLUESTARCO.NS',
'Bombay Dyeing&Mfg.','BOMDYEING.NS',
'Brigade Enterprises','BRIGADE.NS',
'BSE','BSE.NS',
'Can Fin Homes','CANFINHOME.NS',
'Caplin Point Laboratories Ltd','CAPLIPOINT.NS',
'Carborundum Universal','CARBORUNIV.NS',
'CARE Ratings','CARERATING.NS',
'CCL Products India Ltd','CCL.NS',
'CEAT Ltd','CEATLTD.NS',
'Central Depository Services','CDSL.NS',
'Century Plyboards','CENTURYPLY.NS',
'Cera Sanitaryware Ltd','CERA.NS',
'CESC','CESC.NS',
'CG Power and Industrial Solutions','CGPOWER.NS',
'Chalet Hotels','CHALET.NS',
'Chambal Fertilisers','CHAMBLFERT.NS',
'Chennai Petroleum','CHENNPETRO.NS',
'Cochin Shipyard','COCHINSHIP.NS',
'Coffee Day Enterprises','COFFEEDAY.NS',
'Coforge','COFORGE.NS',
// problema & ticker yahoo finance. Will be FIXED!! 'Cox & Kings','COX&KINGS.NS',
'CreditAccess Grameen','CREDITACC.NS',
'Cyient Ltd-B','CYIENT.NS',
'D.B.Corp','DBCORP.NS',
'DCB Bank Ltd','DCBBANK.NS',
'DCM Shriram','DCMSHRIRAM.NS',
'Deepak','DEEPAKFERT.NS',
'Deepak Nitrite Ltd','DEEPAKNTR.NS',
'Delta Corp','DELTACORP.NS',
'Dishman Carbogen Amcis','DCAL.NS',
'Dixon Tech','DIXON.NS',
'Dr Lal PathLabs Ltd','LALPATHLAB.NS',
'E.I.D-Parry','EIDPARRY.NS',
'eClerx Services','ECLERX.NS',
'Elgi Equipments','ELGIEQUIP.NS',
'EPL','EPL.NS',
'Equitas Holdings','EQUITAS.NS',
'FDC','FDC.NS',
'Fine Organic','FINEORG.NS',
'Finolex Industries','FINPIPE.NS',
'Firstsource Solutions','FSL.NS',
'Fortis Healthcare','FORTIS.NS',
'Future Lifestyle Fashions','FLFL.NS',
'Galaxy Surfactants','GALAXYSURF.NS',
//'Gateway Distriparks','GDL.NS',
'Gayatri Projects','GAYAPROJ.NS',
'GE Power India','GEPIL.NS',
// problema & ticker yahoo finance. Will be FIXED!!'Ge T&D India','GET&D.NS',
'GHCL','GHCL.NS',
'Godfrey Phillips India','GODFRYPHLP.NS',
'Granules India','GRANULES.NS',
'Great Eastern Shipping','GESHIP.NS',
'Greaves Cotton','GREAVESCOT.NS',
'Grindwell Norton Ltd','GRINDWELL.NS',
'Gujarat Alkalis&Chemicals','GUJALKALI.NS',
'Gujarat Mineral','GMDCLTD.NS',
'Gujarat Narmada Valley Fert.','GNFC.NS',
'Gujarat Pipavav Port','GPPL.NS',
'Gujarat State Fertilizers','GSFC.NS',
'Gulf Oil Lubricants India Ltd','GULFOILLUB.NS',
'Hathway Cable','HATHWAY.NS',
'HeidelbergCement India','HEIDELBERG.NS',
'Heritage Foods','HERITGFOOD.NS',
'HFCL','HFCL.NS',
'Himadri Speciality Chem','HSCL.NS',
'Himatsingka Seide','HIMATSEIDE.NS',
'Hindustan Copper','HINDCOPPER.NS',
'ICRA','ICRA.NS',
'IDFC','IDFC.NS',
'IFB Industries Ltd','IFBIND.NS',
'ICICI Bank','ICICIBANK.NS',
'India Cements','INDIACEM.NS',
'India Tourism Development','ITDC.NS',
//'Indiabulls Integrated',
'Indiabulls RE','IBREALEST.NS',
'Indian Energy Exchange','IEX.NS',
'Indian Overseas Bank','IOB.NS',
'Indoco Remedies Ltd','INDOCO.NS',
'IndoStar Capital','INDOSTAR.NS',
'Infibeam Avenues','INFIBEAM.NS',
'Inox Leisure','INOXLEISUR.NS',
'Inox Wind Ltd','INOXWIND.NS',
'Intellect Design Arena','INTELLECT.NS',
'IRB Infrastructure','IRB.NS',
'Ircon','IRCON.NS',
'ITD Cementation India','ITDCEM.NS',
'ITI Ltd','ITI.NS',
'J.B. Chemicals&Pharma','JBCHEPHARM.NS',
'J.K. Cement','JKCEMENT.NS',
'Jagran Prakashan','JAGRAN.NS',
'Jai Corp','JAICORPLTD.NS',
'Jain Irrigation','JISLJALEQS.NS',
'Jaiprakash Associates','JPASSOCIAT.NS',
// falla el and en el ticker. CAN BE FIXED!!! 'Jammu and Kashmir Bank','J&KBANK.NS',
'Jamna Auto','JAMNAAUTO.NS',
'Jet Airways','JETAIRWAYS.NS',
'Jindal Saw','JINDALSAW.NS',
'Jindal Stainless (hisar)','JSLHISAR.NS',
'Jindal Stainless','JSL.NS',
'JK Lakshmi Cement','JKLAKSHMI.NS',
'JK Paper Ltd','JKPAPER.NS',
'JK Tyre&Industries','JKTYRE.NS',
'Just Dial','JUSTDIAL.NS',
'Jyothy Labs','JYOTHYLAB.NS',
'Kajaria Ceramics','KAJARIACER.NS',
'Kalpataru Power','KALPATPOWR.NS',
'Karnataka Bank','KTKBANK.NS',
'Karur Vysya Bank','KARURVYSYA.NS',
'Kaveri Seed','KSCL.NS',
'KEC International','KEC.NS',
'KEI Industries Ltd','KEI.NS',
'Kirloskar Oil Engines','KIRLOSENG.NS',
'KNR Constructions','KNRCON.NS',
'Kolte Patil','KOLTEPATIL.NS',
'KPR Mill','KPRMILL.NS',
'Lakshmi Machine Works','LAXMIMACH.NS',
'Laurus Labs','LAURUSLABS.NS',
'Lemon Tree Hotels','LEMONTREE.NS',
'Linde India','LINDEINDIA.NS',
'Lux Industries Ltd','LUXIND.NS',
//'Magma Fincorp','MAGMA.NS',
'Maharashtra Scooters','MAHSCOOTER.NS',
'Maharashtra Seamless','MAHSEAMLES.NS',
'Mahindra','MAHLOG.NS',
'Mahindra Holidays','MHRIL.NS',
'MAS Financial','MASFIN.NS',
'Minda Corp','MINDACORP.NS',
'Minda Industries','MINDAIND.NS',
'MMTC','MMTC.NS',
'MOIL','MOIL.NS',
'Narayana Hrudayalaya','NH.NS',
'National Fertilizers','NFL.NS',
'Nava Bharat Ventures','NBVENTURES.NS',
'Navin Fluorine','NAVINFLUOR.NS',
'NCC','NCC.NS',
'Nesco','NESCO.NS',
'Network 18 Media','NETWORK18.NS',
'Nilkamal','NILKAMAL.NS',
'Omaxe','OMAXE.NS',
'Orient Cement Ltd','ORIENTCEM.NS',
'Orient Electric','ORIENTELEC.NS',
'Parag Milk Foods Ltd','PARAGMILK.NS',
'PC Jeweller','PCJEWELLER.NS',
'Persistent Systems','PERSISTENT.NS',
//'Phillips Carbon Black','PHILIPCARB.NS',
'PNC Infratech','PNCINFRA.NS',
'Praj Industries','PRAJIND.NS',
'Prism Johnson','PRSMJOHNSN.NS',
'Procter & Gamble Health','PGHL.NS',
'PTC India','PTC.NS',
'PVR','PVR.NS',
'Radico Khaitan','RADICO.NS',
'Rain Industries','RAIN.NS',
'Rallis India','RALLIS.NS',
'Ramkrishna Forgings Ltd','RKFORGE.BO',
'Rashtriya Chemicals','RCF.NS',
'Raymond','RAYMOND.NS',
'Redington India','REDINGTON.NS',
'Reliance Communications','RCOM.NS',
'Reliance Home Finance','RHFL.NS',
'Repco Home Finance Ltd','REPCOHOME.NS',
'RITES','RITES.NS',
'Rupa Company Ltd','RUPA.NS',
'S H Kelkar And Company Ltd','SHK.NS',
'Sadbhav Engineering','SADBHAV.NS',
'Schaeffler India','SCHAEFFLER.NS',
'Shankara Building','SHANKARA.NS',
'Sharda Cropchem Ltd','SHARDACROP.NS',
'Sheela Foam','SFL.NS',
'Shilpa Medicare Ltd','SHILPAMED.NS',
'Shipping Corporation','SCI.NS',
'Shoppers Stop','SHOPERSTOP.NS',
'Shree Renuka Sugars','RENUKA.NS',
'Sintex Plastics','SPTL.NS',
'SIS','SIS.NS',
'Sobha Developers','SOBHA.NS',
'Sonata Software','SONATSOFTW.NS',
'South Indian Bank','SOUTHBANK.NS',
'SREI Infrastructure','SREINFRA.NS',
'Star Cement','STARCEMENT.NS',
'Strides Pharma','STAR.NS',
'Sudarshan Chemical Industries','SUDARSCHEM.NS',
'Sundaram Clayton Ltd','SUNCLAYLTD.NS',
'Sunteck Realty','SUNTECK.NS',
'Suprajit Engineering Ltd','SUPRAJIT.NS',
'Suven Life Sciences Ltd','SUVEN.NS',
'Suzlon Energy','SUZLON.NS',
'Swan Energy','SWANENERGY.NS',
'T.V.Today Network','TVTODAY.NS',
'Take Solutions Ltd','TAKE.NS',
'Tamil Nadu','TNPL.NS',
'Tata Coffee','TATACOFFEE.NS',
'Tata Elxsi','TATAELXSI.NS',
'Tata Investment','TATAINVEST.NS',
'TCNS Clothing','TCNSBRANDS.NS',
'Teamlease Services','TEAMLEASE.NS',
'Thyrocare Technologies Ltd','THYROCARE.NS',
'Time Technoplast','TIMETECHNO.NS',
'Timken India','TIMKEN.NS',
'Trident','TRIDENT.NS',
'Triveni Turbine','TRITURBINE.NS',
'TTK Prestige','TTKPRESTIG.NS',
'Tube Invest India','TIINDIA.NS',
'TV18 Broadcast','TV18BRDCST.NS',
'UCO Bank','UCOBANK.NS',
'Uflex','UFLEX.NS',
'Ujjivan Financial Services','UJJIVAN.NS',
'V Mart Retail Ltd','VMART.NS',
'Va Tech Wabag Ltd','WABAG.NS',
'Vakrangee Softwares','VAKRANGEE.NS',
'Vardhman Textiles','VTL.NS',
'Venkys India','VENKEYS.NS',
'Vinati Organics Ltd','VINATIORGA.NS',
'VIP Industries','VIPIND.NS',
'VRL Logistics Ltd','VRLLOG.NS',
'VST Industries','VSTIND.NS',
'Welspun','WELSPUNIND.NS',
'Welspun Corp','WELCORP.NS',
'Wockhardt','WOCKPHARMA.NS',
'Zensar Tech','ZENSARTECH.NS',
'Zydus Wellness','ZYDUSWELL.NS'],
 
NASDAQCOMP_STOCKS:[1,
    1,
    1,
    1,
    '$',
    'Nasdaq COMP','^IXIC',],

RUSSEL2000_STOCKS:[1,
      1,
      1,
      1,
      '$',
      'RUSSELL 2000','^RUT',],

      NYSECOMP_STOCKS:[1,
        1,
        1,
        1,
        '$',
        'NYSE COMPOSITE','^NYA',],


NASDAQ100_STOCKS:[1,
  1,
  1,
  1,
  '$',
  'Nasdaq 100','^NDX',
  'Activision Blizzard','ATVI',		 
 	'Adobe','ADBE',		 
 	'ADP','ADP',		
   'Airbnb','ABNB', 
 	//'Alexion','ALXN',	 
 	'Align','ALGN', 
 	'Alphabet A','GOOGL',		 
 	'Alphabet C','GOOG',		 
 	'Amazon.com','AMZN',		 
 	'AMD','AMD',		 
 	'American Electric Power Company Inc','AEP',	 
 	'Amgen','AMGN',	 
 	'Analog Devices','ADI',	 
 	'ANSYS','ANSS',	 
 	'Apple','AAPL',	 
 	'Applied Materials','AMAT',	 
 	'ASML ADR','ASML', 
 	'Atlassian Corp Plc','TEAM',	
 	'Autodesk','ADSK', 
 	'Baidu','BIDU', 
 	'Biogen','BIIB',	 
 	'Booking','BKNG', 
 	'Broadcom','AVGO',	 
 	'Cadence Design','CDNS',
 	'CDW Corp','CDW',		 
 	//'Cerner','CERN', 
 	'Charter Communications','CHTR',		 
 	'Check Point Software','CHKP', 
 	'Cintas','CTAS',
 	'Cisco','CSCO',
 	'Cognizant A','CTSH',	 
 	'Comcast','CMCSA',	 
 	'Copart','CPRT',		 
 	'Costco','COST',		 
 	'CSX','CSX',	 
 	'DexCom','DXCM',		 
 	'DocuSign','DOCU',		 
 	'Dollar Tree','DLTR',		 
 	'eBay','EBAY', 
 	'Electronic Arts','EA', 		 
 	'Exelon','EXC',	 
 	'Meta Platforms','META',//'Facebook','FB',		 
 	'Fastenal','FAST',		 
 	'Fiserv','FISV', 
 	'Fox Corp A','FOXA',		 
 	'Fox Corp B','FOX',	 
 	'Gilead','GILD', 
 	'IDEXX Labs','IDXX',		 
 	'Illumina','ILMN',	 
 	'Incyte','INCY',
 	'Intel','INTC', 
 	'Intuit','INTU',		 
 	'Intuitive Surgical','ISRG',		 
 	'JD.com Inc Adr','JD',
 	'Keurig Dr Pepper','KDP',		 
 	'KLA-Tencor','KLAC', 
 	'Kraft Heinz','KHC', 
 	'Lam Research','LRCX',		 
 	'Lululemon Athletica','LULU',
 	'Marriott Int','MAR',
 	'Marvell','MRVL',
 	'Match Group','MTCH',		 
 	//'Maxim','MXIM',
 	'MercadoLibre','MELI',		 
 	'Microchip','MCHP',	 
 	'Micron','MU', 
 	'Microsoft','MSFT',		 
 	'Moderna','MRNA',
 	'Mondelez','MDLZ',	 
 	'Monster Beverage','MNST',		 
 	'NetEase','NTES',
 	'Netflix','NFLX', 
 	'NVIDIA','NVDA',	 
 	'NXP','NXPI', 
 	'Okta','OKTA',	 
 	'O^Reilly Automotive','ORLY',		 
 	'PACCAR','PCAR',	 
 	'Paychex','PAYX',	 
 	'PayPal Holdings Inc','PYPL',		 
 	'Peloton Interactive','PTON',		 
 	'PepsiCo','PEP',
 	'Pinduoduo','PDD',	 
 	'Qualcomm','QCOM',	 
 	'Regeneron Pharma','REGN',		 
 	'Ross Stores','ROST',		 
 	'Seagen','SGEN',	 
 	'Sirius XM','SIRI',		 
 	'Skyworks','SWKS',		 
 	'Splunk','SPLK',	 
 	'Starbucks','SBUX',		 
 	'Synopsys','SNPS',	 
 	'T-Mobile US','TMUS',		 
 	'Tesla','TSLA',
 	'Texas Instruments','TXN',		 
 	'Trip.com ADR','TCOM', 
 	'VeriSign','VRSN',		 
 	'Verisk','VRSK',	 
 	'Vertex','VRTX',	 
 	'Walgreens Boots','WBA',		 
 	'Workday','WDAY',	 
 	'Xcel Energy','XEL',		 
 	'Zscaler, Inc','ZS',//'Xilinx','XLNX',		 
 	'Zoom Video','ZM'],	
    

   SP500_STOCKS:[1,
    1,
    1,
    1,
    '$',
    'S&P 500','^GSPC',
    '3M','MMM',
'Abbott Labs','ABT',
'AbbVie','ABBV',		 
'Accenture','ACN', 
'Activision Blizzard','ATVI',	 
'Acuity Brands','AYI',		 
'Adobe','ADBE',	 
'ADP','ADP',
'Advance Auto Parts','AAP',
'Affiliated Managers','AMG',
'Aflac','AFL',
'Agilent Technologies','A',
'AIG','AIG',
'Air Products','APD',
'Akamai','AKAM',
'Alaska Air','ALK',
'Albemarle','ALB',
'Alexandria RE','ARE',
//'Alexion','ALXN', 
'Align','ALGN',
'Allegion PLC','ALLE',
//'Alliance Data Systems','ADS',
'Alliant Energy','LNT',
'Allstate','ALL',
'Alphabet A','GOOGL',
'Alphabet C','GOOG',
'Altria','MO',
'Amazon.com','AMZN',
'AMD','AMD',
'Ameren','AEE',
'American Airlines','AAL',
'American Electric Power','AEP',
'American Express','AXP',
'American Tower','AMT',
'American Water Works','AWK',
'Ameriprise Financial','AMP',
'AmerisourceBergen','ABC',
'Ametek','AME',
'Amgen','AMGN',
'Amphenol','APH',
'Analog Devices','ADI',
'ANSYS','ANSS',
'Anthem','ANTM',
'AO Smith','AOS',
'Aon','AON',
'APA Corp','APA',
'Apartment Invest','AIV',
'Apple','AAPL',
'Applied Materials','AMAT',
'Aptiv','APTV',
'Archer-Daniels-Midland','ADM',
'Arconic','ARNC',
'Arthur J Gallagher','AJG',
'Assurant','AIZ',
'AT&T','T',
'Autodesk','ADSK',
'AutoZone','AZO',
'AvalonBay','AVB',
'Avery Dennison','AVY',
'Baker Hughes A','BKR',
'Ball','BALL',
'Bank of America','BAC',
'Bank of NY Mellon','BK',
'Baxter','BAX',
'Becton Dickinson','BDX',
'Berkshire Hathaway B','BRK-B',
'Best Buy','BBY',
'Biogen','BIIB',
'BlackRock','BLK',
'Boeing','BA',
'Booking','BKNG',
'BorgWarner','BWA',
'Boston Properties','BXP',
'Boston Scientific','BSX',
'Brighthouse Financial','BHF',
'Bristol-Myers Squibb','BMY',
'Broadcom','AVGO',
//'Brookfield Property Reit','BPY',
'Brown Forman','BF-B',
//'Cabot Oil&Gas','COG',
'Cadence Design','CDNS',
'Campbell Soup','CPB',
'Capital One Financial','COF',
'Capri Holdings','CPRI',
'Cardinal Health','CAH',
'CarMax','KMX',
'Carnival Corp','CCL',
'Caterpillar','CAT',
'Cboe Global','CBOE',
//'CBRE A','CEBRE',
'Centene','CNC',
'CenterPoint Energy','CNP',
//'Cerner','CERN',
'CF Industries','CF',
'CH Robinson','CHRW',
'Charter Communications','CHTR',
'Chevron','CVX',
'Chipotle Mexican Grill','CMG',
'Chubb','CB',
'Church&Dwight','CHD',
'Cigna','CI',
//'Cimarex Energy','XEC',
'Cincinnati Financial','CINF',
'Cintas','CTAS',
'Cisco','CSCO',
'Citigroup','C',
'Citizens Financial Group Inc','CFG',
'Citrix Systems','CTXS',
'Clorox','CLX',
'CME Group','CME',
'CMS Energy','CMS',
'Coca-Cola','KO',
'Cognizant A','CTSH',
'Colgate-Palmolive','CL',
'Comcast','CMCSA',
'Comerica','CMA',
'ConAgra Foods','CAG',
'ConocoPhillips','COP',
'Consolidated Edison','ED',
'Constellation Brands A','STZ',
'Cooper','COO',
'Corning','GLW',
'Costco','COST',
'Coty Inc','COTY',
'Crown Castle','CCI',
'CSX','CSX',
'Cummins','CMI',
'CVS Health Corp','CVS',
'Danaher','DHR',
'Darden Restaurants','DRI',
'DaVita','DVA',
'Deere&Company','DE',
'Delta Air Lines','DAL',
'Dentsply','XRAY',
'Devon Energy','DVN',
'Digital','DLR',
'Discover','DFS',
//'Discovery A','DISCA',
//'Discovery Communications C','DISCK',
'DISH Network','DISH',
'Dollar General','DG',
'Dollar Tree','DLTR',
'Dominion Energy','D',
'Dover','DOV',
'DR Horton','DHI',
'DTE Energy','DTE',
'Duke Energy','DUK',
'DuPont De Nemours','DD',
'DXC Technology','DXC',
'Eastman Chemical','EMN',
'Eaton','ETN',
'eBay','EBAY',
'Ecolab','ECL',
'Edison','EIX',
'Edwards Lifesciences','EW',
'Electronic Arts','EA',
'Eli Lilly','LLY',
'Emerson','EMR',
'Entergy','ETR',
'EOG Resources','EOG',
'EQT','EQT',
'Equifax','EFX',
'Equinix','EQIX',
'Equity Residential','EQR',
'Essex Property','ESS',
'Estee Lauder','EL',
'Everest','RE',
'Eversource Energy','ES',
'Exelon','EXC',
'Expedia','EXPE',
'Expeditors Washington','EXPD',
'Extra Space Storage','EXR',
'Exxon Mobil','XOM',
'F5 Networks','FFIV',
'Meta Platforms','META',//'Facebook','FB',
'Fastenal','FAST',
'Federal Realty','FRT',
'FedEx','FDX',
'Fidelity National Info','FIS',
'Fifth Third','FITB',
'FirstEnergy','FE',
'Fiserv','FISV',
//'FLIR Systems','FLIR',
'Flowserve','FLS',
'Fluor','FLR',
'FMC','FMC',
'Foot Locker','FL',
'Ford Motor','F',
'Fortive','FTV',
'Fortune Brands','FBHS',
'Franklin Resources','BEN',
'Freeport-McMoran','FCX',
'Gap','GPS',
'Garmin','GRMN',
'Gartner','IT',
'General Dynamics','GD',
'General Electric','GE',
'General Mills','GIS',
'General Motors','GM',
'Genuine Parts','GPC',
'Gilead','GILD',
'Global Payments','GPN',
'Globe Life','GL',
'Goldman Sachs','GS',
'Goodyear Tire & Rubber Co','GT',
'H&R Block','HRB',
'Halliburton','HAL',
'Hanesbrands','HBI',
'Harley-Davidson','HOG',
'Hartford','HIG',
'Hasbro','HAS',
'HCA','HCA',
'Healthpeak Properties','PEAK',
'Helmerich Payne','HP',
'Henry Schein','HSIC',
'Hershey Co','HSY',
'Hess','HES',
'Hewlett Packard','HPE',
'Hilton Worldwide','HLT',
'Hologic','HOLX',
'Home Depot','HD',
'Honeywell','HON',
'Hormel Foods','HRL',
'Host Hotels Resorts','HST',
'HP Inc','HPQ',
'Humana','HUM',
'Huntington Bancshares','HBAN',
'IBM','IBM',
'ICE','ICE',
'IDEXX Labs','IDXX',
'IFF','IFF',
//'IHS Markit Ltd','INFO',
'Illinois Tool Works','ITW',
'Illumina','ILMN',
'Incyte','INCY',
'Ingersoll Rand','IR',
'Intel','INTC',
'International Paper','IP',
'Intuit','INTU',
'Intuitive Surgical','ISRG',
'Invesco','IVZ',
'IPG','IPG',
'IPG Photonics','IPGP',
'Iron Mountain','IRM',
'J&J','JNJ',
'Jacobs Engineering','J',
'JB Hunt','JBHT',
'Jefferies Financial','JEF',
'JM Smucker','SJM',
'Johnson Controls','JCI',
'JPMorgan','JPM',
'Juniper','JNPR',
//'Kansas City Southern','KSU',
'Kellogg','K',
'Keurig Dr Pepper','KDP',
'KeyCorp','KEY',
'Kimberly-Clark','KMB',
'Kimco','KIM',
'Kinder Morgan','KMI',
'KKR & Co','KKR',
'KLA-Tencor','KLAC',
'Kohls Corp','KSS',
'Kraft Heinz','KHC',
'Kroger','KR',
//'L Brands','LB',
'L3Harris Technologies','LHX',
'Laboratory America','LH',
'Lam Research','LRCX',
'Leggett&Platt','LEG',
'Lennar','LEN',
'Lincoln National','LNC',
'LKQ','LKQ',
'Lockheed Martin','LMT',
'Loews','L',
'Lowe´s','LOW',
'Lumen Technologies','LUMN',
'LyondellBasell Industries','LYB',
'M&T Bank','MTB',
'Macerich','MAC',
'Macy´s Inc','M',
'Marathon Oil','MRO',
'Marathon Petroleum','MPC',
'Marriott Int','MAR',
'Marsh McLennan','MMC',
'Martin Marietta Materials','MLM',
'Masco','MAS',
'Mastercard','MA',
'Mattel','MAT',
'McCormick&Co','MKC',
'McDonald´s','MCD',
'McKesson','MCK',
'Medtronic','MDT',
'Merck&Co','MRK',
'MetLife','MET',
'Mettler-Toledo','MTD',
'MGM','MGM',
'Microchip','MCHP',
'Micron','MU',
'Microsoft','MSFT',
'Mid-America Apartment','MAA',
'Mohawk Industries','MHK',
'Molson Coors Brewing B','TAP',
'Mondelez','MDLZ',
'Monster Beverage','MNST',
'Moodys','MCO',
'Morgan Stanley','MS',
'Mosaic','MOS',
'Motorola','MSI',
'Nasdaq Inc','NDAQ',
'Navient','NAVI',
'NetApp','NTAP',
'Netflix','NFLX',
'Newell Brands','NWL',
'Newmont Goldcorp','NEM',
'News Corp','NWS',
'News Corp A','NWSA',
'NextEra Energy','NEE',
'Nielsen','NLSN',
'Nike','NKE',
'NiSource','NI',
'Nordstrom','JWN',
'Norfolk Southern','NSC',
'Northern Trust','NTRS',
'Northrop Grumman','NOC',
'NortonLifeLock Inc','NLOK',
'Norwegian Cruise Line','NCLH',
'Nov','NOV',
'NRG','NRG',
'Nucor','NUE',
'NVIDIA','NVDA',
'Occidental','OXY',
'Omnicom','OMC',
'ONEOK','OKE',
'Oracle','ORCL',
'O´Reilly Automotive','ORLY',
'PACCAR','PCAR',
'Pacific Gas&Electric','PCG',
'Packaging America','PKG',
'Parker-Hannifin','PH',
'Patterson','PDCO',
'Paychex','PAYX',
'PayPal Holdings Inc','PYPL',
'Pentair','PNR',
//'People´s United','PBCT',
'PepsiCo','PEP',
'PerkinElmer','PKI',
'Perrigo','PRGO',
'Pfizer','PFE',
'Philip Morris','PM',
'Phillips 66','PSX',
'Pinnacle West','PNW',
'Pioneer Natural','PXD',
'PNC Financial','PNC',
'PPG Industries','PPG',
'PPL','PPL',
'Principal Financial','PFG',
'Procter&Gamble','PG',
'Progressive','PGR',
'Prologis','PLD',
'Prudential Financial','PRU',
'Public Service Enterprise','PEG',
'Public Storage','PSA',
'PulteGroup','PHM',
'PVH','PVH',
'Qorvo Inc','QRVO',
'Qualcomm','QCOM',
'Quanta Services','PWR',
'Quest Diagnostics','DGX',
'Ralph Lauren A','RL',
'Range Resources','RRC',
'Raymond James Financial','RJF',
'Raytheon Technologies','RTX',
'Realty Income','O',
'Regency Centers','REG',
'Regeneron Pharma','REGN',
'Regions Financial','RF',
'Republic Services','RSG',
'ResMed','RMD',
'Robert Half','RHI',
'Rockwell Automation','ROK',
'Roper Technologies','ROP',
'Ross Stores','ROST',
'Royal Caribbean Cruises','RCL',
'S&P Global','SPGI',
'Salesforce.com','CRM',
'SBA Communications','SBAC',
'Schlumberger','SLB',
'Seagate','STX',
'Sealed Air','SEE',
'Sempra Energy','SRE',
'Sherwin-Williams','SHW',
'Signet Jewelers','SIG',
'Simon Property','SPG',
'Skyworks','SWKS',
'SL Green','SLG',
'Snap-On','SNA',
'Southern','SO',
'Southwest Airlines','LUV',
'Stanley Black Decker','SWK',
'Starbucks','SBUX',
'State Street','STT',
'Stericycle','SRCL',
'Stryker','SYK',
'Synchrony Financial','SYF',
'Synopsys','SNPS',
'Sysco','SYY',
'T Rowe','TROW',
'Tapestry','TPR',
'Target','TGT',
'TE Connectivity','TEL',
'TechnipFMC','FTI',
'Texas Instruments','TXN',
'Textron','TXT',
'The AES','AES',
'The Charles Schwab','SCHW',
'The Travelers','TRV',
'Thermo Fisher Scientific','TMO',
'TJX','TJX',
'Tractor Supply','TSCO',
'Transdigm','TDG',
'Travel + Leisure Co','TNL',
'TripAdvisor','TRIP',
'Truist Financial Corp','TFC',
'Tyson Foods','TSN',
'US. Bancorp','USB',
'Ulta Beauty','ULTA',
'Under Armour A','UAA',
'Under Armour C','UA',
'Union Pacific','UNP',
'United Airlines Holdings','UAL',
'United Dominion','UDR',
'United Parcel Service','UPS',
'United Rentals','URI',
'UnitedHealth','UNH',
'Universal Health Services','UHS',
'Unum','UNM',
'Valero Energy','VLO',
//'Varian','VAR',
'Ventas','VTR',
'VeriSign','VRSN',
'Verisk','VRSK',
'Verizon','VZ',
'Vertex','VRTX',
'VF','VFC',
//'ViacomCBS','VIAC',
'Viatris','VTRS',
'Visa A','V',
'Vornado','VNO',
'Vulcan Materials','VMC',
'Walgreens Boots','WBA',
'Walmart','WMT',
'Walt Disney','DIS',
'Waste Management','WM',
'Waters','WAT',
'WEC Energy','WEC',
'Wells Fargo&Co','WFC',
'Welltower','WELL',
'Western Digital','WDC',
'Western Union','WU',
'WestRock Co','WRK',
'Weyerhaeuser','WY',
'Whirlpool','WHR',
'Williams','WMB',
//'Willis Towers Watson','WLTW',
'WW Grainger','GWW',
'Wynn Resorts','WYNN',
'Xcel Energy','XEL',
'Xerox','XRX',
//'Xilinx','XLNX',
'Zscaler, Inc','ZS',
'Xylem','XYL',
'Yum! Brands','YUM',
'Zimmer Biomet','ZBH',
'Zions','ZION',
'Zoetis Inc','ZTS'],	
   



FTSE350_STOCKS:[1,
  1,
  1,
  1,
  '£',
  'FTSE 350','^FTLC', 
  '3I Group','III.L',
  '3I Infrastructure','3IN.L',
  '4Imprint','FOUR.L',
  '888 Holdings','888.L',
  'A.G Barr','BAG.L',
  'Aberforth Smaller','ASL.L',
  'Admiral Group','ADM.L',
  //'Aggreko','AGK.L',
  'AJ Bell','AJB.L',
  'Alliance Trust','ATST.L',
  //'Alternative Credit','ACI.L',
  'Anglo American','AAL.L',
  'Antofagasta','ANTO.L',
  'Apax Global Alpha','APAX.L',
  'Ascential','ASCL.L',
  'Ashmore','ASHM.L',
  'Ashtead Group','AHT.L',
  'Assoc British Foods','ABF.L',
  'Assura','AGR.L',
  'Aston Martin Lagonda','AML.L',
  'AstraZeneca','AZN.L',
  'Auto Trader Group','AUTO.L',
  'Avast','AVST.L',
  'Aveva','AVV.L',
  'AVI Global','AGT.L',
  'Aviva','AV.L',
  'B&M European Value','BME.L',
  'Babcock International','BAB.L',
  'BAE Systems','BA.L',
  'Baillie Gifford Japan','BGFD.L',
  'Bakkavor','BAKK.L',
  'Balfour Beatty','BBY.L',
  'Bank of Georgia Grp','BGEO.L',
  'Bankers','BNKR.L',
  'Barclays','BARC.L',
  'Barratt Developments','BDEV.L',
  'BBGI SICAV SA','BBGI.L',
  'Beazley','BEZ.L',
  'Bellway','BWY.L',
  'Berkeley','BKG.L',
  'BHP Group','BHP.L',
  'Big Yellow','BYG.L',
  'Blackrock Smaller','BRSC.L',
  'Blackrock World Mining','BRWM.L',
  'BMO Commercial','BCPT.L',
  'Bmo Global Smaller','BGSC.L',
  'Bodycote','BOY.L',
  'BP','BP.L',
  'Brewin Dolphin','BRW.L',
  'British American Tobacco','BATS.L',
  'British Land Company','BLND.L',
  'Britvic','BVIC.L',
  'BT Group','BT-A.L',
  'Bunzl','BNZL.L',
  'Burberry Group','BRBY.L',
  'Cairn','CRN.L',
  'Caledonia Invest','CLDN.L',
  'Capita','CPI.L',
  'Capital&Counties','CAPC.L',
  'Card Factory','CARD.L',
  'Carnival','CCL.L',
  'Centamin','CEY.L',
  'Centrica','CNA.L',
  'Cineworld','CINE.L',
  'City Of London IT','CTY.L',
  'Clarkson','CKN.L',
  'Close Brothers','CBG.L',
  'CLS','CLI.L',
  'Coats','COA.L',
  'Coca Cola HBC AG','CCH.L',
  'Compass','CPG.L',
  'Computacenter','CCC.L',
  'ContourGlobal','GLO.L',
  'ConvaTec Group','CTEC.L',
  'Countryside Properties','CSP.L',
  'Cranswick','CWK.L',
  'Crest Nicholson','CRST.L',
  'CRH','CRH.L',
  'Croda Intl','CRDA.L',
  'DCC','DCC.L',
  'Dechra Pharma','DPH.L',
  'Derwent','DLN.L',
  'Diageo','DGE.L',
  'Diploma','DPLM.L',
  'Direct Line Insurance','DLG.L',
  //'Dixons Carphone','DC.L',
  'Domino´s Pizza','DOM.L',
  'Drax Group','DRX.L',
  'DS Smith','SMDS.L',
  'Dunelm','DNLM.L',
  'EasyJet','EZJ.L',
  'Edinburgh Investment','EDIN.L',
  //'Electrocomponents','ECM.L',
  'Elementis','ELM.L',
  'Energean Oil Gas','ENOG.L',
  'Entain','ENT.L',
  //'Equiniti Group PLC','EQN.L',
  'Essentra','ESNT.L',
  'Euromoney','ERM.L',
  //'European Opportunities','JEO.L',
  'Evraz','EVR.L',
  'Experian','EXPN.L',
  'F&C Invest','FCIT.L',
  'FDM','FDM.L',
  'Ferguson','FERG.L',
  'Ferrexpo','FXPO.L',
  'Fidelity China','FCSS.L',
  'Fidelity European','FEV.L',
  'Fidelity Special','FSV.L',
  //'Finablr','FINF.L',
  'Finsbury Growth&Income','FGT.L',
  'FirstGroup','FGP.L',
  'Flutter Entertainment','FLTR.L',
  'Foresight Solar Fund','FSFL.L',
  'FRASERS','FRAS.L',
  'Fresnillo','FRES.L',
  'Future','FUTR.L',
  'Galliford Try','GFRD.L',
  'Games Workshop','GAW.L',
  'GCP Infrastructure','GCP.L',
  //'GCP Student Living PLC','DIGS.L',
  //'Genesis Emerg Mkts','GSS.L',
  'Genuit Group','GEN.L',
  'Genus','GNS.L',
  'GlaxoSmithKline','GSK.L',
  'Glencore','GLEN.L',
  'Go-Ahead','GOG.L',
  'Grafton','GFTU.L',
  'Grainger','GRI.L',
  //'Great Portland Estates','GPOR.L',
  'Greencoat','UKW.L',
  'Greencore','GNC.L',
  'Greggs','GRG.L',
  'Halma','HLMA.L',
  'Hammerson','HMSO.L',
  'Harbour Energy','HBR.L',
  'HarbourVest Global','HVPD.L',
  'Hargreaves Lansdown','HL.L',
  'Hays','HAS.L',
  'Henderson Smaller','HSL.L',
  'Herald','HRI.L',
  'HgCapital','HGT.L',
  'HICL Infrastructure','HICL.L',
  'Hikma Pharma','HIK.L',
  'Hill&Smith','HILS.L',
  'Hilton Food','HFG.L',
  'Hiscox','HSX.L',
  'Hochschild','HOC.L',
  'HomeServe','HSV.L',
  'Howden Joinery','HWDN.L',
  'HSBC','HSBA.L',
  'Hunting','HTG.L',
  'IAG','IAG.L',
  'Ibstock PLC','IBST.L',
  'ICG Enterprise','ICGT.L',
  'IG Group','IGG.L',
  'IMI PLC','IMI.L',
  'Imperial Brands','IMB.L',
  'Inchcape','INCH.L',
  'Informa','INF.L',
  'IntegraFin','IHP.L',
  'InterContinental','IHG.L',
  'Intermediate Capital','ICP.L',
  'Intertek','ITRK.L',
  'Intl Public Partnership','INPP.L',
  'Investec','INVP.L',
  'IP Group','IPO.L',
  'ITV','ITV.L',
  'IWG','IWG.L',
  'J D Wetherspoon','JDW.L',
  'J Sainsbury','SBRY.L',
  'James Fisher and Sons','FSJ.L',
  'JD Sports Fashion','JD.L',
  //'John Laing Group Plc','JLG.L',
  'John Wood','WG.L',
  'Johnson Matthey','JMAT.L',
  'JPMorgan American','JAM.L',
  'JPMorgan EM','JMG.L',
  'JPMorgan Indian','JII.L',
  'JPMorgan Japanese','JFJ.L',
  'Jupiter FM','JUP.L',
  'Kainos Group PLC','KNOS.L',
  'Kingfisher','KGF.L',
  'Lancashire','LRE.L',
  'Land Securities','LAND.L',
  'Law Debenture','LWDB.L',
  'Legal & General','LGEN.L',
  'Lloyds Banking','LLOY.L',
  'London Stock Exchange','LSEG.L',
  'Londonmetric Property','LMP.L',
  'M&G','MNG.L',
  'Man Group','EMG.L',
  'Marks & Spencer','MKS.L',
  'Marshalls','MSLH.L',
  'Marston´s','MARS.L',
  //'McCarthy & Stone PLC','MCS.L',
  'Mediclinic Int','MDC.L',
  'Meggitt','MGGT.L',
  'Melrose Industries','MRO.L',
  'Mercantile Investment','MRC.L',
  'Micro Focus','MCRO.L',
  'Mitchells Butlers','MAB.L',
  'Mondi','MNDI.L',
  'Moneysupermarket.Com','MONY.L',
  'Monks','MNKS.L',
  'Morgan Materials','MGAM.L',
  //Morrison Supermarkets','MRW.L',
  'Murray International','MYI.L',
  'National Express','NEX.L',
  'National Grid','NG.L',
  'NatWest Group','NWG.L',
  'NB Global Floating','NBMI.L',
  'Network International Holdings','NETW.L',
  'Newriver Retail','NRR.L',
  'Next','NXT.L',
  'NextEnergy Solar','NESF.L',
  'Ocado','OCDO.L',
  'OSB Group','OSB.L',
  'Oxford Instruments','OXIG.L',
  'Pagegroup','PAGE.L',
  'Pantheon','PIN.L',
  'Paragon Banking Group','PAG.L',
  'PayPoint','PAY.L',
  'Pearson','PSON.L',
  'Pennon','PNN.L',
  'Pershing Square','PSH.L',
  'Persimmon','PSN.L',
  'Personal Assets','PNL.L',
  'Petrofac','PFC.L',
  'Pets at Home Group PLC','PETS.L',
  'Phoenix','PHNX.L',
  'Playtech','PTEC.L',
  'Plus500','PLUS.L',
  'Polar Capital Tech','PCT.L',
  'Polymetal','POLY.L',
  'PPHE Hotel Group Ltd','PPH.L',
  'Primary','PHP.L',
  'Provident Financial','PFG.L',
  'Prudential','PRU.L',
  'PureTech Health PLC','PRTC.L',
  'PZ Cussons','PZC.L',
  'Qinetiq','QQ.L',
  'Quilter','QLT.L',
  'Rank Group','RNK.L',
  'Rathbone Brothers','RAT.L',
  'Reckitt Benckiser','RKT.L',
  'Redrow','RDW.L',
  'REL','RSE.L',
  'Relx','REL.L',
  'Renewables','TRIG.L',
  'Renishaw','RSW.L',
  'Rentokil','RTO.L',
  'Restaurant Group','RTN.L',
  'RHI Magnesita','RHIM.L',
  'Rightmove','RMV.L',
  'Rio Tinto PLC','RIO.L',
  'RIT Capital','RCP.L',
  'Rolls-Royce Holdings','RR.L',
  'Rotork','ROR.L',
  //'Royal Dutch Shell A','RDSA.L',
  //'Royal Dutch Shell B','RDSB.L',
  'Royal Mail','RMG.L',
  //'RSA Insurance','RSA.L',
  'Sabre Insurance','SBRE.L',
  'Safestore','SAFE.L',
  'Sage','SGE.L',
  'Sanne Group PLC','SNN.L',
  'Savills','SVS.L',
  'Schroder Asia Pacific','SDP.L',
  'Schroder Oriental','SOI.L',
  'Schroders','SDR.L',
  'Scottish Investment','SCIN.L',
  'Scottish Mortgage','SMT.L',
  'Segro','SGRO.L',
  'Senior','SNR.L',
  'Sequoia Economic Infrastr','SEQI.L',
  'Serco','SRP.L',
  'Severn Trent','SVT.L',
  'Shaftesbury','SHB.L',
  'SIG','SHI.L',
  //'Signature Aviation','SIG.L',
  'Sirius RE','SRE.L',
  'Smith & Nephew','SN.L',
  'Smiths Group','SMIN.L',
  'Smithson Invest','SSON.L',
  'Smurfit Kappa','SKG.L',
  'Softcat PLC','SCT.L',
  'Spectris','SXS.L',
  'Spirax-Sarco Engineering','SPX.L',
  'Spirent','SPT.L',
  'SSE','SSE.L',
  'SSP','SSPG.L',
  'St. James´s Place','STJ.L',
  //'St.Modwen','SMP.L',
  'Stagecoach','SGC.L',
  'Standard Chartered','STAN.L',
  //'Standard Life Aberdeen','SLA.L',
  'Syncona','SYNC.L',
  'Synthomer','SYNT.L',
  'Tate&Lyle','TATE.L',
  'Taylor Wimpey','TW.L',
  'TBC Bank Group','TBCG.L',
  'Telecom Plus','TEP.L',
  'Temple Bar','TMPL.L',
  'Templeton EM','TEM.L',
  'Tesco','TSCO.L',
  'TI Fluid','TIFS.L',
  'TP ICAP','TCAP.L',
  'TR Property','TRY.L',
  'Trainline','TRN.L',
  'Travis Perkins','TPK.L',
  'Tritax Big Box','BBOX.L',
  'Tui','TUI.L',
  'Tullow Oil','TLW.L',
  //'UDG Healthcare','UDG.L',
  'UK Commercial Property','UKCM.L',
  'Ultra Electronics','ULE.L',
  'Unilever','ULVR.L',
  'Unite','UTG.L',
  'United Utilities','UU.L',
  'Vesuvius','VSVS.L',
  'Victrex','VCT.L',
  'Vietnam Enterprise','VEIL.L',
  'Vinacapital Vietnam','VOF.L',
  'Virgin Money UK','VMUK.L',
  'Vistry Group','VTY.L',
  'Vivo Energy','VVO.L',
  'Vodafone Group PLC','VOD.L',
  'Watches Of Switzerland Group','WOSG.L',
  'Weir Group','WEIR.L',
  'WH Smith','SMWH.L',
  'Whitbread','WTB.L',
  'Witan','WTAN.L',
  'Wizz Air Holdings PLC','WIZZ.L',
  'Workspace','WKP.L',
  'Worldwide Healthcare','WWH.L',
  'WPP','WPP.L'],	

  FTSE250_STOCKS:[1,
    1,
    1,
    1,
    '£',
    'FTSE 250','^FTMC', 
    '3I Infrastructure','3IN.L',
    '4Imprint','FOUR.L',
    '888 Holdings','888.L',
    'A.G Barr','BAG.L',
    'Admiral Group','ADM.L',
    //'Aggreko','AGK.L',
    'AJ Bell','AJB.L',
    'Alliance Trust','ATST.L',
    //'Alternative Credit','ACI.L',
    'Apax Global Alpha','APAX.L',
    'Ascential','ASCL.L',
    'Ashmore','ASHM.L',
    'Assura','AGR.L',
    'Aston Martin Lagonda','AML.L',
    'Avast','AVST.L',
    'AVI Global','AGT.L',
    'Aviva','AV.L',
    'B&M European Value','BME.L',
    'Babcock International','BAB.L',
    'Baillie Gifford Japan','BGFD.L',
    'Bakkavor','BAKK.L',
    'Balfour Beatty','BBY.L',
    'Bank of Georgia Grp','BGEO.L',
    'Bankers','BNKR.L',
    'BBGI SICAV SA','BBGI.L',
    'Beazley','BEZ.L',
    'Bellway','BWY.L',
    'Big Yellow','BYG.L',
    'Blackrock Smaller','BRSC.L',
    'Blackrock World Mining','BRWM.L',
    'BMO Commercial','BCPT.L',
    'Bmo Global Smaller','BGSC.L',
    'Bodycote','BOY.L',
    'Brewin Dolphin','BRW.L',
    'Britvic','BVIC.L',
    'Cairn','CRN.L',
    'Caledonia Invest','CLDN.L',
    'Capita','CPI.L',
    'Capital&Counties','CAPC.L',
    'Card Factory','CARD.L',
    'Centamin','CEY.L',
    'Cineworld','CINE.L',
    'City Of London IT','CTY.L',
    'Clarkson','CKN.L',
    'Close Brothers','CBG.L',
    'CLS','CLI.L',
    'Coats','COA.L',
    'Computacenter','CCC.L',
    'ContourGlobal','GLO.L',
    'ConvaTec Group','CTEC.L',
    'Countryside Properties','CSP.L',
    'Cranswick','CWK.L',
    'Crest Nicholson','CRST.L',
    'Dechra Pharma','DPH.L',
    'Derwent','DLN.L',
    'Diploma','DPLM.L',
    'Direct Line Insurance','DLG.L',
    //'Dixons Carphone','DC.L',
    'Domino´s Pizza','DOM.L',
    'Dunelm','DNLM.L',
    'EasyJet','EZJ.L',
    'Edinburgh Investment','EDIN.L',
    //'Electrocomponents','ECM.L',
    'Elementis','ELM.L',
    'Energean Oil Gas','ENOG.L',
    'Entain','ENT.L',
    //'Equiniti Group PLC','EQN.L',
    'Essentra','ESNT.L',
    'Euromoney','ERM.L',
    //'European Opportunities','JEO.L',
    'F&C Invest','FCIT.L',
    'FDM','FDM.L',
    'Ferrexpo','FXPO.L',
    'Fidelity China','FCSS.L',
    'Fidelity European','FEV.L',
    'Fidelity Special','FSV.L',
    'Finsbury Growth&Income','FGT.L',
    'FirstGroup','FGP.L',
    'Foresight Solar Fund','FSFL.L',
    'FRASERS','FRAS.L',
    'Future','FUTR.L',
    'Galliford Try','GFRD.L',
    'Games Workshop','GAW.L',
    'GCP Infrastructure','GCP.L',
    //'GCP Student Living PLC','DIGS.L',
    //'Genesis Emerg Mkts','GSS.L',
    'Genuit Group','GEN.L',
    'Genus','GNS.L',
    'Go-Ahead','GOG.L',
    'Grafton','GFTU.L',
    'Grainger','GRI.L',
    //'Great Portland Estates','GPOR.L',
    'Greencoat','UKW.L',
    'Greencore','GNC.L',
    'Greggs','GRG.L',
    'Hammerson','HMSO.L',
    'Harbour Energy','HBR.L',
    'HarbourVest Global','HVPD.L',
    'Hays','HAS.L',
    'Henderson Smaller','HSL.L',
    'Herald','HRI.L',
    'HgCapital','HGT.L',
    'HICL Infrastructure','HICL.L',
    'Hill&Smith','HILS.L',
    'Hilton Food','HFG.L',
    'Hochschild','HOC.L',
    'HomeServe','HSV.L',
    'Howden Joinery','HWDN.L',
    'Hunting','HTG.L',
    'Ibstock PLC','IBST.L',
    'ICG Enterprise','ICGT.L',
    'IG Group','IGG.L',
    'IMI PLC','IMI.L',
    'Imperial Brands','IMB.L',
    'Inchcape','INCH.L',
    'IntegraFin','IHP.L',
    'Intermediate Capital','ICP.L',
    'Intl Public Partnership','INPP.L',
    'Investec','INVP.L',
    'IP Group','IPO.L',
    'IWG','IWG.L',
    'J D Wetherspoon','JDW.L',
    'James Fisher and Sons','FSJ.L',
    //'John Laing Group Plc','JLG.L',
    'John Wood','WG.L',
    'JPMorgan American','JAM.L',
    'JPMorgan EM','JMG.L',
    'JPMorgan Indian','JII.L',
    'JPMorgan Japanese','JFJ.L',
    'Jupiter FM','JUP.L',
    'Kainos Group PLC','KNOS.L',
    'Lancashire','LRE.L',
    'Land Securities','LAND.L',
    'Law Debenture','LWDB.L',
    'Londonmetric Property','LMP.L',
    'Man Group','EMG.L',
    'Marks & Spencer','MKS.L',
    'Marshalls','MSLH.L',
    'Marston´s','MARS.L',
    'Mediclinic Int','MDC.L',
    'Mercantile Investment','MRC.L',
    'Micro Focus','MCRO.L',
    'Mitchells Butlers','MAB.L',
    'Mondi','MNDI.L',
    'Moneysupermarket.Com','MONY.L',
    'Monks','MNKS.L',
    'Morgan Materials','MGAM.L',
    'Murray International','MYI.L',
    'National Express','NEX.L',
    'NB Global Floating','NBMI.L',
    'Network International Holdings','NETW.L',
    'Newriver Retail','NRR.L',
    'NextEnergy Solar','NESF.L',
    'OSB Group','OSB.L',
    'Oxford Instruments','OXIG.L',
    'Pagegroup','PAGE.L',
    'Pantheon','PIN.L',
    'Paragon Banking Group','PAG.L',
    'PayPoint','PAY.L',
    'Pennon','PNN.L',
    'Pershing Square','PSH.L',
    'Personal Assets','PNL.L',
    'Petrofac','PFC.L',
    'Pets at Home Group PLC','PETS.L',
    'Playtech','PTEC.L',
    'Plus500','PLUS.L',
    'Polar Capital Tech','PCT.L',
    'PPHE Hotel Group Ltd','PPH.L',
    'Primary','PHP.L',
    'Provident Financial','PFG.L',
    'PureTech Health PLC','PRTC.L',
    'PZ Cussons','PZC.L',
    'Qinetiq','QQ.L',
    'Quilter','QLT.L',
    'Rank Group','RNK.L',
    'Rathbone Brothers','RAT.L',
    'Redrow','RDW.L',
    'REL','RSE.L',
    'Renewables','TRIG.L',
    'Renishaw','RSW.L',
    'Restaurant Group','RTN.L',
    'RHI Magnesita','RHIM.L',
    'RIT Capital','RCP.L',
    'Rotork','ROR.L',
    'Royal Mail','RMG.L',
    'Sabre Insurance','SBRE.L',
    'Safestore','SAFE.L',
    'Sanne Group PLC','SNN.L',
    'Savills','SVS.L',
    'Schroder Asia Pacific','SDP.L',
    'Schroder Oriental','SOI.L',
    'Scottish Investment','SCIN.L',
    'Senior','SNR.L',
    'Sequoia Economic Infrastr','SEQI.L',
    'Serco','SRP.L',
    'Shaftesbury','SHB.L',
    'SIG','SHI.L',
    //'Signature Aviation','SIG.L',
    'Sirius RE','SRE.L',
    'Smithson Invest','SSON.L',
    'Softcat PLC','SCT.L',
    'Spectris','SXS.L',
    'Spirent','SPT.L',
    'SSE','SSE.L',
    'SSP','SSPG.L',
    //'St.Modwen','SMP.L',
    'Stagecoach','SGC.L',
    'Syncona','SYNC.L',
    'Synthomer','SYNT.L',
    'Tate&Lyle','TATE.L',
    'TBC Bank Group','TBCG.L',
    'Telecom Plus','TEP.L',
    'Temple Bar','TMPL.L',
    'Templeton EM','TEM.L',
    'TI Fluid','TIFS.L',
    'TP ICAP','TCAP.L',
    'TR Property','TRY.L',
    'Trainline','TRN.L',
    'Travis Perkins','TPK.L',
    'Tritax Big Box','BBOX.L',
    'Tullow Oil','TLW.L',
    //'UDG Healthcare','UDG.L',
    'UK Commercial Property','UKCM.L',
    'Ultra Electronics','ULE.L',
    'Unite','UTG.L',
    'Vesuvius','VSVS.L',
    'Victrex','VCT.L',
    'Vietnam Enterprise','VEIL.L',
    'Vinacapital Vietnam','VOF.L',
    'Virgin Money UK','VMUK.L',
    'Vistry Group','VTY.L',
    'Vivo Energy','VVO.L',
    'Watches Of Switzerland Group','WOSG.L',
    'Weir Group','WEIR.L',
    'WH Smith','SMWH.L',
    'Witan','WTAN.L',
    'Wizz Air Holdings PLC','WIZZ.L',
    'Workspace','WKP.L',
    'Worldwide Healthcare','WWH.L'],	

  FTSE100_STOCKS:[1,
    1,
    1,
    1,
    '£',
    'FTSE 100','^FTSE',
  '3I Group','III.L',		 
 	'Admiral Group','ADM.L',		 
 	'Anglo American','AAL.L',	 
 	'Antofagasta','ANTO.L',		 
 	'Ashtead Group','AHT.L',		 
 	'Associated British Foods','ABF.L',	 
 	'AstraZeneca','AZN.L',		 
 	'Auto Trader Group Plc','AUTO.L',		 
 	'Avast','AVST.L',	
 	'Aveva','AVV.L',	
 	'Aviva','AV.L',	
 	'B&M European Value Retail SA','BME.L',		 
 	'BAE Systems','BA.L',	
 	'Bank VTB DRC','VTBR.IL',	
 	'Barclays','BARC.L',	
 	'Barratt Developments','BDEV.L',
 	'Berkeley','BKG.L',	
 	'BHP Group','BHP.L',		 
 	'BP','BP.L',		 
 	'British American Tobacco','BATS.L',		 
 	'British Land Company','BLND.L',		 
 	'BT Group','BT-A.L',	 
 	'Bunzl','BNZL.L',	
 	'Burberry Group','BRBY.L',	
 	'Carnival','CCL.L',	
 	'Centrica','CNA.L',	 
 	'Coca Cola HBC AG','CCH.L',	 
 	'Compass','CPG.L',	
 	'CRH','CRH.L',	 
 	'Croda Intl','CRDA.L',	
 	'DCC','DCC.L',	
 	'Diageo','DGE.L',	
 	'DS Smith','SMDS.L',	
 	'EasyJet','EZJ.L',	 
 	'Experian','EXPN.L',	
 	'Ferguson','FERG.L',	
 	'Flutter Entertainment','FLTR.L',		 
 	'Fresnillo','FRES.L',	 
 	'GlaxoSmithKline','GSK.L', 
 	'Glencore','GLEN.L',		 
 	'Halma','HLMA.L',		 
 	'Hargreaves Lansdown','HL.L',		 
 	'Hikma Pharma','HIK.L',		 
 	'HSBC','HSBA.L',	 
 	'IAG','IAG.L',	 
 	'Imperial Brands','IMB.L',		 
 	'Informa','INF.L',	
 	'InterContinental','IHG.L',	 
 	'Intermediate Capital','ICP.L',		 
 	'Intertek','ITRK.L',		 
 	'ITV','ITV.L',	 
 	'J Sainsbury','SBRY.L',		 
 	'Johnson Matthey','JMAT.L',		 
 	'Land Securities','LAND.L',	 
 	'Legal & General','LGEN.L',	 
 	'Lloyds Banking','LLOY.L',		 
 	'London Stock Exchange','LSEG.L',		 
 	'Meggitt','MGGT.L',	 
 	'Melrose Industries','MRO.L',		 
 	'Mondi','MNDI.L',		 
 	'National Grid','NG.L',		 
 	'NatWest Group','NWG.L',	 
 	'Next','NXT.L',	
 	'Norilskiy Nikel ADR','NILSY',	 
 	'Ocado','OCDO.L',		 
 	'Persimmon','PSN.L',		 
 	'Phoenix','PHNX.L',	
 	'Prudential','PRU.L',		 
 	'Reckitt Benckiser','RKT.L',	 
 	'Relx','REL.L',		 
 	'Rentokil','RTO.L',		 
 	'Rightmove','RMV.L',	 
 	'Rio Tinto PLC','RIO.L',		 
 	'Rolls-Royce Holdings','RR.L',		 
 	'Rosneft DRC','ROSN.IL',	
 	//'Royal Dutch Shell A','RDSA.L',		 		 
 	//'Royal Dutch Shell B','RDSB.L',		 
 	//'RSA Insurance','RSA.L',	 
 	'The Sage Group','SGE.L',		 
 	'Samsung Electronics DRC','BC94.L',		 
 	'Sberbank','SBER.IL',		 
 	'Schroders','SDR.L',		 
 	'Scottish Mortgage','SMT.L',		 
 	'Segro','SGRO.L',	
 	'Severn Trent','SVT.L',	 
 	'Smith & Nephew','SN.L',		 
 	'Smiths Group','SMIN.L', 
 	'Spirax-Sarco Engineering','SPX.L',	 
 	'SSE','SSE.L',	 
 	'St. James^s Place','STJ.L',	
 	'Standard Chartered','STAN.L',		 
 	//'Standard Life Aberdeen','SLA.L',	 
 	'Taylor Wimpey','TW.L',		 
 	'Tesco','TSCO.L',		 
 	'Tui','TUI.L',		 
 	'Unilever','ULVR.L',	 
 	'United Utilities','UU.L',		 
 	'Vodafone Group PLC','VOD.L',		 
 	'Whitbread','WTB.L',		 
 	'WPP','WPP.L'],		

   HANGSENG_STOCKS:[1,
    1,
    1,
    1,
    'HK$',
   'HANG SENG','^HSI',
   'AAC Technologies','2018.HK',
'Aia Group','1299.HK',
'Alibaba','9988.HK',
'Alibaba Health Inf Tech','0241.HK',
'ANTA Sports Products','2020.HK',
'Bank of China H','3988.HK',
'Bank of Communications','3328.HK',
'BOC HK','2388.HK',
'Budweiser','1876.HK',
'China Construction Bank','0939.HK',
'China Life Insurance','2628.HK',
'China Mengniu Dairy Co.','2319.HK',
'China Mobile','0941.HK',
'China Overseas','0688.HK',
'China Petrol Chemical H','0386.HK',
'China Res. Land','1109.HK',
'China Unicom Hk','0762.HK',
'CITIC Pacific','0267.HK',
'CK Asset','1113.HK',
'CK Hutchison','0001.HK',
'CK Infrastructure','1038.HK',
'CLP','0002.HK',
'CNOOC','0883.HK',
'Country Garden Holdings','2007.HK',
'CSPC Pharma','1093.HK',
'Galaxy Entertainment Group','0027.HK',
'Geely Automobile','0175.HK',
'Haidilao Intl','6862.HK',
'Hang Lung Prop','0101.HK',
'Hang Seng Bank','0011.HK',
'Henderson Land','0012.HK',
'Hengan','1044.HK',
'HK & China Gas','0003.HK',
'HKEx','0388.HK',
'HSBC','0005.HK',
'ICBC','1398.HK',
'Link Real Estate','0823.HK',
'Longfor Properties','0960.HK',
'Meituan','3690.HK',
'MTR','0066.HK',
//'New World','OO17.HK',
'PetroChina H','0857.HK',
'Ping An','2318.HK',
'Power Assets','0006.HK',
'Sands China','1928.HK',
'Shenzhou Int','2313.HK',
'SHK Prop','0016.HK',
'Sino Biopharmaceutical','1177.HK',
'Sunny Optical Tech','2382.HK',
'Techtronic Industries','0669.HK',
'Tencent Holdings','0700.HK',
'WH Group Ltd','0288.HK',
'Wharf Real Estate','1997.HK',
'WuXi Biologics','2269.HK',
'Xiaomi','1810.HK'],	



BOVESPA_STOCKS:[1,
  1,
  1,
  1,
  'R$',
  'BOVESPA','^BVSP',
'Alpargatas','ALPA4.SA',
'AMBEV SA','ABEV3.SA',
'Americanas SA','AMER3.SA',
'Atacadao','CRFB3.SA',
'Azul SA Pref','AZUL4.SA',
//'B2W DIGITAL ON','BTOW3.SA',
'B3 SA Brasil Bolsa Balcao','B3SA3.SA',
'Banco BTG','BPAC11.SA',
'Banco Inter Unit','BIDI4.SA',
'BBSEGURIDADE ON NM','BBSE3.SA',
'BR MALLS PAR ON','BRML3.SA',
'BRADESCO ON','BBDC3.SA',
'BRADESCO PN EJ N1Â ','BBDC4.SA',
'BRADESPAR PN','BRAP4.SA',
'BRASIL ON','BBAS3.SA',
'Brasileira De Dist','PCAR3.SA',
'BRASKEM PNA','BRKM3.SA',
'BRF SA ON','BRFS3.SA',
'CCR SA ON','CCRO3.SA',
'CEMIG PN Pref','CMIG4.SA',
//'CIA HERING ON','HGTX3.SA',
'CIELO ON','CIEL3.SA',
'Cogna Educacao','COGN3.SA',
'COPEL Pref B','CPLE6.SA',
'COSAN ON','CSAN3.SA',
'CPFL ENERGIAON NM','CPFE3.SA',
'CVC BRASIL ON NM','CVCB3.SA',
'CYRELA REALT ON','CYRE3.SA',
'ECORODOVIAS ON','ECOR3.SA',
'ELETROBRAS ON','ELET3.SA',
'ELETROBRAS PNB','ELET6.SA',
'EMBRAER ON','EMBR3.SA',
'ENERGIAS BR ON','ENBR3.SA',
'Energisa','ENGI11.SA',
'ENEVA ON NM','ENEV3.SA',
'ENGIE BRASILON NM','EGIE3.SA',
'EQUATORIAL ON','EQTL3.SA',
'EZTEC ON','EZTC3.SA',
'FLEURY ON','FLRY3.SA',
'GERDAU MET PN','GOAU4.SA',
'GERDAU PN N1','GGBR4.SA',
'GOL PN N2','GOLL4.SA',
'Hapvida','HAPV3.SA',
'HYPERMARCAS ON','HYPE3.SA',
//'IGUATEMI ON','IGTA3.SA',
'IRB Brasil Resseguros','IRBR3.SA',
'ITAUSA PN','ITSA4.SA',
'ITAUUNIBANCOPN EB N1','ITUB4.SA',
'JBS ON','JBSS3.SA',
'JHSF PART ON','JHSF3.SA',
'KLABIN S/A UNT N2','KLBN11.SA',
'LOCALIZA ON','RENT3.SA',
'LOCAMERICA ON NM','LCAM3.SA',
'Locaweb On','LWSA3.SA',
//'LOJAS AMERIC PN','LAME4.SA',
'LOJAS RENNER ON','LREN3.SA',
'MAGAZ LUIZA ON','MGLU3.SA',
'MARFRIG ON','MRFG3.SA',
'MINERVA ON','BEEF3.SA',
'MRV ON','MRVE3.SA',
'MULTIPLAN ON','MULT3.SA',
'NATURA ON','NTCO3.SA',
//'Notre Dame','GNDI3.SA',
//'Petrobras Distribuidora','BRDT3.SA',
'PETROBRAS ON','PETR3.SA',
'PETROBRAS PN','PETR4.SA',
'PETRORIO ON NM','PRIO3.SA',
'QUALICORP ON','QUAL3.SA',
'RAIADROGASIL ON','RADL3.SA',
'RUMO ON NM','RAIL3.SA',
'SABESP ON','SBSP3.SA',
'SANTANDER BR UNT','SANB11.SA',
'Sendas Distribuidora','ASAI3.SA',
'SID NACIONAL ON','CSNA3.SA',
'SUL AMERICA UNT','SULA11.SA',
'Suzano Papel Celulose','SUZB3.SA',
'TAEE UNIT','TAEE11.SA',
'TELEF BRASIL ON','VIVT3.SA',
'TIM PART S/A ON','TIMS3.SA',
'TOTVS ON','TOTS3.SA',
'ULTRAPAR ON NM','UGPA3.SA',
'USIMINAS PNA','USIM5.SA',
'VALE ON','VALE3.SA',
'Via Varejo','VVAR3.SA',
'WEG ON','WEGE3.SA',
'YDUQS Participacoes','YDUQ3.SA'],
  

CHILEIPSA_STOCKS:[1,
  1,
  1,
  1,
  '$',
  'CHILE IPSA','SP-IPSA.SN', 
  //'Aesgener','AESGENER.SN',
  'Aguas Andinas','AGUAS-A.SN',
  'Banco De Chile (SN)','CHILE.SN',
  'Banco de Credito e Inversiones','BCI.SN',
  'Cap','CAP.SN',
  'Cencosud','CENCOSUD.SN',
   'Cencosud Shopping','CENCOSHOPP.SN',
  'Cervecerias','CCU.SN',
'Colbun','COLBUN.SN',
'Embotelladora Andina B','ANDINA-B.SN',
'Empresas CMPC','CMPC.SN',
'Empresas Copec','COPEC.SN',
'ENEL Americas','ENELAM.SN',
'Enel Chile','ENELCHILE.SN',
'Engie Energia Chile','ECL.SN',
'Entel','ENTEL.SN',
'Falabella','FALABELLA.SN',
'Grupo Security','SECURITY.SN',
'Iam Sa','IAM.SN',
'Inv La Constru','ILC.SN',
'Itau CorpBanca','ITAUCORP.SN',
'Parq Arauco','PARAUCO.SN',
'Plaza','MALLPLAZA.SN',
'Ripley Corp','RIPLEY.SN',
'Santander Chile','BSANTANDER.SN',
'SMU','SMU.SN',
'Sonda','SONDA.SN',
'Soquimich B','SOQUICOM.SN',
'Vapores','VAPORES.SN',
'Vina Concha To','CONCHATORO.SN' ],

MERVAL_STOCKS:[1,
  1,
  1,
  1,
  '$',
  'MERVAL','M.BA',
  'Aluar','ALUA.BA',
  'Banco Macro B','BMA.BA',
  'BBVA Banco Frances','BBAR.BA',
  'Bolsas y Mercados Arg','BYMA.BA',
  'Cablevision','CVH.BA',
  'Central Puerto','CEPU.BA',
  'Comercial del Plata','COME.BA',
  'Cresud SA','CRES.BA',
  'EDENOR','EDN.BA',
  'Grupo Financ Galicia','GGAL.BA',
  'Grupo Financ Valores','VALO.BA',
  'Grupo Supervielle','SUPV.BA',
  'Holcim Argentina','HARG.BA',
  'Loma Negra','LOMA.BA',
  'Mirgor','MIRG.BA',
  'Pampa Energia','PAMP.BA',
  'Telecom Argentina','TECO2.BA',
  'Ternium Argentina','TXAR.BA',
  'Tran Gas Del Sur','TGSU2.BA',
  'TRANSENER','TRAN.BA',
  'Transportadora Gas Norte C','TGNO4.BA',
  'YPF','YPFD.BA'],

  IPCMEXICO_STOCKS:[1,
    1,
    1,
    1,
    '$',
    'IPC MEXICO','^MXX',
    'Alfa','ALFAA.MX',
'Alsea','ALSEA.MX',
'America Movil','AMXL.MX',
'Arca Continental','AC.MX',
'Asur B','ASURB.MX',
'Banco Del Bajio','BBAJIOO.MX',
'Banregio','RA.MX',
'Becle','CUERVO.MX',
'Bimbo','BIMBOA.MX',
'Bolsa Mexico Valores','BOLSAA.MX',
'Cemex','CEMEXCPO.MX',
'Coca-Cola Femsa','KOFUBL.MX',
'Corp Inmob Vesta SAB','VESTA.MX',
'El Puerto Liverpool','LIVEPOLC-1.MX',
'Fomento Econom UBD','FEMSAUBD.MX',
'GAP B','GAPB.MX',
'Genomma Lab Int','LABB.MX',
'Gruma SAB de CV','GRUMAB.MX',
'Grupo Carso','GCARSOA1.MX',
'Grupo Cementos Chihuahua','GCC.MX',
'Grupo Elektra','ELEKTRA.MX',
'Grupo Financ Banorte','GFNORTEO.MX',
'Grupo Financ Inbursa','GFINBURO.MX',
'Grupo Mexico','GMEXICOB.MX',
'Grupo Televisa Unit','TLEVISACPO.MX',
'IENOVA','IENOVA.MX',
//'Industrias Penoles','PE&OLES.MX',
'Kimberly - Clark A','KIMBERA.MX',
'Megacable Holdings','MEGACPO.MX',
'OMA B','OMAB.MX',
'Orbia Advance','ORBIA.MX',
'PINFRA','PINFRA.MX',
'Qualitas Controladora','Q.MX',
'Telesites SAB de CV','SITESB-1.MX',
'Wal Mart Mexico','WALMEX.MX'],

/*
LANGUAGE_BY_LOCALE = {
  af_NA: "Afrikaans (Namibia)",
  af_ZA: "Afrikaans (South Africa)",
  af: "Afrikaans",
  ak_GH: "Akan (Ghana)",
  ak: "Akan",
  sq_AL: "Albanian (Albania)",
  sq: "Albanian",
  am_ET: "Amharic (Ethiopia)",
  am: "Amharic",
  ar_DZ: "Arabic (Algeria)",
  ar_BH: "Arabic (Bahrain)",
  ar_EG: "Arabic (Egypt)",
  ar_IQ: "Arabic (Iraq)",
  ar_JO: "Arabic (Jordan)",
  ar_KW: "Arabic (Kuwait)",
  ar_LB: "Arabic (Lebanon)",
  ar_LY: "Arabic (Libya)",
  ar_MA: "Arabic (Morocco)",
  ar_OM: "Arabic (Oman)",
  ar_QA: "Arabic (Qatar)",
  ar_SA: "Arabic (Saudi Arabia)",
  ar_SD: "Arabic (Sudan)",
  ar_SY: "Arabic (Syria)",
  ar_TN: "Arabic (Tunisia)",
  ar_AE: "Arabic (United Arab Emirates)",
  ar_YE: "Arabic (Yemen)",
  ar: "Arabic",
  hy_AM: "Armenian (Armenia)",
  hy: "Armenian",
  as_IN: "Assamese (India)",
  as: "Assamese",
  asa_TZ: "Asu (Tanzania)",
  asa: "Asu",
  az_Cyrl: "Azerbaijani (Cyrillic)",
  az_Cyrl_AZ: "Azerbaijani (Cyrillic, Azerbaijan)",
  az_Latn: "Azerbaijani (Latin)",
  az_Latn_AZ: "Azerbaijani (Latin, Azerbaijan)",
  az: "Azerbaijani",
  bm_ML: "Bambara (Mali)",
  bm: "Bambara",
  eu_ES: "Basque (Spain)",
  eu: "Basque",
  be_BY: "Belarusian (Belarus)",
  be: "Belarusian",
  bem_ZM: "Bemba (Zambia)",
  bem: "Bemba",
  bez_TZ: "Bena (Tanzania)",
  bez: "Bena",
  bn_BD: "Bengali (Bangladesh)",
  bn_IN: "Bengali (India)",
  bn: "Bengali",
  bs_BA: "Bosnian (Bosnia and Herzegovina)",
  bs: "Bosnian",
  bg_BG: "Bulgarian (Bulgaria)",
  bg: "Bulgarian",
  my_MM: "Burmese (Myanmar [Burma])",
  my: "Burmese",
  yue_Hant_HK: "Cantonese (Traditional, Hong Kong SAR China)",
  ca_ES: "Catalan (Spain)",
  ca: "Catalan",
  tzm_Latn: "Central Morocco Tamazight (Latin)",
  tzm_Latn_MA: "Central Morocco Tamazight (Latin, Morocco)",
  tzm: "Central Morocco Tamazight",
  chr_US: "Cherokee (United States)",
  chr: "Cherokee",
  cgg_UG: "Chiga (Uganda)",
  cgg: "Chiga",
  zh_Hans: "Chinese (Simplified Han)",
  zh_Hans_CN: "Chinese (Simplified Han, China)",
  zh_Hans_HK: "Chinese (Simplified Han, Hong Kong SAR China)",
  zh_Hans_MO: "Chinese (Simplified Han, Macau SAR China)",
  zh_Hans_SG: "Chinese (Simplified Han, Singapore)",
  zh_Hant: "Chinese (Traditional Han)",
  zh_Hant_HK: "Chinese (Traditional Han, Hong Kong SAR China)",
  zh_Hant_MO: "Chinese (Traditional Han, Macau SAR China)",
  zh_Hant_TW: "Chinese (Traditional Han, Taiwan)",
  zh: "Chinese",
  kw_GB: "Cornish (United Kingdom)",
  kw: "Cornish",
  hr_HR: "Croatian (Croatia)",
  hr: "Croatian",
  cs_CZ: "Czech (Czech Republic)",
  cs: "Czech",
  da_DK: "Danish (Denmark)",
  da: "Danish",
  nl_BE: "Dutch (Belgium)",
  nl_NL: "Dutch (Netherlands)",
  nl: "Dutch",
  ebu_KE: "Embu (Kenya)",
  ebu: "Embu",
  en_AS: "English (American Samoa)",
  en_AU: "English (Australia)",
  en_BE: "English (Belgium)",
  en_BZ: "English (Belize)",
  en_BW: "English (Botswana)",
  en_CA: "English (Canada)",
  en_GU: "English (Guam)",
  en_HK: "English (Hong Kong SAR China)",
  en_IN: "English (India)",
  en_IE: "English (Ireland)",
  en_IL: "English (Israel)",
  en_JM: "English (Jamaica)",
  en_MT: "English (Malta)",
  en_MH: "English (Marshall Islands)",
  en_MU: "English (Mauritius)",
  en_NA: "English (Namibia)",
  en_NZ: "English (New Zealand)",
  en_MP: "English (Northern Mariana Islands)",
  en_PK: "English (Pakistan)",
  en_PH: "English (Philippines)",
  en_SG: "English (Singapore)",
  en_ZA: "English (South Africa)",
  en_TT: "English (Trinidad and Tobago)",
  en_UM: "English (U.S. Minor Outlying Islands)",
  en_VI: "English (U.S. Virgin Islands)",
  en_GB: "English (United Kingdom)",
  en_US: "English (United States)",
  en_ZW: "English (Zimbabwe)",
  en: "English",
  eo: "Esperanto",
  et_EE: "Estonian (Estonia)",
  et: "Estonian",
  ee_GH: "Ewe (Ghana)",
  ee_TG: "Ewe (Togo)",
  ee: "Ewe",
  fo_FO: "Faroese (Faroe Islands)",
  fo: "Faroese",
  fil_PH: "Filipino (Philippines)",
  fil: "Filipino",
  fi_FI: "Finnish (Finland)",
  fi: "Finnish",
  fr_BE: "French (Belgium)",
  fr_BJ: "French (Benin)",
  fr_BF: "French (Burkina Faso)",
  fr_BI: "French (Burundi)",
  fr_CM: "French (Cameroon)",
  fr_CA: "French (Canada)",
  fr_CF: "French (Central African Republic)",
  fr_TD: "French (Chad)",
  fr_KM: "French (Comoros)",
  fr_CG: "French (Congo - Brazzaville)",
  fr_CD: "French (Congo - Kinshasa)",
  fr_CI: "French (Côte d’Ivoire)",
  fr_DJ: "French (Djibouti)",
  fr_GQ: "French (Equatorial Guinea)",
  fr_FR: "French (France)",
  fr_GA: "French (Gabon)",
  fr_GP: "French (Guadeloupe)",
  fr_GN: "French (Guinea)",
  fr_LU: "French (Luxembourg)",
  fr_MG: "French (Madagascar)",
  fr_ML: "French (Mali)",
  fr_MQ: "French (Martinique)",
  fr_MC: "French (Monaco)",
  fr_NE: "French (Niger)",
  fr_RW: "French (Rwanda)",
  fr_RE: "French (Réunion)",
  fr_BL: "French (Saint Barthélemy)",
  fr_MF: "French (Saint Martin)",
  fr_SN: "French (Senegal)",
  fr_CH: "French (Switzerland)",
  fr_TG: "French (Togo)",
  fr: "French",
  ff_SN: "Fulah (Senegal)",
  ff: "Fulah",
  gl_ES: "Galician (Spain)",
  gl: "Galician",
  lg_UG: "Ganda (Uganda)",
  lg: "Ganda",
  ka_GE: "Georgian (Georgia)",
  ka: "Georgian",
  de_AT: "German (Austria)",
  de_BE: "German (Belgium)",
  de_DE: "German (Germany)",
  de_LI: "German (Liechtenstein)",
  de_LU: "German (Luxembourg)",
  de_CH: "German (Switzerland)",
  de: "German",
  el_CY: "Greek (Cyprus)",
  el_GR: "Greek (Greece)",
  el: "Greek",
  gu_IN: "Gujarati (India)",
  gu: "Gujarati",
  guz_KE: "Gusii (Kenya)",
  guz: "Gusii",
  ha_Latn: "Hausa (Latin)",
  ha_Latn_GH: "Hausa (Latin, Ghana)",
  ha_Latn_NE: "Hausa (Latin, Niger)",
  ha_Latn_NG: "Hausa (Latin, Nigeria)",
  ha: "Hausa",
  haw_US: "Hawaiian (United States)",
  haw: "Hawaiian",
  he_IL: "Hebrew (Israel)",
  he: "Hebrew",
  hi_IN: "Hindi (India)",
  hi: "Hindi",
  hu_HU: "Hungarian (Hungary)",
  hu: "Hungarian",
  is_IS: "Icelandic (Iceland)",
  is: "Icelandic",
  ig_NG: "Igbo (Nigeria)",
  ig: "Igbo",
  id_ID: "Indonesian (Indonesia)",
  id: "Indonesian",
  ga_IE: "Irish (Ireland)",
  ga: "Irish",
  it_IT: "Italian (Italy)",
  it_CH: "Italian (Switzerland)",
  it: "Italian",
  ja_JP: "Japanese (Japan)",
  ja: "Japanese",
  kea_CV: "Kabuverdianu (Cape Verde)",
  kea: "Kabuverdianu",
  kab_DZ: "Kabyle (Algeria)",
  kab: "Kabyle",
  kl_GL: "Kalaallisut (Greenland)",
  kl: "Kalaallisut",
  kln_KE: "Kalenjin (Kenya)",
  kln: "Kalenjin",
  kam_KE: "Kamba (Kenya)",
  kam: "Kamba",
  kn_IN: "Kannada (India)",
  kn: "Kannada",
  kk_Cyrl: "Kazakh (Cyrillic)",
  kk_Cyrl_KZ: "Kazakh (Cyrillic, Kazakhstan)",
  kk: "Kazakh",
  km_KH: "Khmer (Cambodia)",
  km: "Khmer",
  ki_KE: "Kikuyu (Kenya)",
  ki: "Kikuyu",
  rw_RW: "Kinyarwanda (Rwanda)",
  rw: "Kinyarwanda",
  kok_IN: "Konkani (India)",
  kok: "Konkani",
  ko_KR: "Korean (South Korea)",
  ko: "Korean",
  khq_ML: "Koyra Chiini (Mali)",
  khq: "Koyra Chiini",
  ses_ML: "Koyraboro Senni (Mali)",
  ses: "Koyraboro Senni",
  lag_TZ: "Langi (Tanzania)",
  lag: "Langi",
  lv_LV: "Latvian (Latvia)",
  lv: "Latvian",
  lt_LT: "Lithuanian (Lithuania)",
  lt: "Lithuanian",
  luo_KE: "Luo (Kenya)",
  luo: "Luo",
  luy_KE: "Luyia (Kenya)",
  luy: "Luyia",
  mk_MK: "Macedonian (Macedonia)",
  mk: "Macedonian",
  jmc_TZ: "Machame (Tanzania)",
  jmc: "Machame",
  kde_TZ: "Makonde (Tanzania)",
  kde: "Makonde",
  mg_MG: "Malagasy (Madagascar)",
  mg: "Malagasy",
  ms_BN: "Malay (Brunei)",
  ms_MY: "Malay (Malaysia)",
  ms: "Malay",
  ml_IN: "Malayalam (India)",
  ml: "Malayalam",
  mt_MT: "Maltese (Malta)",
  mt: "Maltese",
  gv_GB: "Manx (United Kingdom)",
  gv: "Manx",
  mr_IN: "Marathi (India)",
  mr: "Marathi",
  mas_KE: "Masai (Kenya)",
  mas_TZ: "Masai (Tanzania)",
  mas: "Masai",
  mer_KE: "Meru (Kenya)",
  mer: "Meru",
  mfe_MU: "Morisyen (Mauritius)",
  mfe: "Morisyen",
  naq_NA: "Nama (Namibia)",
  naq: "Nama",
  ne_IN: "Nepali (India)",
  ne_NP: "Nepali (Nepal)",
  ne: "Nepali",
  nd_ZW: "North Ndebele (Zimbabwe)",
  nd: "North Ndebele",
  nb_NO: "Norwegian Bokmål (Norway)",
  nb: "Norwegian Bokmål",
  nn_NO: "Norwegian Nynorsk (Norway)",
  nn: "Norwegian Nynorsk",
  nyn_UG: "Nyankole (Uganda)",
  nyn: "Nyankole",
  or_IN: "Oriya (India)",
  or: "Oriya",
  om_ET: "Oromo (Ethiopia)",
  om_KE: "Oromo (Kenya)",
  om: "Oromo",
  ps_AF: "Pashto (Afghanistan)",
  ps: "Pashto",
  fa_AF: "Persian (Afghanistan)",
  fa_IR: "Persian (Iran)",
  fa: "Persian",
  pl_PL: "Polish (Poland)",
  pl: "Polish",
  pt_BR: "Portuguese (Brazil)",
  pt_GW: "Portuguese (Guinea-Bissau)",
  pt_MZ: "Portuguese (Mozambique)",
  pt_PT: "Portuguese (Portugal)",
  pt: "Portuguese",
  pa_Arab: "Punjabi (Arabic)",
  pa_Arab_PK: "Punjabi (Arabic, Pakistan)",
  pa_Guru: "Punjabi (Gurmukhi)",
  pa_Guru_IN: "Punjabi (Gurmukhi, India)",
  pa: "Punjabi",
  ro_MD: "Romanian (Moldova)",
  ro_RO: "Romanian (Romania)",
  ro: "Romanian",
  rm_CH: "Romansh (Switzerland)",
  rm: "Romansh",
  rof_TZ: "Rombo (Tanzania)",
  rof: "Rombo",
  ru_MD: "Russian (Moldova)",
  ru_RU: "Russian (Russia)",
  ru_UA: "Russian (Ukraine)",
  ru: "Russian",
  rwk_TZ: "Rwa (Tanzania)",
  rwk: "Rwa",
  saq_KE: "Samburu (Kenya)",
  saq: "Samburu",
  sg_CF: "Sango (Central African Republic)",
  sg: "Sango",
  seh_MZ: "Sena (Mozambique)",
  seh: "Sena",
  sr_Cyrl: "Serbian (Cyrillic)",
  sr_Cyrl_BA: "Serbian (Cyrillic, Bosnia and Herzegovina)",
  sr_Cyrl_ME: "Serbian (Cyrillic, Montenegro)",
  sr_Cyrl_RS: "Serbian (Cyrillic, Serbia)",
  sr_Latn: "Serbian (Latin)",
  sr_Latn_BA: "Serbian (Latin, Bosnia and Herzegovina)",
  sr_Latn_ME: "Serbian (Latin, Montenegro)",
  sr_Latn_RS: "Serbian (Latin, Serbia)",
  sr: "Serbian",
  sn_ZW: "Shona (Zimbabwe)",
  sn: "Shona",
  ii_CN: "Sichuan Yi (China)",
  ii: "Sichuan Yi",
  si_LK: "Sinhala (Sri Lanka)",
  si: "Sinhala",
  sk_SK: "Slovak (Slovakia)",
  sk: "Slovak",
  sl_SI: "Slovenian (Slovenia)",
  sl: "Slovenian",
  xog_UG: "Soga (Uganda)",
  xog: "Soga",
  so_DJ: "Somali (Djibouti)",
  so_ET: "Somali (Ethiopia)",
  so_KE: "Somali (Kenya)",
  so_SO: "Somali (Somalia)",
  so: "Somali",
  es_AR: "Spanish (Argentina)",
  es_BO: "Spanish (Bolivia)",
  es_CL: "Spanish (Chile)",
  es_CO: "Spanish (Colombia)",
  es_CR: "Spanish (Costa Rica)",
  es_DO: "Spanish (Dominican Republic)",
  es_EC: "Spanish (Ecuador)",
  es_SV: "Spanish (El Salvador)",
  es_GQ: "Spanish (Equatorial Guinea)",
  es_GT: "Spanish (Guatemala)",
  es_HN: "Spanish (Honduras)",
  es_419: "Spanish (Latin America)",
  es_MX: "Spanish (Mexico)",
  es_NI: "Spanish (Nicaragua)",
  es_PA: "Spanish (Panama)",
  es_PY: "Spanish (Paraguay)",
  es_PE: "Spanish (Peru)",
  es_PR: "Spanish (Puerto Rico)",
  es_ES: "Spanish (Spain)",
  es_US: "Spanish (United States)",
  es_UY: "Spanish (Uruguay)",
  es_VE: "Spanish (Venezuela)",
  es: "Spanish",
  sw_KE: "Swahili (Kenya)",
  sw_TZ: "Swahili (Tanzania)",
  sw: "Swahili",
  sv_FI: "Swedish (Finland)",
  sv_SE: "Swedish (Sweden)",
  sv: "Swedish",
  gsw_CH: "Swiss German (Switzerland)",
  gsw: "Swiss German",
  shi_Latn: "Tachelhit (Latin)",
  shi_Latn_MA: "Tachelhit (Latin, Morocco)",
  shi_Tfng: "Tachelhit (Tifinagh)",
  shi_Tfng_MA: "Tachelhit (Tifinagh, Morocco)",
  shi: "Tachelhit",
  dav_KE: "Taita (Kenya)",
  dav: "Taita",
  ta_IN: "Tamil (India)",
  ta_LK: "Tamil (Sri Lanka)",
  ta: "Tamil",
  te_IN: "Telugu (India)",
  te: "Telugu",
  teo_KE: "Teso (Kenya)",
  teo_UG: "Teso (Uganda)",
  teo: "Teso",
  th_TH: "Thai (Thailand)",
  th: "Thai",
  bo_CN: "Tibetan (China)",
  bo_IN: "Tibetan (India)",
  bo: "Tibetan",
  ti_ER: "Tigrinya (Eritrea)",
  ti_ET: "Tigrinya (Ethiopia)",
  ti: "Tigrinya",
  to_TO: "Tonga (Tonga)",
  to: "Tonga",
  tr_TR: "Turkish (Turkey)",
  tr: "Turkish",
  uk_UA: "Ukrainian (Ukraine)",
  uk: "Ukrainian",
  ur_IN: "Urdu (India)",
  ur_PK: "Urdu (Pakistan)",
  ur: "Urdu",
  uz_Arab: "Uzbek (Arabic)",
  uz_Arab_AF: "Uzbek (Arabic, Afghanistan)",
  uz_Cyrl: "Uzbek (Cyrillic)",
  uz_Cyrl_UZ: "Uzbek (Cyrillic, Uzbekistan)",
  uz_Latn: "Uzbek (Latin)",
  uz_Latn_UZ: "Uzbek (Latin, Uzbekistan)",
  uz: "Uzbek",
  vi_VN: "Vietnamese (Vietnam)",
  vi: "Vietnamese",
  vun_TZ: "Vunjo (Tanzania)",
  vun: "Vunjo",
  cy_GB: "Welsh (United Kingdom)",
  cy: "Welsh",
  yo_NG: "Yoruba (Nigeria)",
  yo: "Yoruba",
  zu_ZA: "Zulu (South Africa)",
  zu: "Zulu"
}*/

  }