import "./ToptraderList_styles.scss";
import { useState, useRef } from "react";
import './search.css'; //button styles

import { Text,Box,HStack,VStack,Spacer,Flex } from "@chakra-ui/layout";
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react'
import { Button } from "@chakra-ui/button";
import { Avatar } from "@chakra-ui/avatar";
import { Image } from "@chakra-ui/image";

import i18n from "../../i18n"
import glovar from './glovar';
import eventBus from "../eventBus";
import { FormLabel, Switch, Checkbox,Input } from '@chakra-ui/react'



export default ({params}) => {
  
    const [apiResult, setApiResult] = useState([]);
    const [texte, setTexte] = useState('');

    var cars = [];
   

    var MOBILE=false;
    var leftsearchbox='10pc'; //'12pc';
    var WITH_BOX_SEARCH='28pc'; //'30pc';
    var TWITTER_ICON=true;

    try { if(params.type==1) { 
            MOBILE=true; 
            TWITTER_ICON=true;
            leftsearchbox='0pc';
            WITH_BOX_SEARCH='22pc'
          } 

           if(params==2) { 
            MOBILE=true; 
            TWITTER_ICON=true;
            leftsearchbox='0pc';
            WITH_BOX_SEARCH='22pc'
          }

          

        } catch(Exxx) {}

    

    function sendEventGA (category, action, label) {
      try {
        eventBus.dispatch("EventGA", { category, action, label });
      }
      catch(Exx) {}
    }

    function HandleChange(e)
    {

      try { if(e.target.value.length<=0) { setApiResult([])} } catch(Exx) {}
      var SEARCH_STRING=e.target.value;
      
      if(e.target.value.length>0)
      {
   
        const link = glovar.LINK_PROXY_COTIS+SEARCH_STRING;
        //console.log("link:"+link);

  fetch(link).then(response => response.json())
 .then(json => {
  
  //console.log("complete:"+JSON.stringify(json))
      try {
        glovar.READY_TO_READ=true;
        glovar.LAST_ELEMENT_SEARCH=json;
        //console.log("le doy valor true!!!")
        
      }
      catch (Exx) {}
      setApiResult(json.quotes)
      //setTodo(CHART_COTIS)
      //setIsFetching(false);
      /*results.data.map((d) => {
        //console.log("d")
      });*/
      //console.log("All done!");

 })
 .catch((e) => {
  {
    //var TICKER="AAPL"
    //console.log("ERROR PARSING:"+e)
    let headers = new Headers(); 
   headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API_COTIS + ":" + glovar.PASS_API_COTIS));
   headers.set('Parameters',btoa(SEARCH_STRING));


   fetch(glovar.URL_FETCH_COTIS_SEARCH,{ method:'GET', //mode: 'no-cors',
   headers: headers,
   })
  .then(res => res.json())
  .then(res => {
     //console.log("res:"+res)
    if(res=="auth/user-not-found" || res=="auth/wrong-password") {
         return;
    }

    if(res=="auth/Unauthorized" || res=="auth/too-many-requests") {       //setError("ERROR: Unauthorized.");  //Please try again or contact support if you are unable to access your account");
      return;
    }

    //console.log(res)
    setApiResult(res)
    //setTodoData(res) 
    //setApiResult(['AAPL', 'DOCN', 'MSFT', 'GOOG', 'AMZN'])

    //setTodoSearch(['AAPL', 'DOCN', 'MSFT', 'GOOG', 'AMZN'])
    /*switch(SEARCH_STRING.length) { 
      case 0: setApiResult([]); break;
      case 1: setApiResult(['AAPL']); break;
      case 2: setApiResult(['AAPL', 'DOCN']); break;
      case 3: setApiResult(['AAPL', 'DOCN', 'MSFT']); break;
      case 4: setApiResult(['AAPL', 'DOCN', 'MSFT', 'GOOG']); break;
      default:setApiResult(['AAPL', 'DOCN', 'MSFT', 'GOOG', 'AMZN']); break;
    }*/
    //setApiResult(['AAPL', 'DOCN', 'MSFT', 'GOOG', 'AMZN'])
  })
  .catch((e) => {
    //console.log(e);
   })
   .finally(() => {
    //setIsFetchingSearch(false);
   
   });
  
  }

 })
 .finally(() => {
 //setIsFetching(false);
 });


  

 



 

}

      //console.log("mierdaaaaaaaaaaa")
      //cars = ['Ford', 'BMW', 'Audi'];

     // const { todoSearch, isFetchingSearch } = useFetchTodoSearch(e.target.value)//"IAG.MC,EURUSD=X")  
  
      /*if(!isFetchingSearch) {
        setApiResult(todoSearch)
      }*/
      if(1==2)
      {
        //var TICKER="AAPL"
        
        let headers = new Headers(); 
       headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API_COTIS + ":" + glovar.PASS_API_COTIS));
       headers.set('Parameters',btoa(SEARCH_STRING));
    
    
       fetch(glovar.URL_FETCH_COTIS_SEARCH,{ method:'GET', //mode: 'no-cors',
       headers: headers,
       })
      .then(res => res.json())
      .then(res => {
         //console.log("res:"+res)
        if(res=="auth/user-not-found" || res=="auth/wrong-password") {
             return;
        }
    
        if(res=="auth/Unauthorized" || res=="auth/too-many-requests") {       //setError("ERROR: Unauthorized.");  //Please try again or contact support if you are unable to access your account");
          return;
        }
    
        //console.log(res)
        setApiResult(res)
        //setTodoData(res) 
        //setApiResult(['AAPL', 'DOCN', 'MSFT', 'GOOG', 'AMZN'])

        //setTodoSearch(['AAPL', 'DOCN', 'MSFT', 'GOOG', 'AMZN'])
        /*switch(SEARCH_STRING.length) { 
          case 0: setApiResult([]); break;
          case 1: setApiResult(['AAPL']); break;
          case 2: setApiResult(['AAPL', 'DOCN']); break;
          case 3: setApiResult(['AAPL', 'DOCN', 'MSFT']); break;
          case 4: setApiResult(['AAPL', 'DOCN', 'MSFT', 'GOOG']); break;
          default:setApiResult(['AAPL', 'DOCN', 'MSFT', 'GOOG', 'AMZN']); break;
        }*/
        //setApiResult(['AAPL', 'DOCN', 'MSFT', 'GOOG', 'AMZN'])
      })
      .catch((e) => {
        //console.log(e);
       })
       .finally(() => {
        //setIsFetchingSearch(false);
       
       });
      
      }
  

     
        
    };

    /*function gotoSymbol(props) {
      glovar.LAST_ELEMENT_SEARCH=props.brand;
      glovar.READY_TO_READ=true;
      console.log("holaaa:"+JSON.stringify( glovar.LAST_ELEMENT_SEARCH))
      //window.location.replace('/symbol/'+props.brand.symbol) 
    }*/

    function CardTicker(props) {
      return(
      
      
      <Box alignItems="center"  _hover={{ cursor: "pointer" }}  backgroundColor="#F6F6F6"
      pl={3} pr={3} pt={3} pb={3} mt={2} 
       onClick= {() => { 
        //gotoSymbol(props)
        window.location.replace('/symbol/'+props.brand.symbol) 
        try { sendEventGA("search_bar","showSearch_"+props.brand.symbol, props.brand.symbol); } catch (exx) { /*console.log("petada event:"+exx)*/}
      }
       }>
          
          <HStack flexDirection="row" justifyContent="space-between">
          <Text>{props.brand.shortname ? `${props.brand.shortname}` : `${props.brand.longname}`}</Text>
          <Flex flexDirection="column" align="flex-end">
          <Text fontWeight="bold">{ props.brand.symbol }</Text>
          <Text >{props.brand.typeDisp}-{props.brand.exchange}</Text>
          </Flex>
          </HStack>
          </Box>);
      //return (<Flex alignItems="center"  >I am a { props.brand }</Flex>);
    }

   /*
    <TouchableOpacity onPress={()=>addToPORTFOLIO(name)}>
    <View style={styles.item}>
      <View style={styles.firstrow}>
      <Text style={styles.name}>{name.shortname ? `${name.shortname}` : `${name.longname}`}</Text>
      <View style={styles.firstcolumn}>
      <Text style={styles.ticker}>{name.symbol}</Text>
      <Text style={styles.equity}>{name.typeDisp}-{name.exchange}</Text>
      </View>
      </View>
    </View>
    </TouchableOpacity>
    */

    return(

      <Box zIndex={20} ml={leftsearchbox}> 

      <HStack>
      <Input
      placeholder={i18n.t("busca_activo")}
       focusBorderColor='gray.400'
       borderColor='gray.400'
      mt='8px'
      type="search"
      width={WITH_BOX_SEARCH}//'30pc'
      onChange={HandleChange}
    /> 
     {TWITTER_ICON&& <Image src={`${process.env.PUBLIC_URL}/assets/twitterxicon.png`} h="46px" pr="10px" pl="2px" pt="8px" _hover={{ cursor: "pointer" }}
       onClick= {() =>  {
         if(MOBILE) { 
          try { sendEventGA("btn_twitter","btn_twitter_MBL", "btn_twitter_MBL"); } catch (exx) { /*console.log("petada event:"+exx)*/} 
         }
         else {
          try { sendEventGA("btn_twitter","btn_twitter_DSK", "btn_twitter_DSK"); } catch (exx) { /*console.log("petada event:"+exx)*/} 
         }
         window.open(i18n.t("url_twitter"),"_blank")
       }} />
     }
</HStack>

<Box background="white" mixHeight={600} width={WITH_BOX_SEARCH}/*'30pc'*/>
      {apiResult.map((result) => <CardTicker brand={result} />)}
      </Box>

    </Box>
    );
  }


