
import glovar from './glovar';
import React, { useState } from 'react';




const  PrivacyPolicy = ({ }) => {

  

  const fecha = new Date();
  const CURRENT_YEAR = fecha.getFullYear();
 

  const headerStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    margin: '20px 0',
  };

  const paragraphStyle = {
    margin: '0 0 16px', // Ajusta el margen según tus necesidades
  };

  const introStyle = {
    margin: '10px 0',
  };

  const linkStyle = {
    padding:5,
    color: '#007bff',
    textDecoration: 'none',
  };

  const tableStyle = {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '10px',
  };

  const tableHeaderStyle = {
    backgroundColor: '#f0f0f0',
  };

  const tableCellStyle = {
    padding: '8px',
    borderBottom: '1px solid #e0e0e0',
  };

  const buttonStyle = {
    backgroundColor: '#2196F3',
    color: 'white',
    padding: '10px 20px',
    borderRadius: '4px',
    textDecoration: 'none',
  };

  return (
  
    <div className="cookie-policy">
  <h1 style={headerStyle}>Terms and Conditions</h1>
  <p style={paragraphStyle}>
  These general conditions regulate the use and access to the app and the website of Pro1x.com owned by the entity N81, SL (hereinafter “Pro1X) with registered office at 95-97 ent 2º Santuarios St.  Barcelona (SPAIN) with VAT ID B02746535 puts User availability through the internet address:{" "} <a href="https://www.Pro1x.com">https://www.Pro1x.com</a>
     </p>

  <p style={paragraphStyle}>
  The use of the app or the website by any user implies full acceptance of these conditions and criteria that are permanently exposed on the app and the website, under the published version and in force at any time. For this purpose it is recommended that the user carefully read the conditions and instructions set out on the web pages each time they access them, as well as the need to reload at any time, through the function provided for that purpose in their browser, the contents that are in the cache of the user’s own personal computer in order to receive fully updated information. </p>

  <h2 style={headerStyle}>Object</h2>
  <p style={paragraphStyle}>
  The main purpose of the app and the website is to inform about the activities, facts, initiatives, projects and services of Pro1X Access to the app and the website and the use of the different functionalities and services by the user implies the express acceptance of these Terms and Conditions.
 </p>

 <h2 style={headerStyle}>Terms of use for the user</h2>
  <p style={paragraphStyle}>
  The user undertakes to make proper, correct and lawful use of the contents and services of the app and the website. Illegal, illegal or contrary to good faith and public order activities are strictly prohibited; and in general, any conduct that threatens, induces or may go against respect for human dignity and on the principle of non-discrimination based on race, sex, religion, opinion, nationality or any other personal or social circumstance; against the protection of public health and of consumers and users; or the protection of youth and childhood. Likewise, any activity aimed at supplanting any person or entity, or interfering, violating, altering or disconnecting the system, servers, networks or contents, as well as breaching any connection requirement is prohibited.
Pro1X reserves the right to exclude the user from the service without prior notice, and take whatever measures they deem appropriate at any time, in order to avoid the indicated behaviors and activities.
 </p>
  
 <h2 style={headerStyle}>Intellectual or Industrial Property</h2>
  <p style={paragraphStyle}>
  It is expressly warned that all elements of the app and the website are subject to intellectual or industrial property rights. The content, source codes, form, design and designation of products, applications, tools, pages, brands, trade names, logos, images, sounds and graphics, including copyright, are property or have been assigned or licensed. Pro1x, so that the authorship and the right of exploitation in all its terms and modalities are protected by the commercial and criminal laws in force, whose infringement, apart from the corresponding pecuniary responsibilities, constitutes an illegal or criminal activity.

Therefore, the user must refrain from making the reproduction, adaptation, combination or integration in other applications, arrangements or any other manipulation or transformation, as well as any form of public communication or distribution, in relation to all accessible content through the web, unless expressly authorized by Pro1X, and even in that case, will refrain from doing so through procedures other than those specified and provided through the app and the website for users, who in no case may carry out any download , print copy, or transfer of the content to any medium, with alteration or modification of the content provided, so that, defer, omit, add, distort, modify, or imply contradiction in relation to the messages or information exposed and provided to through the web, in the way in which these appear in the system and in the computer records of Pro1X.

Pro1X does not grant any license or authorization of use of any kind on its industrial and intellectual property rights or on any right or property related to the app and the website itself, the services or the contents.

Those app and websites that establish hyperlinks (“hyperlinks”) with the pages of Pro1X, will require express authorization, and in any case, they must refrain from reproducing said pages, as well as directly associating the link in relation to any other page of the site other than the home page (“home page”). Likewise, they must refrain from using distinctive signs, commercial names, labels, information or any other content of the app and website, except for the indication that is part of the link itself. In no case may it be implied or declared that Pro1X has authorized, intervenes, assumes, participates or has supervised in any way the contents of the page on which the hyperlink appears, or even that it consents to its inclusion, except express authorization.
  </p>


  <h2 style={headerStyle}>Privacy Policy</h2>
  <p style={paragraphStyle}>
  The user through the different areas that are part of the app and the website can visit, obtain information, direct requests or communications.

How much data could be provided by users will be treated with absolute confidentiality. Both the entity responsible for the files and those involved in any phase of the treatment and the entities to which they have been notified, under the authorization granted by the user, are subject to the strictest professional secrecy, expressly committing to adopt the levels of protection and the necessary technical and organizational measures that guarantee the security of personal data and prevent its alteration, misuse, loss, theft, treatment or unauthorized access, both externally and within the framework itself structure and dependent personnel, given the state of the technology, the nature of the stored data and the risks to which they are exposed, come from human action or from the physical or natural environment. Likewise, the treatment and registration in files, programs, systems, equipment, premises and centers are guaranteed, which meet the requirements and conditions of integrity and security established in the regulations at each moment in force.

All of this and nevertheless, the user must be aware and is expressly warned that Internet security measures are not impregnable. </p>


<h2 style={headerStyle}>Browsing</h2>
  <p style={paragraphStyle}>
  Simple navigation (free content) through the Pro1X website or app is free and does not require prior registration by the user.
   </p>

   <h2 style={headerStyle}>Personal data</h2>
  <p style={paragraphStyle}>
  Users are informed that the data provided, as well as those that may be subsequently provided or resulting from a computer process derived from those already registered, will be treated and kept by Pro1X as the data controller, with registered office at 95-97 ent 2º Santuarios St. Barcelona (SPAIN), the contact data of the data protection delegate being the following contact@Pro1x.com. Specifically, the personal data provided is necessary both for the acquisition of early users for the Pro1X application and to participate in analysis whose purpose is to meet the needs of users through interviews, surveys, ethnographies, elaboration or segmentation of profiles, among other techniques , and / or find defects and improvements in their products or services, and contact users by any means provided by themselves, including telephone and email.The same will not be transferred to third parties except legal obligation and will be kept as long as they are necessary and once the relationship is over during the limitation period of the actions that could be derived. Likewise, the user guarantees the veracity of the data provided at all times and undertakes to promptly notify Pro1X of any variation on them.

Rights: The interested party may write to the registered office of Pro1X or through the following email address: contact@Pro1x.com with the purpose of exercising the rights of access, rectification, opposition, deletion, Limitation and portability. Likewise, in the event that the interested party / s understands it, they can contact the Agencia Española de Protección de Datos (AEPD).
   </p>


   <h2 style={headerStyle}>Responsibility</h2>
  <p style={paragraphStyle}>
  Pro1X does not guarantee the user the availability, access or continuity in the connection to the website or to any of its mobile applications or the provision of information or any of the different services offered through it. Consequently, Pro1X and any possible content provider will not be liable in case of service interruptions, delays, malfunctions, and in general, any inconvenience that has its origin in causes beyond the control of Pro1X or the providers, or It comes from a malicious or guilty action of the user, or force majeure. Pro1X also does not guarantee the utility, obtaining results or infallibility of the contents provided through the web, which in any case are for guidance and information. This information has been obtained from sources considered reasonably reliable, but they should not be presumed in any case infallible, and should be contrasted with other sources, prior to adopting any state of opinion or decision on the part of the user. Any opinions, measures, or actions of investment or of any other type are adopted by the user, they will be at your own risk.

Pro1X does not assume any responsibility derived from the use by the user of the contents of the Pro1X app and website, reserving the right to modify, replace, update, renew, prevent or restrict access, interrupt, block, temporarily suspend or suspend Cancel definitively any service, information provision or any other content, either in relation to a specific user or in general.

Unless expressly stated otherwise in each case, Pro1X does not intervene, participate, or assume any guarantee in relation to the services or products offered by third party suppliers accessible through hyperlinks or advertising frameworks.

Pro1X assumes no responsibility for any damages that may be caused to users’ computers due to possible computer viruses contracted by the user due to their browsing of the app or website or any other derivatives of their browsing.

(c) N81 SL ({CURRENT_YEAR}). All rights reserved.  </p>

  </div>

    
     
      
    );
  }

  
  
  export default PrivacyPolicy;