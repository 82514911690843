import React from "react";
import PropTypes from "prop-types";
import { fitWidth } from "react-stockcharts/lib/helper";
import { last, timeIntervalBarWidth } from "react-stockcharts/lib/utils";
import { discontinuousTimeScaleProvider } from "react-stockcharts/lib/scale";

import ChartCandleTpl from './ChartCandleTpl';


class areachart extends React.Component {
	render() 
	{
		const _0x304adb=_0x4cbe;function _0xab38(){const _0x7c6ee6=['1674827teHhIV','length','1398gFDUbJ','38071770KlNJpP','4320030eTlHiB','inputDateAccessor','7885422oJAWiO','props','9684TqzgmR','30PWIWYH','60qxhYhK','222IocnIc','8tuLViA','1017865CkuerM'];_0xab38=function(){return _0x7c6ee6;};return _0xab38();}(function(_0x5b4594,_0x410fd7){const _0x347687=_0x4cbe,_0x3af82f=_0x5b4594();while(!![]){try{const _0x3949a7=-parseInt(_0x347687(0xba))/0x1*(parseInt(_0x347687(0xbf))/0x2)+-parseInt(_0x347687(0xb7))/0x3*(-parseInt(_0x347687(0xb9))/0x4)+-parseInt(_0x347687(0xbc))/0x5+parseInt(_0x347687(0xb8))/0x6*(-parseInt(_0x347687(0xbd))/0x7)+parseInt(_0x347687(0xbb))/0x8*(-parseInt(_0x347687(0xb5))/0x9)+-parseInt(_0x347687(0xb3))/0xa+parseInt(_0x347687(0xc0))/0xb;if(_0x3949a7===_0x410fd7)break;else _0x3af82f['push'](_0x3af82f['shift']());}catch(_0x726795){_0x3af82f['push'](_0x3af82f['shift']());}}}(_0xab38,0x9dc81));function _0x4cbe(_0x217890,_0x55643d){const _0xab38ed=_0xab38();return _0x4cbe=function(_0x4cbe0e,_0x1724fa){_0x4cbe0e=_0x4cbe0e-0xb3;let _0x2a5379=_0xab38ed[_0x4cbe0e];return _0x2a5379;},_0x4cbe(_0x217890,_0x55643d);}try{const {type,data:initialData,width,ratio}=this[_0x304adb(0xb6)],xScaleProvider=discontinuousTimeScaleProvider[_0x304adb(0xb4)](_0xc707bd=>_0xc707bd['date']),{data,xScale,xAccessor,displayXAccessor}=xScaleProvider(initialData),start=xAccessor(last(data)),end=xAccessor(data[Math['max'](0x0,data[_0x304adb(0xbe)]-0x96)]),xExtents=[start,end];let props={'type':type,'data':data,'width':width,'ratio':ratio,'xScale':xScale,'xAccessor':xAccessor,'displayXAccessor':displayXAccessor,'xExtents':xExtents};return ChartCandleTpl({...props});}catch(_0x28bd9a){return null;}
	}
}


areachart.propTypes = {
	data: PropTypes.array.isRequired,
	width: PropTypes.number.isRequired,
	ratio: PropTypes.number.isRequired,
	type: PropTypes.oneOf(["svg", "hybrid"]).isRequired,
};

areachart.defaultProps = {
	type: "svg",
};
areachart = fitWidth(areachart);

export default areachart;