import React, { useState } from 'react';
import './CookieConsentPopup.css';
import glovar from './glovar';
import CookiePolicy from './CookiePolicy';
import PrivacyPolicy from './PrivacyPolicy';
import TermsPolicy from './TermsPolicy';
import HeaderMenu from './HeaderMenu';
import FooterMenu from './FooterMenu';
import { SimpleGrid, GridItem, Text, Box, Stack, VStack, HStack,  Spacer, Flex } from "@chakra-ui/layout";


const  ShowLegalText = ({ textType}) => {

  
  if(textType==1) { // cookies

    if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
      return(
        <div>
    <HeaderMenu showSearch={true} />
    <SimpleGrid columns={14} gap={0} p={0} mb={300} height="maxHeight" backgroundColor="#FFFFFF">
      <GridItem colSpan={14} rounded={10} backgroundColor="#F8F8F8" p={2} m={2} mt={0} paddingBottom={10}>
          <CookiePolicy />
      </GridItem>
    </SimpleGrid>
    <FooterMenu/>
  </div>
      );
    }
    else {
  return (
    <div>
    <HeaderMenu showSearch={true} />
    <SimpleGrid columns={14} gap={1} p={2} mb={300} height="maxHeight" backgroundColor="#FFFFFF">
    <GridItem colSpan={2} rounded={10}></GridItem>
      <GridItem colSpan={10} rounded={10} backgroundColor="#F8F8F8" p={12} m={2} mt={0} paddingBottom={10}>
      <HStack width="100%" flexDirection="row" justifyContent="center" mt={8}>
          <CookiePolicy />
        </HStack>
      
      </GridItem>
    </SimpleGrid>
    <FooterMenu/>
  </div>
  );
  } 
  }
  else if (textType==2) { // privacy

    if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
    return (
      <div>
      <HeaderMenu showSearch={true} />
      <SimpleGrid columns={14} gap={0} p={0} mb={300} height="maxHeight" backgroundColor="#FFFFFF">
        <GridItem colSpan={14} rounded={10} backgroundColor="#F8F8F8" p={2} m={2} mt={0} paddingBottom={10}>
        <HStack width="100%" flexDirection="row" justifyContent="center" mt={8}>
            <PrivacyPolicy />
          </HStack>
        
        </GridItem>
      </SimpleGrid>
      <FooterMenu/>
    </div>
    );
    }
    else {
      return (
        <div>
        <HeaderMenu showSearch={true} />
        <SimpleGrid columns={14} gap={1} p={2} mb={300} height="maxHeight" backgroundColor="#FFFFFF">
        <GridItem colSpan={2} rounded={10}></GridItem>
          <GridItem colSpan={10} rounded={10} backgroundColor="#F8F8F8" p={12} m={2} mt={0} paddingBottom={10}>
          <HStack width="100%" flexDirection="row" justifyContent="center" mt={8}>
              <PrivacyPolicy />
            </HStack>
          </GridItem>
        </SimpleGrid>
        <FooterMenu/>
      </div>
      );
    } 
    }
    else if (textType==3) { // terms
      if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
        
      return (
        <div>
        <HeaderMenu showSearch={true} />
        <SimpleGrid columns={14} gap={0} p={0} mb={300} height="maxHeight" backgroundColor="#FFFFFF">
          <GridItem colSpan={14} rounded={10} backgroundColor="#F8F8F8" p={2} m={2} mt={0} paddingBottom={10}>
          <HStack width="100%" flexDirection="row" justifyContent="center" mt={8}>
              <TermsPolicy />
            </HStack>
          
          </GridItem>
        </SimpleGrid>
        <FooterMenu/>
      </div>
      );
      } 
      else {
        return (
          <div>
          <HeaderMenu showSearch={true} />
          <SimpleGrid columns={14} gap={1} p={2} mb={300} height="maxHeight" backgroundColor="#FFFFFF">
          <GridItem colSpan={2} rounded={10}></GridItem>
            <GridItem colSpan={10} rounded={10} backgroundColor="#F8F8F8" p={12} m={2} mt={0} paddingBottom={10}>
            <HStack width="100%" flexDirection="row" justifyContent="center" mt={8}>
                <TermsPolicy />
              </HStack>
            
            </GridItem>
          </SimpleGrid>
          <FooterMenu/>
        </div>
        );
      }
      }

  }
  
  export default ShowLegalText;