import "./ToptraderList_styles.scss";
//import Tag from "./Tag";
import { useState, useRef } from "react";
//import PortfolioListItem from "./PortfolioListItem";
//import { useFetchTodoToptraders } from "../utils/useFetchTodoToptraders";
//import gsap from "gsap";

//import { useFetchTodoPortfolio } from "../utils/useFetchTodoPortfolio";
import { useFetchTodo } from "../utils/useFetchTodo";
import Element from "./element";
import glovar from './glovar';
import {getMONEDADivisa} from './glovar';

import { getLastTimeFavoritos } from '../../utils/Common';


export default ({}) => {
  
  let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);

  if(!glovar.CURRENT_FAVORITOS) {
    return null;
  }

  let ARRAY_COTIS="";
  //ARRAY_COTIS="^IBEX,^STOXX50E,^NDX,^GSPC,CL=F,EURUSD=X";

  if(glovar.CURRENT_FAVORITOS) {
  for (let i=0; i<glovar.CURRENT_FAVORITOS.length;i++)
       {
       ARRAY_COTIS=ARRAY_COTIS+glovar.CURRENT_FAVORITOS[i].symbol+","; 
       }
      }

  //const { todo, isFetching } = useFetchTodo("^IBEX,^STOXX50E,^NDX,^GSPC,CL=F,EURUSD=X")
  console.log("actualizando cotis:"+ARRAY_COTIS)

  var MODE_FECTH=0;

  var TimeNow=Date.now();
  glovar.lastTimeMYWATCHLIST_COTIS=getLastTimeFavoritos();
  var difNow=TimeNow-glovar.lastTimeMYWATCHLIST_COTIS;
     
  if(difNow<glovar.MAXMILIS_REFRESH_MY_PORTFOLIO) {
    MODE_FECTH=1;
  }

  const { todo, isFetching } = useFetchTodo(ARRAY_COTIS,MODE_FECTH)

  const Tag = ({ data }) => {
    return <li>{data}</li>;
  };


 


    if (!isFetching) {



      return (
        
        todo.map((todo) => {
          var m=getMONEDADivisa(todo.currency,todo.quoteType);
          return (
          <Element  element={todo} name={todo.shortName} price={todo.regularMarketPrice}
          pordiff={todo.regularMarketChange}
           diff={todo.regularMarketChangePercent} moneda={m}></Element>
          );  
        }) 
       );
      }
    //const  todoTopTraders = useFetchTodoToptraders()

      
  
}
