import "./ToptraderList_styles.scss";
//import Tag from "./Tag";
import { useState, useRef } from "react";
import ToptraderListItem from "./ToptraderListItem";
import { useFetchTodoToptraders } from "../utils/useFetchTodoToptraders";
//import glovar from "./glovar";
//import gsap from "gsap";

export default ({ }) => {
  
  let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);

  
    const { todoTopTraders, isFetchingTopTraders } = useFetchTodoToptraders()//"IAG.MC,EURUSD=X")

    //const  todoTopTraders = useFetchTodoToptraders()
    //glovar.TOPTRADERSLIST=todoTopTraders
      
  //Slide click
  const slide = (shift) => {
    //console.log("scrollX:"+scrollX)
    scrl.current.scrollLeft += shift;
    setscrollX(scrollX + shift);

    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  

  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  if(!isFetchingTopTraders) {
  return (
    <div className="App">
      {scrollX !== 0 && (
        <button
        style={{fontSize: '24px', color: "#777", margin:" -0px 8px"}}
          //className="prev"
          onClick={() => slide(-250)}
          //onMouseEnter={(e) => anim(e)}
          //onMouseLeave={(e) => anim2(e)}
        >
        {"<"}
        </button>
      )}
      <ul ref={scrl} onScroll={scrollCheck}>
        {todoTopTraders.map((d, i) => (
            <ToptraderListItem Toptrader={d} index={i}></ToptraderListItem>

        ))}
      </ul>
      {!scrolEnd && (
        <button
        style={{fontSize: '24px', color: "#777", margin:" 0 8px",}}
          //className="next"
          onClick={() => slide(+250)}
          //onMouseEnter={(e) => anim(e)}
          //onMouseLeave={(e) => anim2(e)}
        >
          {" > "}
        </button>
      )}
    </div>
  );
}
}
