import "./ToptraderList_styles.scss";
import { useState, useRef } from "react";
import './search.css'; //button styles

import { Text,Box,HStack,VStack,Spacer,Flex, Center, Link } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";

import i18n from "../../i18n"
import glovar from './glovar';
import eventBus from "../eventBus";



export default ({ }) => {
  
  const [showPopPup,setshowPopPup] = useState(false);
  const [email,setEmail] = useState("");

  const fecha = new Date();
  const CURRENT_YEAR = fecha.getFullYear();

  function sendEventGA (category, action, label) {
    try {
      eventBus.dispatch("EventGA", { category, action, label });
    }
    catch(Exx) {}
  }

  const ItemSibedarRRSS = ({ icon, active, id }) => (

      
      
    <Box
      _hover={{ cursor: "pointer" }}
      p={0}
      borderRadius="md"
      bg="#000000"//{(glovar.CURRENT_MENU_ID==id) ? "#000000" : "#888888"}
      textColor="#FFFFFF"//{(glovar.CURRENT_MENU_ID==id) ? "white" : "gray"}
      onClick={() => {
        try {
           //EventGA("click_element","chgMenu_"+glovar.CURRENT_MENU_ID+"to "+id, id);  
        }
        catch (exx) { /*console.log("petada event:"+exx)*/}
         //this.changemenu(id)
         if(id==101) window.open(i18n.t("url_twitter"),"_blank")
      if(id==102) window.open(i18n.t("url_instagram"),"_blank")
        }
      }
    >
      <Image src={icon} w={8}></Image>
    </Box>
  );


  if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
  {
    return(
      <Box>

    


    <Box backgroundColor="#FFFFFF" pb={"0px"} >
      
    

    <VStack p={4} backgroundColor="#000000" minHeight="120px" paddingTop={4}>
         <Image src={`${process.env.PUBLIC_URL}/assets/logoemail.png`}  h="100px" _hover={{ cursor: "pointer" }}
       onClick= {() =>  {
         //try { EventGA("button_sup","pslogoHomeDT", "pslogoHomeDT"); } catch (exx) { /*console.log("petada event:"+exx)*/}
         glovar.CURRENT_MENU_ID=1;
         //changemenu(1)
         window.location.replace('/') 
         //changemenu
       }} />
       <Spacer />

       <VStack alignContent="center" marginLeft="5%" marginRight="5%"> 
       <ItemSibedarRRSS icon={`${process.env.PUBLIC_URL}/assets/ic_twitter_white_24dp.png`}  id={101} />
       <Text color="white" fontSize={11}>Copyright © {CURRENT_YEAR} Pro1X.com | Powered by Pro1X.com</Text>
       <Text color="gray"  fontSize={11} textAlign="center">  This site is protected by reCAPTCHA and the 
        <Link color='lightblue' href='https://policies.google.com/privacy'> Google Privacy Policy </Link>
        and 
        <Link color='lightblue' href='https://policies.google.com/terms'> Terms of Service </Link> apply. Pro1X 
        <Link color='lightblue' href={glovar.URL_TERMS_POLICY}> Terms and Conditions </Link> , 
        <Link color='lightblue' href={glovar.URL_PRIVACY_POLICY}> Privacy Policy </Link>
         & 
         <Link color='lightblue' href={glovar.URL_COOKIES_POLICY}> Cookies Privacy </Link></Text>
       </VStack>
      


    </VStack>

   

   
   </Box> 
   </Box>
    );
   }
  else {
  //return elements for render
  return (
   <Box>

    


    <Box backgroundColor="#FFFFFF" pb={"0px"} >
      
    

    <Flex p={4} backgroundColor="#000000" minHeight="200px" paddingTop={10}>
         <Image src={`${process.env.PUBLIC_URL}/assets/logoemail.png`} h="120px" marginLeft="7.5pc" _hover={{ cursor: "pointer" }}
       onClick= {() =>  {
         //try { EventGA("button_sup","pslogoHomeDT", "pslogoHomeDT"); } catch (exx) { /*console.log("petada event:"+exx)*/}
         glovar.CURRENT_MENU_ID=1;
         //changemenu(1)
         window.location.replace('/') 
         //changemenu
       }} />
       <Spacer />

       <VStack alignContent="center" marginLeft="10%" marginRight="10%"> 
       <ItemSibedarRRSS icon={`${process.env.PUBLIC_URL}/assets/ic_twitter_white_24dp.png`} id={101} />
       <Text color="white" fontSize={14}>Copyright © {CURRENT_YEAR} Pro1X.com | Powered by Pro1X.com</Text>
       <Text color="gray"  fontSize={14} textAlign="center">  This site is protected by reCAPTCHA and the 
        <Link color='lightblue' href='https://policies.google.com/privacy'> Google Privacy Policy </Link>
        and 
        <Link color='lightblue' href='https://policies.google.com/terms'> Terms of Service </Link> apply. Pro1X 
        <Link color='lightblue' href={glovar.URL_TERMS_POLICY}> Terms and Conditions </Link> , 
        <Link color='lightblue' href={glovar.URL_PRIVACY_POLICY}> Privacy Policy </Link>
         & 
         <Link color='lightblue' href={glovar.URL_COOKIES_POLICY}> Cookies Privacy </Link></Text>
       </VStack>
      


    </Flex>

   

   
   </Box> 
   </Box>
  );
  }
}
