import "./ToptraderList_styles.scss";
//import Tag from "./Tag";
import { useState, useRef } from "react";
import ToptraderListItem from "./ToptraderListItem";
import { useFetchTodoToptraders } from "../utils/useFetchTodoToptraders";
import PortfolioTopTraderList from "./PortfolioTopTraderList";
//import MorePopularList from "./MorePopularList";
import ShowAssets from "./ShowAssets";

import './search.css';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  //Select,
} from '@chakra-ui/react'

import Select from 'react-select';

import { IconButton } from "@chakra-ui/button";
import { SimpleGrid } from "@chakra-ui/layout";
import { Grid } from "@chakra-ui/layout";
import { GridItem } from "@chakra-ui/layout";
import { Text } from "@chakra-ui/layout";
import { Stack } from "@chakra-ui/layout";
import Papa from "papaparse"


import { Heading } from "@chakra-ui/layout";
//import { Grid } from "@chakra-ui/layout";
//import { Center } from "@chakra-ui/layout";
import { Box } from "@chakra-ui/layout";
//import { FiPlus,FiEdit,FiCopy,FiTrash2 } from "react-icons/fi";
import { HStack } from "@chakra-ui/layout";
import {Container } from "@chakra-ui/layout";
import { VStack } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { Avatar } from "@chakra-ui/avatar";
import { Image } from "@chakra-ui/image";
import { FormControl, FormLabel, Switch, Checkbox,Input } from '@chakra-ui/react'
import { FiBell, FiSettings, FiSearch, FiLogOut, FiLogIn, FiMessageSquare, FiHeart } from "react-icons/fi";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'



import { Spacer } from "@chakra-ui/layout";
import { Flex } from "@chakra-ui/layout";


import i18n from "../../i18n"

import glovar from './glovar';

//import glovar from './components/content/glovar';

//import glovar from "./glovar";
//import gsap from "gsap";

export default ({ }) => {
  


  let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedCriteri, setSelectedCriteri] = useState(null);

  const [showSearch,setShowSearch] = useState(false);
  const [doSearch,setDoSearch] = useState(false);
  const [doRefresh,setDoRefresh] = useState(false);

  
  const [showPopPup,setshowPopPup] = useState(false);
  const [email,setEmail] = useState("");

  /*
  togglePopup() {
    this.setState({
      showPopup: !this.state.showPopup
    });
  }*/

  function doOrderBy(typeOrder) {
    //console.log("typeOrder:"+typeOrder);
    setDoSearch(true);
    setShowSearch(true);
    setDoRefresh(doRefresh);
    //console.log("refresh!!!")

  }
  function handleIndex(selectedIndex) {
    
    setSelectedIndex(selectedIndex);
    if(doSearch) {setDoSearch(false); }
    setDoRefresh(false);
    //if(showSearch) {setShowSearch(false); }
    /*this.setState({ selectedIndex, }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );*/
  };



    const { todoTopTraders, isFetchingTopTraders } = useFetchTodoToptraders()//"IAG.MC,EURUSD=X")

    //const  todoTopTraders = useFetchTodoToptraders()
    //glovar.TOPTRADERSLIST=todoTopTraders
      
  //Slide click
  const slide = (shift) => {
    //console.log("scrollX:"+scrollX)
    scrl.current.scrollLeft += shift;
    setscrollX(scrollX + shift);

    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  //Anim
  /*const anim = (e) => {
    //gsap.from(e.target, { scale: 1 });
    //gsap.to(e.target, { scale: 1.5 });
  };
  const anim2 = (e) => {
    //gsap.from(e.target, { scale: 1.5 });
    //gsap.to(e.target, { scale: 1 });
  };*/

  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  const groupedOptions = [

    {
      label: "Principales",
      options:  glovar.TICKER_INDEX_PRINCIPAL
    },
    {
      label: "USA",
      options:  glovar.TICKER_INDEX_USA
    }, 
    {
      label: "Europa",
      options: glovar.TICKER_INDEX_EU
    },
    {
      label: "Europa - España",
      options: glovar.TICKER_INDEX_SPAIN
    },
    {
      label: "Europa - France",
      options: glovar.TICKER_INDEX_FRANCE
    },
    {
      label: "Europa - Germany",
      options: glovar.TICKER_INDEX_GERMANY
    },
    {
      label: "UK",
      options: glovar.TICKER_INDEX_UK
    },
   
   
  ];

  const criterisOptions = [

    {
      label:i18n.t("anal_tec"),
      options:  glovar.technical_criteria
    },
    {
      label: i18n.t("anal_fun"),
      options:  glovar.fundamental_criteria
    },
  ];

  //if(!isFetchingTopTraders) 
  if((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)))
  {
    return(
      <Box backgroundColor="#FFFFFF" minHeight="80em" width= "100%" pb={"0px"}>     

      <SimpleGrid   columns={14} gap={1} p={2} m={4} mr={2} ml={2} mt={0} mb={0} rounded={10} height="maxHeight" backgroundColor="#F8F8F8">
         <GridItem colSpan={14} rounded={10} backgroundColor="#F8F8F8" p={2} m={2} mt={8} paddingBottom={6}>
            <Text textAlign="center" mt={0} fontSize="18" textColor="black" fontFamily="Tahoma" fontWeight="semibold" > 
             {i18n.t("title_home_row1")}
            </Text>   
          </GridItem>
    
          
  
        <GridItem colSpan={14} gap={1} justifyItems="center" rounded={10}  m={0} mt={0}  height="maxHeight">
         <Box rounded={6}  height="maxHeight" background="#FFFFFF">  
          <ShowAssets 
            arrayTickers={{label: "USA2", options:  glovar.TICKER_INDEX_USA}} 
            arrayCotis={glovar.MOBILE_ARRAY_COTIS_HOMEPAGE_1}
            anotherSearch={true} criteri={[{"label":i18n.t("div_rent_div"),"value":"trailingAnnualDividendRate,trailingAnnualDividendYield"}]} 
            refreshFiltres={false} noshowFilters={true} modeCompact={true}
          />  
         </Box>  
        </GridItem>
        <GridItem colSpan={14} gap={1} justifyItems="center" rounded={10}   mt={8}  height="maxHeight">
        <Box rounded={6}   mt={0} p={0} height="maxHeight" background="#FFFFFF">  
          <ShowAssets 
            arrayTickers={{ label: "USA", options:  glovar.TICKER_INDEX_USA}} 
            arrayCotis={glovar.MOBILE_ARRAY_COTIS_HOMEPAGE_2}
            anotherSearch={true} criteri={[{ label:  i18n.t("avg_anal_rating"), value: "averageAnalystRating"}]} 
            refreshFiltres={false} noshowFilters={true} modeCompact={true}
          />  
         </Box>  
        </GridItem>
    
        <GridItem colSpan={14} rounded={10} backgroundColor="#F8F8F8" p={2} m={2} mt={10} paddingBottom={16}>
             <VStack width="100%" flexDirection="column" justifyContent="center" mt={0}>
             <button className="formSignInButton" marginTop={5} _hover={{ cursor: "pointer" }} 
              onClick= {() => 
               window.scroll({top: 0, left: 0, behavior: 'smooth' })
              }
              style={{ paddingLeft:20, paddingRight:20, marginTop:"10px", fontWeight:"semibold", textAlign:"center", fontSize:"medium", marginLeft:5}}>
              {"View more >"}
            </button>
            </VStack>
        
          </GridItem>
  
    </SimpleGrid>
  
  
    
  
  
       <SimpleGrid   columns={15} gap={1} p={2} ml={2} mr={2} paddingBottom={50} marginBottom={100} rounded={10} height="maxHeight"  backgroundColor="#F8F8F8">
         
       <GridItem colSpan={15} rounded={10} backgroundColor="#F8F8F8" p={2} m={2} mt={10} paddingBottom={6}>
          <Text textAlign="center" mt={0} fontSize="18" textColor="black" fontFamily="Tahoma" fontWeight="semibold" > 
            {i18n.t("title_home_row2")}
          </Text>
       </GridItem>
  
      <GridItem colSpan={15} justifyItems="center" rounded={10}  m={0} mt={0}  height="maxHeight">
       <Box rounded={6}   mt={0} p={0}  height="maxHeight" background="#FFFFFF">  
        <ShowAssets 
          arrayTickers={{label: "USA2", options:  glovar.TICKER_INDEX_USA}} 
          arrayCotis={glovar.MOBILE_ARRAY_COTIS_HOMEPAGE_3}
          anotherSearch={true} criteri={[{"label":"200-day moving average distance","value":"twoHundredDayAverageChangePercent"}]} 
          refreshFiltres={false} noshowFilters={true} modeCompact={true} superCompact={true}
        />  
       </Box>  
      </GridItem>
  
      <GridItem colSpan={15} justifyItems="center" rounded={10}  m={0} mt={8}  height="maxHeight">
      <Box rounded={6}   mt={0} p={0}  height="maxHeight" background="#FFFFFF">  
        <ShowAssets 
          arrayTickers={{ label: "USA", options:  glovar.TICKER_INDEX_USA}} 
          arrayCotis={glovar.MOBILE_ARRAY_COTIS_HOMEPAGE_4}
          anotherSearch={true} criteri={[{ label:  i18n.t("incr_vol"), value: "averageDailyVolume10Day,averageDailyVolume3Month"}]} 
          refreshFiltres={false} noshowFilters={true} modeCompact={true} superCompact={true}
        />  
       </Box>  
      </GridItem>
  
      <GridItem colSpan={15} justifyItems="center" rounded={10}  m={0} mt={8}  height="maxHeight">
      <Box rounded={6}   mt={0} p={0}  height="maxHeight" background="#FFFFFF">  
        <ShowAssets 
          arrayTickers={{ label: "USA", options:  glovar.TICKER_INDEX_USA}} 
          arrayCotis={glovar.MOBILE_ARRAY_COTIS_HOMEPAGE_5}
          anotherSearch={true} criteri={[{ label: "PE Ratio (TTM)", value: "trailingPE"}]} 
          refreshFiltres={false} noshowFilters={true} modeCompact={true} superCompact={true}
        />  
       </Box>  
      </GridItem>
  
      <GridItem colSpan={15} rounded={10} backgroundColor="#F8F8F8" p={2} m={2} mt={10} paddingBottom={0}>
           <VStack width="100%" flexDirection="column" justifyContent="center" mt={0}>
           <button className="formSignInButton" marginTop={5} _hover={{ cursor: "pointer" }} 
          onClick= {() =>
            window.scroll({top: 0, left: 0, behavior: 'smooth' })
           }
           style={{ paddingLeft:20, paddingRight:20, marginTop:"10px", fontWeight:"semibold", textAlign:"center", fontSize:"medium", marginLeft:5}}>
           {"View more >"}</button>
           </VStack>  
      </GridItem>
  
      
  </SimpleGrid> 
  
  
  
  
     </Box> 
    );
  }
  else  
  {
  return (
   

    <Box backgroundColor="#FFFFFF" minHeight="80em" width= "100%" pb={"0px"}>     

    <SimpleGrid   columns={14} gap={1} p={2} m={4} mt={0} mb={0} rounded={10} height="maxHeight" backgroundColor="#F8F8F8">
       <GridItem colSpan={14} rounded={10} backgroundColor="#F8F8F8" p={2} m={2} mt={12} paddingBottom={6}>
          <Text textAlign="center" mt={0} fontSize="18" textColor="black" fontFamily="Tahoma" fontWeight="semibold" > 
           {i18n.t("title_home_row1")}
          </Text>   
        </GridItem>
  
        <GridItem colSpan={1} gap={1} justifyItems="center" rounded={10}  m={0} mt={0}  height="maxHeight">
          <Box rounded={0} height="maxHeight" background="#FFFFFF">  
          </Box>  
        </GridItem>

      <GridItem colSpan={6} gap={1} justifyItems="center" rounded={10}  m={0} mt={0}  height="maxHeight">
       <Box rounded={6}   mt={0} p={0} mr={6} ml={6} height="maxHeight" background="#FFFFFF">  
        <ShowAssets 
          arrayTickers={{label: "USA2", options:  glovar.TICKER_INDEX_USA}} 
          arrayCotis={glovar.ARRAY_COTIS_HOMEPAGE_1}
          anotherSearch={true} criteri={[{"label":i18n.t("div_rent_div"),"value":"trailingAnnualDividendRate,trailingAnnualDividendYield"}]} 
          refreshFiltres={false} noshowFilters={true} modeCompact={true}
        />  
       </Box>  
      </GridItem>
      <GridItem colSpan={6} gap={1} justifyItems="center" rounded={10}  m={0} mt={0}  height="maxHeight">
      <Box rounded={6}   mt={0} p={0} mr={6} ml={6} height="maxHeight" background="#FFFFFF">  
        <ShowAssets 
          arrayTickers={{ label: "USA", options:  glovar.TICKER_INDEX_USA}} 
          arrayCotis={glovar.ARRAY_COTIS_HOMEPAGE_2}
          anotherSearch={true} criteri={[{ label:  i18n.t("avg_anal_rating"), value: "averageAnalystRating"}]} 
          //anotherSearch={true} criteri={[{ label: "PE Ratio (TTM)", value: "trailingPE"}]} 
          refreshFiltres={false} noshowFilters={true} modeCompact={true}
        />  
       </Box>  
      </GridItem>
  
      <GridItem colSpan={14} rounded={10} backgroundColor="#F8F8F8" p={2} m={2} mt={10} paddingBottom={16}>
           <VStack width="100%" flexDirection="column" justifyContent="center" mt={0}>
           <button className="formSignInButton" marginTop={5} _hover={{ cursor: "pointer" }} 
            onClick= {() => 
             window.scroll({top: 0, left: 0, behavior: 'smooth' })
            }
            style={{ paddingLeft:20, paddingRight:20, marginTop:"10px", fontWeight:"semibold", textAlign:"center", fontSize:"medium", marginLeft:5}}>
            {"View more >"}
          </button>
          </VStack>
      
        </GridItem>

  </SimpleGrid>


  


     <SimpleGrid   columns={15} gap={1} p={2} ml={4} mr={4} paddingBottom={100} marginBottom={50} rounded={10} height="maxHeight"  backgroundColor="#F8F8F8">
       
     <GridItem colSpan={15} rounded={10} backgroundColor="#F8F8F8" p={2} m={2} mt={10} paddingBottom={6}>
        <Text textAlign="center" mt={0} fontSize="18" textColor="black" fontFamily="Tahoma" fontWeight="semibold" > 
          {i18n.t("title_home_row2")}
        </Text>
     </GridItem>

    <GridItem colSpan={5} justifyItems="center" rounded={10}  m={0} mt={0}  height="maxHeight">
     <Box rounded={6}   mt={0} p={0} mr={6} ml={6} height="maxHeight" background="#FFFFFF">  
      <ShowAssets 
        arrayTickers={{label: "USA2", options:  glovar.TICKER_INDEX_USA}} 
        arrayCotis={glovar.ARRAY_COTIS_HOMEPAGE_3}
        anotherSearch={true} criteri={[{"label":"200-day moving average distance","value":"twoHundredDayAverageChangePercent"}]} 
        refreshFiltres={false} noshowFilters={true} modeCompact={true} superCompact={true}
      />  
     </Box>  
    </GridItem>

    <GridItem colSpan={5} justifyItems="center" rounded={10}  m={0} mt={0}  height="maxHeight">
    <Box rounded={6}   mt={0} p={0} mr={6} ml={6} height="maxHeight" background="#FFFFFF">  
      <ShowAssets 
        arrayTickers={{ label: "USA", options:  glovar.TICKER_INDEX_USA}} 
        arrayCotis={glovar.ARRAY_COTIS_HOMEPAGE_4}
        anotherSearch={true} criteri={[{ label:  i18n.t("incr_vol"), value: "averageDailyVolume10Day,averageDailyVolume3Month"}]} 
        refreshFiltres={false} noshowFilters={true} modeCompact={true} superCompact={true}
      />  
     </Box>  
    </GridItem>

    <GridItem colSpan={5} justifyItems="center" rounded={10}  m={0} mt={0}  height="maxHeight">
    <Box rounded={6}   mt={0} p={0} mr={6} ml={6} height="maxHeight" background="#FFFFFF">  
      <ShowAssets 
        arrayTickers={{ label: "USA", options:  glovar.TICKER_INDEX_USA}} 
        arrayCotis={glovar.ARRAY_COTIS_HOMEPAGE_5}
        anotherSearch={true} criteri={[{ label: "PE Ratio (TTM)", value: "trailingPE"}]} 
        refreshFiltres={false} noshowFilters={true} modeCompact={true} superCompact={true}
      />  
     </Box>  
    </GridItem>

    <GridItem colSpan={15} rounded={10} backgroundColor="#F8F8F8" p={2} m={2} mt={10} paddingBottom={16}>
         <VStack width="100%" flexDirection="column" justifyContent="center" mt={0}>
         <button className="formSignInButton" marginTop={5} _hover={{ cursor: "pointer" }} 
        onClick= {() =>
          window.scroll({top: 0, left: 0, behavior: 'smooth' })
         }
         style={{ paddingLeft:20, paddingRight:20, marginTop:"10px", fontWeight:"semibold", textAlign:"center", fontSize:"medium", marginLeft:5}}>
         {"View more >"}</button>
         </VStack>  
    </GridItem>

    
</SimpleGrid> 




   </Box> 
 
  );
}
}
