import eventBus from "../eventBus";
import { Box } from "@chakra-ui/layout";
import React from "react";
import PropTypes from "prop-types";
import { format } from "d3-format";	
import { timeFormat } from "d3-time-format";

import { ChartCanvas, Chart, ZoomButtons } from "react-stockcharts";

import { XAxis, YAxis } from "react-stockcharts/lib/axes";
import { fitWidth } from "react-stockcharts/lib/helper";

import {
	CrossHairCursor,
	CurrentCoordinate,
	MouseCoordinateX,
	MouseCoordinateY,
	EdgeIndicator,

} from "react-stockcharts/lib/coordinates";



import { CandlestickSeries,BarSeries,LineSeries,MACDSeries, RSISeries,
	    AreaSeries,RenkoSeries, KagiSeries, PointAndFigureSeries,
		StochasticSeries,
		StraightLine,
		AlternatingFillAreaSeries,ElderRaySeries,
		BollingerSeries, SARSeries, 
	    VolumeProfileSeries, } from "react-stockcharts/lib/series";

import { last, toObject } from "react-stockcharts/lib/utils";
import { discontinuousTimeScaleProvider } from "react-stockcharts/lib/scale";



import { TrendLine,FibonacciRetracement,EquidistantChannel, 
	 GannFan, StandardDeviationChannel, DrawingObjectSelector,
	 InteractiveText,  InteractiveYCoordinate } from "react-stockcharts/lib/interactive";



import {
	OHLCTooltip,
	MovingAverageTooltip,
	SingleValueTooltip,
	MACDTooltip,
	RSITooltip,
	StochasticTooltip,
	BollingerBandTooltip
} from "react-stockcharts/lib/tooltip";

import {
	saveInteractiveNodes,
	getInteractiveNodes,
} from "./interactiveutils";



import { getMorePropsForChart } from "react-stockcharts/lib/interactive/utils";
import { head } from "react-stockcharts/lib/utils";


import { ema, wma, sma, tma, macd, heikinAshi,renko, kagi, pointAndFigure,rsi,atr,
	stochasticOscillator, forceIndex,
	change, elderRay,
	bollingerBand, sar } from "react-stockcharts/lib/indicator";

import ReactDOM from "react-dom";

import { Flex } from "@chakra-ui/layout";
import { Image } from "@chakra-ui/image";

import {
  Checkbox,
  Text,
  Collapse,
  VStack,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';


import { Button, Select, Input, 
	 Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react';


import algo from "react-stockcharts/lib/algorithm";
import {
	Label,
	Annotate,
	SvgPathAnnotation,
	buyPath,
	sellPath,
} from "react-stockcharts/lib/annotation";
import glovar from "./glovar";




export default  (props) => {
	
	const textAppearance = {
	
		bgFill: "#D3D3D3",
		bgOpacity: 1,
		bgStrokeWidth: 1,
		textFill: "#AAAA40",
		fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
		fontSize: 12,
		fontStyle: "normal",
		fontWeight: "normal",
		text: "Lorem ipsum2..."
	
};


	const colorsModal = [
		'#FF5733', '#FFBD33', '#FF33C9', '#FF33A8', '#FF3381',
		'#33FF7A', '#33FFB3', '#33A9FF', '#3371FF', '#334BFF',
		'#33FFA8', '#33FF61', '#33B4FF', '#335DFF', '#3339FF',
		'#FF3388', '#FF3359', '#FFA833', '#FFE933', '#A1FF33',
		'#33E5FF', '#33FFD0', '#5A33FF', '#9D33FF', '#CC33FF'
	  ];
	
	  const bbStroke = {
		top: "#964B00",
		middle: "#000000",
		bottom: "#964B00",
	};
	
	const bbFill = "#4682B4";

	const stoAppearance = {
		stroke: Object.assign({},
			StochasticSeries.defaultProps.stroke)
	};
	
	const macdAppearance = {
		stroke: {
			macd: "#FF0000",
			signal: "#00F300",
		},
		fill: {
			divergence: "#4682B4"
		},
	};
	
	const sarAppearance = {
		
		fill: {
			falling: "#FF0000",
			rising: "#00FF00",
		},
		highlightOnHover: true
	};


const TYPE_CANDLESTICK=0, TYPE_HEIKIN_ASHI=1, TYPE_AREA_SERIES=2, TYPE_LINE_SERIES=3, TYPE_RENKO_SERIES=4, TYPE_KAGI_SERIES=5, TYPE_POINTFIGURE_SERIES=6;
const OFFSET_Y_CHART=60;
	
function saveCanvasNodeFibLocal (node) {
	//console.log("Ejecuto saveCanvasNode!!")
	//this.canvasNode = node;
	//console.log("Ejecuto node:"+node)
	if(node!=null && node!=undefined) {
		props.saveCanvasNodeFib(node);
		//console.log("Node salvat!")
	}
}

		return (
			<div style={props.containerStyle}>

<div style={{ position: 'absolute', zIndex: 2, opacity: 1 }}>

					{(props.refreshButtons || !props.refreshButtons) &&<Box p="0" marginLeft={2} display="flex">
      		<Flex overflowX="auto" flex={1} >
          	{props.iconData.map((data, index) => (
          	<Image
            key={index}
            src={data.url}
			//background={colorFondos[index] || "#f0f0f0"}
			background={(props.llistaClicked[data.id]  ? data.backclicked : data.back)}
			//background="red"
            //alt={`Icono ${index + 1}`}
            boxSize="23px"//"24px"
            mr="0.5"
            onClick={data.onClick}
            style={{ cursor: 'pointer' }}
            />
            ))}

	       

	  <Box borderRadius='md' bg= "#FFFFFF" ml={1} p={1} pt={0.5} >
          

		  <Flex align="center" onClick={props.toggleDropdownMA}>
          <Text color='black' fontSize='14' fontWeight='semibold' ml={1}
		  >MAvg</Text>
		  <img
            src={props.isOpenDropdown ? `${process.env.PUBLIC_URL}/assets/arrowup.png` : 
			`${process.env.PUBLIC_URL}/assets/arrowdown.png`}
            //alt={CollapseIsOpen1 ? 'Cerrar' : 'Abrir'}
            style={{ width: '16px', marginLeft: '6px', marginTop: '2px' }}
          />
        </Flex>

		

		  {props.isOpenDropdown &&
		        <Box>
		        <Box display="flex" alignItems="center" onClick={props.handleModalOpen} 
				style={{ cursor: 'pointer', marginTop:10,  background:"#E0E0E0", paddingBottom:"5px", paddingTop:"5px" }}>
                <img src={`${props.publicUrl}/assets/outadd_circle_black.png`} alt="Add" 
				   style={{ width: '16px', marginLeft: '6px', marginTop: '2px', cursor: 'pointer' }}  />
				
				<Text color='black' fontSize='14' fontWeight='semibold' ml={1}>
                New
				</Text>
				
                </Box>
			
				</Box> }
				


		  <Collapse in={props.isOpenDropdown} 
		style={{ position: 'absolute', zIndex: 2, paddingTop:14, background: 'rgba(255, 255, 255, 1)' }}
		 >
          <VStack align="start" spacing={4} >
            {props.maOptions.map((option, index) => (
              <Box display="flex" alignItems="center">
                <img src={`${props.publicUrl}/assets/garbage.png`} alt="Delete" onClick={() => props.handleOptionDelete(index)} 
				   style={{ width: '16px', marginLeft: '6px', marginTop: '2px', cursor: 'pointer' }}  />
				
				<Text color='black' fontSize='14' fontWeight='semibold' ml={1}>
                {option}
				</Text>
                </Box>

             
            ))}
          </VStack>
        </Collapse>

		

        <Modal isOpen={props.isModalOpen || props.isModalEdit} onClose={props.handleModalClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader> {props.isModalOpen ? "New Moving Average" : "Edit Moving Average"}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Select placeholder="Select a type of moving average" //value={props.selectedOption}
			   //onChange={(e) => this.setState({ selectedOption: e.target.value, /*newOptionNumber: ''*/ })}
			   onChange={(e) => props.setStateselectedOption(e.target.value)}
			   >
            
			
			    <option value="ema" selected={props.selectedOption === "ema"}>Exponential Moving Average</option>
                <option value="wma" selected={props.selectedOption === "wma"}>Weighted Moving Average</option>
                <option value="sma" selected={props.selectedOption === "sma"}>Simple Moving Average</option>
				<option value="tma" selected={props.selectedOption === "tma"}>Triangular Moving Average </option>
              </Select>
             
              <Input placeholder="Period" type="number" //value={props.newOptionNumber} 
			  //onChange={(e) => this.setState({ newOptionNumber: e.target.value, /*selectedOption: ''*/ })} 
			  onChange={(e) => props.setStatenewOptionNumber(e.target.value)}
			  />
			  <Input type="color" value={props.selectedColor} onChange={props.handleColorChange} />
			</ModalBody>
            <ModalFooter>
			{props.isModalOpen&&<Button background="black"  color="white" onClick={() => { props.handleCreateOption(); }} 
			disabled={props.isCreateButtonDisabled}>Create</Button>}
			
			{props.isModalEdit&&<Button background="black"  color="white" onClick={() => { props.editIndicator(props.editIndicatorIndex); }}>Update</Button>}

            <Button colorScheme="gray" marginLeft={4} onClick={props.handleModalClose}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>

	  <props.Dialog
					showModal={props.showModal}
					text={props.text}
					chartId={props.chartId}
					onClose={props.handleDialogClose}
					onSave={props.handleTextChange}
				/>

<props.Dialog2
					showModal={props.showModal3}
					alert={props.alertToEdit_alert}
					text={props.text}
					chartId={props.chartId}
					onClose={props.handleDialogClose3}
					onSave={props.handleChangeAlert3}
				/>


  <Box borderRadius='md' bg= "#FFFFFF" ml={1} p={1} pt={0.5} >
        <Flex align="center" onClick={props.toggleDropdown}>
          <Text color='black' fontSize='14' fontWeight='semibold' ml={1}
		  >{props.labelCollapse}</Text>
		  <img
            src={props.CollapseIsOpen1 ? `${process.env.PUBLIC_URL}/assets/arrowup.png` : 
			`${process.env.PUBLIC_URL}/assets/arrowdown.png`}
            //alt={CollapseIsOpen1 ? 'Cerrar' : 'Abrir'}
            style={{ width: '16px', marginLeft: '6px', marginTop: '2px' }}
          />
        </Flex>
        <Collapse in={props.CollapseIsOpen1} 
		style={{ position: 'absolute', zIndex: 2, marginTop:8, background: 'rgba(255, 255, 255, 1)' }}
		 >
          <VStack align="start" spacing={2} >
            {props.optionsCollapse.map((option,index) => (
              <Checkbox
			  colorScheme="gray"
                key={option}
                isChecked={props.selectedOptions1.includes(option)}
                onChange={() => props.toggleOption(option)}
              >
				<Text color='black' fontSize='14' fontWeight='semibold' ml={1}>
                {option}
				</Text>
              </Checkbox>
            ))}
          </VStack>
        </Collapse>
      </Box>
            </Flex>
            
		

			<Box position="relative" marginLeft="1">
        <Flex align="center"  onClick={props.toggleDropdown2}>
          <Image src={props.selectedOptions2} alt="Label" boxSize="30px" /> {/* Usar la imagen de la opción seleccionada */}
		  {1==2&&<img
            src={props.CollapseIsOpen1 ? `${process.env.PUBLIC_URL}/assets/arrowup.png` : 
			`${process.env.PUBLIC_URL}/assets/arrowdown.png`}
            //alt={CollapseIsOpen1 ? 'Cerrar' : 'Abrir'}
            style={{ width: '18px', marginLeft: '6px', marginTop: '2px' }}
          />}
        </Flex>
        <Collapse in={props.CollapseIsOpen2} style={{ position: 'absolute', zIndex: 1, }}>
          <VStack align="start"   bg="white">
            {props.optionsCollapse2.map((option,index) => (
              <Image
                key={option}
                src={option}
                alt="Option"
                boxSize="30px"
                cursor="pointer"
                onClick={() => props.selectOption2(option,index)}
                border={props.selectedOptions2 === option ? '2px solid lightgray' : 'none'}
              />
            ))}
          </VStack>
        </Collapse>
      </Box>

			

            <Box marginLeft="4" >
			<Box as='button' borderRadius='md' bg= {props.isDailyData ? "#BBBBBB" : "#F0F0F0"}
	        onClick={() => props.switchDataScale()}
			color='black' fontSize='11' fontWeight='bold' alignContent="center" textAlign="center" w={10} h={6} mr={1}>
			1D
			</Box>
			<Box as='button' borderRadius='md' bg= {!props.isDailyData ? "#BBBBBB" : "#F0F0F0"}
			onClick={() => props.switchDataScale()}
			color='black' fontSize='11' fontWeight='bold' alignContent="center" textAlign="center" w={10} h={6} mr={6}>
			1W
			</Box>
			</Box>


	



            </Box>}

			
			</div>

				
			<div style={props.backgroundStyle}></div>
			
			<div style={props.chartCanvasStyle}>

		
			<ChartCanvas 
			    ref={props.saveCanvasNodeFib}
			    height={450+props.OFFSET_Y_FULLSCREEN}
			    ratio={props.ratio} 
				width={props.width}
				margin={{ left: 50, right: 50, top: 10, bottom: 25 }}
				padding={{ left: 10, right: 10, top: 0, bottom: 0 }}
				
				seriesName=""
				data={props.data} 
				type={props.type}

				xScale={props.xScale}
				xAccessor={props.xAccessor}
				displayXAccessor={props.displayXAccessor}
				xExtents={props.xExtents}
                zoomAnchor={props.zoomAnchor}
				clamp={false}

				

				//xAccessor={d => d.date}
				//xScale={scaleTime()}

				//displayXAccessor={d => d.date}
				//xExtents={[new Date(2011, 0, 1), new Date(2013, 0, 2)]}
			>


                   <Label x={65} y={62} 
					fontSize="11" text={props.labelChart.substring(0, 25)} />



				<Chart id={1} yExtents={d => [d.high, d.low]} height={415-props.offsetYchart+props.OFFSET_Y_FULLSCREEN}>
				
				
				{(props.offsetYchart==0)&&<XAxis axisAt="bottom" orient="bottom" ticks={6}
				 	zoomEnabled={props.zoomEvent}
						{...props.xGrid} />}
					<YAxis axisAt="right" orient="right" ticks={5}
					zoomEnabled={props.zoomEvent}
						{...props.yGrid} />
				<MouseCoordinateY
						at="bottom"
						orient="right"
						displayFormat={format(".2f")}
					/>
					{props.selectedOptions1.includes('Volume Profile')&&<VolumeProfileSeries />}

					{(props.candleTypeChart==TYPE_CANDLESTICK ||props.candleTypeChart==TYPE_HEIKIN_ASHI) &&<CandlestickSeries />}
					{props.candleTypeChart==TYPE_AREA_SERIES&&<AreaSeries yAccessor={(d) => d.close} stroke="#000000" strokeWidth="2" fill="#AAAAAA"/>}
					{props.candleTypeChart==TYPE_LINE_SERIES&&<LineSeries yAccessor={(d) => d.close} stroke="#000000" strokeWidth="3" />}
					{props.candleTypeChart==TYPE_RENKO_SERIES&&<RenkoSeries />}
					{props.candleTypeChart==TYPE_KAGI_SERIES&&<KagiSeries />}
					{props.candleTypeChart==TYPE_POINTFIGURE_SERIES&&<PointAndFigureSeries />}

					{props.selectedOptions1.includes('SAR')&&<SARSeries yAccessor={d => d.sar} {...sarAppearance} />}

					{props.selectedOptions1.includes('Bollinger Band')&&<BollingerSeries yAccessor={d => d.bb}
						stroke={bbStroke}
						fill={bbFill} />}
				
				

{props.indicators.map((indicator, index) => (
              <LineSeries key={index} yAccessor={indicator.accessor()} stroke={indicator.options().color} />
            ))}

					{1==2&&<LineSeries yAccessor={props.sma20.accessor()} stroke={props.sma20.stroke()}/>}
					{1==2&&<LineSeries yAccessor={props.ema10.accessor()} stroke={props.ema10.stroke()}/>}
					{1==2&&<LineSeries yAccessor={props.ema30.accessor()} stroke={props.ema30.stroke()}/>}
					{1==2&&<LineSeries yAccessor={props.ema50.accessor()} stroke={props.ema50.stroke()}/>}
					
					{1==2&&<CurrentCoordinate yAccessor={props.sma20.accessor()} fill={props.sma20.stroke()} />}
					{1==2&&<CurrentCoordinate yAccessor={props.ema10.accessor()} fill={props.ema10.stroke()} />}
					{1==2&&<CurrentCoordinate yAccessor={props.ema30.accessor()} fill={props.ema30.stroke()} />}
					{1==2&&<CurrentCoordinate yAccessor={props.ema50.accessor()} fill={props.ema50.stroke()} />}

{props.indicators.map((indicator, index) => (
              <CurrentCoordinate key={index} yAccessor={indicator.accessor()} fill={indicator.stroke()} />
            ))}

					<EdgeIndicator itemType="last" orient="right" edgeAt="right"
						yAccessor={d => d.close} fill={d => d.close > d.open ? "#6BA583" : "#FF0000"}/>


					<OHLCTooltip origin={[-40, 0]}/>

					{props.selectedOptions1.includes('Bollinger Band')&&<BollingerBandTooltip
						origin={[-38, 50]}
						yAccessor={d => d.bb}
						options={props.bb.options()} />}

{props.selectedOptions1.includes('SAR')&&<SingleValueTooltip
						yLabel={`SAR (${props.accelerationFactor}, ${props.maxAccelerationFactor})`}
						yAccessor={d => d.sar}
						origin={[-38, 70]}/>}


					{props.indicators.map((indicator, index) => (
    <MovingAverageTooltip
      key={`tooltip-${index}`}
      //onClick={() => this.removeIndicator(index)} // Opcional: Agrega la función de eliminación
	  onClick={() => props.handleModalEdit(index)} 
      //origin={[0, 15 * (index + 1)]} // Ajusta la posición vertical del tooltip
	  origin={[-38+(66* (index + 0)), 15]} // Ajusta la posición horizontal del tooltip
      //options={indicator.options()} // Pasar opciones del indicador para el tooltip

	  options={[
		
		{
			yAccessor: indicator.accessor(),
			//type: "EMA",
			type: indicator.options().type.toUpperCase(),
			stroke: indicator.options().color,//indicator.stroke(),
			windowSize: indicator.options().windowSize,
			echo: "some echo here",
		},
		
	]}

      yAccessor={indicator.accessor()} // Definir el accessor del indicador
      appearance={{
        stroke: indicator.options().color,//indicator.type === 'ema' ? 'blue' : 'green',
        strokeOpacity: 1,
        strokeWidth: 2,
      }}
    />
  ))}


					
					
						
					{props.showZoom&&<ZoomButtons
						onReset={props.handleReset}
					/>}


{props.selectedOptions1.includes('MA Crossover')&&<LineSeries yAccessor={props.ema20.accessor()} stroke={props.ema20.stroke()}/>}
{props.selectedOptions1.includes('MA Crossover')&&<LineSeries yAccessor={props.ema50.accessor()} stroke={props.ema50.stroke()}/>}


{props.selectedOptions1.includes('MA Crossover')&&<Annotate with={SvgPathAnnotation} when={d => d.longShort === "LONG"}
						usingProps={props.longAnnotationProps} />}
					{props.selectedOptions1.includes('MA Crossover')&&<Annotate with={SvgPathAnnotation} when={d => d.longShort === "SHORT"}
						usingProps={props.shortAnnotationProps} />}


<TrendLine
						ref={props.saveInteractiveNodes("Trendline", 1)}
						enabled={props.enableTrendLine}
						type="RAY"
						snap={false}
						snapTo={d => [d.high, d.low]}
						//onStart={() => console.log("START")}
						onComplete={props.onDrawCompleteChart1}
						trends={props.trends_1}
					/>


                    {<FibonacciRetracement
						ref={props.saveInteractiveNodes("FibonacciRetracement", 1)}
						enabled={props.enableFib}
						retracements={props.retracements_1}
						onComplete={props.onFibComplete1}
					/>}


						<EquidistantChannel
						ref={props.saveInteractiveNodes("EquidistantChannel", 1)}
						enabled={props.enableChannel}
						//onStart={() => console.log("START")}
						onComplete={props.onDrawCompleteChannel}
						channels={props.channels_1}
					/>


						<GannFan
						ref={props.saveInteractiveNodes("GannFan", 1)}
						enabled={props.enableGann}
						//onStart={() => console.log("START")}
						onComplete={props.onDrawCompleteGann}
						fans={props.fans}
					/>

						{1==1&&<StandardDeviationChannel
						ref={props.saveInteractiveNodes("StandardDeviationChannel", 1)}
						enabled={props.enableStddev}
						//onStart={() => console.log("START")}
						onComplete={props.onDrawCompleteStddev}
						channels={props.stddev_1}
					/>}

                         <InteractiveText
							ref={props.saveInteractiveNodes("InteractiveText", 1)}
							enabled={props.enableInteractiveText}
							text={props.text}//"Lorem ipsum..."
							onDragComplete={props.onDrawCompleteText}
							//onComplete={this.onDrawCompleteText}
							textList={props.textList_1}
							appearance={textAppearance}
							fill="blue" // Cambiar el color del texto a azul				
						/>


							<InteractiveYCoordinate
							ref={props.saveInteractiveNodes("InteractiveYCoordinate", 1)}
							enabled={props.enableInteractiveY}
							onDragComplete={props.onDragComplete3}
							onDelete={props.onDelete3}
							yCoordinateList={props.yCoordinateList_1}
						/>

							{1==1&&<InteractiveText
							ref={props.saveInteractiveNodes("InteractiveText1", 1)}
							enabled={props.enableArrowUp}
							text={props.text}//"Lorem ipsum..."
							onDragComplete={props.onDrawCompleteArrowUp}
							//onComplete={this.onDrawCompleteText}
							textList={props.arrowUpList_1}
							appearance={textAppearance}
							fill="blue" // Cambiar el color del texto a azul				
						/>}

						   {1==1&&<InteractiveText
							ref={props.saveInteractiveNodes("InteractiveText1", 1)}
							enabled={props.enableArrowDown}
							text={props.text}//"Lorem ipsum..."
							onDragComplete={props.onDrawCompleteArrowDown}
							//onComplete={this.onDrawCompleteText}
							textList={props.arrowDownList_1}
							appearance={textAppearance}
							fill="blue" // Cambiar el color del texto a azul				
						/>}


				</Chart>


				{(props.selectedOptions1.includes('Volume')) &&<Chart id={2} origin={(w, h) => [0, h - 60-props.offsetYchart]} height={60} yExtents={d => d.volume}>
					<YAxis axisAt="left" orient="left" ticks={4} tickFormat={format(".2s")}
					zoomEnabled={props.zoomEvent}/>
					<BarSeries yAccessor={d => d.volume} fill={(d) => d.close > d.open ? "#888888" : "#CCCCCC"} />
					

				</Chart>}

				{(props.selectedOptions1.includes('MACD')) &&<Chart id={33} height={OFFSET_Y_CHART}
					yExtents={props.macdCalculator.accessor()}
					origin={(w, h) => [0, h - props.offsetYchart]} padding={{ top: 20, bottom: 0 }}
				>
					<XAxis axisAt="bottom" orient="bottom"
					showTicks={!((props.selectedOptions1.includes('ATR') 
					|| (props.selectedOptions1.includes('Stochastic Oscillator'))
					|| (props.selectedOptions1.includes('ForceIndex 1'))
					|| (props.selectedOptions1.includes('ForceIndex 13'))
					|| (props.selectedOptions1.includes('RSI'))
					|| (props.selectedOptions1.includes('Elder Ray'))
					))} 
					/>
					<YAxis axisAt="right" orient="right" ticks={2} />

					<MouseCoordinateX
						at="bottom"
						orient="bottom"
						displayFormat={timeFormat("%Y-%m-%d")} />
					<MouseCoordinateY
						at="right"
						orient="right"
						displayFormat={format(".2f")} />
					
					<MACDSeries yAccessor={d => d.macd}
						{...macdAppearance} />
					<MACDTooltip
						origin={[-38, 15]}
						yAccessor={d => d.macd}
						options={props.macdCalculator.options()}
						appearance={macdAppearance}
					/>
				</Chart>}

				{(props.selectedOptions1.includes('RSI')) &&<Chart id={34}
					height={OFFSET_Y_CHART}
					yExtents={props.rsiCalculator.accessor()}
					origin={(w, h) => [0, h - (OFFSET_Y_CHART
						+(OFFSET_Y_CHART*(props.selectedOptions1.includes('ATR')))
						+(OFFSET_Y_CHART*(props.selectedOptions1.includes('Stochastic Oscillator')))
						+(OFFSET_Y_CHART*(props.selectedOptions1.includes('ForceIndex 1')))
						+(OFFSET_Y_CHART*(props.selectedOptions1.includes('ForceIndex 13'))) //two graphics
						+(OFFSET_Y_CHART*(props.selectedOptions1.includes('Elder Ray')))
						//+(OFFSET_Y_CHART*(props.selectedOptions1.includes('xxx4')))
						//+(OFFSET_Y_CHART*(props.selectedOptions1.includes('xxx5')))
						
						)]} 
					
					padding={{ top: 20, bottom: 0 }}

				>
					<XAxis axisAt="bottom" orient="bottom" 
					//showTicks={true}
					showTicks={!((props.selectedOptions1.includes('ATR') 
					|| (props.selectedOptions1.includes('Stochastic Oscillator'))
					|| (props.selectedOptions1.includes('ForceIndex 1'))
					|| (props.selectedOptions1.includes('ForceIndex 13'))
					|| (props.selectedOptions1.includes('Elder Ray'))
					//|| (props.selectedOptions1.includes('ATR3'))
					//|| (props.selectedOptions1.includes('ATR4'))
					//|| (props.selectedOptions1.includes('ATR5'))
					))} 
					outerTickSize={0} />
					<YAxis axisAt="right"
						orient="right"
						tickValues={[30, 50, 70]}/>
					<MouseCoordinateY
						at="right"
						orient="right"
						displayFormat={format(".2f")} />

					<RSISeries yAccessor={d => d.rsi} />

					<RSITooltip origin={[-38, 15]}
						yAccessor={d => d.rsi}
						options={props.rsiCalculator.options()} />
				</Chart>}


				{(props.selectedOptions1.includes('ATR')) &&<Chart id={35}
				height={OFFSET_Y_CHART}
				yExtents={props.atr14.accessor()}
				origin={(w, h) => [0, h - (OFFSET_Y_CHART
					//+(OFFSET_Y_CHART*(props.selectedOptions1.includes('ATR')))
					+(OFFSET_Y_CHART*(props.selectedOptions1.includes('Stochastic Oscillator')))
					+(OFFSET_Y_CHART*(props.selectedOptions1.includes('ForceIndex 1')))
					+(OFFSET_Y_CHART*(props.selectedOptions1.includes('ForceIndex 13'))) //two graphics
					+(OFFSET_Y_CHART*(props.selectedOptions1.includes('Elder Ray')))
					//+(OFFSET_Y_CHART*(props.selectedOptions1.includes('xxx5')))
					)]} 
					padding={{ top: 20, bottom: 0 }}
				>
					<XAxis axisAt="bottom" orient="bottom" 
					showTicks={!((props.selectedOptions1.includes('ATR0') 
					|| (props.selectedOptions1.includes('Stochastic Oscillator'))
					|| (props.selectedOptions1.includes('ForceIndex 1'))
					|| (props.selectedOptions1.includes('ForceIndex 13'))
					|| (props.selectedOptions1.includes('Elder Ray'))
				
					//|| (props.selectedOptions1.includes('ATR5'))
					))} 
					/>
					<YAxis axisAt="right" orient="right" ticks={2}/>

					<MouseCoordinateX
						at="bottom"
						orient="bottom"
						displayFormat={timeFormat("%Y-%m-%d")} />
					<MouseCoordinateY
						at="right"
						orient="right"
						displayFormat={format(".2f")} />

					<LineSeries yAccessor={props.atr14.accessor()} stroke={props.atr14.stroke()}/>
					<SingleValueTooltip
						yAccessor={props.atr14.accessor()}
						yLabel={`ATR (${props.atr14.options().windowSize})`}
						yDisplayFormat={format(".2f")}
						
						origin={[-40, 15]}/>
				</Chart>}


				{(props.selectedOptions1.includes('Stochastic Oscillator')) &&<Chart id={36}
					height={OFFSET_Y_CHART}
					yExtents={props.fullSTO.accessor()}
					origin={(w, h) => [0, h - (OFFSET_Y_CHART
						//+(OFFSET_Y_CHART*(props.selectedOptions1.includes('ATR')))
						//+(OFFSET_Y_CHART*(props.selectedOptions1.includes('Stochastic Oscillator')))
						+(OFFSET_Y_CHART*(props.selectedOptions1.includes('ForceIndex 1')))
						+(OFFSET_Y_CHART*(props.selectedOptions1.includes('ForceIndex 13'))) //two graphics
						+(OFFSET_Y_CHART*(props.selectedOptions1.includes('Elder Ray')))
						
						//+(OFFSET_Y_CHART*(props.selectedOptions1.includes('xxx5')))
						
						)]} 
				>
					<XAxis axisAt="bottom" orient="bottom" {...props.xGrid}
					showTicks={!((props.selectedOptions1.includes('ATR0') 
					//|| (props.selectedOptions1.includes('Stochastic Oscillator'))
					|| (props.selectedOptions1.includes('ForceIndex 1'))
					|| (props.selectedOptions1.includes('ForceIndex 13'))
					|| (props.selectedOptions1.includes('Elder Ray'))
					//|| (props.selectedOptions1.includes('ATR3'))
					//|| (props.selectedOptions1.includes('ATR4'))
					//|| (props.selectedOptions1.includes('ATR5'))
					))} 
					/>
					<YAxis axisAt="right" orient="right"
						tickValues={[20, 50, 80]} />

					<MouseCoordinateX
						at="bottom"
						orient="bottom"
						displayFormat={timeFormat("%Y-%m-%d")} />
					<MouseCoordinateY
						at="right"
						orient="right"
						displayFormat={format(".2f")} />
					<StochasticSeries
						yAccessor={d => d.fullSTO}
						{...stoAppearance} />

					<StochasticTooltip
						origin={[-38, 15]}
						yAccessor={d => d.fullSTO}
						options={props.fullSTO.options()}
						appearance={stoAppearance}
						label="Full STO" />
				</Chart>}


				{(props.selectedOptions1.includes('ForceIndex 1')) &&<Chart id={37} 
				    height={OFFSET_Y_CHART}
					yExtents={props.fi.accessor()}
					padding={{ top: 20, bottom: 0 }}
					origin={(w, h) => [0, h - (OFFSET_Y_CHART*1
						//+(OFFSET_Y_CHART*(props.selectedOptions1.includes('ATR')))
						+(OFFSET_Y_CHART*(props.selectedOptions1.includes('ForceIndex 13')))
						+(OFFSET_Y_CHART*(props.selectedOptions1.includes('Elder Ray')))
						
						//+(OFFSET_Y_CHART*(props.selectedOptions1.includes('xxx5')))
						
						)]} 

				>
					<XAxis axisAt="bottom" orient="bottom" 
					showTicks={!((props.selectedOptions1.includes('ATR0') 
					//|| (props.selectedOptions1.includes('Stochastic Oscillator'))
					|| (props.selectedOptions1.includes('ForceIndex 13'))
					|| (props.selectedOptions1.includes('Elder Ray'))
					))} 

					outerTickSize={0} />
					<YAxis axisAt="right" orient="right" ticks={4} tickFormat={format(".2s")}/>
					<MouseCoordinateY
						at="right"
						orient="right"
						displayFormat={format(".4s")} />

					<AreaSeries baseAt={scale => scale(0)} yAccessor={props.fi.accessor()} />
					<StraightLine yValue={0} />

					<SingleValueTooltip
						yAccessor={props.fi.accessor()}
						yLabel="ForceIndex (1)"
						yDisplayFormat={format(".4s")}
						origin={[-40, 15]}
					/>
				</Chart>}
				{(props.selectedOptions1.includes('ForceIndex 13')) &&<Chart id={38} 
				    yExtents={props.fiEMA13.accessor()}
					height={OFFSET_Y_CHART}
					
					
					padding={{ top: 20, bottom: 0 }}
					origin={(w, h) => [0, h - (OFFSET_Y_CHART*1
						//+(OFFSET_Y_CHART*(props.selectedOptions1.includes('ATR')))
						//+(OFFSET_Y_CHART*(props.selectedOptions1.includes('Stochastic Oscillator')))
						+(OFFSET_Y_CHART*(props.selectedOptions1.includes('Elder Ray')))
						
						//+(OFFSET_Y_CHART*(props.selectedOptions1.includes('xxx5')))
						
						)]} 
				>
					<XAxis axisAt="bottom" orient="bottom"
					showTicks={!((props.selectedOptions1.includes('Elder Ray') 
					//|| (props.selectedOptions1.includes('Stochastic Oscillator'))
					
					//|| (props.selectedOptions1.includes('ATR5'))
					))}  />
					<YAxis axisAt="right" orient="right" ticks={4} tickFormat={format(".2s")}/>

					<MouseCoordinateX
						at="bottom"
						orient="bottom"
						displayFormat={timeFormat("%Y-%m-%d")} />
					<MouseCoordinateY
						at="right"
						orient="right"
						displayFormat={format(".4s")} />

					{}
					<AlternatingFillAreaSeries
						baseAt={0}
						yAccessor={props.fiEMA13.accessor()}
					/>
					<StraightLine yValue={0} />

					<SingleValueTooltip
						yAccessor={props.fiEMA13.accessor()}
						yLabel={`ForceIndex (${props.fiEMA13.options().windowSize})`}
						yDisplayFormat={format(".4s")}
						origin={[-40, 15]}
					/>
				</Chart>}

				{(props.selectedOptions1.includes('Elder Ray')) &&<Chart id={39} 
					yExtents={[0, props.elder.accessor()]}
					height={OFFSET_Y_CHART}
					
					
					padding={{ top: 20, bottom: 0 }}
					origin={(w, h) => [0, h - (OFFSET_Y_CHART
						)]} 
				>
					<XAxis axisAt="bottom" orient="bottom" 
					showTicks={true} //pq es el últim indicador 
					outerTickSize={0} />
					<YAxis axisAt="right" orient="right" ticks={4} tickFormat={format(".2f")}/>

					<MouseCoordinateY
						at="right"
						orient="right"
						displayFormat={format(".2f")} />

					<ElderRaySeries yAccessor={props.elder.accessor()} />
					<SingleValueTooltip
						yAccessor={props.elder.accessor()}
						yLabel="Elder Ray"
						yDisplayFormat={d => `${format(".2f")(d.bullPower)}, ${format(".2f")(d.bearPower)}`}
						origin={[-40, 15]}/>
				</Chart>}

				<CrossHairCursor />

				


				<DrawingObjectSelector
						enabled={!(props.enableTrendLine && props.enableFib && props.enableGann 
							&& props.enableChannel && props.enableStddev)}
						getInteractiveNodes={props.getInteractiveNodes}
						drawingObjectMap={{
							FibonacciRetracement: "retracements",
							Trendline: "trends",
							EquidistantChannel: "channels",
							GannFan: "fans",
							StandardDeviationChannel: "channels",
							//InteractiveText: "textList"
							//StandardDeviationChannel: "stddev",
						}}
						onSelect={props.handleSelection}
					/>

						<DrawingObjectSelector
						enabled={!(props.enableInteractiveText)}
						getInteractiveNodes={props.getInteractiveNodes}
						drawingObjectMap={{
							InteractiveText: "textList"
						}}
						onSelect={props.handleSelection2}
					/>

						{1==1&&<DrawingObjectSelector
						enabled={!(props.enableArrowUp)}
						getInteractiveNodes={props.getInteractiveNodes}
						drawingObjectMap={{
							InteractiveText1: "arrowUpList"
						}}
						onSelect={props.handleSelectionUP}
						/>}

						{1==1&&<DrawingObjectSelector
						enabled={!(props.enableArrowDown)}
						getInteractiveNodes={props.getInteractiveNodes}
						drawingObjectMap={{
							InteractiveText1: "arrowDownList"
						}}
						onSelect={props.handleSelectionDown}
						/>}


						<DrawingObjectSelector
						enabled={!(props.enableInteractiveY)}
						getInteractiveNodes={props.getInteractiveNodes}
						drawingObjectMap={{
							InteractiveYCoordinate: "yCoordinateList"
						}}
						onSelect={props.handleSelection3}
						
						onDoubleClick={props.handleDoubleClickAlert3}
					/>

					
				{1==2&&<DrawingObjectSelector
					enabled={!props.enableFib}
					getInteractiveNodes={props.getInteractiveNodes}
					drawingObjectMap={{
						FibonacciRetracement: "retracements"
					}}
					onSelect={props.handleSelectionFib}
				/>}

					{1==2&&<DrawingObjectSelector
					enabled={!props.enableTrendLine}
					getInteractiveNodes={props.getInteractiveNodes}
					drawingObjectMap={{
						Trendline: "trends"
					}}
					onSelect={props.handleSelection}
				/>}

					
			</ChartCanvas>
			</div>
      </div>
		);
		}

