import {useState, useEffect} from 'react'
import glovar from '../content/glovar';
import { setMYWATCHLIST,setMOREPOPULAR } from '../../utils/Common';


export const useFetchTodoRadar = (screener,MODE_USER_FECTH) => {

  const [todo, setTodo] = useState(null);
  const [isFetching, setIsFetching] = useState(true);
 
  useEffect(() => {
    //console.log("useFetchTodo MODE_USER_FECTH:"+ARRAY_COTIS)
    var TIRAR_DE_CACHE=false;

   
    if(!TIRAR_DE_CACHE) 
    {
      //if(MODE_USER_FECTH==0) { console.log("download morepopular!!!")}
      //if(MODE_USER_FECTH==1) { console.log("download watchlist!!!")}
 
    let headers = new Headers(); 
   headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API_COTIS + ":" + glovar.PASS_API_COTIS));
   headers.set('Parameters',btoa(screener));


   fetch(process.env.REACT_APP_URL_FETCH_RADAR,{ method:'GET', //mode: 'no-cors',
   headers: headers,
   })
  .then(res => res.json())
  .then(res => {
     //console.log("res:"+res)
    if(res=="auth/user-not-found" || res=="auth/wrong-password") {
      //try { setError("ERROR: "+i18n.t("email_try_again"));  } catch(Exx) {setError("ERROR: The email or password you entered is incorrect. Please try again.");   }
      return;
    }

    if(res=="auth/Unauthorized" || res=="auth/too-many-requests") {
      //setError("ERROR: Unauthorized.");  //Please try again or contact support if you are unable to access your account");
      return;
    }

    setTodo(res)//.quoteResponse.result)

    /*
    if(MODE_USER_FECTH==0) {
      setMOREPOPULAR(res)
      try { glovar.MOREPOPULAR_COTIS=res;} catch(exxx) {}
    }
    else if(MODE_USER_FECTH==1) {
      setMYWATCHLIST(res)
      try { glovar.MYWATCHLIST_COTIS=res;} catch(exxx) {}
    }*/
  })
  .catch((e) => {
    //console.log(e);
   })
   .finally(() => {
    setIsFetching(false);
   });
  }
 


  },[]);
 
 return { todo, isFetching }
 
 }
 