import {useState, useEffect} from 'react'
import glovar from '../content/glovar';


export const useFetchTodoToptraders = () => {

 
  /*
   let isFetchingTopTraders = false;
    ];*/

   const [todoTopTraders, setTodo] = useState([]);
   const [isFetchingTopTraders, setIsFetching] = useState(true);
 
  useEffect(() => {

    var TIRAR_DE_CACHE=false;

  try {
    if(glovar.TOPTRADERSLIST.length>10) {
    //console.log("glovar.TOPTRADERSLIST:"+glovar.TOPTRADERSLIST)
    TIRAR_DE_CACHE=true;
    setTodo(glovar.TOPTRADERSLIST)
    setIsFetching(false)
    }
  }
  catch(Exx) {}

  if(!TIRAR_DE_CACHE) 
  {
    let headers = new Headers(); 
    headers.set('Authorization', 'Basic ' + btoa(glovar.USER_API + ":" + glovar.PASS_API));
  
    
    fetch(glovar.URL_BACKEND_PROD+'/api/toptraders', { method:'GET', //mode: 'no-cors',
   
  headers: headers,
       //'mode': 'no-cors',
       //'headers': {
       //    'Access-Control-Allow-Origin': '*',
      // }
   }).then(response => response.json())
   .then(json => {

     //Aqui tenemos que ordenarlo por rent.
    try { json.sort(function(a,b){ return parseFloat(b.rentab)  - parseFloat(a.rentab);}) }
    catch(Exxpp) {}
    
    setTodo(json)
   })
   .catch((e) => {
    //console.log(e);
   })
   .finally(() => {
    setIsFetching(false);
   });
  }


  },[]);
 
 return { todoTopTraders, isFetchingTopTraders }
   

 //return { todoTopTraders }
 
 }
 

 